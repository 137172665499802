import { useContext } from 'react';
import { AppContext } from '../context/App/AppContext';
import { Route } from 'react-router-dom';
import AdminSelectGym from '../containers/ManageGym/AdminSelectGym/AdminSelectGym';
import AdminAddGym from '../containers/ManageGym/AddEditGym/AddEditGym';
import AdminTrainerDashboard from '../containers/AdminDashboard/AdminTrainerDashboard';
import ManageAdmins from '../containers/ManageUsers/ManageAdmins/ManageAdmins';
import AddEditAdmin from '../containers/ManageUsers/ManageAdmins/AddEditAdmin/AddEditAdmin';
import ManageTrainers from '../containers/ManageUsers/ManageTrainers/ManageTrainers';
import AddEditTrainer from '../containers/ManageUsers/ManageTrainers/AddEditTrainer/AddEditTrainer';
import Container from '../hoc/Container/Container';
import ManageClients from '../containers/ManageUsers/ManageClients/ManageClients';
import ClientProfile from '../containers/AdminDashboard/ClientProfile/ClientProfile';
import StaffCalendar from '../containers/ManageStaffShifts/StaffCalendar/StaffCalendar';
import ManageGym from '../containers/ManageGym/ManageGym';
import DatabaseSearches from '../containers/AdminDashboard/DatabaseSearches/DatabaseSearches';
import BulkEditing from '../containers/BulkEditing/BulkEditing';
import ManageReports from '../containers/ManageReports/ManageReports';

const useAdminTrainerRoutes = () => {
  const { userData } = useContext(AppContext);

  return userData.isAdminOrTrainer ? (
    <>
      {/* MANAGE GYM */}
      <Route path="/gym" element={<AdminSelectGym />} />
      <Route
        path="/add-gym"
        element={
          <Container show={userData.isAdmin}>
            <AdminAddGym />
          </Container>
        }
      />
      <Route
        path="/gym/:gymId"
        element={
          <Container>
            <AdminTrainerDashboard />
          </Container>
        }
      />
      <Route
        path="/gym/:gymId/settings"
        element={
          <Container show={userData.isAdmin} dataCy={'admins'}>
            <ManageGym />
          </Container>
        }
      />
      {/* MANAGE ADMINS */}
      <Route
        path={`/gym/:gymId/admins`}
        element={
          <Container show={userData.isAdmin} dataCy={'admins'}>
            <ManageAdmins />
          </Container>
        }
      />
      <Route
        path={`/gym/:gymId/admins/add`}
        element={
          <Container show={userData.isAdmin} dataCy={'admins'}>
            <AddEditAdmin />
          </Container>
        }
      />
      {/* MANAGE TRAINERS */}
      <Route
        path={`/gym/:gymId/trainers`}
        element={
          <Container show={userData.isAdmin}>
            <ManageTrainers />
          </Container>
        }
      />
      <Route
        path={`/gym/:gymId/trainers/add`}
        element={
          <Container show={userData.isAdmin}>
            <AddEditTrainer />
          </Container>
        }
      />
      <Route
        path={`/gym/:gymId/staff-calendar`}
        element={
          <Container>
            <StaffCalendar />
          </Container>
        }
      />
      {/* MANAGE CLIENTS */}
      <Route
        path={`/gym/:gymId/clients`}
        element={
          <Container>
            <ManageClients allGymsClients={false} />
          </Container>
        }
      />
      <Route
        path={`/gym/:gymId/clients/:clientId`}
        element={
          <Container>
            <ClientProfile />
          </Container>
        }
      />
      {/* DATABASE SEARCH */}
      <Route
        path={`/gym/:gymId/search`}
        element={
          <Container show={userData.isAdmin}>
            <DatabaseSearches />
          </Container>
        }
      />
      {/* REPORT */}
      <Route
        path={`/gym/:gymId/reports`}
        element={
          <Container show={userData.isAdmin}>
            <ManageReports />
          </Container>
        }
      />

      {/* BULK MANAGEMENT */}
      <Route
        path={`/gym/:gymId/bulk-management`}
        element={
          <Container show={userData.isAdmin}>
            <BulkEditing />
          </Container>
        }
      />

      {/* COUPONS */}
      {/*  <Route
        path={`/gym/:gymId/coupons`}
        element={
          <Container show={userData.isAdmin}>
            <ManageCoupons membershipPlans={props.plansList} />
          </Container>
        }
      />*/}

      {/* VIDEOS */}
      {/*        <Route
                      path={`${props.match.url}/video-passes`}
                      exact
                      render={() =>
                          props.selectedGym.settings.videoOnDemand ? <ManageTimetables classType={CLASS.VIDEO} /> : null
                      }
                  />
                  <Route
                      path={`${props.match.url}/videos/:videoId`}
                      exact
                      render={({ match }) => <Viewer videoId={match.params.videoId} />}
                  />

                  <Route
                      path={`${props.match.url}/videos/live-stream`}
                      exact
                      render={() => <LiveStreamBroadcaster userData={props.userData} />}
                  />*/}
      {/*
                  <Route
                    path={`${props.match.url}/video-passes`}
                    exact
                    render={() => (props.gym.settings.videoOnDemand ? <ManagePasses classType={CLASS.VIDEO} /> : null)}
                  />
               */}
    </>
  ) : null;
};

export default useAdminTrainerRoutes;
