import { useState } from 'react';
import { Checkbox } from 'antd';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Button from '../../../../components/UI/Button/Button';

const StepOne_ClientSelector = ({ attendanceList, onSelect, nextStep, onCloseModal }) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const clients = attendanceList.filter((elem) => ['active', 'pending', 'attended', 'absent'].includes(elem.status));
  const singleClient = (client) => (
    <div className="block my-2">
      <Checkbox checked={selectedClient && selectedClient._id === client._id} onChange={() => onSelectClient(client)}>
        {client.name} {client.last_name}
      </Checkbox>
    </div>
  );
  const onSelectClient = (client) => {
    onSelect(client);
    setSelectedClient(client);
  };
  const handleNextBtn = () => {
    nextStep(2);
  };

  const returnClients =
    clients.length > 0 ? <div>{clients.map((elem) => singleClient(elem.user))}</div> : <div>No clients</div>;

  return (
    <>
      <div className="mb-2">Please select client:</div>
      {returnClients}
      <ModalButtonsContainer>
        <Button secondary onClick={() => handleNextBtn()} disabled={selectedClient === null} label={'Next step'} />
        <Button
          onClick={() => {
            onCloseModal();
            setSelectedClient(null);
          }}
          label={'Cancel'}
        />
      </ModalButtonsContainer>
    </>
  );
};

export default StepOne_ClientSelector;
