import { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { birthdayQuotes } from './birthdayQuotes';
import moment from 'moment';
import { AppContext } from '../../context/App/AppContext';
import Icon from '../UI/Icon/Icon';

const BirthdayWishesModal = () => {
  const {
    userData: { dateofbirth }
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const randomQuoteIndex = Math.floor(Math.random() * birthdayQuotes.length);

  useEffect(() => {
    if (
      moment(dateofbirth, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD')) &&
      localStorage.getItem('birthdayDisplayed') === null
    ) {
      setShowModal(true);
    }
  }, [dateofbirth]);

  const onClose = () => {
    localStorage.setItem('birthdayDisplayed', 'true');
    setShowModal(false);
  };

  return (
    <>
      <Modal open={showModal} onCancel={onClose}>
        <div className="text-center text-xl font-bold">
          <Icon name={'birthday'} className={'mx-auto'} size={50} />
          <div>{birthdayQuotes[randomQuoteIndex]}</div>
          <div className={'text-2xl mt-4'}>Happy birthday!</div>
        </div>
      </Modal>
    </>
  );
};

export default BirthdayWishesModal;
