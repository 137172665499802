import { useContext, useState } from 'react';
import Icon from '../../../components/UI/Icon/Icon';
import { useMutation } from '@tanstack/react-query';
import { deleteFAQ, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { returnFirstErrorMsg } from '../../../shared/utility';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Button from '../../../components/UI/Button/Button';
import { Modal } from 'antd';
import AddEditFAQ from '../AddEditFAQ/AddEditFAQ';

const SingleFAQ = ({ singleFAQ, isAdmin = false, gymId }) => {
  const { openNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(true);

  const { mutate: onDelete, isLoading: onDeleteIsLoading } = useMutation(() => deleteFAQ(singleFAQ._id), {
    onSuccess: () => {
      setShowModal(false);
      openNotification('delete', 'success');
      refreshFetchFunctions([['faq', gymId]]);
    },
    onError: (err) => {
      openNotification(null, 'error', returnFirstErrorMsg(err));
    }
  });
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="listItem grid grid-cols-[1fr_80px]">
      <Modal open={showModal} onCancel={handleCloseModal}>
        {isEditModal ? (
          <AddEditFAQ onCancel={handleCloseModal} selectedFAQ={singleFAQ} />
        ) : (
          <>
            <div className="text-center">Do you want to delete below FAQ?</div>
            <div className="font-bold mt-2.5">{singleFAQ.question}</div>
            <div>{singleFAQ.answer}</div>
            <ModalButtonsContainer isLoading={onDeleteIsLoading}>
              <Button label={'Delete'} secondary onClick={onDelete} />
              <Button label="Cancel" primary onClick={handleCloseModal} />
            </ModalButtonsContainer>
          </>
        )}
      </Modal>
      <div>
        <h4>{singleFAQ.question}</h4>
        <p>{singleFAQ.answer}</p>
      </div>

      {isAdmin && (
        <div className="flex">
          <Icon
            name="edit"
            onClick={() => {
              setShowModal(true);
              setIsEditModal(true);
            }}
          />
          <Icon
            name="delete"
            onClick={() => {
              setShowModal(true);
              setIsEditModal(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SingleFAQ;
