import { useState } from 'react';
import classes from './CalendarHeader.module.scss';
import Icon from '../UI/Icon/Icon';
import moment from 'moment';
import { returnDate } from '../../shared/date-utility';

const CalendarHeader = ({
  startDate = moment(),
  daysJumpValue = 7,
  hasNext = true,
  hasPrevious = true,
  onLeftArrow = () => {},
  onRightArrow = () => {},
  show = false,
  currentDay = null
}) => {
  const [currentStartDate, setCurrentDate] = useState(startDate);

  const countEndDate = (date) => moment(date, 'YYYY-MM-DD').add(daysJumpValue, 'd').format('DD/MM');
  const onLeftArrowClick = () => {
    onLeftArrow();
    setCurrentDate((state) => moment(state, 'YYYY-MM-DD').subtract(daysJumpValue, 'd'));
  };
  const onRightArrowClick = () => {
    onRightArrow();
    setCurrentDate((state) => moment(state, 'YYYY-MM-DD').add(daysJumpValue, 'd'));
  };

  const leftArrow = hasPrevious ? <Icon name="previous" onClick={onLeftArrowClick} /> : null;
  const rightArrow = hasNext ? <Icon name="next" onClick={onRightArrowClick} /> : null;
  const date =
    daysJumpValue === 1 ? (
      <h3>
        {moment(currentDay != null ? currentDay : currentStartDate, 'YYYY-MM-DD')
          .format('dddd')
          .toUpperCase()}{' '}
        - {currentDay != null ? returnDate(currentDay) : returnDate(currentStartDate)}
      </h3>
    ) : (
      <h3>
        {returnDate(currentStartDate)} - {countEndDate(currentStartDate)}
      </h3>
    );

  if (!show) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div>{leftArrow}</div>
      <div>{date}</div>
      <div>{rightArrow}</div>
    </div>
  );
};

export default CalendarHeader;
