import { useState } from 'react';
import classes from './SingleClass.module.scss';
import ClassActionButtons from '../../../../ActionButtons/ClassActionButtons/ClassActionButtons';
import ReturnClassPrice from '../../../HelperComponents/ReturnClassPrice/ReturnClassPrice';
import EmptyDiv from '../../../../../components/UI/EmptyDiv/EmptyDiv';
import { Modal } from 'antd';
import Icon from 'antd/es/icon';

const SingleClass = ({ selectedClass, classesDetails, tableFields }) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  const showField = (fieldName) => tableFields.findIndex((elm) => elm.name === fieldName) >= 0;
  const duration = showField('duration') ? `- ${selectedClass.duration} min` : '';
  const cost = showField('cost') && (
    <ReturnClassPrice selectedClass={selectedClass} classType={classesDetails.type} classConfig={selectedClass.__t} />
  );
  const name = showField('name') ? <div className={classes.name}>{selectedClass.name}</div> : <EmptyDiv />;
  const description = showField('description') ? (
    <div>
      <Modal onCancel={() => setShowDescriptionModal(false)} open={showDescriptionModal}>
        <span className={classes.label}>Description:</span>
        {selectedClass.description}
      </Modal>

      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className={classes.pointer}
        onClick={() => setShowDescriptionModal(true)}>
        <Icon name={'info'} />
      </div>
    </div>
  ) : null;
  const location = showField('location') && selectedClass.location;
  const trainer =
    showField('trainer') &&
    (selectedClass.trainer
      ? `${selectedClass.trainer.name} ${selectedClass.trainer.last_name.substring(0, 1)}.`
      : null);
  const attendees =
    showField('attendees') && `${selectedClass.limit} - ${selectedClass.joinedUsers} of ${selectedClass.limit}`;

  return (
    <>
      <div
        className={[classes.singleClass, selectedClass.enabled ? '' : classes.hidden].join(' ')}
        data-cy="singleClass">
        <div className={classes.informationContainer}>
          <div className={classes.time}>{`${selectedClass.classTime} ${duration}`}</div>
          {cost}
        </div>

        <div className={classes.informationContainer}>
          {name}
          {description}
        </div>

        <div className={classes.informationContainer}>
          <div className={classes.location}>
            {location} {showField('location') && showField('trainer') ? '/' : ''} {trainer}
          </div>

          <div className={classes.limit}>{attendees}</div>
        </div>
        <div style={{ height: '41px' }}>
          <ClassActionButtons
            selectedClass={selectedClass}
            classesDetails={classesDetails}
            userAttendance={{
              stripeOrderId: selectedClass.userAttendanceStripeOrderId,
              status: selectedClass.userAttendanceStatus,
              _id: selectedClass.userAttendanceId
            }}
          />
        </div>

        {!selectedClass.enabled && <div>Hidden</div>}
      </div>
    </>
  );
};

export default SingleClass;
