import moment from 'moment';
import { useContext } from 'react';
import { AppContext } from '../../../../context/App/AppContext';
import Title from '../../../../components/UI/Title/Title';
const ClientMembership = () => {
  const { userData } = useContext(AppContext);

  return (
    <>
      <Title title={'Your membership'} />
      <h3 className={'my-4'}>{userData.currentMembershipPlan.planName}</h3>
      <div>
        Membership renews on {moment(userData.currentMembershipPlan.current_period_end).format('Do MMM h:mm A')}
      </div>

      <div className={'mt-6'}>
        {userData.currentMembershipPlan.ssPlanId.unlimited ? (
          'Unlimited plan'
        ) : (
          <div>
            <div>Classes left (this month): {userData.classesLeft}</div>
            <div>Classes left (next month): {userData.classesLeftNextMonth}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientMembership;
