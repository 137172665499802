import { useContext, useState } from 'react';
import { isValueCorrect } from '../../../shared/userFunctions';
import { ALL_CLASS_TYPES_WITH_DETAILS } from '../../../constants';
import { returnArrayOfClassesDetailInSelectedGym } from '../../../shared/classFunctions';
import { returnInitialStateForSelectedClassType } from './ClassTypesCheckboxes';
import { AppContext } from '../../../context/App/AppContext';

export const returnSelectedClassTypesAsArray = (classTypes) => {
  const keys = Object.keys(classTypes);
  return keys.filter((key) => !!classTypes[key]);
};

const useClassTypesCheckoxes = () => {
  const [initialState, setInitialState] = useState({
    gym: undefined,
    allClassTypes: false,
    allSelected: false
  });
  const { gymsList } = useContext(AppContext);
  const [selectedClassType, setSelectedClassType] = useState([]);
  const [classTypes, setClassTypes] = useState([]);

  /*  useEffect(() => {
    const { selected, classTypesInGym } = returnInitialState(initialState);
    //  setSelectedClassType(selected);
    //setClassTypes(classTypesInGym);
    //onChange(selected);
  }, [initialState]);*/

  const returnInitialState = ({ gym = undefined, allClassTypes = false, allSelected = false }) => {
    if (isValueCorrect(gym)) {
      const classTypesInGym = allClassTypes
        ? ALL_CLASS_TYPES_WITH_DETAILS()
        : returnArrayOfClassesDetailInSelectedGym(gymsList.find(({ _id }) => _id === gym).settings);
      let selected = returnInitialStateForSelectedClassType(classTypesInGym, allSelected);

      return { selected, classTypesInGym, selectedArray: returnSelectedClassTypesAsArray(selected) };
    }
  };
  const handleClassTypeChange = ({ name, value }) => {
    const selected = {
      ...selectedClassType,
      [name]: value
    };
    setSelectedClassType(selected);
  };
  return {
    selectedClassType,
    setSelectedClassType,
    classTypes,
    setClassTypes,
    returnInitialState,
    handleClassTypeChange,
    initialState,
    setInitialState
  };
};

export default useClassTypesCheckoxes;
