import { useState, useContext } from 'react';

import {
  CLIENTS_FILTERS,
  CLIENTS_ACTIVE_FILTERS,
  CLIENTS_STATUS_FILTERS,
  MEMBERSHIP_STATUS_FILTERS
} from '../../../constants';

import Loading from '../../../components/Loading/Loading';

import ExportToCSV from '../../AdminDashboard/ExportToCSV/ExportToCSV';

import SingleClient from './SingleClient/SingleClient';
import Filters from '../../../components/ClientFilters';

import moment from 'moment';
import { AppContext } from '../../../context/App/AppContext';
import { useQuery } from '@tanstack/react-query';
import Notification from '../../../components/UI/Notification/Notification';
import { fetchUsersListAllData } from '../../../apiFunctions/apiFunctions';
import Title from '../../../components/UI/Title/Title';

const ManageClient = ({
  allGymsClients = false,
  classPasses = null,
  personalTrainings = null,
  gymMemberships = null
}) => {
  const { selectedGym, userData } = useContext(AppContext);

  const [clientsList, setClientsList] = useState(null);

  const {
    data: gymsUsers,
    isLoading: gymsUsersIsLoading,
    isError: gymsUsersIsError
  } = useQuery(['gymsUsers', selectedGym._id], () => fetchUsersListAllData(selectedGym._id), {
    onSuccess: (data) => setClientsList(data.users)
  });

  const handleFilterClientTypes = (filter) => {
    if (gymsUsers && gymsUsers.users) {
      let filteredClients = gymsUsers.users;

      if (filter.statusFilter && filter.statusFilter !== CLIENTS_STATUS_FILTERS.ALL) {
        filteredClients = filteredClients.filter((x) => x.status === filter.statusFilter);
      }

      if (filter.gymMembershipsStatusFilter && filter.gymMembershipsStatusFilter !== MEMBERSHIP_STATUS_FILTERS.ALL) {
        filteredClients = filteredClients.filter((x) => {
          if (filter.gymMembershipsStatusFilter === MEMBERSHIP_STATUS_FILTERS.ACTIVE && x.currentMembershipPlan) {
            return moment().isBefore(x.currentMembershipPlan.current_period_end);
          }
          if (filter.gymMembershipsStatusFilter === MEMBERSHIP_STATUS_FILTERS.EXPIRED && x.currentMembershipPlan) {
            return moment().isAfter(x.currentMembershipPlan.current_period_end);
          }
          return null;
        });
      }

      if (filter.productFilter && filter.productFilter !== CLIENTS_FILTERS.ALL_MEMBERS) {
        filteredClients = filteredClients.filter((x) => x.customerType === filter.productFilter);
      }

      if (filter.activeFilter && filter.activeFilter !== CLIENTS_ACTIVE_FILTERS.ALL) {
        filteredClients = filteredClients.filter((x) => x.status === filter.activeFilter);
      }

      if (filter.classPassFilter && filter.classPassFilter !== 'all') {
        filteredClients = filteredClients.filter((x) =>
          x.products.some((p) => {
            return p.productType === 'classPass' && p.productId._id === filter.classPassFilter;
          })
        );
      }

      if (filter.gymMembershipFilter && filter.gymMembershipFilter !== 'all') {
        filteredClients = filteredClients.filter(
          (x) =>
            x.currentMembershipPlan &&
            x.currentMembershipPlan.ssPlanId &&
            x.currentMembershipPlan.ssPlanId._id === filter.gymMembershipFilter
        );
      }

      if (filter.personalTrainingsFilter && filter.personalTrainingsFilter !== 'all') {
        filteredClients = filteredClients.filter((x) =>
          x.products.some((p) => {
            return p.productType === 'personalTrainingPass' && p.productId._id === filter.personalTrainingsFilter;
          })
        );
      }

      if (filter.nameFilter && filter.nameFilter !== '') {
        filteredClients = filteredClients.filter(
          (x) =>
            x.name.toLowerCase().startsWith(filter.nameFilter.toLowerCase()) ||
            x.last_name.toLowerCase().startsWith(filter.nameFilter.toLowerCase())
        );
      }

      setClientsList(filteredClients);
    }
  };

  const renderClientsList = () => {
    if (gymsUsersIsLoading || clientsList === null) {
      return <Loading />;
    }
    if (gymsUsersIsError) {
      return <Notification defaultMsg />;
    }
    if (clientsList.length === 0) {
      return <h3>No clients</h3>;
    }
    return clientsList.map((client) => (
      <SingleClient key={client._id} client={client} gymId={selectedGym._id} isAdmin={userData.isAdmin} />
    ));
  };

  return (
    <div data-cy={'clients'}>
      <div className="titleAndBtnContainer">
        <Title title={'manage clients'} />
        {clientsList && clientsList.length > 0 && (
          <ExportToCSV data={clientsList} type={'clientsDetailReport'} gymName={selectedGym.name} />
        )}
      </div>

      {gymsUsers !== null && (
        <Filters
          onFilter={handleFilterClientTypes}
          membershipProducts={allGymsClients ? null : selectedGym.settings.membershipProducts}
          classPasses={allGymsClients ? null : selectedGym.settings.class ? classPasses : null}
          personalTrainings={allGymsClients ? null : selectedGym.settings.personalTraining ? personalTrainings : null}
          gymMemberships={allGymsClients ? null : selectedGym.settings.membershipProducts ? gymMemberships : null}
          allClientStatuses={allGymsClients ? true : selectedGym.settings.newClientsHaveToBeApprovedByAdmin}
          membershipType={selectedGym.settings.membershipProducts}
        />
      )}

      {renderClientsList()}
    </div>
  );
};

export default ManageClient;
