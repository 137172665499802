import { classNames } from '../../../shared/utility';

const Error404 = ({ className = '', errorNumber = '404', errorMsg = 'Page not found' }) => {
  const headerStyle = {
    fontWeight: 700,
    fontSize: '144px',
    color: '#FFFFFF',
    lineHeight: '42px',
    textShadow: '0 0 50px rgba(0,0,0,0.20)',
    margin: '0 28px 0 0'
  };

  const column = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%'
  };

  const secondHeaderStyle = {
    fontSize: '36px',
    fontWeight: 700,
    margin: 0
  };

  const thirdHeaderStyle = {
    margin: 0,
    fontSize: '18px',
    fontWeight: 300
  };

  return (
    <div className={classNames('body-container min-h-[700px] flex items-center justify-center', className)}>
      <h1 style={headerStyle}>{errorNumber}</h1>
      <div style={column}>
        <h2 style={secondHeaderStyle}>OOPS!</h2>
        <h3 style={thirdHeaderStyle}>{errorMsg}</h3>
      </div>
    </div>
  );
};
export default Error404;
