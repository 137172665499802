import { useContext } from 'react';
import ProfilePicturePlaceholder from '../../../assets/profile-photo-placeholder.png';
import Loading from '../../../components/Loading/Loading';
import classes from './ProfilePicture.module.scss';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { useMutation } from '@tanstack/react-query';
import { uploadPicture } from '../../../apiFunctions/apiFunctions';

const ProfilePicture = ({ user, isEditable = false }) => {
  const { openNotification } = useContext(NotificationContext);
  const imageSrc = user.image ? user.image : null;

  const { mutate: onPictureUpload, isLoading: onPictureUploadIsLoading } = useMutation({
    mutationFn: (data) => uploadPicture(user._id, data),
    onSuccess: () => openNotification('userImgUpload', 'success'),
    onError: () => openNotification('userImgUpload', 'error')
  });
  const uploadNewPhoto = (e) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();
    let exceedFileSize = false;
    files.forEach((file) => {
      if (file.size / (1024 * 1024) > 5) {
        exceedFileSize = true;
      }
      formData.append('image', file);
    });
    if (exceedFileSize) {
      openNotification(null, 'error', 'File size should not be more than 5MB');
    } else {
      onPictureUpload(formData);
    }
  };

  let src = ProfilePicturePlaceholder;
  if (imageSrc) {
    src = imageSrc;
  }

  return (
    <div className="my-8 md:mt-0">
      <label htmlFor="single">
        {!onPictureUploadIsLoading ? <img src={src} className={classes.profilePicture} alt="Profile" /> : <Loading />}
      </label>
      {isEditable ? (
        <>
          <input className={classes.visibility} type="file" name="image" id="single" onChange={uploadNewPhoto} />
          <div className="text-center text-sm">
            Upload a photo or take a quick snap. Please make sure we can see your whole face. <br />
            You can only upload your photo once.
          </div>
        </>
      ) : null}
    </div>
  );
};
export default ProfilePicture;
