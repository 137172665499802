import { useEffect, useState } from 'react';
import { checkIfTrainerIsAvailableAtProvidedSchedule } from '../../../../shared/classFunctions';
import { Modal } from 'antd';
import Button from '../../../../components/UI/Button/Button';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';

const ScheduleValidator = ({ schedule, selectedTrainer, setStaffShiftsSolution }) => {
  const [showModal, setShowModal] = useState(false);
  const [incorrectClasses, setIncorrectClasses] = useState([]);

  useEffect(
    () => {
      if (schedule !== null) {
        if (selectedTrainer && selectedTrainer.shifts && selectedTrainer.shifts.length === 0) {
          setShowModal(true);
        } else {
          const countWrongClasses = checkIfTrainerIsAvailableAtProvidedSchedule(selectedTrainer, schedule);
          setIncorrectClasses(countWrongClasses);
          setShowModal(countWrongClasses.length > 0);
          if (countWrongClasses.length === 0) {
            saveFunction();
          }
        }
      }
    }, // eslint-disable-next-line
    [schedule]
  );

  const saveFunction = () => {
    setStaffShiftsSolution('save');
    setShowModal(false);
  };
  const cancelFunction = () => {
    setStaffShiftsSolution('cancel');
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderInfo = () =>
    selectedTrainer && selectedTrainer.shifts && selectedTrainer.shifts.length === 0 ? (
      <div>No shifts are set for this trainer.</div>
    ) : (
      <div>
        <div>Trainer is not available at below time:</div>
        <div>
          {incorrectClasses.map((elem, i) => (
            <div key={i}>{elem}</div>
          ))}
        </div>
      </div>
    );
  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      {renderInfo()}
      <div>Do you still want to save schedule?</div>
      <ModalButtonsContainer>
        <Button secondary label={'Confirm'} onClick={saveFunction} />
        <Button label={'Cancel'} onClick={cancelFunction} />
      </ModalButtonsContainer>
    </Modal>
  );
};

export default ScheduleValidator;
