import { useState, useEffect } from 'react';
import classes from './WeekView.module.scss';
import moment from 'moment';
import RedirectToTrainerDetails from '../../../ActionButtons/RedirectToTrainerDetails/RedirectToTrainerDetails';

const WeekView = ({ timetable, openDayInModal }) => {
  const [weekDays, setWeekDays] = useState(moment.weekdaysShort());

  useEffect(
    () => {
      let tmpWeekDays = weekDays;
      tmpWeekDays.push(tmpWeekDays.shift());
      setWeekDays(tmpWeekDays);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const viewDay = (leader, classes) => {
    if (classes.length) {
      openDayInModal({ leader, classes });
    }
  };

  const renderCell = (rowIndex, cellIndex, day, event) => {
    const thisDayEvents = event.classes.filter((evt) => moment(evt.date, 'YYYY-MM-DD').format('ddd') === day);
    return (
      <div
        key={`${rowIndex}_${cellIndex}`}
        className={[classes.item, classes.eventCell].join(' ')}
        onClick={() => viewDay(event.leader, thisDayEvents)}>
        {thisDayEvents.map((dayEvent, index) => (
          <p
            className={`  ${classes.event} ${dayEvent.type === 'beth_interview' ? classes.bethInterview : ''}  `}
            key={`${index}_${rowIndex}_${cellIndex}`}></p>
        ))}
      </div>
    );
  };

  const renderRows = (event, rowIndex) => (
    <div key={rowIndex} className={classes.grid}>
      <div className={[classes.item, classes.trainerContainer].join(' ')}>
        <RedirectToTrainerDetails trainer={event.leader} className={classes.xsHidden} />
        <div className={classes.xsOnly}>
          {event.leader.name.charAt(0)} {event.leader.last_name}
        </div>
        <div className={classes.xsHidden}>
          {event.leader.name} {event.leader.last_name}
        </div>
      </div>

      {weekDays.map((day, index) => renderCell(rowIndex, index, day, event))}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.grid}>
        <div className={[classes.item, classes.header].join(' ')}>Names</div>
        {weekDays.map((day, index) => (
          <div key={index} className={[classes.header, classes.item].join(' ')}>
            {window.innerWidth < 600 ? day.substring(0, 1) : day}
          </div>
        ))}
      </div>
      {timetable.length > 0 ? (
        timetable.map((userEvents, index) => renderRows(userEvents, index))
      ) : (
        <div>>No record found</div>
      )}
    </div>
  );
};

export default WeekView;
