import { useContext, useEffect, useState } from 'react';
import Loading from '../../../components/Loading/Loading';
import { returnAllUpcomingClasses, returnClassesDetails } from '../../../shared/classFunctions';
import CalendarHeader from '../../../components/CalendarHeader/CalendarHeader';
import useTimetableFields from '../../../hooks/useTimetableFields';
import OldTimetableView from './OldTimetableView/OldTimetableView';
import { ALL_CLASS_TYPES_DETAILS, CLASS } from '../../../constants';
import { returnClassConfig } from '../../../shared/gymFunctions';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/App/AppContext';
import Notification from '../../../components/UI/Notification/Notification';
import Title from '../../../components/UI/Title/Title';
import Table from '../../../components/Table/Table';
import Filters from '../../../components/Filters/Filters';
import useFilters from '../../../components/Filters/useFilters';

/**
 type:         upcoming  / history / upcomingAllGyms /  upcomingTrainers  / upcomingPublicClasses
 cancelType:   upcoming  / singleClass
 **/

//TODO: check styles if class is cancelled or etc

const DefaultTimetable = ({
  classesList,
  classesListError,
  classesListLoading,
  classType,
  type = 'upcoming',
  cancelType = 'upcoming',
  filtersArray = [],
  setFilters = () => {},
  openFilters = false
}) => {
  const { userData, selectedGym } = useContext(AppContext);
  const { filters, onFilters, filteredData: filteredDataFromFilters, setFiltersInitialData } = useFilters();
  const navigate = useNavigate();
  const { tableFields, isSingleDayTimetable, isViewSetUp } = useTimetableFields(selectedGym, classType, userData, type);
  const [currentDay, setCurrentDay] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const isOldTimetableView = classType === CLASS.CLASS && userData.isUser && isSingleDayTimetable;
  const classesDetails = returnClassesDetails(classType);
  const isHistoryComponent = type === 'history';
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);
  const isClassesArrayEmpty = classesList && classesList.length === 0;

  useEffect(
    () => {
      let result = type === 'upcoming' ? returnAllUpcomingClasses(classesList) : classesList;

      if (type === 'upcoming' && isSingleDayTimetable) {
        setCurrentDay(0);
        setHasPrevious(false);
        setHasNext(classesList.length > 1);
        result = classesList && classesList.length > 0 ? classesList[0].classes : [];
      }

      if (classType === CLASS.PERSONAL_TRAINING && userData.isUser) {
        result = result.map((elm) => {
          return {
            ...elm.class,
            userAttendanceId: elm._id,
            userAttendanceStatus: elm.status,
            userAttendanceStripeOrderId: elm.stripeOrderId
          };
        });
      }
      setFiltersInitialData(result);
    },
    //eslint-disable-next-line
    [classesList]
  );

  const changeDay = (value) => {
    setCurrentDay(currentDay + value);
    setHasPrevious(currentDay + value >= 1);
    setHasNext(classesList.length - 1 > currentDay + value);
    setFiltersInitialData(classesList[currentDay + value].classes);
  };

  const returnResult = () => {
    if (classesListLoading) {
      return <Loading />;
    }

    if (classesListError) {
      return <Notification message="Something went wrong. Please try again." status="critical" />;
    }

    if (isOldTimetableView) {
      return classesList && classesList.length > 0 ? (
        <OldTimetableView
          classesList={classesList}
          classesDetails={classesDetails}
          tableFields={tableFields.filter((elm) => elm.showInTimetable)}
        />
      ) : (
        <h2>No events</h2>
      );
    }
    return (
      <Table
        fields={tableFields.filter((elm) => elm.showInTimetable).map((elm) => elm.dbName)}
        showHideFieldsBtn
        data={filteredDataFromFilters}
        actionType={type}
        additionalData={{
          cancelType,
          isHistoryComponent,
          classConfig
        }}>
        {!isClassesArrayEmpty && (
          <CalendarHeader
            startDate={classesList[currentDay]._id}
            currentDay={classesList[currentDay]._id}
            daysJumpValue={1}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            onLeftArrow={() => changeDay(-1)}
            onRightArrow={() => changeDay(1)}
            show={isSingleDayTimetable}
          />
        )}
      </Table>
    );
  };

  const noViewSetUp =
    !isViewSetUp && userData.isAdmin ? (
      <h2
        className="p-4 bg-custom-error border-2 border-custom-error-border  font-bold text-lg text-center text-custom-red cursor-pointer"
        onClick={() =>
          navigate(`/gym/${selectedGym._id}/settings`, {
            state: {
              activeTabIndex: 3,
              secondActiveTabIndex: ALL_CLASS_TYPES_DETAILS.findIndex(({ type }) => type === classType)
            }
          })
        }>
        Go to gym settings to set up timetable view!
      </h2>
    ) : null;

  return (
    <div data-testid="default-single-day-timetable">
      {type === 'upcoming' && userData.isUser ? (
        <Title title={classesDetails.name} subtitle={'UPCOMING SESSIONS'} />
      ) : null}
      {noViewSetUp}

      {filtersArray.length > 0 && (
        <Filters
          open={openFilters}
          filtersTypes={filtersArray.map((elm) => elm.name)}
          filters={filters}
          onFilters={(data) => {
            setFilters(data);
            onFilters(data);
          }}
          initialState={filtersArray}
        />
      )}
      {returnResult()}
    </div>
  );
};

export default DefaultTimetable;
