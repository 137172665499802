import { useContext } from 'react';
import Title from '../../components/UI/Title/Title';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/App/AppContext';
import RenderForm from '../../components/Forms/RenderForm';

const Login = () => {
  const { onLogin, onLoginIsLoading } = useContext(AppContext);

  const onFinish = (values) => {
    onLogin(values);
  };

  return (
    <div className="body-container">
      <div className="sm:w-2/3 mx-auto">
        <Title title="Login" />
        <RenderForm
          autoComplete={'on'}
          onFinish={onFinish}
          formFields={['email', 'password', 'rememberMe']}
          initialValues={{ rememberMe: true }}
          btnIsLoading={onLoginIsLoading}
          btnLabel={'login'}
        />

        <Link to="/forgot-password" className="text-lg text-secondary">
          Forgot password?
        </Link>
      </div>
    </div>
  );
};

export default Login;
