import { useContext, useState } from 'react';
import { ALL_CLASS_TYPES_WITH_DETAILS } from '../../../constants';
import { Tabs } from 'antd';
import DefaultTimetable from '../DefaultTimetable/DefaultTimetable';
import { useQuery } from '@tanstack/react-query';
import { fetchUpcomingClassesInAllGyms } from '../../../apiFunctions/apiFunctions';
import Title from '../../../components/UI/Title/Title';
import { returnAllUpcomingClasses } from '../../../shared/classFunctions';
import { AppContext } from '../../../context/App/AppContext';
import { isValueCorrect } from '../../../shared/userFunctions';
import useFilters from '../../../components/Filters/useFilters';
import dayjs from 'dayjs';

const returnClassesList = (array = []) =>
  returnAllUpcomingClasses(array).map((elem) => ({
    ...elem,
    limit: elem.capacity,
    _id: elem.classId,
    name: elem.className,
    classDate: elem.date,
    classTime: elem.time,
    gymName: elem.locationName,
    gymId: elem.locationId,
    joinedUsers: elem.totalNumberOfAttendees,
    active: true,
    duration: 60
  }));
const initialFiltersState = {
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().add(14, 'day').format('YYYY-MM-DD'),
  gyms: []
};

const UpcomingClassesForAllGyms = ({ cancelType }) => {
  const classTypes = ALL_CLASS_TYPES_WITH_DETAILS();
  const { userData } = useContext(AppContext);
  const [selectedClassConfig, setSelectedClassConfig] = useState(classTypes[0]);
  const [filters, setFilters] = useState(
    userData.isAdmin ? initialFiltersState : { ...initialFiltersState, myEvents: true }
  );
  const { returnArrayFromFilters } = useFilters();

  const { data, isError, isLoading } = useQuery(
    [
      'fetchClassesInAllGyms',
      { classType: selectedClassConfig.type, startDate: filters.startDate, endDate: filters.endDate }
    ],
    () => fetchUpcomingClassesInAllGyms(selectedClassConfig.type, filters.startDate, filters.endDate),
    { enabled: isValueCorrect(filters) && isValueCorrect(filters.startDate) && isValueCorrect(filters.endDate) }
  );

  const filtersArray = returnArrayFromFilters(
    userData.isAdmin ? initialFiltersState : { ...initialFiltersState, myEvents: true }
  );
  return (
    <div data-cy={'all_bookings'}>
      <Title title="all upcoming classes" />
      <Tabs
        onChange={(key) => setSelectedClassConfig(classTypes[key])}
        items={classTypes.map((elm, index) => ({
          key: index,
          label: elm.title,
          children: (
            <DefaultTimetable
              classesList={isValueCorrect(data) ? returnClassesList(data) : []}
              classesListLoading={isLoading}
              classesListError={isError}
              classType={selectedClassConfig.type}
              type={'upcomingAllGyms'}
              cancelType={cancelType}
              filtersArray={filtersArray}
              setFilters={setFilters}
              openFilters={true}
            />
          )
        }))}
      />
    </div>
  );
};

export default UpcomingClassesForAllGyms;
