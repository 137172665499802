import { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  editUserData,
  fetchGymDetails,
  fetchGymsList,
  fetchTrainers,
  fetchUserData,
  onAuthLogin,
  onSignup,
  refreshFetchFunctions,
  fetchUsersList,
  fetchUserSources
} from '../../apiFunctions/apiFunctions';
import { NotificationContext } from '../Notification/NotificationContext';
import axios, { setAuthHeader } from '../../axios-global';
import userFunctions from './userFunctions';
import { useNavigate } from 'react-router-dom';
import { isAdminOrTrainer, isFullAccessAdminOrTrainer, isUser, isValueCorrect } from '../../shared/userFunctions';
import { returnTimetableDisplaySettings } from '../../shared/gymFunctions';
import { returnErrorFromResponse } from '../../shared/utility';

export const AppContext = createContext(null);

const AppProvider = ({ children }) => {
  const { openNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [isToken, setIsToken] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isGlobalError, setIsGlobalError] = useState(false);
  const [gymId, setGymId] = useState(undefined);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setAuthHeader(isValueCorrect(token) ? token : undefined);
    setIsToken(isValueCorrect(token));
  }, []);

  // interceptor listening to auth errors
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response === undefined) {
        setIsGlobalError(true);
      }

      if (error.response && error.response.status === 401) {
        //onLogout();
      }

      return Promise.reject(error);
    }
  );

  /**
   *       AUTH
   */
  const { data: userData, isLoading: userDataIsLoading } = useQuery({
    queryKey: ['userData'],
    queryFn: fetchUserData,
    enabled: isToken,
    onSuccess: (data) => {
      setIsAuthenticated(true);
      if (isUser(data)) {
        onGymChange(data.gymId);
      }
      if (!isFullAccessAdminOrTrainer(data) && data.gymId.length === 1) {
        onGymChange(data.gymId[0]);
      }
    },
    onError: (data) => {
      if (data?.response?.data?.message === 'TokenExpiredError') {
        onLogout();
        openNotification('token', 'error');
      }
    }
  });

  const { mutate: onUpdateUserData, isLoading: onUpdateUserDataIsLoading } = useMutation(
    ['updateUserData'],
    ({ userId, data }) => editUserData(userId, data),
    {
      onSuccess: () => {
        openNotification(null, 'success', 'Successfully saved!');
        if (userData.role === 'user') {
          refreshFetchFunctions(['userData']);
        }
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );

  const {
    mutate: onSignUp,
    isLoading: onSignUpIsLoading,
    isSuccess: onSignUpIsSuccess
  } = useMutation({
    mutationFn: (data) => onSignup(data),
    onSuccess: ({ token }) => {
      localStorage.setItem('token', token);
      setAuthHeader(token);
      setIsToken(true);
      navigate('/gym');
    },
    onError: (err) => {
      openNotification(null, 'error', returnErrorFromResponse(err));
      setIsAuthenticated(false);
    }
  });

  const { mutate: onLogin, isLoading: onLoginIsLoading } = useMutation({
    mutationFn: (data) => onAuthLogin(data),
    onSuccess: ({ token }) => {
      localStorage.setItem('token', token);
      setAuthHeader(token);
      setIsAuthenticated(true);
      setIsToken(true);
      openNotification('login');
      navigate('/gym');
    },
    onError: (err) => {
      openNotification(null, 'error', returnErrorFromResponse(err));
      setIsAuthenticated(false);
    }
  });

  const onLogout = () => {
    localStorage.removeItem('token');
    setAuthHeader(null);
    setIsAuthenticated(false);
    setIsToken(false);
    openNotification('logout');
    setIsGlobalError(false);
    navigate('/');
  };

  const {
    data: userPaymentSources,
    isLoading: userPaymentSourcesIsLoading,
    isError: userPaymentSourcesIsError
  } = useQuery({
    queryKey: ['userPaymentSources'],
    queryFn: fetchUserSources,
    enabled: isValueCorrect(userData) && isUser(userData)
  });

  /**
   *       GYM / GYMs
   */

  const { data: selectedGym, isLoading: selectedGymIsLoading } = useQuery({
    queryKey: ['selectedGym', gymId],
    queryFn: () => fetchGymDetails(gymId),
    enabled: isValueCorrect(gymId)
  });

  const onGymChange = (id) => {
    if (isValueCorrect(id)) {
      setGymId(id);
    }
  };

  const {
    data: gymsList,
    isLoading: gymsListIsLoading,
    isError: gymsListIsError
  } = useQuery({
    queryKey: ['gymsList'],
    queryFn: fetchGymsList,
    enabled: isAdminOrTrainer(userData) && (isFullAccessAdminOrTrainer(userData) || userData.gymId.length > 0)
  });

  /**
   *       TRAINERS LIST
   */
  const {
    data: trainersList,
    isLoading: trainersListIsLoading,
    isError: trainersListIsError
  } = useQuery({
    queryKey: ['trainersList', gymId],
    queryFn: () => fetchTrainers(gymId),
    enabled: isAdminOrTrainer(userData) && isValueCorrect(gymId)
  });

  /**
   *       USERS LIST
   */

  const {
    data: usersList,
    isLoading: usersListIsLoading,
    isError: usersListIsError
  } = useQuery({
    queryKey: ['usersList', gymId],
    queryFn: () => fetchUsersList(gymId),
    enabled: isAdminOrTrainer(userData) && isValueCorrect(gymId)
  });

  const value = {
    isGlobalError,
    setIsGlobalError,

    isAppLoading:
      (userDataIsLoading && isToken) ||
      (selectedGymIsLoading && isValueCorrect(gymId)) ||
      (isAdminOrTrainer(userData) && gymsListIsLoading),

    userDataIsLoading,
    userData: userFunctions(userData),
    isAuthenticated,

    userPaymentSources,
    userPaymentSourcesIsLoading,
    userPaymentSourcesIsError,

    onLogin,
    onLoginIsLoading,
    onLogout,

    onSignUp,
    onSignUpIsLoading,
    onSignUpIsSuccess,

    onUpdateUserData,
    onUpdateUserDataIsLoading,

    selectedGym,
    gymId: isValueCorrect(selectedGym) ? selectedGym?._id : null,

    selectedGymIsLoading,
    onGymChange,
    timetableDisplaySettings: isValueCorrect(selectedGym) ? returnTimetableDisplaySettings(selectedGym.settings) : {},

    gymsList,
    gymsListIsLoading: gymsListIsLoading,
    gymsListIsError,

    trainersList,
    trainersListIsLoading,
    trainersListIsError,

    usersList,
    usersListIsLoading,
    usersListIsError
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
export default AppProvider;
