import Icon from '../../../components/UI/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../context/App/AppContext';

const RedirectToUserDetails = ({ client, showIcon = true, showName = false }) => {
  const navigate = useNavigate();
  const { selectedGym } = useContext(AppContext);
  return (
    <div
      className="flex cursor-pointer my-2 hover:font-bold"
      onClick={() => navigate(`/gym/${client.gymId === null ? selectedGym._id : client.gymId}/clients/${client._id}`)}>
      {showIcon ? <Icon className="mr-2" name={'userData'} /> : null}
      {showName ? (
        <div>
          {client.name} {client.last_name}
        </div>
      ) : null}
    </div>
  );
};

export default RedirectToUserDetails;
