import Icon from '../../../components/UI/Icon/Icon';
import Title from '../../../components/UI/Title/Title';
import companyDetails from '../../companyDetails';
import { Link } from 'react-router-dom';
import GoogleMaps from '../../../components/GoogleMaps/GoogleMaps';

export default () => (
  <div className={'body-container '}>
    <Title title="Contact us" />
    <div className={'md:flex justify-between'}>
      <div>
        <div className="font-bold md:grid md:grid-cols-2 md:w-3/4 gap-4 text-md">
          <div>Mail:</div>
          <div className="text-secondary mb-6 md:mb-0">
            <a href={`mailto:${companyDetails.email}`}>{companyDetails.email}</a>
          </div>
          <div>Address:</div>
          <div className="mb-6 md:mb-0">
            Motive8 Group Ltd
            <br />
            Gibraltar House, Rodd Estate <br />
            Govett Avenue, Shepperton <br />
            TW17 8AB <br />
          </div>
          <div>Telephone number:</div>
          <div>
            Freephone 0800 028 0198 <br />
            Telephone 020 8481 9700
          </div>
        </div>

        <div className={'mt-4 md:grid md:grid-cols-2 gap-4'}>
          <div>
            <div className={'text-secondary text-4xl font-bold'}>London</div>
            <div className={'font-bold'}>
              65 High Street <br />
              Teddington <br />
              Middlesex <br />
              TW11 8HA <br />
              <br />
              Telephone: 0800 028 0198
            </div>
          </div>

          <div>
            <div className={'text-secondary text-4xl font-bold'}>London</div>
            <div className={'font-bold'}>
              65 High Street <br />
              Teddington <br />
              Middlesex <br />
              TW11 8HA <br />
              <br />
              Telephone: 0800 028 0198
            </div>
          </div>
        </div>

        <div className="flex gap-x-3 my-14">
          {companyDetails.socialMedia.map(({ name, url }) => (
            <Link key={name} to={url} target="_blank" rel="noopener noreferrer">
              <Icon name={name} size={40} color={companyDetails.secondary} />
            </Link>
          ))}
        </div>
      </div>

      <GoogleMaps />
    </div>
  </div>
);
