import { useState, useEffect, useContext } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { returnClassPrice } from '../../../shared/classFunctions';
import Loading from '../../../components/Loading/Loading';
import { payForClass, payForPass, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { useMutation } from '@tanstack/react-query';
import { returnErrorFromResponse } from '../../../shared/utility';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { AppContext } from '../../../context/App/AppContext';

const GoogleApplePayButton = ({ product, productType, initWebPayment, selectedClass, classType, closeModal }) => {
  const stripe = useStripe();
  const { openNotification } = useContext(NotificationContext);
  const { userData } = useContext(AppContext);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [error, setError] = useState(null);

  const mutatePayForClass = useMutation(
    (stripePaymentId) => payForClass(selectedClass._id, stripePaymentId, 'webPay'),
    {
      onSuccess: () => {
        openNotification(null, 'success', 'Successfully paid');
        refreshFetchFunctions([
          ['upcomingClasses', selectedClass.__t],
          ['clientUpcomingClasses', selectedClass.__t],
          ['userData']
        ]);
      },
      onError: (err) => {
        openNotification(null, 'error', returnErrorFromResponse(err));
      }
    }
  );

  const mutatePayForPass = useMutation(
    (stripeOrderId) => payForPass(product._id, stripeOrderId, 'webPay', product.currency),
    {
      onSuccess: () => {
        openNotification(null, 'success', 'Successfully paid');
        refreshFetchFunctions([['userData'], ['passes', classType]]);
        closeModal();
      },
      onError: (err) => {
        openNotification(null, 'error', returnErrorFromResponse(err));
      }
    }
  );

  useEffect(() => {
    setError(null);
    const label = productType === 'pass' ? product.name : selectedClass.__t;
    const amount = productType === 'pass' ? parseInt(product.cost) * 100 : returnClassPrice(selectedClass, userData);
    if (stripe && (product || selectedClass)) {
      try {
        const pr = stripe.paymentRequest({
          country: 'GB',
          currency: 'gbp',
          total: {
            label: label,
            amount: amount
          },
          requestPayerName: true,
          requestPayerEmail: true
        });

        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
          } else {
            setPaymentRequest(false);
          }
        });
      } catch (error) {
        setError(error);
      }
    }
  }, [stripe, product, productType, selectedClass, userData]);

  if ((initWebPayment === null || paymentRequest === null || mutatePayForClass.isLoading) && !error) {
    return <Loading />;
  }

  if (paymentRequest && initWebPayment) {
    paymentRequest.on('paymentmethod', function (ev) {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      stripe
        .confirmCardPayment(
          initWebPayment.data.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        )
        .then(function (confirmResult) {
          if (confirmResult.error) {
            // Report to the browser that the payment failed, prompting it to re-show the payment interface, or show an error message and close the payment interface.
            ev.complete('fail');
            openNotification('default', 'error');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            if (confirmResult.paymentIntent.status === 'requires_action') {
              stripe.confirmCardPayment(initWebPayment.data.clientSecret).then(function (result) {
                if (result.error) {
                  openNotification('default', 'error');
                } else {
                  // The payment has succeeded. Recording payment in backend system
                  if (productType === 'pass') {
                    mutatePayForPass.mutate(confirmResult.paymentIntent.id);
                  } else {
                    mutatePayForClass.mutate(confirmResult.paymentIntent.id);
                  }
                }
              });
            } else {
              // The payment has succeeded. Recording payment in backend system
              if (productType === 'pass') {
                mutatePayForPass.mutate(confirmResult.paymentIntent.id);
              } else {
                mutatePayForClass.mutate(confirmResult.paymentIntent.id);
              }
            }
          }
        });
    });
    return (
      <div className={'my-3'}>
        <div className={'w-full'}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      </div>
    );
  }

  if (!paymentRequest) {
    return null;
  }
};

export default GoogleApplePayButton;
