import axios from '../axios-global';
import { PRODUCT_TYPES } from '../constants';
import moment from 'moment';
import axiosAuthApi from '../axios-auth';
import { queryClient } from '../index';
import { isValueCorrect } from '../shared/userFunctions';
import { returnSelectedClassTypesAsArray } from '../components/Selectors/ClassTypesCheckboxes/useClassTypesCheckoxes';

export const refreshFetchFunctions = (queryKeys) =>
  new Promise((resolve) => {
    Promise.all(queryKeys.forEach((key) => queryClient.invalidateQueries(key)))
      .then(resolve)
      .catch(resolve); // if error occurs just continue
  });

/** AUTH  **/
export const onForgotPassword = (email, url) =>
  axios.post(`/users/reset-password`, { email, url }).then((resp) => resp.data);
export const onResetPassword = (newPassword, passwordResetCode) =>
  axios.post(`/users/change-reseted-password`, { newPassword, passwordResetCode }).then((resp) => resp.data);
export const onAuthLogin = (data) => axiosAuthApi.post(`/local`, data).then((resp) => resp.data);

export const onSignup = (data) => axios.post(`/users`, data).then((resp) => resp.data);

/**
 *         CLASS TYPES / EVENTS
 */
export const fetchPublicUpcomingClasses = (gymId) =>
  axios.get(`/class/public/all?locationId=${gymId}&fromDate=${moment().format('YYYY-MM-DD')}`, {});
export const fetchPublicClass = (classId) => axios.get(`/class/public/${classId}`, {});
export const fetchClassesByAdmin = (
  classType,
  startDate = moment().format('DD-MM-YYYY'),
  endDate = moment().format('DD-MM-YYYY').add(14, 'D')
) =>
  axios
    .get(`/class/all`, {
      params: {
        classType,
        startDate,
        endDate
      }
    })
    .then((resp) => resp.data);

export const fetchClassesPerScheduleId = (scheduleId) =>
  axios.get(`/class/scheduledClasses/${scheduleId}`).then((resp) => resp.data);

export const fetchSingleClass = (classId) => axios.get(`/class/${classId}`).then((resp) => resp.data);

export const fetchUpcomingClassesInAllGyms = (classType, dateFrom = null, endDate = null) => {
  let url = `/class/all`;
  if (isValueCorrect(dateFrom) && isValueCorrect(endDate)) {
    url = `${url}?${new URLSearchParams({
      dateFrom,
      endDate
    }).toString()}`;
  }

  return axios.get(url, { params: { classType } }).then((resp) => resp.data);
};

export const fetchClasses = (classType, gymId = 'all', dateFrom = null, dateTo = null) => {
  const params =
    dateFrom && dateTo
      ? {
          dateFrom,
          dateTo
        }
      : null;
  return axios.get(`/class/gym/${gymId}/${classType}`, { params }).then((resp) => resp.data);
};

export const fetchClassesHistory = (classType, gymId = 'all') =>
  axios.get(`/class/${classType}/gym/${gymId}/history`).then((resp) => resp.data);

export const fetchClientUpcomingClasses = (classType) =>
  axios.get(`/users/${classType}/upcoming`).then((resp) => resp.data);

export const joinClass = (classId, userId, isSinglePayment = false) =>
  axios
    .post(`/class/${classId}`, {
      userId,
      isSinglePayment
    })
    .then((resp) => resp.data);

export const payForClass = (classId, stripePaymentId, method) =>
  axios
    .post(`/class/${classId}/pay`, {
      stripePaymentId,
      method
    })
    .then((resp) => resp.data);

export const payForAttendance = (attendanceId, stripeOrderId) =>
  axios
    .post(`/attendance/${attendanceId}/payment`, {
      stripeOrderId: stripeOrderId,
      method: 'web'
    })
    .then((resp) => resp.data);

export const moveClientsBetweenClasses = (attendanceId, classId) =>
  axios
    .patch(`/attendance/${attendanceId}/reschedule`, {
      classId
    })
    .then((resp) => resp.data);

export const signUpAndPayForClass = async (classId, userId, isSinglePayment = false) => {
  const joined = await joinClass(classId, userId, isSinglePayment);
  if (joined.stripeOrderId) {
    const payed = await payForAttendance(joined.attendanceId, joined.stripeOrderId);
    return payed;
  } else {
    return joined;
  }
};

export const changeAttendanceStatus = (attendanceId, status) =>
  axios.patch(`/attendance/${attendanceId}`, { status }).then((resp) => resp.data);

export const cancelAttendance = (attendanceId, userId = null, checkTiming = true) =>
  axios
    .patch(`/attendance/${attendanceId}/cancel?checkTiming=${checkTiming}`, userId && { userId })
    .then((resp) => resp.data);

export const buyProductAndUpdateAttendance = (attendanceId, method, userId, productId, isSinglePayment) =>
  axios
    .post(`/attendance/${attendanceId}/payByOtherMethod`, {
      method,
      userId,
      productId,
      isSinglePayment
    })
    .then((resp) => resp.data);

export const cancelClass = (classId, shouldAdminChargeClients = false) =>
  axios.delete(`/class/${classId}/checkTiming/${shouldAdminChargeClients}`).then((resp) => resp.data);

export const activateClass = (classId) => axios.patch(`/class/${classId}`, { active: true }).then((resp) => resp.data);

export const addClass = (gymId, classType, formData) =>
  axios.post(`/class/gym/${gymId}/create/${classType}`, formData).then((resp) => resp.data);

export const editClass = (classId, formData) => axios.patch(`/class/${classId}`, formData).then((resp) => resp.data);

export const createClassAndSignUpAllUsersToClass = async (gymId, classType, formData, usersList) => {
  const newClass = await addClass(gymId, classType, formData);

  for (const clientId of usersList) {
    try {
      await joinClass(newClass._id, clientId);
    } catch (error) {
      await cancelClass(newClass._id);
      return Promise.reject(`Error: Client with ID: ${clientId}  cannot sign up`);
    }
  }
  return newClass;
};
export const bulkClassEditing = (data) =>
  axios.patch(`/class/update/multipleClasses`, { data }).then((resp) => resp.data);

export const signUpWaitList = (classId, actionType) =>
  axios.post(`/wait-list/class/${classId}/${actionType}`).then((resp) => resp.data);

/**
 *          USER
 */
export const fetchUserData = () => axios.get(`/users/me`).then((resp) => resp.data);
export const fetchUserSources = () => axios.get(`/users/me/source`).then((resp) => resp.data);
export const addUserSource = (sourceId) =>
  axios
    .post('/users/me/payment-methods', {
      source: sourceId,
      method: 'web'
    })
    .then((resp) => resp.data);
export const deleteUserSource = (sourceId) =>
  axios.delete(`/users/me/delete-payment/${sourceId}`).then((resp) => resp.data);
export const selectUserSource = (sourceId) =>
  axios
    .post('/users/me/payment-default', {
      source: sourceId,
      method: 'web'
    })
    .then((resp) => resp.data);

export const editUserData = (userId, body) => axios.patch(`/users/${userId}`, body).then((resp) => resp.data);
export const uploadPicture = (userId, formData) =>
  axios.put(`/users/${userId}/image`, formData).then((resp) => resp.data);

export const fetchSingleClientsDetails = (clientId) => axios.get(`users/${clientId}`).then((resp) => resp.data);

export const fetchUsersListAllData = (gymId) => axios.get(`users/gym/${gymId}/all`).then((resp) => resp.data);

export const fetchUsersList = (gymId) => axios.get(`users/gym/${gymId}`).then((resp) => resp.data);

export const searchUsersByName = (name) => axios.get(`users/all?name=${name}`).then((resp) => resp.data);

/**
 *          TRAINER
 */
export const fetchTrainers = (gymId) => axios.get(`/users/gym/${gymId}/trainers`).then((resp) => resp.data);
export const addTrainer = (gymId, data) => axios.post(`/users/gym/${gymId}`, data).then((resp) => resp.data);
export const editTrainer = (userId, data) => axios.patch(`/users/${userId}`, data).then((resp) => resp.data);
export const searchTrainersByName = (name) => axios.get(`users/trainers?name=${name}`).then((resp) => resp.data);
export const fetchTrainersEvents = (gymId, data) =>
  axios.get(`/class/gym/${gymId}/calendar?${new URLSearchParams(data).toString()}`, data).then((resp) => resp.data);
/**
 *          ADMIN
 */
export const fetchAdmins = (gymId) => axios.get(`/users/admin/gym/${gymId}`).then((resp) => resp.data);

export const addAdmin = (data) => axios.post(`/users/admin`, data).then((resp) => resp.data);
export const editAdmin = (adminId, data) => axios.patch(`/users/admin/${adminId}`, data).then((resp) => resp.data);
export const deleteAdmin = (adminId) => axios.delete(`/users/${adminId}`).then((resp) => resp.data);
export const searchAdminsByName = (name) => axios.get(`users/admins?name=${name}`).then((resp) => resp.data);

/**
 *          PASSES
 */
export const fetchPasses = (classType, gymId) =>
  axios.get(`/products/passes/${classType}Pass/${gymId}/all`).then((resp) => resp.data);

export const fetchVideoPasses = ({ classType = null, gymId = null }) =>
  axios.get(`/products/passes/videoPass/${gymId}/all`).then(({ data }) =>
    data.filter((product) => {
      if (classType !== null) {
        return product.__t === PRODUCT_TYPES.VIDEO && product.forClass === classType;
      }
      return product.__t === PRODUCT_TYPES.VIDEO;
    })
  );

export const orderPass = (passId, req) => axios.post(`/products/passes/${passId}/order`, req).then((resp) => resp.data);

export const payForPass = (productId, stripeOrderId, method = 'web', currency, userId) =>
  axios.post(`/products/passes/pay`, { productId, stripeOrderId, method, currency, userId }).then((resp) => resp.data);

export const orderAndPayForPass = async (passId, method = 'web', currency, promoCode) => {
  const req = {
    method: method
  };
  if (promoCode !== undefined && promoCode !== null && promoCode.trim().length !== 0) {
    req['coupon'] = promoCode;
  }
  const order = await orderPass(passId, req);
  if (order.stripeOrderId) {
    const payed = await payForPass(passId, order.stripeOrderId, method, currency);
    return payed;
  } else {
    return order;
  }
};

export const addPass = (classType, body) => axios.post(`/products/passes/${classType}`, body).then((resp) => resp.data);

export const editPass = (passId, body = {}) =>
  axios.patch(`/products/passes/${passId}`, body).then((resp) => resp.data);

export const deletePass = (passId) => editPass(passId, { active: false });

export const getDiscountedPriceForCoupon = (passId, couponCode) =>
  axios.get(`/products/${passId}/discountedPrice?couponCode=${couponCode}`).then((resp) => resp.data);

export const addVideoToProducts = (videoId, products = []) => {
  return Promise.all(
    products.map((product) => {
      if (product.videos && product.videos.includes(videoId)) {
        return Promise.resolve();
      }
      return editPass(product._id, {
        videos: product.videos ? [...product.videos, videoId] : [videoId]
      });
    })
  );
};

export const removeVideoFromProducts = (videoId, products = []) => {
  return Promise.all(
    products.map((product) => {
      if (!product.videos || !product.videos.length || !product.videos.includes(videoId)) {
        return Promise.resolve();
      }
      return editPass(product._id, {
        videos: product.videos.filter((video) => {
          return video !== videoId;
        })
      });
    })
  );
};

/**
 *          MEMBERSHIP PLANS
 */

export const fetchMembershipPlans = (gymId) =>
  axios.get(`/membership-plan/gymPlans/${gymId}`).then((resp) => resp.data);
export const fetchAvailableMembershipPlans = (gymId) =>
  axios.get(`/membership-plan/availablePlans/${gymId}`).then((resp) => resp.data);
export const addMembershipPlan = (body) => axios.post(`/membership-plan/create`, body).then((resp) => resp.data);
export const editMembershipPlan = (planId, body) =>
  axios.patch(`/membership-plan/${planId}`, body).then((resp) => resp.data);
export const deleteMembershipPlan = (planId) => axios.delete(`/membership-plan/${planId}`).then((resp) => resp.data);

export const addMembershipPlanPayment = (body) =>
  axios.post('/membership-plan/payment', body).then((resp) => resp.data);
export const subscribeToMembershipPlan = (body) =>
  axios.post(`/membership-plan/subscribe/${body.planId}`, body).then((resp) => resp.data);
export const changeClientMembershipPlans = (body) => axios.patch('/users/plan', body).then((resp) => resp.data);

/**
 *          TEMPLATES
 */

export const fetchTemplates = (gymId, classType = 'all') =>
  axios.get(`/class-template/gym/${gymId}?classType=${classType}`).then((resp) => resp.data);

export const addTemplate = (gymId, body) => axios.post(`/class-template/gym/${gymId}`, body).then((resp) => resp.data);

export const editTemplate = (templateId, body) =>
  axios.patch(`/class-template/${templateId}`, body).then((resp) => resp.data);

export const deleteTemplate = (templateId) => axios.delete(`/class-template/${templateId}`).then((resp) => resp.data);

/**
 *          SCHEDULED CLASSES
 */
export const fetchAllScheduledClasses = (gymId) => axios.get(`/scheduled-class/gym/${gymId}`).then((resp) => resp.data);

export const fetchScheduledClasses = (classType, gymId) =>
  axios.get(`/scheduled-class/gym/${gymId}/${classType}`).then((resp) => resp.data);

export const addScheduledClass = (gymId, body = {}) =>
  axios.post(`/scheduled-class/gym/${gymId}`, body).then((resp) => resp.data);

export const editScheduledClass = (scheduledId, body = {}) =>
  axios.patch(`/scheduled-class/${scheduledId}`, body).then((resp) => resp.data);

export const deleteScheduled = (scheduledId) =>
  axios.delete(`/scheduled-class/${scheduledId}`).then((resp) => resp.data);

/**
 *          FAQS
 */
export const fetchFAQs = (gymId) => axios.get(`/faq/all/gym/${gymId}`).then((resp) => resp.data);

export const addFAQ = (gymId, body = {}) => axios.post(`/faq/add/gym/${gymId}`, body).then((resp) => resp.data);

export const editFAQ = (faqId, body = {}) => axios.patch(`/faq/${faqId}`, body).then((resp) => resp.data);

export const deleteFAQ = (faqId) => axios.delete(`/faq/${faqId}`).then((resp) => resp.data);

export const uploadFAQJsonFile = (gymId, body = {}) =>
  axios.post(`faq/add/json/gym/${gymId}`, body).then((resp) => resp.data);

export const fetchUpcomingPts = () => axios.get(`/users/personalTraining/upcoming`).then((resp) => resp.data);

/**
 *          REPORTS
 */
export const fetchDebtorsReport = (gymId = null) => {
  const url = gymId !== null ? `/reports/debtors/${gymId}` : '/reports/debtors';
  return axios.get(url).then((resp) => resp.data);
};

export const fetchPassesReport = (data) => {
  let url = `/reports/${data.gymId}/classpass/remaining`;

  if (isValueCorrect(data.passTypeSelector)) {
    url = `${url}?productPassType=${data.passTypeSelector}Pass`;
  }
  if (isValueCorrect(data.passSelector)) {
    url = `${url}&productPassId=${data.passSelector}`;
  }
  return axios.get(url).then((resp) => resp.data);
};

export const fetchRecordedAttendancesReport = ({ gymId, startDate, endDate }) =>
  axios.get(`/gym-attendances/all?gymId=${gymId}&startDate=${startDate}&endDate=${endDate}`).then((resp) => resp.data);

export const fetchClassAttendancesReport = ({
  gymId = 'all',
  startDate = undefined,
  endDate = undefined,
  reportNameDetails = undefined
}) => {
  let url = `/reports/class-attendance/${gymId}`;

  if (isValueCorrect(startDate) && isValueCorrect(endDate)) {
    url = url + `?fromDate=${startDate}&toDate=${endDate}`;
  }
  if (isValueCorrect(reportNameDetails) && reportNameDetails) {
    url =
      isValueCorrect(startDate) && isValueCorrect(endDate)
        ? url + '&includeAttendeesDetails=true'
        : url + '?includeAttendeesDetails=true';
  }
  return axios.get(url).then((resp) => resp.data);
};

export const fetchPayrollReport = (data) => {
  let url = '/users/trainers/timesheet';
  url = `${url}?${new URLSearchParams({
    fromDate: data.startDate,
    toDate: data.endDate,
    trainerIds: data.trainerSelector,
    classTypes: returnSelectedClassTypesAsArray(data.classTypesCheckboxes)
  }).toString()}`;
  return axios.get(url, data).then((resp) => resp.data);
};

/**
 *          VIDEO
 */
export const fetchVideos = () => axios.get(`/videos`).then((resp) => resp.data);

export const fetchVideoById = (id) => axios.get(`/videos/${id}`).then((resp) => resp.data);

export const uploadVideo = async (renderFormData, products = []) => {
  const formData = new FormData();
  formData.append('video', renderFormData.file);
  try {
    const videoData = await axios.post(`/videos`, formData);
    const videoConfig = renderFormData.videoConfig.map(({ name, options, active }) => ({ name, options, active }));

    const data = {
      ...renderFormData,
      selectedTrainer: renderFormData.trainer,
      videoConfig,
      file: {
        title: renderFormData.title,
        name: renderFormData.file.name,
        type: renderFormData.file.type,
        size: renderFormData.file.size,
        url: videoData.data.videoUrl
      }
    };

    const workoutTypeSelection = renderFormData.videoConfig.find((category) => category.name === 'Workout Type');
    const workoutType =
      workoutTypeSelection && workoutTypeSelection.options.length
        ? workoutTypeSelection.options.sort((a, b) => a.localeCompare(b)).join('-')
        : 'uncategorised';
    const workoutSlug = workoutType.toLowerCase().replace(' ', '-');
    const videoId = await axios.post(`/videos/${workoutSlug}`, data);

    await addVideoToProducts(videoId.data._id, products);
    return { status: 200, msg: 'Success' };
  } catch (error) {
    return { status: 422, msg: 'error' };
  }
};

export const saveVideo = (workoutType, body) => {
  const workoutSlug = workoutType.toLowerCase().replace(' ', '-');
  return axios.post(`/videos/${workoutSlug}`, body).then((resp) => resp.data);
};
export const editVideo = async (videoId, data) => {
  await axios.patch(`/videos/editVideo/${videoId}`, data);
};

export const deleteVideo = async (videoId, products) => {
  await markVideoAsDeleted(videoId);
  await removeVideoFromProducts(videoId, products);
};
const markVideoAsDeleted = (videoId) => axios.delete(`/videos/${videoId}`).then((resp) => resp.data);

export const deleteVideoFromS3 = (fileName) => axios.delete(`/videos/s3/${fileName}`).then((resp) => resp.data);

export const videoPassCompleted = (purchaseId) => {
  if (!purchaseId) return Promise.reject(new Error('Missing purchase id'));
  return axios.post(`/users/videos/${purchaseId}/completeConfirmation`);
};

/**
 *          VIDEO CATEGORIES
 */
export const fetchVideoCategories = () => axios.get(`/video-config/all`).then((resp) => resp.data);

export const addVideoCategory = (data) => axios.post(`/video-config`, data).then((resp) => resp.data);

export const editVideoCategory = (categoryId, body) =>
  axios.patch(`/video-config/${categoryId}`, body).then((resp) => resp.data);

export const deleteVideoCategory = (categoryId) =>
  axios.delete(`/video-config/${categoryId}`).then((resp) => resp.data);

/**
 *          COUPONS
 */

export const fetchCoupons = (gymId) => axios.get(`/coupons/gym/${gymId}`).then((resp) => resp.data);

export const addCoupon = (gymId, body) =>
  axios.post(`/coupons/${body.couponType}/gym/${gymId}`, body).then((resp) => resp.data);

export const editCoupon = (couponId, body) => axios.patch(`/coupons/${couponId}`, body).then((resp) => resp.data);

export const deleteCoupon = (couponId) => axios.delete(`/coupons/${couponId}`).then((resp) => resp.data);

/**
 *          GYM
 */
export const fetchGymsList = () => axios.get('/gym/all').then((resp) => resp.data);
export const fetchGymDetails = (gymId) => axios.get(`/gym/${gymId}`).then((resp) => resp.data);
export const createGym = (gymData) => axios.post('/gym/create', gymData).then((resp) => resp.data);
export const editGym = (gymId, body) => axios.patch(`/gym/${gymId}`, body).then((resp) => resp.data);
export const editGymSettings = (settingsId, body) =>
  axios.patch(`/gym/settings/${settingsId}`, body).then((resp) => resp.data);
export const fetchGymDetailsByCityAndName = (city, name) =>
  axios.get(`/gym/location/${city}/${name}`).then((resp) => resp.data);
export const fetchCitiesWithGyms = () => axios.get(`/gym/cities`).then((resp) => resp.data);

/**
 *          KIT LIST
 */
export const fetchKitList = () => axios.get(`/kit-list`).then((resp) => resp.data);
export const addNewKit = (data) => axios.post(`/kit-list`, data).then((resp) => resp.data);
