const defaultMessage = 'Something went wrong. Please try again later.';

//  status:  critical   success  warning

const Notification = ({ message, status = 'critical', children = null, defaultMsg, className }) => {
  const style =
    status === 'critical'
      ? 'bg-custom-red border-custom-red bg-opacity-20'
      : status === 'success'
        ? 'bg-custom-ok border-custom-ok-border'
        : 'bg-amber-100 border-amber-300';
  return (
    <div className={['p-4 border-2 text-center my-4', style, className].join(' ')}>
      <div className="font-bold ">{defaultMsg ? defaultMessage : message} </div>
      {children}
    </div>
  );
};

export default Notification;
