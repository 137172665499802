import { useContext } from 'react';
import Icon from '../../UI/Icon/Icon';
import { classNames } from '../../../shared/utility';
import NavigationLink from '../NavigationLink/NavigationLink';
import classes from './SideDrawer.module.scss';
import { AppContext } from '../../../context/App/AppContext';

const SideDrawer = ({ dashboardLink, menuItems, showSideDrawer, setShowSideDrawer }) => {
  const { onLogout, userData, selectedGym, isAuthenticated } = useContext(AppContext);

  let attachedclasses = [classes.sideDrawer, 'bg-primary', classes.close];
  if (showSideDrawer) {
    attachedclasses = [classes.sideDrawer, 'bg-primary z-0', classes.open];
  }

  let authSection = (
    <>
      <NavigationLink link="/login">Log in</NavigationLink>
    </>
  );
  let dashboard,
    userSettings,
    navigationLinks = null;

  if (isAuthenticated) {
    dashboard = (
      <NavigationLink link={dashboardLink} data-cy="dashboard_btn">
        Dashboard
      </NavigationLink>
    );
    userSettings =
      userData.isActive ||
      (userData.isAdminOrTrainer && (
        <NavigationLink link={'/user/settings'} data-cy="settings_btn">
          Settings
        </NavigationLink>
      ));
    authSection = (
      <div className="text-white font-bold text-lg uppercase pointer" onClick={onLogout}>
        Logout
      </div>
    );
    navigationLinks = (
      <>
        <div className={classes.space} />
        {selectedGym &&
          menuItems.map((elem) => (
            <NavigationLink key={elem.name} link={elem.link}>
              {elem.name}
            </NavigationLink>
          ))}
        <NavigationLink link="/terms-of-website">Terms of website</NavigationLink>
        <NavigationLink link="/privacy-policy">Privacy policy</NavigationLink>
        <NavigationLink link={userData.isUser ? '/gym/terms-of-service' : `/gym/${selectedGym._id}/terms-of-service`}>
          Terms of service
        </NavigationLink>
        <NavigationLink link={'/health-statement'}>Health Statement</NavigationLink>
      </>
    );
  }

  const userLinks = (!isAuthenticated || userData.isUser) && (
    <>
      <div className={classes.space} />
      <NavigationLink link="/contact">Contact</NavigationLink>
    </>
  );

  return (
    <div className="z-50">
      <div className={attachedclasses.join(' ')}>
        <Icon
          className={classNames('fixed right-[12px] top-[12px]', classes.icon)}
          name={'next'}
          onClick={() => setShowSideDrawer(false)}
          color="white"
          stroke="white"
          fill="white"
          size={30}
        />

        <nav className={classes.mobileMenu}>
          {authSection}
          <div className={classes.space} />
          {dashboard}
          {navigationLinks}
          {userLinks}
          {userSettings}
        </nav>
      </div>
    </div>
  );
};

export default SideDrawer;
