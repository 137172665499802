import useSelectFilter from '../hooks/useSelectFilter';
import { MEMBERSHIP_STATUS_FILTERS } from '../../../constants';
import { Radio, Space } from 'antd';

const ClientMembershipTypeFilter = ({ onFilter }) => {
  const { onSelectedFilterChanged, isSelectedFilter } = useSelectFilter(MEMBERSHIP_STATUS_FILTERS.ALL, onFilter);

  return (
    <Space direction="vertical" size={2} className="block">
      <h4>Membership Status</h4>
      <Radio
        id={MEMBERSHIP_STATUS_FILTERS.ACTIVE}
        name="membership_status"
        type="radio"
        value={MEMBERSHIP_STATUS_FILTERS.ACTIVE}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(MEMBERSHIP_STATUS_FILTERS.ACTIVE)}>
        Active
      </Radio>

      <Radio
        id={MEMBERSHIP_STATUS_FILTERS.EXPIRED}
        name="membership_status"
        type="radio"
        label="Expired"
        value={MEMBERSHIP_STATUS_FILTERS.EXPIRED}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(MEMBERSHIP_STATUS_FILTERS.EXPIRED)}>
        Expired
      </Radio>
      <Radio
        id={MEMBERSHIP_STATUS_FILTERS.ALL}
        name="membership_status"
        type="radio"
        value={MEMBERSHIP_STATUS_FILTERS.ALL}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(MEMBERSHIP_STATUS_FILTERS.ALL)}>
        All
      </Radio>
    </Space>
  );
};

export default ClientMembershipTypeFilter;
