import { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

const DateRangePicker = ({
  startDate = null,
  endDate = null,
  onChange = () => {},
  onlyPast = false,
  onlyFuture = false,
  row = false,
  className = ''
}) => {
  const [dateFrom, setDateFrom] = useState(startDate);
  const [isDateFromValid, setIsDateFromValid] = useState(true);
  const [dateTo, setDateTo] = useState(endDate);
  const [isDateToValid, setIsDateToValid] = useState(true);
  const [areBothDateValid, setAreBothDateValid] = useState(true);

  useEffect(
    () => {
      const isDateFromCorrect = checkIsDateValid(dateFrom);
      const isDateToCorrect = checkIsDateValid(dateTo);
      const areBothDatesCorrect =
        dateFrom && dateTo ? moment(dateFrom, 'YYYY-MM-DD').isSameOrBefore(moment(dateTo, 'YYYY-MM-DD')) : true;

      if (dateFrom && dateTo && isDateFromCorrect && isDateToCorrect && areBothDatesCorrect) {
        onChange({ dateFrom, dateTo });
      }
      setIsDateFromValid(isDateFromCorrect);
      setIsDateToValid(isDateToCorrect);
      setAreBothDateValid(areBothDatesCorrect);
    },
    //eslint-disable-next-line
    [dateFrom, dateTo]
  );

  const checkIsDateValid = (value) =>
    value
      ? onlyPast
        ? moment(value, 'YYYY-MM-DD').isSameOrBefore(moment())
        : onlyFuture
          ? moment(value, 'YYYY-MM-DD').isSameOrAfter(moment())
          : true
      : true;

  return (
    <div className={className}>
      <div className={row ? 'flex justify-center' : ''}>
        <div className={row ? 'w-[200px] mr-10' : 'w-full'}>
          <div className="formLabel font-bold">From:</div>
          <DatePicker
            allowClear={false}
            className="w-full"
            format="YYYY-MM-DD"
            onChange={(value) => setDateFrom(value.format('YYYY-MM-DD'))}
            value={dayjs(dateFrom)}
          />
          {!isDateFromValid ? (
            <div className="formErrorMsg text-center">Date has to be {onlyPast ? 'in the past' : 'in the future'}</div>
          ) : null}
        </div>
        <div className={row ? 'w-[200px] mr-10' : 'w-full'}>
          <div className="formLabel font-bold">To:</div>
          <DatePicker
            allowClear={false}
            className="w-full"
            format="YYYY-MM-DD"
            onChange={(value) => setDateTo(value.format('YYYY-MM-DD'))}
            value={dayjs(dateTo)}
          />
          {!isDateToValid ? (
            <div className="formErrorMsg text-center">Date has to be {onlyPast ? 'in the past' : 'in the future'}</div>
          ) : null}
        </div>
      </div>
      <div className="formErrorMsg text-center">
        {!areBothDateValid ? 'Date "From" has to be before date "To"' : ''}
      </div>
    </div>
  );
};

export default DateRangePicker;
