import { useContext } from 'react';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import Notification from '../../../../components/UI/Notification/Notification';
import Loading from '../../../../components/Loading/Loading';
import { fetchVideoById } from '../../../../apiFunctions/apiFunctions';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../../context/App/AppContext';
import Title from '../../../../components/UI/Title/Title';
import Button from '../../../../components/UI/Button/Button';

const Viewer = () => {
  const { videoId } = useParams();
  const { userData } = useContext(AppContext);
  const navigate = useNavigate();

  const {
    data: video,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['video', videoId],
    queryFn: () => fetchVideoById(videoId),
    enabled: !!videoId && !!videoId.length
  });

  if (isError || !videoId || !videoId.length) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }
  if (isLoading || !video) {
    return <Loading size={50} className={'mt-10'} />;
  }

  const HLSFiles = video.media && video.media.find((media) => media.type === 'HLS');
  const sources = HLSFiles.playlistUrl ? [HLSFiles.playlistUrl] : [];
  let length;

  if (video.videoConfig) {
    const lengthConfig = video.videoConfig.find((config) => config.name === 'Length');
    if (lengthConfig && lengthConfig.options && lengthConfig.options.length) {
      length = lengthConfig.options[0];
    }
  }

  const onVideoWatched = () => {
    if (userData.isUser) {
      localStorage.setItem('VOD', video._id);
      navigate(-1);
    }
  };

  return (
    <>
      <div className={'titleAndBtnContainer'}>
        <Title title={video.title || 'Video'} />
        <Button secondary label={'Go back'} onClick={() => navigate(-1)} />
      </div>

      <VideoPlayer sources={sources} onVideoComplete={onVideoWatched} />

      <div className={'flex justify-between flex-wrap text-sm'}>
        {video.videoConfig.length > 0 && (
          <div className={'text-sm'}>
            {video.videoConfig.map((elm) => (
              <div key={elm.name} className={'flex'}>
                <div>{elm.name}:</div>
                <div>
                  {elm.options.map((option) => (
                    <div key={option} className={'mx-2'}>
                      {option}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={'text-right'}>
          <div>{moment(video.updatedDate).format('LL') || ''}</div>
          <div>{length || ''}</div>
        </div>
      </div>
    </>
  );
};

export default Viewer;
