import AddPaymentMethod from '../AddPaymentMethod/AddPaymentMethod';
import Title from '../../../components/UI/Title/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { isValueCorrect } from '../../../shared/userFunctions';

const AddPaymentMethodPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={'body-container'}>
      <Title title={'Add card details'} />
      <div className="my-6 mx-auto md:w-1/2">
        <AddPaymentMethod
          title={''}
          redirectTo={() =>
            isValueCorrect(location.state?.pathname)
              ? navigate(location.state.pathname, {
                  state: { ...location.state }
                })
              : navigate(-1)
          }
        />
      </div>
    </div>
  );
};

export default AddPaymentMethodPage;
