import { useContext } from 'react';
import { AppContext } from '../context/App/AppContext';
import { Route } from 'react-router-dom';
import ManagePasses from '../containers/ManagePasses/ManagePasses';
import ManageTimetables from '../containers/ManageBookings/ManageTimetables';
import ManageFAQ from '../containers/FAQ/ManageFAQ';
import ManageKitList from '../containers/ManageKitList/ManageKitList';
import Container from '../hoc/Container/Container';
import HistoryComponent from '../containers/Timetables/HistoryComponent/HistoryComponent';
import UpcomingClassesForAllGyms from '../containers/Timetables/UpcomingClassesForAllGyms/UpcomingClassesForAllGyms';
import ClassPage from '../containers/ClassComponents/ClassPage/ClassPage';
import { CLASS } from '../constants';
import ManageUserSettings from '../containers/ManageUsers/ManageUserSettings/ManageUserSettings';
import ManageMembershipPlans from '../containers/ManageMembershipPlans/ManageMembershipPlans';
import ManageVideos from '../containers/ManageVideos/ManageVideos';
import Viewer from '../containers/ManageVideos/ShowVideos/Viewer/Viewer';
import AddPaymentMethodPage from '../containers/Stripe/AddPaymentMethodPage/AddPaymentMethodPage';
import TermsOfService from '../containers/Pages/TermsOfService/TermsOfService';
import VideosListForVirtualPass from '../containers/ManageBookings/VirtualBookings/VideosListForVirtualPass/VideosListForVirtualPass';

const useRoutes = () => {
  const { isAuthenticated, userData } = useContext(AppContext);

  if (isAuthenticated) {
    const mainPath = userData.isUser ? '/gym' : '/gym/:gymId';
    if (userData.isUser && !userData.isActive) {
      return null;
    }

    return (
      <>
        {/* TERMS OF SERVICE */}
        <Route
          path={`${mainPath}/terms-of-service`}
          element={
            <Container>
              <TermsOfService />
            </Container>
          }
        />
        {/* ALL UPCOMING CLASSES FOR ALL GYMS */}
        <Route
          path={`${mainPath}/timetable/all`}
          element={
            <Container show={userData.isAdminOrTrainer}>
              <UpcomingClassesForAllGyms cancelType="upcomingClassesInAllGyms" />
            </Container>
          }
        />
        {/* ALL HISTORY CLASSES FOR ALL GYMS */}
        <Route
          path={`${mainPath}/class-history`}
          element={
            <Container show={userData.isAdminOrTrainer}>
              <HistoryComponent />
            </Container>
          }
        />
        {/* SINGLE CLASS PAGE */}
        <Route
          path={`${mainPath}/class/:classId`}
          element={
            <Container show={userData.isAdminOrTrainer}>
              <ClassPage />
            </Container>
          }
        />
        {/* CLASS   ----   TYPE */}
        <Route
          path={`${mainPath}/class-timetable`}
          element={
            <Container show={userData.isAuthenticated} gymSettingsRestriction={'class'}>
              <ManageTimetables classType={'class'} />
            </Container>
          }
        />
        <Route
          path={`${mainPath}/class-passes`}
          element={
            <Container show={userData.isUser} gymSettingsRestriction={CLASS.CLASS}>
              <ManagePasses classType={CLASS.CLASS} />
            </Container>
          }
        />
        {/* PERSONAL TRAINING   ----   TYPE */}
        <Route
          path={`${mainPath}/personal-training-timetable`}
          element={
            <Container show={userData.isAuthenticated} gymSettingsRestriction={CLASS.PERSONAL_TRAINING}>
              <ManageTimetables classType={CLASS.PERSONAL_TRAINING} />
            </Container>
          }
        />
        <Route
          path={`${mainPath}/personal-training-passes`}
          element={
            <Container show={userData.isUser} gymSettingsRestriction={CLASS.PERSONAL_TRAINING}>
              <ManagePasses classType={CLASS.PERSONAL_TRAINING} />
            </Container>
          }
        />
        {/* INDUCTION   ----   TYPE */}
        <Route
          path={`${mainPath}/induction-timetable`}
          element={
            <Container show={userData.isAuthenticated} gymSettingsRestriction={CLASS.INDUCTION}>
              <ManageTimetables classType={CLASS.INDUCTION} />
            </Container>
          }
        />
        <Route
          path={`${mainPath}/induction-passes`}
          element={
            <Container show={userData.isUser} gymSettingsRestriction={CLASS.INDUCTION}>
              <ManagePasses classType={CLASS.INDUCTION} />
            </Container>
          }
        />
        {/* GYM   ----   TYPE */}
        <Route
          path={`${mainPath}/gym-timetable`}
          element={
            <Container show={userData.isAuthenticated} gymSettingsRestriction={CLASS.GYM_CLASS}>
              <ManageTimetables classType={CLASS.GYM_CLASS} />
            </Container>
          }
        />
        <Route
          path={`${mainPath}/gym-passes`}
          element={
            <Container show={userData.isUser} gymSettingsRestriction={CLASS.GYM_CLASS}>
              <ManagePasses classType={CLASS.GYM_CLASS} />
            </Container>
          }
        />
        {/* SWIM   ----   TYPE */}
        <Route
          path={`${mainPath}/swim-timetable`}
          element={
            <Container show={userData.isAuthenticated} gymSettingsRestriction={CLASS.SWIMMING_CLASS}>
              <ManageTimetables classType={CLASS.SWIMMING_CLASS} />
            </Container>
          }
        />
        <Route
          path={`${mainPath}/swim-passes`}
          element={
            <Container show={userData.isUser} gymSettingsRestriction={CLASS.SWIMMING_CLASS}>
              <ManagePasses classType={CLASS.SWIMMING_CLASS} />
            </Container>
          }
        />
        {/* MASSAGE   ----   TYPE */}
        <Route
          path={`${mainPath}/massage-timetable`}
          element={
            <Container show={userData.isAuthenticated} gymSettingsRestriction={CLASS.MASSAGE_CLASS}>
              <ManageTimetables classType={CLASS.MASSAGE_CLASS} />
            </Container>
          }
        />
        <Route
          path={`${mainPath}/massage-passes`}
          element={
            <Container show={userData.isUser} gymSettingsRestriction={CLASS.MASSAGE_CLASS}>
              <ManagePasses classType={CLASS.MASSAGE_CLASS} />
            </Container>
          }
        />
        {/* TENNIS   ----   TYPE */}
        <Route
          path={`${mainPath}/tennis-timetable`}
          element={
            <Container show={userData.isAuthenticated} gymSettingsRestriction={CLASS.TENNIS_CLASS}>
              <ManageTimetables classType={CLASS.TENNIS_CLASS} />
            </Container>
          }
        />
        <Route
          path={`${mainPath}/tennis-passes`}
          element={
            <Container show={userData.isUser} gymSettingsRestriction={CLASS.TENNIS_CLASS}>
              <ManagePasses classType={CLASS.TENNIS_CLASS} />
            </Container>
          }
        />
        {/* MEMBERSHIP PLAN */}
        <Route
          path={`${mainPath}/membership-plans`}
          element={
            <Container show={userData.isAdmin || userData.isUser}>
              <ManageMembershipPlans />
            </Container>
          }
        />
        {/* VIDEOS */}
        <Route
          path={`${mainPath}/videos`}
          exact
          element={
            <Container gymSettingsRestriction={'videoOnDemand'} show={userData.isAdmin}>
              <ManageVideos />
            </Container>
          }
        />

        <Route
          path={`${mainPath}/videos/pass/:id`}
          exact
          element={
            <Container gymSettingsRestriction={'videoOnDemand'} show={userData.isUser}>
              <VideosListForVirtualPass />
            </Container>
          }
        />
        <Route
          path={`${mainPath}/videos/:videoId`}
          exact
          element={
            <Container gymSettingsRestriction={'videoOnDemand'} show={userData.isAdmin || userData.isUser}>
              <Viewer />
            </Container>
          }
        />
        {/* FAQ */}
        <Route
          path={`${mainPath}/faq`}
          element={
            <Container gymSettingsRestriction={'faq'}>
              <ManageFAQ />
            </Container>
          }
        />
        {/* KIT LIST */}
        <Route
          path={`${mainPath}/kit-list`}
          element={
            <Container gymSettingsRestriction={'kitList'}>
              <ManageKitList />
            </Container>
          }
        />
        {/* USER ACCOUNT SETTINGS */}
        <Route
          path={`/user/settings`}
          element={
            <Container>
              <ManageUserSettings />
            </Container>
          }
        />
        {/* ADD PAYMENT METHOD */}
        <Route
          path={`/user/add-payment-method`}
          element={
            <Container show={userData.isUser}>
              <AddPaymentMethodPage />
            </Container>
          }
        />
      </>
    );
  }
  return null;
};

export default useRoutes;
