import {
  isActiveMembershipUser,
  isActiveUser,
  isFullAccessAdminOrTrainer,
  isUnlimitedMembershipUser,
  isAdmin,
  isTrainer,
  isUser,
  isMembershipUser,
  isClassPassUser,
  isAdminOrTrainer
} from '../../shared/userFunctions';

export default (userData) => {
  return {
    ...userData,
    isAdmin: isAdmin(userData),
    isUser: isUser(userData),
    isTrainer: isTrainer(userData),
    isAdminOrTrainer: isAdminOrTrainer(userData),

    isActive: isActiveUser(userData),
    isActiveMembershipUser: isActiveMembershipUser(userData),
    isUnlimitedMembershipUser: isUnlimitedMembershipUser(userData),
    isMembershipUser: isMembershipUser(userData),
    isClassPassUser: isClassPassUser(userData),

    isFullAccessAdminOrTrainer: isFullAccessAdminOrTrainer(userData),
    isFullAccessAdmin: isFullAccessAdminOrTrainer(userData) && isAdmin(userData),
    isFullAccessTrainer: isFullAccessAdminOrTrainer(userData) && isTrainer(userData)
  };
};
