import { useContext } from 'react';
import { Select } from 'antd';
import Loading from '../../Loading/Loading';
import { AppContext } from '../../../context/App/AppContext';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { isActiveUser } from '../../../shared/userFunctions';
import { returnClientsListWithSamePTPasses } from '../../../shared/gymFunctions';

const ClientSelector = ({
  showLabel = false,
  onSelect = () => {},
  onlyPTClients = false,
  selectedClass = null,
  isLoading = false,
  excludeClientsArray = []
}) => {
  const { openNotification } = useContext(NotificationContext);
  const { usersList, usersListIsLoading, usersListIsError } = useContext(AppContext);

  if (usersListIsLoading || isLoading) {
    return <Loading />;
  }

  if (usersListIsError) {
    return openNotification(null, 'error', `Unable to fetch clients list`);
  }

  let activeUsersList =
    usersList?.length > 0
      ? usersList.filter((elm) => isActiveUser(elm) && !excludeClientsArray.map((elm) => elm._id).includes(elm._id))
      : [];
  if (onlyPTClients) {
    activeUsersList = returnClientsListWithSamePTPasses(
      activeUsersList,
      selectedClass?.duration,
      selectedClass?.places
    );
  }

  if (activeUsersList.length === 0) {
    return <h4 className="text-custom-red">No clients</h4>;
  }

  return (
    <div>
      {showLabel && <div className="font-bold">Select client</div>}
      <Select
        className="w-full"
        placeholder={'Select client'}
        showSearch
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        options={activeUsersList.map(({ _id, name, last_name }) => ({
          value: _id,
          label: name + ' ' + last_name
        }))}
        onChange={onSelect}
      />
    </div>
  );
};

export default ClientSelector;
