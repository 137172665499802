import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { classNames } from '../../../shared/utility';

const VideoConfig = ({
  categories = { active: [], inActive: [] },
  isActive = true,
  isInactive = false,
  multiple = true,
  className = '',
  onChange = () => {},
  initialState = [],
  labelClassName = ''
}) => {
  const [selected, setSelected] = useState([]);

  useEffect(
    () => {
      if (initialState.length > 0) {
        setSelected(initialState);
      }
    }, //eslint-disable-next-line
    [initialState]
  );
  const handleChange = (id, options, isActiveValue = true, name) => {
    const newState = [...selected];
    const index = newState.findIndex((elm) => elm.name.toLowerCase() === name.toLowerCase());

    if (index < 0) {
      newState.push({ name, options, active: isActiveValue, id });
    } else {
      newState[index] = { ...newState[index], options, active: isActiveValue };
    }

    onChange(newState);
    setSelected(newState);
  };

  const returnSelection = (array, isActiveValue = true) =>
    array.map(({ name, options, id }) => (
      <div key={id} className={classNames('my-2', className)}>
        <div className={classNames('formLabel', labelClassName)}>{name}</div>
        <Select
          defaultValue={initialState.find((elm) => elm.name === name)?.options}
          onChange={(selectedOptions) => handleChange(id, selectedOptions, isActiveValue, name)}
          className={'w-full'}
          mode={multiple && 'multiple'}
          placeholder={`Select ${name}`}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          options={options.map((elm) => ({
            value: elm,
            label: elm,
            key: elm
          }))}
        />
      </div>
    ));

  return (
    <div>
      {isActive ? returnSelection(categories.active, true) : null}
      {isInactive ? returnSelection(categories.inActive, false) : null}
    </div>
  );
};

export default VideoConfig;
