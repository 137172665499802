import { useContext, useState } from 'react';
import { isFullAccessAdminOrTrainer } from '../../../../shared/userFunctions';
import { useMutation } from '@tanstack/react-query';
import { deleteAdmin, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import { AppContext } from '../../../../context/App/AppContext';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import AddEditTrainer from '../AddEditTrainer/AddEditTrainer';
import Loading from '../../../../components/Loading/Loading';
import Icon from '../../../../components/UI/Icon/Icon';
import { Modal } from 'antd';

const SingleTrainer = ({ singleTrainer, show = false }) => {
  const { userData, selectedGym } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showModal, setShowModal] = useState(show);

  const { mutate: onDeleteTrainer, isLoading: onDeleteTrainerIsLoading } = useMutation({
    mutationFn: () => deleteAdmin(singleTrainer._id),
    onSuccess: () => {
      openNotification('delete', 'success');
      refreshFetchFunctions([['trainersList', selectedGym._id]]);
    },
    onError: () => {
      setShowModal(false);
      openNotification('delete', 'error');
    }
  });

  const handleTrainerDelete = () => {
    if (!showConfirm) {
      openNotification(null, 'warning', 'Click once again to confirm the deletion of the admin in all gyms');
      return setShowConfirm(true);
    }
    onDeleteTrainer();
  };

  const handleOpenModal = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <Modal onCancel={handleModalClose} open={showModal}>
        <AddEditTrainer selected={singleTrainer} onCancel={handleModalClose} />
      </Modal>

      <div className={'listItem'}>
        <div className="flex justify-between align-middle">
          <div>
            <div className="font-bold">
              {singleTrainer.name} {singleTrainer.last_name}
            </div>
            {isFullAccessAdminOrTrainer(singleTrainer) && <div className="text-sm">Full access</div>}
          </div>

          {userData._id !== singleTrainer._id ? (
            onDeleteTrainerIsLoading ? (
              <Loading />
            ) : (
              <div className="flex">
                <Icon name={'edit'} onClick={handleOpenModal} />
                <Icon name={'delete'} onClick={handleTrainerDelete} />
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SingleTrainer;
