import { useState, useEffect } from 'react';
import { Select } from 'antd';

export default function ClientProfilePaymentMethodSelection({ onMethodSelected }) {
  const [method, setMethod] = useState('cash');
  useEffect(
    () => onMethodSelected('cash'),
    // eslint-disable-next-line
    []
  );
  const chooseNewMethod = (value) => {
    setMethod(value);
    onMethodSelected(value);
  };

  const methods = ['cash', 'bank transfer', 'card', 'web'];

  return (
    <Select
      className="w-full"
      options={methods.map((elm) => ({ label: elm, value: elm }))}
      value={method}
      onChange={chooseNewMethod}
    />
  );
}
