import { useContext } from 'react';
import RenderForm from '../../../../components/Forms/RenderForm';
import Title from '../../../../components/UI/Title/Title';
import ProfilePicture from '../../../../components/UI/ProfilePicture/ProfilePicture';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import { AppContext } from '../../../../context/App/AppContext';
import dayjs from 'dayjs';
const UserSettings = ({ selectedClient = null, onCancel }) => {
  const { userData, selectedGym, onUpdateUserData, onUpdateUserDataIsLoading, gymsList } = useContext(AppContext);

  const isAdminEditingUserData = selectedClient !== null;
  const onFormFinish = (data) =>
    onUpdateUserData({ userId: isAdminEditingUserData ? selectedClient._id : userData._id, data });
  const formFields = [
    'title',
    'userName',
    'lastName',
    'dateofbirth',
    'email',
    'phone',
    'addressLine',
    'city',
    'postcode',
    'emergencyName',
    'emergencyPhone',
    'marketing'
  ];
  const initialValues = isAdminEditingUserData
    ? { ...selectedClient, dateofbirth: dayjs(selectedClient.dateofbirth, 'YYYY-MM-DD') }
    : { ...userData, dateofbirth: dayjs(userData.dateofbirth, 'YYYY-MM-DD') };
  if (isAdminEditingUserData) {
    formFields.unshift('selectGym');
  }

  return (
    <div>
      <Title title="Account settings" subtitle={isAdminEditingUserData ? '' : 'Your account settings'} />
      <div className={isAdminEditingUserData ? '' : 'md:grid grid-cols-[60%_40%] gap-8'}>
        <RenderForm
          initialValues={initialValues}
          onFinish={onFormFinish}
          formFields={formFields}
          btnLabel="Save"
          btnIsLoading={onUpdateUserDataIsLoading}
          gymsList={gymsList}
          onCancel={onCancel}
          cancelBtn={isAdminEditingUserData}
          gymSettings={selectedGym.settings}
        />
        <div>
          {!isAdminEditingUserData && <ProfilePicture user={userData} isEditable />}
          {!isAdminEditingUserData && <PaymentMethods />}
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
