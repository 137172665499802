import { useContext } from 'react';

import { AppContext } from '../../context/App/AppContext';

import AdminManageMembershipPlans from './AdminView/AdminManageMembershipPlans';
import ClientSubscribeToMembershipPlan from './UserView/ClientSubscribeToMembershipPlan/ClientSubscribeToMembershipPlan';

const ManageMembershipPlans = () => {
  const { userData } = useContext(AppContext);
  return userData.isUser ? <ClientSubscribeToMembershipPlan /> : <AdminManageMembershipPlans />;
};
export default ManageMembershipPlans;
