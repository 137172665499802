import { useContext } from 'react';
import RenderForm from '../../../components/Forms/RenderForm';
import { useMutation } from '@tanstack/react-query';
import { addTemplate, editTemplate } from '../../../apiFunctions/apiFunctions';
import { AppContext } from '../../../context/App/AppContext';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { CLASS } from '../../../constants';

const AddEditTemplate = ({ classType, selectedTemplate = null, onCancel = () => {}, templatesListRefetch }) => {
  const { openNotification } = useContext(NotificationContext);
  const { gymId, selectedGym } = useContext(AppContext);
  const isNew = selectedTemplate === null;

  const { mutate: onTemplateSave, isLoading: onTemplateSaveIsLoading } = useMutation({
    mutationFn: (formData) =>
      isNew ? addTemplate(gymId, { ...formData, classType }) : editTemplate(selectedTemplate._id, formData),
    onSuccess: () => {
      templatesListRefetch();
      openNotification(null, 'success', isNew ? 'Successfully created!' : 'Successfully updated!');
      onCancel();
    },
    onError: () => {
      openNotification('default', 'error');
    }
  });

  let formFields = [
    'name',
    'defaultDescription',
    'currency',
    'defaultCost',
    'defaultLimit',
    'defaultDuration',
    'defaultMinimalTimeCancellationInHours',
    'active',
    'private'
  ];
  if (classType === CLASS.CLASS) {
    if (selectedGym.settings.membershipProducts) {
      formFields.splice(4, 0, 'membershipClassPrice');
    }
  }

  let initialValues = {
    currency: 'gbp',
    defaultDuration: 60,
    defaultMinimalTimeCancellationInHours: 2,
    active: true,
    private: false
  };
  if (!isNew) {
    initialValues = { ...initialValues, ...selectedTemplate };
  }

  return (
    <RenderForm
      formFields={formFields}
      initialValues={initialValues}
      onFinish={onTemplateSave}
      btnIsLoading={onTemplateSaveIsLoading}
      cancelBtn={!isNew}
      onCancel={onCancel}
    />
  );
};

export default AddEditTemplate;
