export default {
  white: '#fff',
  black: '#000',
  'custom-ok': '#f6ffed',
  'custom-ok-border': '#b7eb8f',
  'custom-error': '#fff2f0',
  'custom-error-border': '#ffccc7',
  'custom-hover': '#f5f5f5',
  'custom-red': '#ff4d4f',
  'custom-add-to-calendar-bg': '#eae9ed',
  'custom-icon-ok': '#389e0d',
  'custom-icon-warning': '#fcd34d',
  'custom-icon-error': '#f5222d'
};
