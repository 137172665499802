import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { bulkClassEditing, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import RenderForm from '../../../components/Forms/RenderForm';
import { AppContext } from '../../../context/App/AppContext';

const BulkEditingForm = ({ data, fields, closeModal, classType }) => {
  const { openNotification } = useContext(NotificationContext);
  const { trainersList } = useContext(AppContext);

  const { mutate: onBulkClassEditing, isLoading: onBulkClassEditingIsLoading } = useMutation(
    (formData) => bulkClassEditing(data.map((elm) => ({ ...formData, _id: elm._id }))),
    {
      onSuccess: () => {
        openNotification('update', 'success');
        refreshFetchFunctions([['upcomingClasses', classType]]);
        closeModal();
      },
      onError: () => {
        openNotification('update', 'error');
      }
    }
  );

  let formFields = [
    'trainer',
    'name',
    'description',
    'location',
    'limit',
    'classTime',
    'classDate',
    'duration',
    'minimalTimeCancellationInHours',
    'cost',
    'waitListConfig',
    'private',
    'sendClassChangeNotification'
  ].filter((elm) => fields.includes(elm) || elm === 'sendClassChangeNotification');

  return (
    <div>
      <RenderForm
        formFields={formFields}
        trainersList={trainersList}
        onFinish={onBulkClassEditing}
        btnLabel="Submit changes"
        btnIsLoading={onBulkClassEditingIsLoading}
      />
    </div>
  );
};

export default BulkEditingForm;
