import { useContext, useEffect, useState } from 'react';
import Logo from '../../UI/Logo/Logo';
import MenuNavigation from '../MenuNavigation/MenuNavigation';
import Button from '../../UI/Button/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import UserNavigation from '../UserNavigation/UserNavigation';
import companyDetails from '../../../brandConfig/companyDetails';
import { AppContext } from '../../../context/App/AppContext';
import { menuItemsArray } from '../MenuNavigation/menuItems';

const Header = ({ showSideDrawer, setShowSideDrawer }) => {
  const { userData, isAuthenticated, selectedGym, gymsList } = useContext(AppContext);
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();
  const dashboardLink = userData.isAdminOrTrainer && selectedGym !== undefined ? `/gym/${selectedGym._id}` : '/gym';

  useEffect(() => {
    if (userData !== undefined && selectedGym !== undefined) {
      if (userData.isUser && userData.isActive) {
        setMenuItems(menuItemsArray(userData, selectedGym.settings, '/gym', gymsList, 'showInMenu'));
      } else if (gymsList !== undefined) {
        setMenuItems(menuItemsArray(userData, selectedGym.settings, `/gym/${selectedGym._id}`, gymsList, 'showInMenu'));
      }
    }
  }, [selectedGym, userData, gymsList]);

  const signUpBtn =
    location.pathname !== '/' && !isAuthenticated && selectedGym ? (
      <Button
        label="SIGN UP"
        data-cy="btn_go_to_signup"
        onClick={() => navigate(`/gym/${selectedGym._id}/signup`)}
        primary
      />
    ) : null;

  const loginBtn = !isAuthenticated ? (
    <Button label="LOGIN" secondary onClick={() => navigate('/login')} primary />
  ) : null;

  const loggedInMenu = isAuthenticated ? (
    <div className="flex items-center">
      <div className={'hidden md:block'}>
        <UserNavigation />
      </div>

      <MenuNavigation
        dashboardLink={dashboardLink}
        menuItems={menuItems}
        show={userData.isAdminOrTrainer ? location.pathname !== '/gym' : true}
        setShowSideDrawer={setShowSideDrawer}
        showSideDrawer={showSideDrawer}
      />

      <Button
        className={'hidden md:block'}
        label="Dashboard"
        dataCy="dashboard_btn"
        onClick={() => navigate(dashboardLink)}
        primary
      />
    </div>
  ) : null;

  return (
    <div className="p-4 sm:p-6 md:p-8 grid grid-cols-[70%_30%] md:grid-cols-2 justify-between items-center z-[100] h-[110px]">
      <Logo
        dark={(companyDetails.isHomepageHeroDark && location.pathname === '/') || location.state?.isHomepageHeroDark}
        isAuthenticated={isAuthenticated}
      />

      <div className="flex items-center z-10 mr-0 ml-auto">
        {loginBtn}
        {signUpBtn}
        {loggedInMenu}
      </div>
    </div>
  );
};

export default Header;
