import { useContext } from 'react';
import { AppContext } from '../../../context/App/AppContext';
import Notification from '../../../components/UI/Notification/Notification';
import Title from '../../../components/UI/Title/Title';
import { isMembershipPaid } from '../../../shared/userFunctions';

const ClientStatusNotification = () => {
  const { selectedGym, userData } = useContext(AppContext);

  const { status, name, last_name } = userData;
  const waitingForApproval = (
    <div>
      Thank you for registering on our platform. Please allow 1 working day for our team to review your account
      application. After 1 working day please log back into your account and you should be able to make bookings. If you
      are still unable to access your account after this time period, please contact us
      {selectedGym.email === undefined || selectedGym.email.length === 0 ? (
        ' '
      ) : (
        <span>
          {' '}
          on <a href={`mailto:${selectedGym.email}?subject=${name}%20${last_name}-Status`}>{selectedGym.email}</a>{' '}
        </span>
      )}
      along with your full name for more support.
    </div>
  );
  const permissionDenied = 'Your access to the gym has been denied, if you believe this is an error please contact us.';
  const inactive = 'Your account has been marked as inactive, if you think this is an error please contact us.';
  const membershipNotPaid = 'Your membership plan expired. Please contact us.';

  return (
    <>
      <Title title={status === 'inactive' ? 'Your account is inactive.' : 'Your account needs approval'} />
      <Notification status={['inactive', 'permissionDenied'].includes(status) ? 'critical' : 'warning'}>
        {status === 'waitingForApproval'
          ? waitingForApproval
          : status === 'permissionDenied'
            ? permissionDenied
            : isMembershipPaid(userData)
              ? membershipNotPaid
              : inactive}
      </Notification>
    </>
  );
};

export default ClientStatusNotification;
