import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { orderAndPayForPass, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { classNames, getCurrencyDetail, returnErrorFromResponse } from '../../../shared/utility';
import { Modal } from 'antd';
import GoogleApplePayModal from '../../ActionButtons/GoogleApplePayButton/GoogleApplePayModal';
import { useStripe } from '@stripe/react-stripe-js';
import CouponField from '../../ManageCoupons/CouponField/CouponField';
import Button from '../../../components/UI/Button/Button';
import { AppContext } from '../../../context/App/AppContext';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import Icon from '../../../components/UI/Icon/Icon';
import { isValueCorrect } from '../../../shared/userFunctions';

const RenderPassActionButtons = ({
  classPass,
  editPass = () => {},
  buttonStyle = {},
  classType,
  label = 'Pay',
  successMessage = 'Successfully paid',
  goBack = true
}) => {
  const {
    userData: { isUser, isAdmin },
    userPaymentSources,
    selectedGym
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const currency = getCurrencyDetail(classPass.currency).value;
  const stripe = useStripe();
  const { openNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const areCouponsAvailable = selectedGym.settings.coupon;
  const location = useLocation();

  useEffect(() => {
    if (isValueCorrect(location.state?.itemId) && location.state?.itemId === classPass._id) {
      setShowModal(true);
    }
  }, [location, classPass]);

  const mutateOrderAndPay = useMutation(() => orderAndPayForPass(classPass._id, 'web', currency, coupon), {
    onSuccess: async ({ status, clientSecret }) => {
      if (status === 'requires_action') {
        const result = await stripe.confirmCardPayment(clientSecret, {});
        if (result.error) {
          openNotification(null, 'error', result.error?.message);
        }
      }
      setShowModal(false);
      setCoupon(null);
      openNotification(null, 'success', successMessage);

      await refreshFetchFunctions([['userData'], ['passes', classType], ['videoPasses']]);
      if (!isValueCorrect(location.state?.itemId) && goBack) {
        navigate(-1);
      }
    },
    onError: (err) => {
      openNotification(
        null,
        'error',
        err.response.data.errors[0].param === 'coupon' ? err.response.data.errors[0].msg : returnErrorFromResponse(err)
      );
    }
  });

  if (isAdmin) {
    return <Icon name={'edit'} onClick={() => editPass(classPass)} data-cy={'edit_icon'} />;
  }

  if (isUser) {
    if (userPaymentSources?.length === 0 && classPass.cost > 0) {
      return <GoogleApplePayModal product={classPass} productType={'pass'} classType={classType} />;
    }

    const renderModal = (
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        <CouponField productId={classPass._id} setCoupon={setCoupon} type={'pass'} show={areCouponsAvailable} />

        <div className={classNames('text-center', areCouponsAvailable && 'mt-9')}>
          Do you want to pay for the selected product? <br />
          This product will be paid for using your default card payment. You can change it{' '}
          <Link className="text-secondary font-bold" to="/user/add-payment-method">
            here
          </Link>{' '}
          if you prefer.
          <ModalButtonsContainer isLoading={mutateOrderAndPay.isLoading}>
            <Button dataCy="yes_btn" label="Yes" secondary onClick={mutateOrderAndPay.mutate} />
            <Button dataCy="cancel_btn" label="Cancel" primary onClick={() => setShowModal(false)} />
          </ModalButtonsContainer>
        </div>
      </Modal>
    );

    const openModal = () => {
      if (classPass?.cost === 0) {
        return mutateOrderAndPay.mutate();
      }
      setShowModal(true);
    };

    return (
      <>
        {renderModal}
        <Button
          fullWidth
          style={{ ...buttonStyle }}
          dataCy="payBtn"
          label={label}
          secondary
          onClick={openModal}
          disabled={mutateOrderAndPay.isLoading}
        />
      </>
    );
  }

  return null;
};

export default RenderPassActionButtons;
