import { createContext } from 'react';
import { notification } from 'antd';
import notificationDefaultMessages from './notificationDefaultMessages';

//Statuses: 'success' | 'info' | 'warning' | 'error';
export const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type = null, status = 'success', message = '', description = null) => {
    api[status]({
      message: type !== null ? notificationDefaultMessages[type][status].message : message,
      description: type !== null ? notificationDefaultMessages[type][status].description : description,
      placement: 'top'
    });
  };

  const value = {
    openNotification
  };

  return (
    <NotificationContext.Provider value={value}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
export default NotificationProvider;
