import { useContext, useState } from 'react';
import { AppContext } from '../../../context/App/AppContext';
import Icon from '../../../components/UI/Icon/Icon';
import Button from '../../../components/UI/Button/Button';
import { Input, Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { editGym, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { returnFirstErrorMsg } from '../../../shared/utility';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
const EddiAssociatedNames = () => {
  const { selectedGym } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const [name, setName] = useState(null);
  const [editedName, setEditedName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(true);
  const [associatedNameIndex, setAssociatedNameIndex] = useState(null);

  const { mutate: onSave, isLoading: onSaveIsLoading } = useMutation({
    mutationFn: (associatedNames) =>
      editGym(selectedGym._id, {
        associatedNames
      }),
    onSuccess: () => {
      openNotification(null, 'success', 'Successfully updated!');
      refreshFetchFunctions([['selectedGym', selectedGym._id]]);
      handleModalClose();
    },
    onError: (resp) => openNotification(null, 'error', returnFirstErrorMsg(resp))
  });
  const onIconClick = (isEditModalType, index) => {
    setShowModal(true);
    setIsEditModal(isEditModalType);
    setAssociatedNameIndex(index);
    setEditedName(selectedGym.associatedNames[index]);
  };

  const returnNamesList =
    selectedGym.associatedNames.length === 0 ? (
      <h3>No associated names</h3>
    ) : (
      selectedGym.associatedNames.map((elm, index) => (
        <div className="listItem grid grid-cols-[90%_10%]" key={index}>
          <div>{elm}</div>
          <div className="flex">
            <Icon name={'edit'} onClick={() => onIconClick(true, index)} />
            <Icon name={'delete'} onClick={() => onIconClick(false, index)} />
          </div>
        </div>
      ))
    );
  const handleModalClose = () => {
    setShowModal(false);
    setAssociatedNameIndex(null);
    setName(null);
  };

  const returnModalContent = () => {
    return isEditModal ? (
      <div>
        <div>Do you want to change associated name?</div>
        <Input
          onChange={(e) => setEditedName(e.target.value)}
          placeholder={'Edit associated name'}
          value={editedName}
        />
      </div>
    ) : (
      <div className="text-center">
        Are you sure you want to delete below associated name?
        <div className="font-bold">{selectedGym.associatedNames[associatedNameIndex]}</div>
      </div>
    );
  };

  const returnAssociatedNamesArray = (type) => {
    let data = [...selectedGym.associatedNames];
    if (type === 'addNew') {
      data.push(name.trim().replace(/ /g, '-'));
    } else if (type === 'edit') {
      data[associatedNameIndex] = editedName.trim().replace(/ /g, '-');
    } else {
      data.splice(associatedNameIndex, 1);
    }
    return data;
  };

  return (
    <div>
      <Modal open={showModal} onCancel={handleModalClose}>
        {returnModalContent()}
        <ModalButtonsContainer isLoading={onSaveIsLoading}>
          <Button
            label="OK"
            secondary
            onClick={() => onSave(returnAssociatedNamesArray(isEditModal ? 'edit' : 'delete'))}
          />
          <Button label="Cancel" primary onClick={handleModalClose} />
        </ModalButtonsContainer>
      </Modal>

      <div className="sm:grid grid-cols-[80%_20%] mb-4">
        <Input onChange={(e) => setName(e.target.value)} placeholder={'Add new associated name'} value={name} />
        <Button
          fullWidth
          primary
          label={'add'}
          onClick={() => onSave(returnAssociatedNamesArray('addNew'))}
          isLoading={onSaveIsLoading}
          disabled={!name?.length > 0}
        />
      </div>

      {returnNamesList}
    </div>
  );
};

export default EddiAssociatedNames;
