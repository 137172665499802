import { useState, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';
import { classNames } from '../../../../shared/utility';

import { deleteVideo, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import Icon from '../../../../components/UI/Icon/Icon';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Button from '../../../../components/UI/Button/Button';
import { Modal } from 'antd';
import AddEditVideo from '../../VideosList/AddEditVideo/AddEditVideo';

const Thumbnail = ({
  video = {},
  isAdmin = false,
  categories = { active: [] },
  productList = [],
  gymId = '',
  isSelector = false,
  onSelect = () => {},
  isDefaultSelected = false,
  locked = false,
  className = '',
  isLastWatchedVideo = false
}) => {
  const { openNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isSelected, setIsSelected] = useState(isDefaultSelected);
  const navigate = useNavigate();
  const thumbnailUrl = video.media.find((media) => media.type === 'Thumbnail').files[0].path;

  const { mutate: onDeleteVideo, isLoading: onDeleteVideoIsLoading } = useMutation(
    () =>
      deleteVideo(
        video._id,
        productList.filter((elm) => elm.videos.includes(video._id))
      ),
    {
      onSuccess: () => {
        refreshFetchFunctions([['fetchVideos'], ['fetchPasses', 'videoPasses']]);
        openNotification('delete', 'success');
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );
  const onCloseModal = () => setShowModal(false);
  const returnModalContent = () => {
    if (isEdit) {
      return (
        <AddEditVideo selected={video} productsList={productList} categories={categories} onCancel={onCloseModal} />
      );
    }
    return (
      <>
        <div className="text-center">Are you sure you want to delete video: {video.title} ?</div>
        <ModalButtonsContainer isLoading={onDeleteVideoIsLoading}>
          <Button label="delete" secondary onClick={onDeleteVideo} />
          <Button label="cancel" primary onClick={onCloseModal} />
        </ModalButtonsContainer>
      </>
    );
  };
  const handleSelection = (id) => {
    setIsSelected((state) => !state);
    onSelect(id);
  };

  return (
    <>
      <Modal open={showModal} onCancel={onCloseModal}>
        {returnModalContent()}
      </Modal>
      <div
        className={classNames(
          'w-full max-w-[300px] min-w-[150px] pb-3  m-2 relative',
          !isSelector && 'hover:brightness-50 hover:text-custom-accent',
          className
        )}>
        <img
          src={thumbnailUrl}
          alt={video.title}
          className={classNames(
            'rounded-t-lg object-cover',
            locked ? 'cursor-not-allowed blur-sm' : 'cursor-pointer',
            isSelected && 'brightness-50'
          )}
          onClick={() =>
            locked
              ? null
              : isSelector
                ? handleSelection(video._id)
                : navigate(isAdmin ? `/gym/${gymId}/videos/${video._id}` : `/gym/videos/${video._id}`)
          }
        />
        <Icon
          name={'lock'}
          cursorPointer={false}
          className={'absolute top-[calc(50%-40px)] left-[calc(50%-30px)]'}
          show={locked}
          size={40}
        />

        <div
          className={classNames(
            isAdmin ? 'grid grid-cols-[1fr_80px] gap-x-2' : '',
            isSelected && 'rounded-b-lg bg-custom-ok-border brightness-50'
          )}>
          <div className={'ml-2 my-2 font-bold items-center'}>{video.title}</div>
          {isAdmin && !isSelector && (
            <div className={'flex my-2'}>
              <Icon
                name={'edit'}
                onClick={() => {
                  setIsEdit(true);
                  setShowModal(true);
                }}
              />
              <Icon
                name={'trash'}
                onClick={() => {
                  setIsEdit(false);
                  setShowModal(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Thumbnail;

/*

/!*
  const [toast, setToast] = useState(null);
  const [openSaveDialogue, setOpenSaveDialogue] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [videoMetaData, setVideoMetaData] = useState({
    title: '',
    selectedTrainer: '',
    videoConfig: []
  });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { isLoading: metaDataIsLoading, mutateAsync: mutateMetaData } = useMutation(({ videoId, body }) =>
    editVideo(videoId, body)
  );
  const { isLoading: removeVideoFromProductsIsLoading, mutateAsync: mutateRemoveVideoFromProducts } = useMutation(
    ({ videoId, products }) => removeVideoFromProducts(videoId, products)
  );
  const { isLoading: addVideoToProductsIsLoading, mutateAsync: mutateAddVideoToProducts } = useMutation(
    ({ videoId, products }) => addVideoToProducts(videoId, products)
  );
  const { isLoading: deleteIsLoading, mutateAsync: mutateDeleteVideo } = useMutation(({ videoId, products }) =>
    deleteVideo(videoId, products)
  );



  const isValid = useCallback(() => {
    if (videoMetaData.title.length) {
      return true;
    }
    return false;
  }, [videoMetaData]);

  const setConfig = (productList, video) => {
    const { title, trainer, videoConfig } = video;
    setSelectedProducts(
      productList
        ? productList
            .filter((product) => product.videos && product.videos.includes(video._id))
            .map((product) => ({
              label: product.name,
              value: product._id
            }))
        : []
    );
    setVideoMetaData({
      title,
      selectedTrainer: trainer,
      videoConfig
    });
  };

  useEffect(() => {
    setConfig(productList, video);
  }, [productList, video]);

  const onSave = async () => {
    const selectedProductIds = selectedProducts.map((selectedProduct) => selectedProduct.value);
    const deletedFrom = productList.filter(
      (product) => product.videos && product.videos.includes(video._id) && !selectedProductIds.includes(product._id)
    );
    const addedTo = productList.filter(
      (product) =>
        !product.videos ||
        (product.videos && !product.videos.includes(video._id) && selectedProductIds.includes(product._id))
    );
    const titleChanged = video.title !== videoMetaData.title;
    const trainerChanged = video.trainer !== videoMetaData.selectedTrainer;
    const categoriesChanged = !isEqual(video.videoConfig, videoMetaData.videoConfig);

    try {
      if (titleChanged || trainerChanged || categoriesChanged) {
        await mutateMetaData({
          videoId: video._id,
          body: {
            ...(titleChanged ? { title: videoMetaData.title } : {}),
            ...(trainerChanged ? { trainer: videoMetaData.selectedTrainer } : {}),
            ...(categoriesChanged ? { videoConfig: videoMetaData.videoConfig } : {})
          }
        });
      }
      if (deletedFrom.length) {
        await mutateRemoveVideoFromProducts({
          videoId: video._id,
          products: deletedFrom
        });
      }
      if (addedTo.length) {
        await mutateAddVideoToProducts({
          videoId: video._id,
          products: addedTo
        });
      }
      setToast({
        status: 'ok',
        msg: 'Video Updated',
        show: true
      });
      refreshFetchFunctions(['fetchVideos', 'fetchPasses'], 0);
    } catch (error) {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(error),
        show: true
      });
    }
    setOpenSaveDialogue(false);
  };



  const getContent = () => (
    <>
      <div className={classes.thumbnailImg}>
        {locked && <Lock className={classes.lockIcon} />}

        <Image src={thumbnailUrl} alt={video.title} fit="cover" />
      </div>
      <div className={classes.content}>
        <Heading tag="h4">{video.title}</Heading>
      </div>
    </>
  );

  return (
    <>
      <CustomToast toast={toast} />
      <Modal
        appElement={document.getElementById('root')}
        isOpen={openSaveDialogue}
        onRequestClose={() => {
          setOpenSaveDialogue(false);
          setConfig(productList, video);
        }}
        className={`ss-modal`}>
        {metaDataIsLoading || addVideoToProductsIsLoading || removeVideoFromProductsIsLoading ? (
          <div className={classes.spinner}>
            <Loading size="xlarge" />
          </div>
        ) : (
          <>

            </div>
          </>
        )}
      </Modal>
      <Modal

          <>
            <Heading tag="h3">{`Are you sure you wish to delete ${video.title}?`}</Heading>
            <div className={classes.buttonBar}>
              <CustomButton label="Cancel" secondary={true} onClick={() => setOpenDeleteDialogue(false)} />
              <CustomButton label="Delete" primary onClick={onDelete} />
            </div>
          </>
        )}
      </Modal>

      <div className={`${classes.thumbnail} ${locked ? classes.locked : ''}`}>
        {picker ? (
          <div
            className={`${classes.picker} ${selected ? classes.selected : ''}`}
            onClick={() => onPick(video)}
            aria-label={`Pick ${video.title}`}>
            {getContent()}
          </div>
        ) : (
          <Link
            key={video._id}
            aria-label={`View ${video.title}`}
            to={isAdmin ? `/gym/${gymId}/videos/${video._id}` : `/gym/videos/${video._id}`}
            className={`${classes.link} ${watched ? classes.watched : ''} ${locked ? classes.locked : ''}`}>
            {getContent()}
          </Link>
        )}
        {isAdmin && !picker ? (
          <div className={classes.actionBar}>
            <CustomButton icon={<EditIcon />} onClick={() => setOpenSaveDialogue(true)} />
            <CustomButton icon={<DeleteIcon />} onClick={() => setOpenDeleteDialogue(true)} />
          </div>
        ) : null}
      </div>
    </>
  );
};



*!/
*/
