import { Checkbox } from 'antd';

export const returnInitialStateForSelectedClassType = (classTypes, allSelected = false) => {
  let result = {};
  classTypes.forEach((classType) => (result[classType.type] = allSelected));
  return result;
};

const ClassTypesCheckboxes = ({ classTypes, label = '', selectedClassType = [], onChange, row = false }) => {
  return (
    <div>
      <div className={'font-bold'}>{label}</div>
      <div
        className={row ? 'grid items-center' : 'flex flex-col'}
        style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(110px, 1fr))' }}>
        {classTypes.map(({ title, type }) => (
          <Checkbox
            key={type}
            checked={selectedClassType[type]}
            onChange={(e) => onChange({ name: type, value: e.target.checked })}>
            <span className="text-sm">{title}</span>
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default ClassTypesCheckboxes;
