import { CLASS, TIMETABLE_DISPLAY_FIELDS } from '../constants';
import { updateObject } from '../shared/utility';
import { returnTimetableDisplaySettings } from '../shared/gymFunctions';

const timetableFieldsPerType = {
  upcomingAllGyms: {
    admin: ['gym', 'name', 'date', 'time', 'duration', 'cost', 'trainer', 'attendees', 'actionColumn'],
    trainer: ['gym', 'name', 'date', 'time', 'duration', 'cost', 'trainer', 'attendees', 'actionColumn']
  },
  upcomingPublicClasses: ['name', 'date', 'time', 'endDate', 'duration', 'trainer'],
  history: {
    admin: ['name', 'date', 'time', 'duration', 'cost', 'trainer', 'attendees', 'actionColumn'],
    trainer: ['name', 'date', 'time', 'duration', 'cost', 'trainer', 'attendees', 'actionColumn']
  },
  upcomingTrainers: {
    trainer: ['name', 'date', 'time', 'duration', 'cost', 'attendees', 'actionColumn']
  },
  noViewSetUp: ['date', 'time', 'actionColumn'],
  noViewSetUpForPT: ['date', 'time', 'notesForClient', 'notesForCompany', 'actionColumn']
};

const useTimetableFields = (selectedGym, classType, userData, type = 'upcoming') => {
  const timetableDisplaySettings = returnTimetableDisplaySettings(selectedGym.settings);

  if (userData === null) {
    return {
      tableFields: TIMETABLE_DISPLAY_FIELDS.filter((elm) => timetableFieldsPerType[type].includes(elm.name))
        .map((elm) => updateObject(elm, { value: true, showInTimetable: true }))
        .sort((a, b) => timetableFieldsPerType[type].indexOf(a.name) - timetableFieldsPerType[type].indexOf(b.name)),
      isSingleDayTimetable: false,
      isViewSetUp: true
    };
  }
  const userRole = userData.role;
  const isPT = classType === CLASS.PERSONAL_TRAINING;

  let tableFields = timetableDisplaySettings[classType][userRole];

  const isSingleDayTimetable = isPT
    ? false
    : type === 'upcoming' && tableFields.find((elm) => elm.name === 'dayView').value;

  const isViewSetUp = {
    user:
      type === 'upcoming'
        ? !timetableDisplaySettings[classType].user.find((elm) => elm.name === 'noSettings').value
        : true,
    admin:
      type === 'upcoming'
        ? !timetableDisplaySettings[classType].admin.find((elm) => elm.name === 'noSettings').value
        : true,
    trainer:
      type === 'upcoming'
        ? !timetableDisplaySettings[classType].trainer.find((elm) => elm.name === 'noSettings').value
        : true
  };

  if (!isViewSetUp[userRole]) {
    tableFields = tableFields.filter(({ name }) =>
      isPT ? timetableFieldsPerType.noViewSetUpForPT.includes(name) : timetableFieldsPerType.noViewSetUp.includes(name)
    );
  } else if (type === 'upcoming') {
    tableFields = tableFields.filter(({ name }) => name !== 'dayView').filter((elm) => elm.value);

    if (isSingleDayTimetable) {
      tableFields = tableFields.filter(({ name }) => name !== 'date');
    }
  } else {
    tableFields = tableFields.filter(({ name }) => timetableFieldsPerType[type][userRole].includes(name));
  }

  return {
    tableFields,
    isSingleDayTimetable,
    isViewSetUp: isViewSetUp.user && isViewSetUp.admin && isViewSetUp.trainer
  };
};

export default useTimetableFields;
