import { useState } from 'react';
import Button from '../../../components/UI/Button/Button';
import { getDiscountedPriceForCoupon } from '../../../apiFunctions/apiFunctions';
import { getCurrencySymbol } from '../../../shared/utility';
import { useMutation } from '@tanstack/react-query';
import { Input } from 'antd';
import Notification from '../../../components/UI/Notification/Notification';

const returnSuccessMsg = (response, type = 'pass') => {
  if (type === 'pass') {
    let message = response.message;
    let lastIndex = message.lastIndexOf(' ');
    let priceWithCurrency = getCurrencySymbol(response.currency) + message.substring(lastIndex, message.length);
    message = message.substring(0, lastIndex);

    if (response.discountedCost !== 0) {
      return `${message} ${priceWithCurrency}, you have to pay only ${getCurrencySymbol(response.currency)}${
        response.discountedCost
      } for the pass of worth ${getCurrencySymbol(response.currency)} ${response.actualCost}`;
    } else if (response.discountedCost <= 0) {
      return `${message} ${priceWithCurrency},  New cost is ${getCurrencySymbol(response.currency)} 0`;
    }
    return null;
  }
};

const CouponField = ({ productId, setCoupon, type = 'pass', show = true }) => {
  const [msg, setMsg] = useState(null);
  const [promoCode, setPromoCode] = useState('');

  const mutateApplyPromoCode = useMutation(() => getDiscountedPriceForCoupon(productId, promoCode), {
    onSuccess: (response) => {
      setMsg(returnSuccessMsg(response, type));
      setCoupon(promoCode);
    },
    onError: (error) => {
      setMsg(error.response.data.errors[0].msg);
    }
  });

  const handleApplyBtn = () => {
    if (productId !== undefined && productId !== null && promoCode.length > 0) {
      setMsg(null);
      mutateApplyPromoCode.mutate();
    }
  };

  const handlePromoCodeChange = (e) => {
    if (e.target.value !== promoCode) {
      setMsg('Please apply coupon by clicking on the button');
    }
    setPromoCode(e.target.value.trim());
    setCoupon(e.target.value.trim());
  };
  const renderMsg = () => msg && <Notification message={msg} status={'warning'} />;

  if (show) {
    return (
      <div>
        {renderMsg()}
        Please enter a coupon code if you have any
        <div className="flex">
          <Input
            placeholder="Enter coupon code"
            value={promoCode}
            className=""
            onChange={handlePromoCodeChange}
            data-cy="coupon_code"
          />
          <Button
            isLoading={mutateApplyPromoCode.isLoading}
            label="Apply"
            secondary
            onClick={handleApplyBtn}
            dataCy="coupon_apply_btn"
          />
        </div>
      </div>
    );
  }
  return null;
};

export default CouponField;
