import { useContext, useEffect } from 'react';
import Loading from '../../../components/Loading/Loading';
import Notification from '../../../components/UI/Notification/Notification';
import Title from '../../../components/UI/Title/Title';
import { useNavigate, Navigate } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import { AppContext } from '../../../context/App/AppContext';
import Filters from '../../../components/Filters/Filters';
import useFilters from '../../../components/Filters/useFilters';
import NoResultsFound from '../../../components/UI/NoResultsFound/NoResultsFound';

export default () => {
  const navigate = useNavigate();
  const { userData, gymsList, gymsListIsError, gymsListIsLoading, onGymChange } = useContext(AppContext);
  const { onFilters, filteredData, setFiltersInitialData } = useFilters();

  useEffect(
    () => {
      setFiltersInitialData(gymsList !== undefined ? gymsList : []);
    }, //eslint-disable-next-line
    [gymsList]
  );

  if (!userData.isFullAccessAdminOrTrainer && userData.gymId.length === 1) {
    return <Navigate to={`/gym/${userData.gymId[0]}`} />;
  }

  if (gymsListIsLoading) {
    return <Loading />;
  }
  if (gymsListIsError) {
    return (
      <div className={'body-container'}>
        <Notification defaultMsg />
      </div>
    );
  }

  const handleGymChange = (gymId) => {
    onGymChange(gymId);
    navigate(`/gym/${gymId}`);
  };

  return (
    <div className="body-container">
      <div className="titleAndBtnContainer">
        <Title title={'Manage gyms'} />
        <Button label="Add" secondary onClick={() => navigate('/add-gym')} show={userData.isFullAccessAdmin} />
      </div>
      <Filters open filtersTypes={['name']} onFilters={onFilters} />

      <h2 className="text-secondary">Gyms list:</h2>
      {filteredData.length === 0 ? (
        <NoResultsFound text={'No gyms'} />
      ) : (
        filteredData.map((elm) => (
          <div
            key={elm._id}
            onClick={() => handleGymChange(elm._id)}
            className="listItem text-lg font-bold cursor-pointer">
            {elm.name}
          </div>
        ))
      )}
    </div>
  );
};
