import { useContext } from 'react';
import Loading from '../../../components/Loading/Loading';
import ClientProfileMainDetails from '../../../components/ClientProfile/ClientProfileMainDetails/ClientProfileMainDetails';
import ClientProfilePlanStatus from '../../../components/ClientProfile/ClientProfilePlanStatus/ClientProfilePlanStatus';
import ClientProfilePayments from '../../../components/ClientProfile/ClientProfilePayments';
import ClientProfileAddProducts from '../../../components/ClientProfile/ClientProfileAddProducts/index';
import ClientProfileSignUpClient from '../../../components/ClientProfile/ClientProfileSignUpClient';
import ClientProfileOperationalButtons from '../../../components/ClientProfile/ClientProfileOperationalButtons';
import ClientProfileProductsDetail from '../../../components/ClientProfile/ClientProfileProductsDetail/ClientProfileProductsDetail';
import classes from './ClientProfile.module.scss';
import ClientProfileAcceptStatements from '../../../components/ClientProfile/ClientProfileAcceptStatements/ClientProfileAcceptStatements';
import BirthdayNotificationComponent from '../../../components/BirthdayNotification/BirthdayNotificationComponent';
import { AppContext } from '../../../context/App/AppContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { editUserData, fetchSingleClientsDetails, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { useParams } from 'react-router-dom';
import Notification from '../../../components/UI/Notification/Notification';
import { isActiveUser, isUserStatusActive } from '../../../shared/userFunctions';
import ClientStatus from './ClientStatus';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import ClientProfileNotes from '../../../components/ClientProfile/ClientProfileNotes/ClientProfileNotes';
import ClientProfileChangeCustomerType from '../../../components/ClientProfile/ClientProfileChangeCustomerType/ClientProfileChangeCustomerType';
const shouldDisplaySignUpButton = (client) => client.customerType === 'full-member';

const ClientProfile = () => {
  const { userData, selectedGym } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const { clientId } = useParams();

  const {
    data: selectedClient,
    isLoading: selectedClientIsLoading,
    isError: selectedClientIsError
  } = useQuery({
    queryKey: ['selectedClient', clientId],
    queryFn: () => fetchSingleClientsDetails(clientId),
    enabled: clientId !== null
  });

  const {
    mutate: onUpdateSelectedClient,
    isLoading: onUpdateSelectedClientIsLoading,
    isSuccess: onUpdateSelectedClientIsSuccess
  } = useMutation(['updateSelectedClient', clientId], (data) => editUserData(clientId, data), {
    onSuccess: () => {
      openNotification(null, 'success', 'Successfully updated!');
      refreshFetchFunctions(['selectedClient', clientId]);
    },
    onError: () => {
      openNotification('default', 'error');
    }
  });

  if (selectedClientIsLoading) {
    return <Loading />;
  }
  if (selectedClientIsError) {
    return <Notification message="No customer found for this ID or this QR code" status="critical" />;
  }

  return (
    <>
      <div className={classes.detailsContainer}>
        <ClientStatus
          isUserActive={isUserStatusActive(selectedClient)}
          selectedClient={selectedClient}
          isAdmin={userData.isAdmin}
          onUpdateSelectedClient={onUpdateSelectedClient}
          onUpdateSelectedClientIsLoading={onUpdateSelectedClientIsLoading}
        />
        <BirthdayNotificationComponent dateOfBirth={selectedClient.dateofbirth} />
        <ClientProfileAcceptStatements client={selectedClient} />
        <ClientProfileMainDetails selectedClient={selectedClient} isAdmin={userData.isAdmin} />
        <ClientProfileNotes
          selectedClient={selectedClient}
          onUpdateSelectedClient={onUpdateSelectedClient}
          onUpdateSelectedClientIsLoading={onUpdateSelectedClientIsLoading}
        />
        <div className="my-2"></div>
        <ClientProfileAddProducts
          show={userData.isAdmin && isActiveUser(selectedClient)}
          gymSettings={selectedGym.settings}
          selectedClient={selectedClient}
        />
        <ClientProfileChangeCustomerType
          selectedClient={selectedClient}
          show={userData.isAdmin}
          onUpdateSelectedClient={onUpdateSelectedClient}
          onUpdateSelectedClientIsLoading={onUpdateSelectedClientIsLoading}
          onUpdateSelectedClientIsSuccess={onUpdateSelectedClientIsSuccess}
        />

        <ClientProfileProductsDetail
          gymSettings={selectedGym.settings}
          selectedClient={selectedClient}
          bookings={selectedClient.fitnessClassHistory}
          gymId={selectedClient.gymId}
        />

        <ClientProfilePlanStatus selectedClient={selectedClient} />

        {shouldDisplaySignUpButton(selectedClient) && (
          <ClientProfileSignUpClient isAdmin={userData.isAdmin} selectedClient={selectedClient} />
        )}

        <ClientProfilePayments selectedClient={selectedClient} />

        {userData.isAdmin && isUserStatusActive(selectedClient) && (
          <ClientProfileOperationalButtons showQRBtn={selectedGym.settings.qrCode} selectedClient={selectedClient} />
        )}
      </div>
    </>
  );
};

export default ClientProfile;
