import { useContext, useEffect, useState } from 'react';
import { Modal, Tabs, Select } from 'antd';
import Button from '../../UI/Button/Button';
import ClientProfilePaymentMethodSelection from '../ClientProfilePaymentMethodSelection';
import './ClientProfileAddProducts.scss';
import useTabIndex from '../../../hooks/useTabIndex';
import ModalButtonsContainer from '../../UI/ModalButtonsContainer/ModalButtonsContainer';
import { useMutation } from '@tanstack/react-query';
import { payForPass, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { ALL_CLASS_TYPES_WITH_DETAILS, CLASS_TYPES } from '../../../constants';
import axios from '../../../axios-global';
import Loading from '../../Loading/Loading';
import { NotificationContext } from '../../../context/Notification/NotificationContext';

export default function ClientProfileAddProducts({ selectedClient, gymSettings, show = false }) {
  const { openNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [productId, setProductId] = useState(null);
  const { activeTabIndex, onTabChange } = useTabIndex();
  const [isLoading, setIsLoading] = useState(false);
  const [allPasses, setAllPasses] = useState(CLASS_TYPES.reduce((a, v) => ({ ...a, [v]: [] }), {}));

  useEffect(() => {
    if (selectedClient !== null && show) {
      setIsLoading(true);
      CLASS_TYPES.forEach((elem, i) => {
        if (
          selectedClient &&
          selectedClient.gymSettings[elem] &&
          selectedClient.gymSettings.classConfig.filter((config) => config.classType === elem)[0] &&
          selectedClient.gymSettings.classConfig.filter((config) => config.classType === elem)[0].allowedToBuyPasses
        ) {
          if (!(selectedClient.customerType === 'full-member' && elem === 'class')) {
            axios
              .get(`/products/passes/${elem}Pass/${selectedClient.gymId}/all?userId=${selectedClient._id}`)
              .then((response) => {
                setAllPasses((state) => {
                  return { ...state, [elem]: response.data };
                });
              })
              .catch((err) =>
                // eslint-disable-next-line
                console.log(err)
              );
          }
        }
        if (i === CLASS_TYPES.length - 1) {
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedClient]);

  const { mutate: onAddNewProduct, isLoading: onAddNewProductIsLoading } = useMutation({
    mutationFn: () => payForPass(productId, null, paymentMethod, null, selectedClient._id),
    enabled: productId !== null,
    onSuccess: () => {
      openNotification(null, 'success', 'Product successfully added.');
      handleModalClose();
      refreshFetchFunctions(['selectedClient', selectedClient._id]);
    },
    onError: ({ message }) => {
      openNotification(null, 'error', message);
    }
  });

  const returnLabel = (item, classType) => {
    if (classType === 'class') {
      return `${item.name} (x${item.classCount})`;
    }
    if (classType === 'personalTrainings') {
      return `${item.name} - ${item.classCount} x ${item.duration} mins, Places: ${item.places}`;
    }
    return `${item.name} - cost: ${item.cost} (x${item.classCount})`;
  };
  const returnOptions = (classType) =>
    allPasses[classType].map((elm) => {
      return {
        label: returnLabel(elm, classType),
        value: elm._id
      };
    });

  const tabsItems = ALL_CLASS_TYPES_WITH_DETAILS()
    .map((classType, index) => {
      return {
        key: index,
        type: classType.type,
        label: classType.title,
        children: <Select className="w-full" options={returnOptions(classType.type)} onChange={setProductId} />
      };
    })
    .filter(
      (elem) =>
        gymSettings[elem.type] &&
        (selectedClient.customerType === 'class-pass-member' ||
          (selectedClient.customerType === 'full-member' && elem.type !== 'class'))
    );

  const handleModalClose = () => setShowModal(false);

  if (show) {
    if (isLoading) {
      return <Loading />;
    }
    return (
      <>
        <Modal open={showModal} onCancel={handleModalClose} classNames="w-3/4">
          <h2>Choose product </h2>
          <Tabs defaultActiveKey={activeTabIndex} items={tabsItems} onChange={onTabChange} />

          <h2>Choose payment method</h2>
          <ClientProfilePaymentMethodSelection onMethodSelected={setPaymentMethod} />

          <ModalButtonsContainer isLoading={onAddNewProductIsLoading}>
            <Button label="OK" secondary onClick={onAddNewProduct} />
            <Button label="Cancel" primary onClick={handleModalClose} />
          </ModalButtonsContainer>
        </Modal>

        <Button primary fullWidth label="Add products" onClick={() => setShowModal(true)} />
      </>
    );
  }
  return null;
}
