import TilesBoard from '../../../../components/UI/TilesBoard/TilesBoard';
import CalendarIcon from '../../../../assets/svg/calendar.svg';
import StarIcon from '../../../../assets/svg/star.svg';

export default ({ setAccountType }) => {
  const tiles = [
    {
      title: 'Pay As You Go',
      icon: <CalendarIcon />,
      actionPayload: 'class-pass-member',
      dataCy: 'classPassesTile'
    },
    {
      title: 'Membership',
      icon: <StarIcon />,
      actionPayload: 'full-member',
      dataCy: 'membershipTile'
    }
  ];

  return <TilesBoard tiles={tiles} onClick={setAccountType} />;
};
