import { Checkbox, InputNumber } from 'antd';

const optionLabels = {
  allowedToBuyPasses: 'Allowed to buy passes',
  trainerRequired: 'Trainer required',
  consecutiveBookingsEnabled: 'Allow consecutive bookings'
};

const ClassSettings = ({ config, setConfig }) => {
  const handleChange = (keyName, value = 1) =>
    setConfig((state) => {
      return {
        ...state,
        [keyName]:
          keyName === 'consecutiveBookingsCoolOff' ||
          keyName === 'seventhDayAvailabilityHour' ||
          keyName === 'minutesAfterStartToAllowSignUps'
            ? value
            : !state[keyName]
      };
    });

  return (
    <div>
      <div className="flex items-center">
        <InputNumber
          min={0}
          max={7}
          defaultValue={config.seventhDayAvailabilityHour === true ? 1 : config.seventhDayAvailabilityHour}
          placeholder={'Enter hours'}
          onChange={(value) => handleChange('seventhDayAvailabilityHour', value)}
        />
        <div className="m-2 text-lg">Set a time for 7th day to be shown from</div>
      </div>

      <div className="flex items-center">
        <InputNumber
          min={0}
          defaultValue={config.minutesAfterStartToAllowSignUps}
          placeholder={'Minutes after start to allow sign ups'}
          onChange={(value) => handleChange('minutesAfterStartToAllowSignUps', value)}
        />
        <div className="m-2 text-lg">Minutes after start to allow sign ups</div>
      </div>

      {Object.keys(optionLabels).map((key) => (
        <div className="block my-2" key={config.classType + '-' + key}>
          <Checkbox checked={config[key]} onChange={() => handleChange(key)}>
            {optionLabels[key]}
          </Checkbox>
        </div>
      ))}

      {!config.consecutiveBookingsEnabled && (
        <div className="flex items-center">
          <InputNumber
            min={0}
            defaultValue={config.consecutiveBookingsCoolOff}
            placeholder={'Enter hours'}
            onChange={(value) => handleChange('consecutiveBookingsCoolOff', value)}
          />
          <div className="m-2 text-lg">Min time between bookings (minutes)</div>
        </div>
      )}
    </div>
  );
};

export default ClassSettings;
