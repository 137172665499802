import { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { returnClassesDetails } from '../../../../shared/classFunctions';
import { refreshFetchFunctions, signUpAndPayForClass } from '../../../../apiFunctions/apiFunctions';
import { useMutation } from '@tanstack/react-query';
import { useStripe } from '@stripe/react-stripe-js';
import moment from 'moment';
import Button from '../../../../components/UI/Button/Button';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import { AppContext } from '../../../../context/App/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Loading/Loading';
import { isValueCorrect } from '../../../../shared/userFunctions';

const SignUpAndPayButton = ({ selectedClass, classConfig }) => {
  const { openNotification } = useContext(NotificationContext);
  const { userData, userPaymentSources } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [userHasClassesLeftToUse, setUserHasClassesLeftToUse] = useState(false);
  const classesDetails = returnClassesDetails(selectedClass.__t);
  const isFreeEvent = selectedClass.cost === 0;
  const isClassMembershipUser = userData.isActiveMembershipUser && selectedClass.__t === 'class';
  const stripe = useStripe();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isUnlimitedClassUser = userData.isUnlimitedMembershipUser && selectedClass.__t === 'class';
    const userHasClassesLeftForThisMonth =
      moment(selectedClass.classDate).month() === moment().month() && userData[classesDetails.usersClassesLeft] > 0;
    const userHasClassesLeftForNextMonth =
      moment(selectedClass.classDate).month() === moment().add(1, 'M').month() &&
      userData[classesDetails.usersClassesLeftForNextMont] > 0;

    setUserHasClassesLeftToUse(
      isUnlimitedClassUser || userHasClassesLeftForThisMonth || userHasClassesLeftForNextMonth
    );
  }, [userData, classesDetails, selectedClass]);

  useEffect(() => {
    if (isValueCorrect(location.state?.itemId) && location.state?.itemId === selectedClass._id) {
      setShowModal(true);
    }
  }, [location, selectedClass]);

  const mutateSignUpAndPay = useMutation(
    ({ isSinglePayment }) => signUpAndPayForClass(selectedClass._id, userData._id, isSinglePayment),
    {
      onSuccess: async ({ status, clientSecret }) => {
        if (status === 'requires_action') {
          const result = await stripe.confirmCardPayment(clientSecret, {});

          if (result.error) {
            openNotification(null, 'error', result.error?.message);
          }
        }
        openNotification(
          null,
          'success',
          isFreeEvent || userHasClassesLeftToUse ? 'Booking successful.' : 'Successfully added to this class'
        );
        setShowModal(false);

        refreshFetchFunctions([
          ['upcomingClasses', selectedClass.__t],
          ['clientUpcomingClasses', selectedClass.__t],
          ['userData']
        ]);
      },
      onError: (err) => {
        setShowModal(false);
        openNotification(
          null,
          'error',
          err.response && err.response.status === 403
            ? 'Please activate your membership plan'
            : err.response && err.response.status === 422
              ? err.response.data.message
              : 'Something went wrong. Try again'
        );
      }
    }
  );

  const renderPaymentUpModal = () => {
    const needsAddPaymentMethod = userPaymentSources?.length === 0 && selectedClass?.cost > 0;
    const handleModalClose = () => {
      navigate({}, { replace: true });
      setShowModal(false);
    };
    const closeModal = (
      <Button className="my-2" fullWidth dataCy="cancel_btn" label="Cancel" primary onClick={handleModalClose} />
    );

    return (
      <Modal close={handleModalClose} open={showModal} data-cy="class_booking_modal">
        <div className="my-6">
          {!isFreeEvent && classConfig.allowedToBuyPasses && !userHasClassesLeftToUse
            ? isClassMembershipUser
              ? 'Do you want to pay for single one?'
              : 'Pay for this single one or buy a block?'
            : 'Confirm booking'}
        </div>
        {mutateSignUpAndPay.isLoading ? (
          <Loading />
        ) : (
          <>
            {isFreeEvent || userHasClassesLeftToUse ? (
              <Button
                className="my-2"
                secondary
                label="Book"
                dataCy="book_btn"
                fullWidth
                onClick={() => mutateSignUpAndPay.mutate({ isSinglePayment: true })}
              />
            ) : (
              <Button
                className="my-2"
                fullWidth
                dataCy="pay_for_single_btn"
                label="Pay for single"
                secondary
                onClick={() =>
                  needsAddPaymentMethod
                    ? navigate(`/user/add-payment-method`, {
                        state: { itemId: selectedClass._id, pathname: location.pathname }
                      })
                    : mutateSignUpAndPay.mutate({
                        isSinglePayment: true,
                        userId: userData._id,
                        classId: selectedClass._id
                      })
                }
              />
            )}
            {classConfig.allowedToBuyPasses && !userHasClassesLeftToUse && !isFreeEvent && !isClassMembershipUser && (
              <Button
                className="my-2"
                label="Buy a block"
                secondary
                dataCy="buy_a_block_btn"
                fullWidth
                onClick={() =>
                  navigate(`/gym/${classesDetails.passesUrl}`, {
                    state: { goBackToPreviousPage: true }
                  })
                }
              />
            )}

            {closeModal}
          </>
        )}
      </Modal>
    );
  };

  return (
    <div>
      {renderPaymentUpModal()}
      <Button fullWidth label="Sign up" secondary onClick={() => setShowModal(true)} dataCy="sign_up_btn" />
    </div>
  );
};

export default SignUpAndPayButton;
