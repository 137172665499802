import { useContext } from 'react';
import { AppContext } from '../../../../context/App/AppContext';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import RenderForm from '../../../../components/Forms/RenderForm';
import { useMutation } from '@tanstack/react-query';
import { addMembershipPlan, editMembershipPlan, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import { returnFirstErrorMsg } from '../../../../shared/utility';

const AddEditMembershipPlan = ({ selectedPlan = null, onCancel = () => {} }) => {
  const { openNotification } = useContext(NotificationContext);
  const { selectedGym } = useContext(AppContext);
  const isNew = selectedPlan === null;

  const { mutate: onSave, isLoading: onSaveIsLoading } = useMutation({
    mutationFn: (formData) =>
      isNew
        ? addMembershipPlan({ ...formData, gymId: selectedGym._id })
        : editMembershipPlan(selectedPlan._id, formData),
    onSuccess: () => {
      refreshFetchFunctions([['membershipPlansList', selectedGym._id]]);
      openNotification(null, 'success', isNew ? 'Successfully created!' : 'Successfully updated!');
      onCancel();
    },
    onError: (err) => {
      openNotification(null, 'error', returnFirstErrorMsg(err));
    }
  });

  const formFields = [
    'name',
    'description',
    'currency',
    'price',
    'minContractLength',
    'classPrice',
    'displayOrder',
    'classesIncluded',
    'joiningFee',
    'unlimitedClasses',
    'active'
  ];

  let initialValues = {
    currency: 'gbp',
    active: true,
    joiningFee: false,
    unlimitedClasses: false
  };
  if (!isNew) {
    initialValues = { ...initialValues, ...selectedPlan };
  }

  return (
    <RenderForm
      formFields={formFields}
      excludeFormFields={isNew ? [] : ['currency', 'price']}
      initialValues={initialValues}
      onFinish={onSave}
      btnIsLoading={onSaveIsLoading}
      cancelBtn={!isNew}
      onCancel={onCancel}
    />
  );
};

export default AddEditMembershipPlan;
