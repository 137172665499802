import { useContext, useState } from 'react';
import { moveClientsTableData, moveClientsTableHeaderLabels } from '../table/table';
import { useMutation } from '@tanstack/react-query';
import Button from '../../../../components/UI/Button/Button';
import { moveClientsBetweenClasses, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../../shared/utility';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Notification from '../../../../components/UI/Notification/Notification';
import { useNavigate } from 'react-router-dom';

const StepTree_Summary = ({ currentClass, selectedClient, selectedClass, onCloseModal }) => {
  const { openNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [isClientSuccessfullyMoved, setIsClientSuccessfullyMoved] = useState(false);
  const clientAttendance = currentClass.attendanceList
    .filter((elem) => elem.user._id === selectedClient._id)
    .filter((elem) => ['active', 'pending', 'attended', 'absent'].includes(elem.status))[0]._id;

  const { mutate: onMoveClient, isLoading: onMoveClientIsLoading } = useMutation(
    () => moveClientsBetweenClasses(clientAttendance, selectedClass._id),
    {
      onSuccess: () => {
        openNotification(null, 'success', 'Client successfully moved!');
        setIsClientSuccessfullyMoved(true);
      },
      onError: (err) => {
        openNotification(null, 'error', returnErrorFromResponse(err));
      }
    }
  );
  const renderClientDetails = (
    <div>
      <div className="font-bold text-xl mt-5">Client:</div>
      {selectedClient.name} {selectedClient.last_name}
    </div>
  );
  const renderClassDetails = (
    <div>
      <div className="font-bold text-xl mt-5">Current:</div>
      <div>{moveClientsTableHeaderLabels()}</div>
      <div>{moveClientsTableData(selectedClass, selectedClient, [currentClass], false)}</div>

      <div className="font-bold text-xl mt-5">Selected:</div>
      <div>{moveClientsTableHeaderLabels()}</div>
      <div>{moveClientsTableData(selectedClass, selectedClient, [selectedClass], false)}</div>
    </div>
  );
  const renderMoveClientsBtn = (
    <Button
      fullWidth
      isLoading={onMoveClientIsLoading}
      label="Move client"
      secondary
      onClick={onMoveClient}
      className="mt-8"
    />
  );

  if (isClientSuccessfullyMoved) {
    const redirectToClass = () => {
      navigate(`/gym/${selectedClass.gymId[0]._id}/class/${selectedClass._id}`);
      onCloseModal();
    };
    const handleClose = () => {
      refreshFetchFunctions([['singleClass']]);
      onCloseModal();
    };
    return (
      <div>
        <Notification message="Client has been successfully moved." status="success" />
        <div className="mt-4">Do you want to be redirected to that event?</div>
        <ModalButtonsContainer>
          <Button label="Yes" secondary onClick={redirectToClass} />
          <Button label="No" primary onClick={handleClose} />
        </ModalButtonsContainer>
      </div>
    );
  }

  return (
    <div>
      <div>{renderClientDetails}</div>
      <div>{renderClassDetails}</div>
      <div>{renderMoveClientsBtn}</div>
    </div>
  );
};

export default StepTree_Summary;
