import { useContext } from 'react';
import PayButton from './PayButton/PayButton';
import SignUpAndPayButton from './SignUpAndPayButton/SignUpAndPayButton';
import CancelAttendanceOrClass from './CancelAttandanceOrClass/CancelAttendanceOrClass';
import { hasClassStarted } from '../../../shared/classFunctions';
import WaitListModal from '../../ClassComponents/WaitList/WaitListModal';
import RedirectToClassPage from '../RedirectToClassPage/RedirectToClassPage';
import classes from './ClassActionButtons.module.scss';
import GoogleApplePayModal from '../GoogleApplePayButton/GoogleApplePayModal';
import { returnUserClassesLeftAmount } from '../../../shared/userFunctions';
import EmptyDiv from '../../../components/UI/EmptyDiv/EmptyDiv';
import { AppContext } from '../../../context/App/AppContext';
import { returnClassConfig } from '../../../shared/gymFunctions';

const ClassActionButtons = ({
  selectedClass,
  userAttendance = null,
  isHistoryComponent = false,
  type = null,
  cancelType = 'upcoming'
}) => {
  const { userData, selectedGym, userPaymentSources } = useContext(AppContext);
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, selectedClass.__t);
  const userEventAttendance =
    userAttendance !== null
      ? userAttendance
      : {
          _id: selectedClass.userAttendanceId,
          status: selectedClass.userAttendanceStatus,
          stripeOrderId: selectedClass.userAttendanceStripeOrderId
        };
  const hasClassAlreadyStarted = hasClassStarted(selectedClass, classConfig.minutesAfterStartToAllowSignUps);
  const userHasClassesLeft = returnUserClassesLeftAmount(selectedClass.__t, userData, selectedClass) > 0;
  const needsAddPaymentMethod = userPaymentSources?.length === 0 && selectedClass?.cost > 0 && !userHasClassesLeft;
  const isClassFull = selectedClass.limit - selectedClass.joinedUsers === 0;
  const isClassCancelled = !selectedClass.active;

  const isClassCancelledContent = <div className={classes.warningClassInfo}>Cancelled</div>;

  const hasClassAlreadyStartedContent = <div className={classes.alreadyTakenPlace}>Already taken place.</div>;

  const cancelButton = (
    <CancelAttendanceOrClass
      type={userData.isUser ? (type !== null ? type : 'cancelAttendanceButton') : 'cancelClassXicon'}
      selectedClass={selectedClass}
      user={userData}
      attendance={userEventAttendance}
    />
  );

  const redirectToClassPage = (
    <RedirectToClassPage
      gymId={
        userData.isUser
          ? selectedClass.locationId
          : cancelType === 'upcoming'
            ? selectedClass.gymId[0]._id
            : selectedClass.gymId
      }
      classId={selectedClass._id}
    />
  );

  if (userData.isUser) {
    if (isClassCancelled) {
      return isClassCancelledContent;
    }

    if (hasClassAlreadyStarted) {
      return hasClassAlreadyStartedContent;
    }

    if (userEventAttendance && userEventAttendance.status === 'active') {
      return <div>{cancelButton}</div>;
    }

    if (userEventAttendance && (userEventAttendance.status === 'attended' || userEventAttendance.status === 'absent')) {
      return null;
    }

    if (isClassFull) {
      if (selectedClass.waitListConfig && selectedClass.waitListConfig.active) {
        return <WaitListModal selectedClass={selectedClass} />;
      }
      return <div className={classes.warningClassInfo}>Full</div>;
    }

    if (userEventAttendance && userEventAttendance.status === 'pending') {
      return (
        <div>
          {userEventAttendance?.stripeOrderId && !userEventAttendance?.paid ? (
            <PayButton attendanceId={userEventAttendance._id} stripeOrderId={userEventAttendance.stripeOrderId} />
          ) : (
            <div className={'text-green-600 text-sm font-bold'}>Payment pending</div>
          )}
        </div>
      );
    }
    if (needsAddPaymentMethod) {
      return (
        <GoogleApplePayModal selectedClass={selectedClass} productType={'class'} sourcesList={userPaymentSources} />
      );
    }

    return <SignUpAndPayButton selectedClass={selectedClass} classConfig={classConfig} />;
  }

  if (isHistoryComponent) {
    return <div className={classes.historyActionContainer}>{redirectToClassPage}</div>;
  }

  return (
    <div className={classes.adminActionButtonContainer}>
      {!isClassCancelled && hasClassAlreadyStarted ? hasClassAlreadyStartedContent : <EmptyDiv />}
      <WaitListModal selectedClass={selectedClass} />
      {redirectToClassPage}
      {!userData.isUser ? (
        <CancelAttendanceOrClass
          cancelType={cancelType}
          type="cancelClassXicon"
          selectedClass={selectedClass}
          user={userData}
          attendance={selectedClass.attendanceList}
        />
      ) : (
        <EmptyDiv />
      )}
      {isClassCancelled ? isClassCancelledContent : <EmptyDiv />}
    </div>
  );
};

export default ClassActionButtons;
