import { useContext } from 'react';
import RenderForm from '../../../components/Forms/RenderForm';
import { returnAvailableClassTypesInGym } from '../../../shared/gymFunctions';
import { AppContext } from '../../../context/App/AppContext';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { useMutation } from '@tanstack/react-query';
import { returnFirstErrorMsg } from '../../../shared/utility';
import { addFAQ, editFAQ, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';

const AddEditFAQ = ({ selectedFAQ = null, onCancel = () => {} }) => {
  const { openNotification } = useContext(NotificationContext);
  const { selectedGym } = useContext(AppContext);
  const isNew = selectedFAQ === null;

  const { mutate: onSave, isLoading: onSaveIsLoading } = useMutation({
    mutationFn: (formData) => (isNew ? addFAQ(selectedGym._id, formData) : editFAQ(selectedFAQ._id, formData)),
    onSuccess: () => {
      onCancel();
      refreshFetchFunctions([['faq', selectedGym._id]]);
      openNotification('save', 'success');
    },
    onError: (err) => {
      openNotification(null, 'error', returnFirstErrorMsg(err));
    }
  });

  return (
    <RenderForm
      formFields={['selectQuestionType', 'question', 'answer']}
      initialValues={isNew ? null : selectedFAQ}
      onFinish={onSave}
      btnIsLoading={onSaveIsLoading}
      cancelBtn={true}
      onCancel={onCancel}
      availableClassTypes={returnAvailableClassTypesInGym(selectedGym.settings)}
    />
  );
};

export default AddEditFAQ;
