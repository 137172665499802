import LocationsPage from '../../../brandConfig/components/LocationsPage/LocationsPage';

import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  fetchCitiesWithGyms,
  fetchGymDetailsByCityAndName,
  fetchPublicUpcomingClasses
} from '../../../apiFunctions/apiFunctions';
import Loading from '../../../components/Loading/Loading';
import companyDetails from '../../../brandConfig/companyDetails';
import { goToHomePage } from '../../../routes/AppRoutes';
import { returnAllUpcomingClasses } from '../../../shared/classFunctions';
import Error404 from '../../../components/UI/Error404/Error404';

const returnEventsArray = (events) =>
  returnAllUpcomingClasses(events).map((elm) => ({
    ...elm,
    classDate: elm.startDate,
    classTime: elm.startTime,
    _id: elm.eventId,
    trainer: { ...elm.staff }
  }));

const Location = () => {
  const { city, urlName } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery({
    queryKey: [city, urlName],
    queryFn: () => fetchGymDetailsByCityAndName(city, urlName),
    enabled: city !== 'gym' && urlName.length > 0,
    onSuccess: () => {
      navigate(null, { state: { isHomepageHeroDark: companyDetails.isHomepageHeroDark } });
    }
  });
  const { data: citiesArray, isLoading: citiesArrayIsLoading } = useQuery({
    queryKey: [city],
    queryFn: () => fetchCitiesWithGyms(),
    enabled: city !== 'gym'
  });

  const {
    data: upcomingEvents,
    isLoading: upcomingEventsIsLoading,
    isError: upcomingEventsIsError
  } = useQuery({
    queryKey: ['upcomingEvents'],
    queryFn: () => fetchPublicUpcomingClasses(data.gymId),
    enabled: data !== undefined && companyDetails.locationsPage.showUpcomingEvents
  });

  if (city === 'gym' && urlName.length > 0) {
    return goToHomePage();
  }

  if (isLoading || citiesArrayIsLoading) {
    return <Loading size="50" />;
  }
  if (isError) {
    return <Error404 />;
  }

  return (
    <LocationsPage
      settings={companyDetails.locationsPage}
      upcomingEvents={upcomingEvents !== undefined ? returnEventsArray(upcomingEvents.data) : []}
      upcomingEventsIsLoading={upcomingEventsIsLoading}
      upcomingEventsIsError={upcomingEventsIsError}
      gymData={data}
      gymDataIsError={isError}
      isCityNameCorrect={citiesArray.map((elm) => elm.toLowerCase()).includes(city.toLowerCase())}
    />
  );
};

export default Location;
