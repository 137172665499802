import ClassActionButtons from '../../containers/ActionButtons/ClassActionButtons/ClassActionButtons';
import Icon from '../UI/Icon/Icon';

const TableActionColumn = ({ actionType, data }) => {
  if (['upcoming', 'history', 'upcomingAllGyms'].includes(actionType)) {
    return (
      <ClassActionButtons
        cancelType={data.cancelType}
        selectedClass={data.element}
        isHistoryComponent={data.isHistoryComponent}
        classConfig={data.classConfig}
      />
    );
  }

  if (actionType === 'editDelete') {
    const showDelete = !(data?.type === 'pass' && data.element?.active === false);
    return (
      <div className="flex items-center justify-between">
        <Icon name="edit" onClick={() => data.onEdit(data.element)} />
        <Icon name="delete" onClick={() => data.onDelete(data.element)} show={showDelete} />
      </div>
    );
  }
  return null;
};

export default TableActionColumn;
