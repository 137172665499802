import { useContext, useEffect } from 'react';
import Thumbnail from '../ShowVideos/Thumbnail/Thumbnail';
import { AppContext } from '../../../context/App/AppContext';
import Filters from '../../../components/Filters/Filters';
import useFilters from '../../../components/Filters/useFilters';
import NoResultsFound from '../../../components/UI/NoResultsFound/NoResultsFound';
import { VideoContext } from '../../../context/Video/VideoContext';

const VideosList = ({ isSelector = false, onSelect = () => {}, selectedInitialState = [] }) => {
  const { videos, videoCategories, videoPasses } = useContext(VideoContext);
  const { onFilters, filters, setFiltersInitialData, filteredData } = useFilters();
  const { userData, gymId } = useContext(AppContext);
  useEffect(
    () => {
      setFiltersInitialData(videos);
    }, //eslint-disable-next-line
    [videos]
  );

  return (
    <div>
      <Filters
        onFilters={onFilters}
        filters={filters}
        open={false}
        filtersTypes={['title', 'trainerSelector', 'videoCategories']}
        videoCategories={videoCategories}
        productsList={videoPasses}
        initialState={[
          { name: 'trainerSelector', value: undefined },
          { name: 'videoCategories', value: videoCategories.active.map((elm) => ({ ...elm, options: [] })) }
        ]}
      />

      <div className={'flex justify-around flex-wrap'}>
        {filteredData.length > 0 ? (
          filteredData.map((video) => (
            <Thumbnail
              key={`${video._id}-`}
              video={video}
              isAdmin={userData.isAdmin}
              isSelector={isSelector}
              onSelect={onSelect}
              isDefaultSelected={selectedInitialState.includes(video._id)}
              productList={videoPasses}
              categories={videoCategories}
              gymId={gymId}
              className={isSelector ? 'max-w-[150px]' : ''}
              //locked={enforceVideoOrder ? index > 0 && !video[index - 1]?.watched : false}
              //watched={video?.watched}
            />
          ))
        ) : (
          <NoResultsFound text={'No videos'} />
        )}
      </div>
    </div>
  );
};

export default VideosList;
