import { useContext, useState } from 'react';
import { returnAvailableClassTypesInGym } from '../../../shared/gymFunctions';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import SingleClassTypeHistoryComponent from './SingleClassTypeHistoryComponent';
import moment from 'moment';
import { Tabs } from 'antd';
import { AppContext } from '../../../context/App/AppContext';
import Title from '../../../components/UI/Title/Title';

const startDate = moment().subtract(14, 'd').format('YYYY-MM-DD');
const endDate = moment().format('YYYY-MM-DD');

const HistoryComponent = () => {
  const { selectedGym } = useContext(AppContext);
  const [dateRange, setDateRange] = useState({ dateFrom: startDate, dateTo: endDate });
  const tabs = returnAvailableClassTypesInGym(selectedGym.settings);

  return (
    <div data-cy="history">
      <Title title={'History'} />
      <DateRangePicker
        row
        onChange={setDateRange}
        onlyPast
        startDate={startDate}
        endDate={endDate}
        className={'filterContainer'}
      />
      <Tabs
        items={tabs.map((elem, index) => {
          return {
            label: elem.title,
            key: index,
            children: (
              <SingleClassTypeHistoryComponent classType={elem.type} dateRange={dateRange} selectedGym={selectedGym} />
            )
          };
        })}
      />
    </div>
  );
};

export default HistoryComponent;
