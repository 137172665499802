import Icon from '../../../components/UI/Icon/Icon';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../context/App/AppContext';

const RedirectToTrainerDetails = ({ trainer, size = 20, className = '' }) => {
  const { userData, selectedGym } = useContext(AppContext);

  return userData.isAdmin ? (
    <Link to={`/gym/${selectedGym._id}/trainers`} state={{ trainerId: trainer._id }}>
      <Icon name="userData" className={className} size={size} />
    </Link>
  ) : null;
};

export default RedirectToTrainerDetails;
