import { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import moment from 'moment';
import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import { PERSONAL_TRAINING_SESSION_DETAILS, CLASS_DETAILS } from '../../../constants';
import { returnProductTypeName } from '../../../shared/classFunctions';
import './ClientProfileProductsDetail.scss';
import { returnAvailableClassTypesInGym } from '../../../shared/gymFunctions';
import useTabIndex from '../../../hooks/useTabIndex';
import { useNavigate } from 'react-router-dom';
import { classNames } from '../../../shared/utility';

const ClientProfileProductsDetail = ({ gymSettings, selectedClient, bookings, gymId }) => {
  const [products, setProducts] = useState([]);
  const [futureBookings, setFutureBookings] = useState([]);
  const [pastBookings, setPastBookings] = useState([]);
  const { activeTabIndex, onTabChange } = useTabIndex();
  const allTabs = returnAvailableClassTypesInGym(gymSettings);
  const navigate = useNavigate();
  useEffect(
    () => {
      setProducts(
        selectedClient.products.filter((product) => product.productType === allTabs[activeTabIndex].productType)
      );
      setFutureBookings(
        bookings.filter(
          (booking) =>
            booking.class &&
            booking.class.__t === allTabs[activeTabIndex].type &&
            moment() <
              moment(booking?.class?.classDate).set({
                hour: moment(booking.class.classTime, ['hh:mm A']).get('hour'),
                minute: moment(booking.class.classTime, ['hh:mm A']).get('minute')
              })
        )
      );
      setPastBookings(
        bookings.filter(
          (booking) =>
            booking.class &&
            booking.class.__t === allTabs[activeTabIndex].type &&
            moment() >
              moment(booking?.class?.classDate).set({
                hour: moment(booking.class.classTime, ['hh:mm A']).get('hour'),
                minute: moment(booking.class.classTime, ['hh:mm A']).get('minute')
              })
        )
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTabIndex]
  );

  const getBookingList = (bookings) =>
    bookings.map((booking) => (
      <div
        className="booking-item"
        onClick={() => {
          navigate(`/gym/${gymId}/class/${booking.class._id}`);
        }}
        key={booking._id}>
        <ProfileTextWithLabel
          key={booking._id}
          label={`Status -  ${booking.status}`}
          text={`${moment(booking.classDate).format('Do MMM YYYY')}`}
        />
      </div>
    ));
  const returnTabChildren = (type, usersClassesLeft) => (
    <>
      {type === PERSONAL_TRAINING_SESSION_DETAILS.type ? (
        <ProfileTextWithLabel
          label="Remaining:"
          text={
            selectedClient[usersClassesLeft] === 'error'
              ? 'error'
              : selectedClient[usersClassesLeft] === 0
                ? 0
                : selectedClient[usersClassesLeft].map((elem, i) => (
                    <span key={i}>
                      {elem.classesLeft} x {elem.duration} mins {elem.places > 1 && `(${elem.places} places)`}
                    </span>
                  ))
          }
        />
      ) : type === CLASS_DETAILS.type ? (
        <>
          <ProfileTextWithLabel
            label="Remaining this month:"
            text={selectedClient.classesLeft === true ? 'Unlimited' : selectedClient.classesLeft}
          />
          <ProfileTextWithLabel
            label="Remaining next month:"
            text={selectedClient.classesLeftNextMonth === true ? 'Unlimited' : selectedClient.classesLeftNextMonth}
          />
        </>
      ) : (
        <ProfileTextWithLabel label="Remaining:" text={selectedClient[usersClassesLeft]} />
      )}
      <Tabs
        items={[
          {
            key: 0,
            label: 'Pass Purchases',
            children:
              products.length > 0
                ? products.map((elem) => (
                    <ProfileTextWithLabel
                      key={elem._id}
                      label={returnProductTypeName(elem.productType)}
                      text={`${elem.classCount}x, bought on ${moment(elem.orderDate).format('Do MMM YY h:mm A')}`}
                    />
                  ))
                : 'No details available'
          },
          {
            key: 1,
            label: 'Past Bookings',
            children: (
              <>
                {pastBookings.length === 0 && 'No past bookings available'}
                {getBookingList(pastBookings)}
              </>
            )
          },
          {
            key: 2,
            label: 'Future Bookings',
            children: (
              <>
                {futureBookings.length === 0 && 'No future bookings'}
                {getBookingList(futureBookings)}
              </>
            )
          }
        ]}
      />
    </>
  );

  const tabsItems = allTabs.map(({ title, type, usersClassesLeft }, index) => {
    return {
      key: index,
      label: title,
      children: returnTabChildren(type, usersClassesLeft)
    };
  });
  return (
    <div className={classNames('product-detail-container', 'mb-2')}>
      <h3 className="uppercase mt-2">PURCHASES AND BOOKINGS</h3>
      <Tabs defaultActiveKey={activeTabIndex} onChange={onTabChange} items={tabsItems} />
    </div>
  );
};

export default ClientProfileProductsDetail;
