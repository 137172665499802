import RenderForm from '../../Forms/RenderForm';

const ClientProfileNotes = ({ selectedClient, onUpdateSelectedClient, onUpdateSelectedClientIsLoading }) => {
  return (
    <RenderForm
      formFields={['paymentNotes', 'generalNotes']}
      initialValues={selectedClient}
      onFinish={onUpdateSelectedClient}
      btnIsLoading={onUpdateSelectedClientIsLoading}
    />
  );
};
export default ClientProfileNotes;
