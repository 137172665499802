import { useContext } from 'react';
import { NotificationContext } from '../../context/Notification/NotificationContext';
import Title from '../../components/UI/Title/Title';
import { useMutation } from '@tanstack/react-query';
import { onForgotPassword } from '../../apiFunctions/apiFunctions';
import RenderForm from '../../components/Forms/RenderForm';
import { returnErrorFromResponse } from '../../shared/utility';

const notificationType = 'forgotPassword';
const ForgotPassword = () => {
  const { openNotification } = useContext(NotificationContext);
  const { mutate, isLoading, isError } = useMutation({
    mutationFn: (formData) => onForgotPassword(formData.email, window.location.origin),
    onSuccess: () => {
      openNotification(notificationType);
    },
    onError: (err) => {
      openNotification(null, 'error', returnErrorFromResponse(err));
    }
  });

  return (
    <div className={'body-container'}>
      <div className="sm:w-2/3 mx-auto">
        <Title title="Enter your e-mail" />
        <RenderForm
          formFields={['email']}
          onFinish={mutate}
          btnLabel="Get reset link"
          btnIsLoading={isLoading && !isError}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
