import { useContext, useEffect, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import AddEditTemplate from './AddEditTemplate/AddEditTemplate';
import Notification from '../../components/UI/Notification/Notification';
import Button from '../../components/UI/Button/Button';
import Table from '../../components/Table/Table';
import { useMutation } from '@tanstack/react-query';
import { deleteTemplate } from '../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../context/Notification/NotificationContext';
import ModalButtonsContainer from '../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import { Modal } from 'antd';
import useFilters from '../../components/Filters/useFilters';
import { AppContext } from '../../context/App/AppContext';
import { CLASS } from '../../constants';
import Filters from '../../components/Filters/Filters';

const ManageTemplates = ({
  classType,
  templatesList,
  templatesListIsLoading,
  templatesListIsError,
  templatesListRefetch
}) => {
  const { openNotification } = useContext(NotificationContext);
  const { selectedGym } = useContext(AppContext);
  const { onFilters, filteredData, setFiltersInitialData, filters } = useFilters();
  const [showAddComponent, setShowAddComponent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(true);
  const [selected, setSelected] = useState(null);

  const { mutate: onDeleteTemplate, isLoading: onDeleteTemplateIsLoading } = useMutation({
    mutationFn: () => deleteTemplate(selected._id),
    onSuccess: () => {
      openNotification('delete', 'success');
      onCloseModal();
      templatesListRefetch();
    },
    onError: () => {
      openNotification('delete', 'error');
    }
  });
  useEffect(
    () => {
      if (templatesList?.length > 0) {
        setFiltersInitialData(templatesList);
      }
    },
    //eslint-disable-next-line
    [templatesList]
  );

  if (templatesListIsLoading) {
    return <Loading />;
  }
  if (templatesListIsError) {
    return <Notification status={'critical'} message={'Unable to fetch templates'} />;
  }

  const onCloseModal = () => {
    setSelected(null);
    setShowModal(false);
    setShowAddComponent(false);
  };

  const onIconsClick = (selected, isEditModal) => {
    setSelected(selected);
    setIsEditModal(isEditModal);
    setShowModal(true);
  };

  let fields = ['name', 'defaultDescription', 'defaultLimit', 'defaultCost', 'defaultDuration', 'actionColumn'];
  if (classType === CLASS.CLASS) {
    if (selectedGym.settings.membershipProducts) {
      fields.splice(4, 0, 'membershipClassPrice');
    }
  }

  const addEditComponent = (
    <AddEditTemplate
      selectedTemplate={selected}
      onCancel={onCloseModal}
      templatesListRefetch={templatesListRefetch}
      classType={classType}
    />
  );

  return (
    <>
      <Modal open={showModal} onCancel={onCloseModal}>
        {isEditModal ? (
          addEditComponent
        ) : (
          <>
            <div className="text-center">Are you sure you want to delete {selected?.name}?</div>
            <ModalButtonsContainer isLoading={onDeleteTemplateIsLoading}>
              <Button label="delete" secondary onClick={onDeleteTemplate} />
              <Button label="cancel" primary onClick={onCloseModal} />
            </ModalButtonsContainer>
          </>
        )}
      </Modal>

      <Button
        fullWidth
        className="mb-4"
        label={showAddComponent ? 'Templates list' : 'Add new template'}
        secondary
        onClick={() => {
          setSelected(null);
          setShowAddComponent((state) => !state);
        }}
      />
      {showAddComponent ? (
        addEditComponent
      ) : (
        <>
          <Filters
            open={false}
            filtersTypes={['name']}
            filters={filters}
            onFilters={onFilters}
            initialState={[{ name: 'name', value: '' }]}
          />
          <Table
            fields={fields}
            showHideFieldsBtn
            data={filteredData}
            actionType="editDelete"
            additionalData={{
              classType,
              onEdit: (selected) => onIconsClick(selected, true),
              onDelete: (selected) => onIconsClick(selected, false)
            }}
          />
        </>
      )}
    </>
  );
};

export default ManageTemplates;
