import { useContext, useState } from 'react';
import RedirectToUserDetails from '../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import { useMutation } from '@tanstack/react-query';
import { editUserData, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Icon from '../../../../components/UI/Icon/Icon';
import { Modal } from 'antd';
import Button from '../../../../components/UI/Button/Button';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';

const SingleClient = ({ client = null, gymId, isAdmin = false }) => {
  const { openNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);
  const { mutate: onUpdateClient, isLoading: onUpdateClientIsLoading } = useMutation(
    ['updateClient'],
    (status) => editUserData(client._id, { status: status }),
    {
      onSuccess: () => {
        setShowModal(false);
        openNotification(null, 'success', 'Successfully updated!');
        refreshFetchFunctions([['gymsUsers', gymId]]);
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );
  const addUser = <Icon name="userAdd" onClick={() => onUpdateClient('active')} />;
  const deniedUser = <Icon name="userDeny" onClick={() => onUpdateClient('permissionDenied')} />;
  const inactiveUser = <Icon name="close" onClick={() => setShowModal(true)} />;

  return (
    <div key={client.id} className="listItem flex justify-between items-center">
      <Modal close={() => setShowModal(false)} open={showModal}>
        <div className="text-center text-base">
          Do you want to deactivate {client.name} {client.last_name}?
        </div>
        <ModalButtonsContainer isLoading={onUpdateClientIsLoading}>
          <Button secondary label={'Yes'} onClick={() => onUpdateClient('inactive')} />
          <Button primary label={'Cancel'} onClick={() => setShowModal(false)} />
        </ModalButtonsContainer>
      </Modal>

      <RedirectToUserDetails client={client} showName />

      {isAdmin && (
        <div>
          {client.status === 'waitingForApproval' && (
            <div className="flex">
              <span>Activate new client?</span>
              {addUser}
              {deniedUser}
            </div>
          )}
          {client.status === 'active' && <> {inactiveUser}</>}
          {client.status === 'inactive' && (
            <div className="flex">
              <span>Inactive client</span>
              {addUser}
            </div>
          )}
          {client.status === 'permissionDenied' && (
            <div className="flex">
              <span>Permission denied</span>
              {addUser}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleClient;
