export const USERS_TYPES = ['user', 'admin', 'trainer'];
export const CLIENTS_FILTERS = {
  FULL_MEMBERS: 'full-member',
  CLASS_PASS_MEMBERS: 'class-pass-member',
  ALL_MEMBERS: 'all-member'
};
export const MEMBERSHIP_STATUS_FILTERS = {
  ACTIVE: 'member-active',
  EXPIRED: 'member-expired',
  ALL: 'member-all'
};

export const CLIENTS_ACTIVE_FILTERS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ALL: 'all'
};

export const CLIENTS_STATUS_FILTERS = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PERMISSION_DENIED: 'permissionDenied',
  WAITING_FOR_APPROVAL: 'waitingForApproval'
};

export const CLIENTS_TYPES = {
  FULL_MEMBER: 'full-member',
  CLASS_PASS_MEMBER: 'class-pass-member'
};

export const PRODUCT_TYPES = {
  CLASS_PASS: 'classPass',
  PERSONAL_TRAINING_PASS: 'personalTrainingPass',
  INDUCTION: 'inductionPass',
  GYM_CLASS: 'gymClassPass',
  SWIMMING_CLASS: 'swimmingClassPass',
  TENNIS_CLASS: 'tennisClassPass',
  MASSAGE_CLASS: 'massageClassPass',
  VIDEO: 'videoPass'
};

export const CLASS = {
  CLASS: 'class',
  PERSONAL_TRAINING: 'personalTraining',
  INDUCTION: 'induction',
  GYM_CLASS: 'gymClass',
  SWIMMING_CLASS: 'swimmingClass',
  TENNIS_CLASS: 'tennisClass',
  MASSAGE_CLASS: 'massageClass',
  VIDEO: 'video'
};

export const CLASS_TYPES = [
  CLASS.CLASS,
  CLASS.PERSONAL_TRAINING,
  CLASS.INDUCTION,
  CLASS.GYM_CLASS,
  CLASS.SWIMMING_CLASS,
  CLASS.TENNIS_CLASS,
  CLASS.MASSAGE_CLASS
];

export const ALL_CLASS_TYPES_WITH_DETAILS = () => [
  CLASS_DETAILS,
  PERSONAL_TRAINING_SESSION_DETAILS,
  INDUCTION_CLASS_DETAILS,
  GYM_CLASS_DETAILS,
  SWIMMING_CLASS_DETAILS,
  TENNIS_CLASS_DETAILS,
  MASSAGE_CLASS_DETAILS
];

export const CLASS_DETAILS = {
  type: CLASS.CLASS,
  name: 'class',
  title: 'Class',
  passesUrl: 'class-passes',
  addOneOffUrl: 'class-timetable/add-one-off',
  usersClassesLeft: 'classesLeft',
  usersClassesLeftForNextMont: 'classesLeftNextMonth',
  productType: PRODUCT_TYPES.CLASS_PASS,
  timetableUrl: 'class-timetable'
};

export const PERSONAL_TRAINING_SESSION_DETAILS = {
  type: CLASS.PERSONAL_TRAINING,
  name: 'personal training',
  title: 'PT',
  passesUrl: 'personal-training-passes',
  addOneOffUrl: 'personal-training-timetable/add-one-off',
  usersClassesLeft: 'PTclassesLeft',
  productType: PRODUCT_TYPES.PERSONAL_TRAINING_PASS,
  timetableUrl: 'personal-training-timetable'
};

export const INDUCTION_CLASS_DETAILS = {
  type: CLASS.INDUCTION,
  name: 'induction',
  title: 'Induction',
  passesUrl: 'induction-passes',
  addOneOffUrl: 'induction-timetable/add-one-off',
  usersClassesLeft: null,
  productType: PRODUCT_TYPES.INDUCTION,
  timetableUrl: 'induction-timetable'
};

export const GYM_CLASS_DETAILS = {
  type: CLASS.GYM_CLASS,
  name: 'gym',
  title: 'Gym',
  passesUrl: 'gym-passes',
  addOneOffUrl: 'gym-timetable/add-one-off',
  usersClassesLeft: 'gymClassesLeft',
  productType: PRODUCT_TYPES.GYM_CLASS,
  timetableUrl: 'gym-timetable'
};

export const SWIMMING_CLASS_DETAILS = {
  type: CLASS.SWIMMING_CLASS,
  name: 'swim',
  title: 'Swim',
  passesUrl: 'swim-passes',
  addOneOffUrl: 'swim-timetable/add-one-off',
  usersClassesLeft: 'swimmingClassesLeft',
  productType: PRODUCT_TYPES.SWIMMING_CLASS,
  timetableUrl: 'swim-timetable'
};

export const TENNIS_CLASS_DETAILS = {
  type: CLASS.TENNIS_CLASS,
  name: 'tennis',
  title: 'Tennis',
  passesUrl: 'tennis-passes',
  addOneOffUrl: 'tennis-timetable/add-one-off',
  usersClassesLeft: 'tennisClassesLeft',
  productType: PRODUCT_TYPES.TENNIS_CLASS,
  timetableUrl: 'tennis-timetable'
};

export const MASSAGE_CLASS_DETAILS = {
  type: CLASS.MASSAGE_CLASS,
  name: 'massage',
  title: 'Massage',
  passesUrl: 'massage-passes',
  addOneOffUrl: 'massage-timetable/add-one-off',
  usersClassesLeft: 'massageClassesLeft',
  productType: PRODUCT_TYPES.MASSAGE_CLASS,
  timetableUrl: 'massage-timetable'
};

export const VIDEO_CLASS_DETAILS = {
  type: CLASS.VIDEO,
  name: 'video',
  title: 'Video',
  passesUrl: 'video-passes',
  addOneOffUrl: null,
  usersClassesLeft: null,
  productType: PRODUCT_TYPES.VIDEO,
  timetableUrl: ''
};

export const ALL_CLASS_TYPES_DETAILS = [
  CLASS_DETAILS,
  PERSONAL_TRAINING_SESSION_DETAILS,
  INDUCTION_CLASS_DETAILS,
  GYM_CLASS_DETAILS,
  SWIMMING_CLASS_DETAILS,
  TENNIS_CLASS_DETAILS,
  MASSAGE_CLASS_DETAILS
];

export const ALL_CURRENCIES = [
  {
    label: 'Pound Sterling',
    value: 'gbp',
    symbol: '£'
  },
  {
    label: 'Euro',
    value: 'eur',
    symbol: '€'
  },
  {
    label: 'US Dollar',
    value: 'usd',
    symbol: '$'
  },
  {
    label: 'Australian Dollar',
    value: 'aud',
    symbol: 'A$'
  }
];

export const CUSTOMER_TYPE = [
  {
    label: 'Membership',
    value: 'full-member'
  },
  {
    label: 'Pay as you go',
    value: 'class-pass-member'
  }
];

export const PASS_PAYMENT_TYPE = [
  'classPass_payment',
  'personalTrainingPass_payment',
  'tennisClassPass_payment',
  'gymClassPass_payment',
  'swimmingClassPass_payment'
];

export const SUBSCRIPTION_PAYMENT_TYPE = [
  'Successful Subscription Charge',
  'Subscription downgrade refund',
  'Subscription upgrade Fee',
  'Joining Fee',
  'Failed Subscription Charge'
];

export const ALL_CLASS_WITH_CLASS_TYPES = () => [...ALL_CLASS_TYPES_WITH_DETAILS(), VIDEO_CLASS_DETAILS];

export const GYM_SETTINGS = {
  class: true,
  personalTraining: true,
  induction: true,
  qrCode: false,
  minimalUserAge: 18,
  newClientsHaveToBeApprovedByAdmin: false,
  membershipProducts: true,
  privateGym: false,
  kitList: false,
  faq: false,
  gymClass: false,
  swimmingClass: false,
  tennisClass: false,
  massageClass: false,
  videoOnDemand: false,
  coupon: false,
  liveStreaming: false,
  currency: 'gbp',
  staffShifts: false
};

export const CLASS_CONFIG_INITIAL_STATE = (classType) => {
  return {
    allowedToBuyPasses: false,
    classType,
    consecutiveBookingsCoolOff: 30,
    consecutiveBookingsEnabled: true,
    minutesAfterStartToAllowSignUps: 15,
    seventhDayAvailabilityHour: 0,
    timetableDisplaySettings: {
      admin: [],
      trainer: [],
      user: []
    },
    trainerRequired: false
  };
};

export const TIMETABLE_DISPLAY_FIELDS = [
  {
    name: 'gym',
    dbName: 'gym',
    label: 'Location',
    additionalDescription: null,
    value: false,
    order: 1,
    showInTimetable: true,
    showInSettings: false
  },
  {
    name: 'dayView',
    dbName: 'dayView',
    label: 'Day view',
    additionalDescription: null,
    value: false,
    order: 2,
    showInTimetable: true,
    showInSettings: true
  },
  {
    name: 'date',
    dbName: 'classDate',
    label: 'Date',
    additionalDescription: null,
    value: true,
    order: 3,
    showInTimetable: true,
    showInSettings: false
  },
  {
    name: 'time',
    dbName: 'classTime',
    label: 'Time',
    additionalDescription: null,
    value: true,
    order: 4,
    showInTimetable: true,
    showInSettings: false
  },
  {
    name: 'name',
    dbName: 'name',
    label: 'Name',
    additionalDescription: null,
    value: false,
    order: 5,
    showInTimetable: true,
    showInSettings: true
  },
  {
    name: 'description',
    dbName: 'description',
    label: 'Description',
    additionalDescription: null,
    value: false,
    order: 6,
    showInTimetable: true,
    showInSettings: true
  },
  {
    name: 'location',
    dbName: 'location',
    label: 'Location',
    additionalDescription: null,
    value: false,
    order: 7,
    showInTimetable: true,
    showInSettings: true
  },
  {
    name: 'duration',
    dbName: 'duration',
    label: 'Duration',
    additionalDescription: null,
    value: false,
    order: 8,
    showInTimetable: true,
    showInSettings: true
  },
  {
    name: 'cost',
    dbName: 'cost',
    label: 'Price',
    additionalDescription: null,
    value: false,
    order: 9,
    showInTimetable: true,
    showInSettings: true
  },
  {
    name: 'attendees',
    dbName: 'attendees',
    label: 'Attendees',
    additionalDescription: null,
    value: false,
    order: 10,
    showInTimetable: true,
    showInSettings: true
  },
  {
    name: 'trainer',
    dbName: 'trainer',
    label: 'Trainer',
    additionalDescription: null,
    value: false,
    order: 11,
    showInTimetable: true,
    showInSettings: true
  },
  {
    name: 'notesForClient',
    dbName: 'notesForClient',
    label: 'Notes for client',
    additionalDescription: null,
    value: false,
    order: 12,
    showInTimetable: false,
    showInSettings: false
  },
  {
    name: 'notesForCompany',
    dbName: 'notesForCompany',
    label: 'Notes for company',
    additionalDescription: null,
    value: false,
    order: 13,
    showInTimetable: false,
    showInSettings: false
  },
  {
    name: 'endDate',
    dbName: 'endDate',
    label: 'End Date',
    additionalDescription: null,
    value: false,
    order: 14,
    showInTimetable: false,
    showInSettings: false
  },
  {
    name: 'actionColumn',
    dbName: 'actionColumn',
    label: ' ',
    additionalDescription: null,
    value: true,
    order: 100,
    showInTimetable: true,
    showInSettings: false
  },
  {
    name: 'noSettings',
    dbName: 'noSettings',
    label: '',
    additionalDescription: '',
    value: true,
    order: 101,
    showInTimetable: false,
    showInSettings: false
  }
];
