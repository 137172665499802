import { useState } from 'react';
import Title from '../../components/UI/Title/Title';
import { Tabs } from 'antd';
import ExportToCSV from '../AdminDashboard/ExportToCSV/ExportToCSV';
import AttendancesReport from './AttendancesReport/AttendancesReport';
import PayrollReports from './PayrollReports/PayrollReports';
import PassesReport from './Passes/PassesReport';
import DebtorsReports from './DebtorReports/DebtorReports';
import RecordAttendancesReport from './RecordAttendancesReport/RecordAttendancesReport';

const ManageReports = () => {
  const [CSVData, setCSVData] = useState({ data: [], reportType: 'classPassesReport', gymName: 'gymName' });

  const items = [
    {
      key: 0,
      label: 'Passes',
      children: <PassesReport setCSVData={setCSVData} />
    },
    {
      key: 1,
      label: 'Attendance',
      children: <AttendancesReport setCSVData={setCSVData} />
    },
    {
      key: 3,
      label: 'Recorded Attendances',
      children: <RecordAttendancesReport setCSVData={setCSVData} />
    },
    {
      key: 4,
      label: 'Debtors',
      children: <DebtorsReports setCSVData={setCSVData} />
    },
    {
      key: 5,
      label: 'Payroll',
      children: <PayrollReports setCSVData={setCSVData} />
    }
  ];
  return (
    <div>
      <div className="titleAndBtnContainer">
        <Title title={'reports'} />
        <ExportToCSV
          data={CSVData.data}
          type={CSVData.reportType}
          gymName={CSVData.gymName}
          disabled={CSVData.data.length === 0}
        />
      </div>

      <Tabs tabPosition={'left'} items={items} />
    </div>
  );
};

export default ManageReports;
