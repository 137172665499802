import { useContext } from 'react';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import Button from '../../../components/UI/Button/Button';
import { addUserSource, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { useMutation } from '@tanstack/react-query';

const AddPaymentMethod = ({
  title = 'Add new payment method',
  isSubscribeToPlan = false,
  onSubscribeToPlan = () => {},
  redirectTo = () => {}
}) => {
  const { openNotification } = useContext(NotificationContext);

  const {
    mutate: onAddNewSource,
    isLoading: onAddNewSourceIsLoading,
    isSuccess: onAddNewSourceIsSuccess
  } = useMutation((sourceId) => addUserSource(sourceId), {
    onSuccess: () => {
      openNotification(null, 'success', 'Thanks for adding your card details!');
      redirectTo();
      if (isSubscribeToPlan) {
        onSubscribeToPlan();
      } else {
        refreshFetchFunctions([['userPaymentSources']]);
      }
    },
    onError: () => {
      openNotification('default', 'error');
    }
  });

  const handleSubmit = async (event, elements, stripe) => {
    event.preventDefault();

    if (!stripe || !elements) return;
    const cardElement = elements.getElement(CardElement);
    const { error, source } = await stripe.createSource({ ...cardElement, currency: 'gbp' });

    // eslint-disable-next-line
    console.log('[Payment Method]', source);

    if (error) {
      // eslint-disable-next-line
      console.log('[error]', error);
    } else {
      onAddNewSource(source.id);
      if (onAddNewSourceIsSuccess) {
        cardElement.clear();
      }
    }
  };
  return (
    <div>
      <h3 className="uppercase">{title}</h3>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
            <CardElement className={'p-3 border-[1px] border-secondary'} />
            <Button
              htmlType={'submit'}
              secondary
              label={isSubscribeToPlan ? 'pay' : 'save'}
              fullWidth
              isLoading={onAddNewSourceIsLoading}
            />
          </form>
        )}
      </ElementsConsumer>
    </div>
  );
};

export default AddPaymentMethod;
