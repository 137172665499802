import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { deleteUserSource, refreshFetchFunctions, selectUserSource } from '../../../../../apiFunctions/apiFunctions';
import Loading from '../../../../../components/Loading/Loading';
import Notification from '../../../../../components/UI/Notification/Notification';
import Icon from '../../../../../components/UI/Icon/Icon';
import { NotificationContext } from '../../../../../context/Notification/NotificationContext';
import AddPaymentMethod from '../../../../Stripe/AddPaymentMethod/AddPaymentMethod';
import { classNames, returnFirstErrorMsg } from '../../../../../shared/utility';
import { Checkbox } from 'antd';
import { AppContext } from '../../../../../context/App/AppContext';

const PaymentMethods = () => {
  const { openNotification } = useContext(NotificationContext);
  const { userPaymentSources, userPaymentSourcesIsLoading, userPaymentSourcesIsError } = useContext(AppContext);

  const { mutate: onSourceDelete, isLoading: onSourceDeleteIsLoading } = useMutation((id) => deleteUserSource(id), {
    onSuccess: () => {
      openNotification(null, 'success', 'Payment method successfully deleted!');
      refreshFetchFunctions([['userPaymentSources']]);
    },
    onError: (err) => openNotification(null, 'error', returnFirstErrorMsg(err))
  });
  const { mutate: onSourceChange, isLoading: onSourceChangeIsLoading } = useMutation((id) => selectUserSource(id), {
    onSuccess: () => {
      openNotification(null, 'success', 'Payment method has been successfully changed!');
      refreshFetchFunctions([['userPaymentSources']]);
    },
    onError: (err) => openNotification(null, 'error', returnFirstErrorMsg(err))
  });

  const handleChange = (element) => {
    if (element.default) {
      return;
    }
    return onSourceChange(element.id);
  };

  if (userPaymentSourcesIsLoading) {
    return <Loading />;
  }
  if (userPaymentSourcesIsError) {
    return <Notification status="critical" message={'Unable to show payment methods'} />;
  }

  return (
    <div className="border-t-[1px] border-neutral-400">
      <div className="my-6">
        <h3 className="uppercase">Payment Methods</h3>
        {userPaymentSources.length > 0 ? (
          userPaymentSources.map((elm, i) => (
            <div key={i} className={classNames('grid grid-cols-[1fr_30px]', 'listItem p-2.5')}>
              <Checkbox key={i} checked={elm.default} onChange={() => handleChange(elm)}>
                <div className="text-black">
                  {elm.card.brand} ending {elm.card.last4}
                </div>
              </Checkbox>
              {onSourceDeleteIsLoading || onSourceChangeIsLoading ? (
                <Loading />
              ) : (
                <Icon name={'delete'} onClick={() => onSourceDelete(elm.id)} />
              )}
            </div>
          ))
        ) : (
          <div>No payment methods</div>
        )}
      </div>
      <AddPaymentMethod />
    </div>
  );
};

export default PaymentMethods;
