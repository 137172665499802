import { useContext, useEffect, useState } from 'react';
import Button from '../../../../components/UI/Button/Button';
import { useMutation } from '@tanstack/react-query';
import { signUpWaitList } from '../../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';

export const WaitListUserButton = ({ selectedClass }) => {
  const { openNotification } = useContext(NotificationContext);
  const [hasUserSignUpWaitList, setHasUserSignUpWaitList] = useState(false);

  useEffect(() => setHasUserSignUpWaitList(selectedClass.hasUserSignUpWaitList), [selectedClass.hasUserSignUpWaitList]);
  const { mutate: onSignUpWaitList, isLoading: onSignUpWaitListIsLoading } = useMutation({
    mutationFn: () => signUpWaitList(selectedClass._id, hasUserSignUpWaitList ? 'signOut' : 'signUp'),
    onSuccess: () => {
      openNotification(
        null,
        'success',
        hasUserSignUpWaitList
          ? 'You have left the waiting list.'
          : 'Thanks you are now on the waiting list. We will email you if a space becomes available.'
      );
      setHasUserSignUpWaitList(!hasUserSignUpWaitList);
    },
    onError: () => {
      openNotification('default', 'error');
    }
  });

  return (
    <Button
      padding={'p-0'}
      btnClassName={' text-xs'}
      textWrap
      label={hasUserSignUpWaitList ? 'Leave waiting list' : 'Join waiting list'}
      onClick={onSignUpWaitList}
      isLoading={onSignUpWaitListIsLoading}
    />
  );
};
