import { useState } from 'react';
import GoogleApplePayButton from './GoogleApplePayButton';
import axios from '../../../axios-global';
import Loading from '../../../components/Loading/Loading';
import Button from '../../../components/UI/Button/Button';
import { Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const GoogleApplePayModal = ({ productType = 'class', product, selectedClass, classType }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentIntentResponse, setPaymentIntentResponse] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const onWebPayHandler = async () => {
    setLoading(true);
    const response =
      productType === 'pass'
        ? await axios.post(`/payments/webPay/${product._id}`, {
            type: 'product'
          })
        : await axios.post(`/payments/webPay/${selectedClass._id}`, {
            type: 'class'
          });

    if (response) {
      setLoading(true);
      setPaymentIntentResponse(response);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
    onWebPayHandler().then(() => {
      // eslint-disable-next-line
      setLoading(false);
    });
  };

  const content = (
    <div>
      <div className={'text-md mb-3 text-center'}>Choose payment method</div>

      <GoogleApplePayButton
        product={product}
        productType={productType}
        initWebPayment={paymentIntentResponse}
        selectedClass={selectedClass}
        classType={classType}
        closeModal={() => setShowModal(false)}
      />
      <Button
        dataCy="addPaymentMethod"
        label="Add payment method"
        secondary
        fullWidth
        className={'mt-4'}
        onClick={() =>
          navigate(`/user/add-payment-method`, {
            state: { itemId: productType === 'class' ? selectedClass._id : product._id, pathname: location.pathname }
          })
        }
      />
      <Button fullWidth className={'mt-4'} label="Cancel" onClick={() => setShowModal(false)} />
    </div>
  );

  return (
    <div>
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        {loading ? <Loading /> : <div>{content}</div>}
      </Modal>

      <Button secondary fullWidth label={'Pay'} onClick={handleOpenModal} />
      {/* ) : (
        <Button
          label="Pay"
          secondary
          fullWidth
          btnClassName={'text-sm'}
          onClick={() =>
            navigate(`/user/add-payment-method`, {
              state: { itemId: productType === 'class' ? selectedClass._id : product._id, pathname: location.pathname }
            })
          }
        />
      )}*/}
    </div>
  );
};

export default GoogleApplePayModal;
