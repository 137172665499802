import { useContext } from 'react';
import { ALL_CLASS_TYPES_DETAILS } from '../../../constants';
import { AppContext } from '../../../context/App/AppContext';
import SingleUpcomingClasses from './SingleUpcomingClasses/SingleUpcomingClasses';

const DefaultClientUpcomingClasses = () => {
  const { selectedGym } = useContext(AppContext);
  return ALL_CLASS_TYPES_DETAILS.map((elm) =>
    selectedGym.settings[elm.type] ? <SingleUpcomingClasses key={elm.type} classType={elm} /> : null
  );
};

export default DefaultClientUpcomingClasses;
