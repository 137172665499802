import { AiOutlineUser } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { BsDatabaseX } from 'react-icons/bs';
import { FaBirthdayCake } from 'react-icons/fa';
import {
  GrMoney,
  GrCalendar,
  GrStatusCritical,
  GrStatusDisabled,
  GrStatusGood,
  GrLinkNext,
  GrLinkPrevious,
  GrRefresh,
  GrClose,
  GrEdit,
  GrAdd,
  GrDocumentUser,
  GrUserAdd,
  GrClear,
  GrMenu,
  GrView,
  GrNotes,
  GrCaretNext,
  GrGroup,
  GrFacebookOption,
  GrInstagram,
  GrTwitter,
  GrCircleInformation,
  GrTrash,
  GrLock
} from 'react-icons/gr';
import { classNames } from '../../../shared/utility';
import customColors from '../../../theme/customColors';

const Icon = ({
  name,
  className = '',
  size,
  disabled = false,
  onClick = () => {},
  cursorPointer = true,
  show = true,
  ...restProps
}) => {
  const props = {
    ...restProps,
    size: size ? size : 20,
    className: classNames(
      'my-auto mx-2',
      cursorPointer && 'cursor-pointer',
      disabled && 'cursor-not-allowed',
      className
    ),
    onClick: () => (disabled ? () => {} : onClick())
  };
  if (!show) {
    return null;
  }

  switch (name) {
    case 'loading':
      return (
        <FaSpinner {...restProps} size={size} className={[className ? className : '', 'animate-spin'].join(' ')} />
      );
    case 'user':
      return <AiOutlineUser {...props} />;
    case 'userData':
      return <GrDocumentUser {...props} />;
    case 'userAdd':
      return <GrUserAdd {...props} />;
    case 'userDeny':
    case 'clear':
      return <GrClear {...props} />;
    case 'currency':
    case 'money':
      return <GrMoney {...props} />;
    case 'calendar':
      return <GrCalendar {...props} />;
    case 'statusOk':
      return <GrStatusGood color={customColors['custom-icon-ok']} {...props} />;
    case 'statusDisabled':
      return <GrStatusDisabled color={customColors['custom-icon-warning']} {...props} />;
    case 'statusCritical':
      return <GrStatusCritical color={customColors['custom-icon-error']} {...props} />;
    case 'next':
      return <GrLinkNext {...props} />;
    case 'previous':
      return <GrLinkPrevious {...props} />;
    case 'refresh':
      return <GrRefresh {...props} />;
    case 'delete':
    case 'close':
      return <GrClose {...props} />;
    case 'edit':
      return <GrEdit {...props} />;
    case 'add':
      return <GrAdd {...props} />;
    case 'menu':
      return <GrMenu {...props} />;
    case 'view':
      return <GrView {...props} />;
    case 'notes':
      return <GrNotes {...props} />;
    case 'triangle':
      return <GrCaretNext {...props} />;
    case 'noData':
      return <BsDatabaseX {...props} />;
    case 'group':
      return <GrGroup {...props} />;
    case 'facebook':
      return <GrFacebookOption {...props} />;
    case 'instagram':
      return <GrInstagram {...props} />;
    case 'twitter':
      return <GrTwitter {...props} />;
    case 'birthday':
      return <FaBirthdayCake {...props} />;
    case 'info':
    case 'information':
      return <GrCircleInformation {...props} />;
    case 'trash':
      return <GrTrash {...props} />;
    case 'lock':
    case 'locked':
      return <GrLock {...props} />;

    default:
      return null;
  }
};

export default Icon;
