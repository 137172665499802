import { useContext, useEffect, useState } from 'react';
import { checkIfTrainerIsAvailableAtProvidedDateAndTime } from '../../../../shared/classFunctions';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Button from '../../../../components/UI/Button/Button';
import { Modal } from 'antd';
import moment from 'moment/moment';
import { AppContext } from '../../../../context/App/AppContext';

const SingleClassValidator = ({ data = null, setIsStaffShiftsValid = () => {} }) => {
  const [show, setShow] = useState(false);
  const { trainersList } = useContext(AppContext);
  useEffect(
    () => {
      const dateAndTime = moment(data.classDate.format('YYYY-MM-DD') + ' ' + data.classTime.format('HH:mm')).format(
        'YYYY-MM-DD HH:mm'
      );
      const currentTrainer = trainersList.find((elm) => elm._id === data.trainerId);
      const isValid =
        currentTrainer && currentTrainer.shifts.length > 0
          ? checkIfTrainerIsAvailableAtProvidedDateAndTime(currentTrainer, dateAndTime, data.duration)
          : false;

      setShow(!isValid);
      setIsStaffShiftsValid(isValid);
    }, //eslint-disable-next-line
    [data]
  );

  const onOkBtn = () => {
    setIsStaffShiftsValid(true);
    setShow(false);
  };
  const onCancelBtn = () => {
    setIsStaffShiftsValid(false);
    setShow(false);
  };

  return (
    <Modal open={show} onCancel={onCancelBtn}>
      <div className={'text-center'}>Trainer is not available at that time.</div>
      <div className={'text-center mt-5'}>Do you still want to continue? </div>
      <ModalButtonsContainer>
        <Button secondary label={'Confirm'} onClick={onOkBtn} />
        <Button label={'Cancel'} onClick={onCancelBtn} />
      </ModalButtonsContainer>
    </Modal>
  );
};

export default SingleClassValidator;
