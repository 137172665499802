import { useContext } from 'react';
import { returnGymsByAccess } from '../../../../shared/userFunctions';
import { AppContext } from '../../../../context/App/AppContext';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { addTrainer, editTrainer, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import Title from '../../../../components/UI/Title/Title';
import Button from '../../../../components/UI/Button/Button';
import RenderForm from '../../../../components/Forms/RenderForm';
import dayjs from 'dayjs';

const AddEditTrainer = ({ selected = null, onCancel = () => {} }) => {
  const { gymsList, userData, selectedGym } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const isEditMode = selected !== null;
  const navigate = useNavigate();

  const { mutate: onTrainerUpdate, isLoading: onTrainerUpdateIsLoading } = useMutation(
    (formData) =>
      isEditMode ? editTrainer(selected._id, formData) : addTrainer(selectedGym._id, { ...formData, role: 'trainer' }),
    {
      onSuccess: () => {
        openNotification('save', 'success');
        if (!isEditMode) {
          navigate(-1);
        } else {
          onCancel();
        }
        refreshFetchFunctions([['trainersList', selectedGym._id]]);
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );
  let initialValues = isEditMode ? selected : { title: 'Mr', gymId: [] };
  let excludeFormFields = isEditMode ? ['password', 'confirmPassword'] : [];
  if (!selectedGym.settings.staffShifts) {
    excludeFormFields.push('shifts');
  } else {
    initialValues.shifts = initialValues.shifts?.map((elm) => ({
      ...elm,
      startingFrom: dayjs(elm.startingFrom, 'HH:mm'),
      endsAt: dayjs(elm.endsAt, 'HH:mm')
    }));
  }

  return (
    <>
      <div className="titleAndBtnContainer">
        <Title title={isEditMode ? 'Edit trainer' : 'Add new trainer'} />
        {!isEditMode && <Button primary label="Cancel" onClick={() => navigate(-1)} />}
      </div>

      <div>
        <RenderForm
          className="md:order-1"
          initialValues={initialValues}
          formFields={[
            'title',
            'userName',
            'lastName',
            'email',
            'phone',
            'password',
            'confirmPassword',
            'selectGymsAccess',
            'shifts'
          ]}
          excludeFormFields={excludeFormFields}
          btnLabel="save"
          btnIsLoading={onTrainerUpdateIsLoading}
          onFinish={onTrainerUpdate}
          gymsList={returnGymsByAccess(gymsList, userData)}
          onCancel={onCancel}
          cancelBtn={isEditMode}
        />
      </div>
    </>
  );
};
export default AddEditTrainer;
