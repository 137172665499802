import { useContext } from 'react';
import { returnAllUpcomingClasses } from '../../../shared/classFunctions';
import { useQuery } from '@tanstack/react-query';
import { fetchClasses } from '../../../apiFunctions/apiFunctions';
import DefaultTimetable from '../DefaultTimetable/DefaultTimetable';
import moment from 'moment';
import { AppContext } from '../../../context/App/AppContext';

const initialDateState = {
  dateFrom: moment().subtract(14, 'd').format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD')
};

const SingleClassTypeHistoryComponent = ({ classType, dateRange = initialDateState }) => {
  const { selectedGym, userData } = useContext(AppContext);
  const { data, error, isLoading, isFetching } = useQuery(
    ['historyClasses', [classType, dateRange.dateFrom, dateRange.dateTo, selectedGym._id]],
    () => fetchClasses(classType, selectedGym._id, dateRange.dateFrom, dateRange.dateTo),
    {
      enabled: dateRange.dateFrom !== null && dateRange.dateTo !== null
    }
  );

  return (
    <>
      <DefaultTimetable
        classesList={
          userData.isAdmin
            ? returnAllUpcomingClasses(data)
            : returnAllUpcomingClasses(data).filter((elm) => elm.trainer.id === userData._id)
        }
        classesListLoading={isLoading || isFetching}
        classesListError={error}
        classType={classType}
        type="history"
      />
    </>
  );
};

export default SingleClassTypeHistoryComponent;
