import { useContext } from 'react';
import { AppContext } from '../../../context/App/AppContext';
import UserSettings from './User/UserSettings';
import TrainerSettings from './Trainer/TrainerSettings';

const ManageUserSettings = () => {
  const { userData } = useContext(AppContext);

  if (userData.isUser) {
    return <UserSettings userData={userData} />;
  }
  if (userData.isTrainer) {
    return <TrainerSettings />;
  }

  return <h1>Admin settings are currently unavailable</h1>;
};

export default ManageUserSettings;
