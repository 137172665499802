import useSelectFilter from '../hooks/useSelectFilter';
import { Input } from 'antd';

const ClientNameFilter = ({ onFilter }) => {
  const { onSelectedFilterChanged } = useSelectFilter('', onFilter);

  return (
    <div className="block">
      <h4>Filter by name</h4>
      <Input name="client-name-filter" placeholder="Enter name or last name" onChange={onSelectedFilterChanged} />
    </div>
  );
};

export default ClientNameFilter;
