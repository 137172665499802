import Button from '../../UI/Button/Button';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import RenderForm from '../../Forms/RenderForm';

const ClientProfileChangeCustomerType = ({
  selectedClient,
  show = false,
  onUpdateSelectedClient,
  onUpdateSelectedClientIsLoading,
  onUpdateSelectedClientIsSuccess
}) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setShowModal(false);
  }, [onUpdateSelectedClientIsSuccess]);

  if (show) {
    return (
      <div className={'my-4'}>
        <Modal open={showModal} onCancel={() => setShowModal(false)}>
          <RenderForm
            initialValues={selectedClient}
            onFinish={onUpdateSelectedClient}
            formFields={['customerType']}
            btnLabel={'save'}
            btnIsLoading={onUpdateSelectedClientIsLoading}
            cancelBtn={'Cancel'}
            onCancel={() => setShowModal(false)}
          />
        </Modal>

        <Button red fullWidth label="Change account type" onClick={() => setShowModal(true)} />
      </div>
    );
  }
  return null;
};

export default ClientProfileChangeCustomerType;
