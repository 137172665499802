import { Tabs } from 'antd';
import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import ProfilePaymentHistory from '../../UI/ProfilePaymentHistory/ProfilePaymentHistory';
import { PASS_PAYMENT_TYPE, SUBSCRIPTION_PAYMENT_TYPE } from '../../../constants';
const ClientProfilePaymentHistoryTabs = (props) => {
  const dataSubscriptionPayments = props.paymentHistory.filter((elem) => SUBSCRIPTION_PAYMENT_TYPE.includes(elem.type));

  const dataOneOffPayments = props.paymentHistory.filter(
    (elem) => !PASS_PAYMENT_TYPE.includes(elem.type) && !SUBSCRIPTION_PAYMENT_TYPE.includes(elem.type)
  );

  const dataPassPayments = props.paymentHistory.filter((elem) => PASS_PAYMENT_TYPE.includes(elem.type));

  const tabsItems = [
    {
      key: 0,
      label: 'Subscription',
      children:
        dataSubscriptionPayments.length === 0
          ? 'No detail available'
          : dataSubscriptionPayments.map((passElem) => (
              <ProfileTextWithLabel
                paymentsInfo
                key={passElem.date}
                label={passElem.type}
                text={<ProfilePaymentHistory lastPaymentInfo={passElem} />}
              />
            ))
    },
    {
      key: 1,
      label: 'Passes',
      children:
        dataPassPayments.length === 0
          ? 'No detail available'
          : dataPassPayments.map((passElem) => (
              <ProfileTextWithLabel
                paymentsInfo
                key={passElem.date}
                label={passElem.type}
                text={<ProfilePaymentHistory lastPaymentInfo={passElem} />}
              />
            ))
    },
    {
      key: 3,
      label: 'Single Pass',
      children:
        dataOneOffPayments.length === 0
          ? 'No detail available'
          : dataOneOffPayments.map((passElem) => (
              <ProfileTextWithLabel
                paymentsInfo
                key={passElem.date}
                label={passElem.type}
                text={<ProfilePaymentHistory lastPaymentInfo={passElem} />}
              />
            ))
    }
  ];

  return (
    <>
      <h3 className="uppercase mt-2">payment history</h3>
      <Tabs items={tabsItems} />
    </>
  );
};
export default ClientProfilePaymentHistoryTabs;
