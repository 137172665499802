import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  addClass,
  createClassAndSignUpAllUsersToClass,
  editClass,
  refreshFetchFunctions
} from '../../../apiFunctions/apiFunctions';
import Loading from '../../../components/Loading/Loading';
import { CLASS } from '../../../constants';
import RenderForm from '../../../components/Forms/RenderForm';
import { AppContext } from '../../../context/App/AppContext';
import Notification from '../../../components/UI/Notification/Notification';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { returnClassConfig } from '../../../shared/gymFunctions';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Button from '../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const AddEditSingleClass = ({
  classType,
  selectedClass = null,
  onCancel = () => {},
  templatesList = [],
  templatesListIsLoading = false,
  templatesListIsError = false
}) => {
  const { openNotification } = useContext(NotificationContext);
  const {
    selectedGym,
    trainersList,
    trainersListIsLoading,
    trainersListIsError,
    usersList,
    usersListIsLoading,
    usersListIsError
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [createdClassData, setCreatedClassData] = useState(null);
  const navigate = useNavigate();
  const isNew = selectedClass === null;
  const isPT = classType === CLASS.PERSONAL_TRAINING;
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);

  const { mutate: onCreateClass, isLoading: onCreateClassIsLoading } = useMutation(
    (formData) =>
      isNew
        ? isPT
          ? createClassAndSignUpAllUsersToClass(selectedGym._id, classType, formData, formData.attendanceList)
          : addClass(selectedGym._id, classType, formData)
        : editClass(selectedClass._id, formData),
    {
      onSuccess: (resp) => {
        if (isNew && moment(resp?.classDate).isSameOrAfter(moment().add(14, 'd'))) {
          setShowModal(true);
          setCreatedClassData(resp);
        } else {
          onSuccessfulResp();
        }
      },
      onError: () => {
        openNotification('create', 'error');
      }
    }
  );
  const onSuccessfulResp = () => {
    isNew
      ? refreshFetchFunctions([['upcomingClasses', classType]])
      : refreshFetchFunctions([['singleClass', selectedClass._id]]);
    openNotification('create', 'success');
    onCancel();
  };

  const formFields =
    classType === CLASS.CLASS
      ? [
          'template',
          'name',
          'description',
          'selectTrainer',
          'location',
          'limit',
          'duration',
          'currency',
          'cost',
          'membershipClassPrice',
          'classTime',
          'classDate',
          'minimalTimeCancellationInHours',
          'private',
          'enabled',
          'waitList'
        ]
      : isPT
        ? [
            'selectTrainer',
            'selectPTClients',
            'notesForClient',
            'notesForCompany',
            'classTime',
            'classDate',
            'minimalTimeCancellationInHours',
            'enabled'
          ]
        : [
            'template',
            'selectTrainer',
            'limit',
            'duration',
            'currency',
            'cost',
            'classTime',
            'classDate',
            'minimalTimeCancellationInHours',
            'private',
            'enabled',
            'waitList'
          ];

  let initialValues = {
    duration: 60,
    minimalTimeCancellationInHours: isPT ? 24 : 2,
    enabled: true,
    private: isPT,
    currency: 'gbp',
    waitListConfig: {
      active: false,
      minimalTimeBeforeClassStartsToNotifyUsers: 2,
      timeDelayForSendingNotifications: 2
    }
  };

  if (!isNew) {
    initialValues = {
      ...initialValues,
      ...selectedClass,
      classTime: dayjs(selectedClass.classTime, 'h:mm A'),
      classDate: dayjs(selectedClass.classDate),
      trainerId: selectedClass.trainer?._id
    };
  }

  let excludeFormFields = isNew ? [] : ['template', 'currency', 'membershipClassPrice', 'selectPTClients'];
  if (!classConfig.trainerRequired) {
    excludeFormFields.push('selectTrainer');
  }
  if (!selectedGym.settings.membershipProducts && isNew) {
    excludeFormFields.push('membershipClassPrice');
  }

  if (templatesListIsLoading || trainersListIsLoading || (usersListIsLoading && isPT)) {
    return <Loading />;
  }
  if (templatesListIsError || trainersListIsError || (usersListIsError && isPT)) {
    return (
      <Notification
        status={'critical'}
        message={'Unable to fetch ' + templatesListIsError ? 'templates' : 'trainers'}
      />
    );
  }

  return (
    <div>
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        <div className={'my-4 text-center'}>Do you want to be redirected to that event?</div>
        <ModalButtonsContainer>
          <Button
            secondary
            label={'yes'}
            onClick={() => navigate(`/gym/${createdClassData.gymId}/class/${createdClassData._id}`)}
          />
          <Button
            primary
            label={'no'}
            onClick={() => {
              setShowModal(false);
              onSuccessfulResp();
            }}
          />
        </ModalButtonsContainer>
      </Modal>
      <RenderForm
        formFields={formFields}
        excludeFormFields={excludeFormFields}
        initialValues={initialValues}
        onFinish={onCreateClass}
        btnIsLoading={onCreateClassIsLoading}
        templatesList={templatesList}
        trainersList={trainersList}
        usersList={usersList}
        onCancel={onCancel}
        cancelBtn={!isNew}
        classType={classType}
        clearFormOnFinish={true}
        showStaffShifts={selectedGym.settings.staffShifts}
      />
    </div>
  );
};

export default AddEditSingleClass;
