import Icon from '../UI/Icon/Icon';

const Loading = ({ className = '', centered = true, size = 30 }) => {
  return (
    <div className={[className, centered ? 'flex justify-center' : ''].join(' ')}>
      <Icon name="loading" size={size} />
    </div>
  );
};

export default Loading;
