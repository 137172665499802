import { useContext } from 'react';
import classes from './UploadFAQ.module.scss';
import { useMutation } from '@tanstack/react-query';
import { refreshFetchFunctions, uploadFAQJsonFile } from '../../../apiFunctions/apiFunctions';
import { returnFirstErrorMsg } from '../../../shared/utility';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import Button from '../../../components/UI/Button/Button';
import { AppContext } from '../../../context/App/AppContext';

const UploadFaq = ({ setShowAddNewFAQ }) => {
  const { openNotification } = useContext(NotificationContext);
  const { selectedGym, userData } = useContext(AppContext);

  const mutateSaveJSONFAQ = useMutation((data) => uploadFAQJsonFile(selectedGym._id, data), {
    onSuccess: () => {
      openNotification(null, 'success', 'JSON file successfully uploaded.');
      refreshFetchFunctions([['faq', selectedGym._id]]);
    },
    onError: (err) => {
      openNotification(null, 'error', returnFirstErrorMsg(err));
    }
  });

  const uploadFromJSON = () => {
    const uploadFileFunction = (event) => {
      let file = event.target.files[0];
      let reader = new FileReader();

      reader.onload = () => {
        let content = reader.result;
        mutateSaveJSONFAQ.mutate(JSON.parse(content));
      };
      reader.readAsText(file);
    };
    return (
      <div className={classes.uploadFAQ}>
        <a href={`${process.env.PUBLIC_URL}/assets/faqExample.json`} download="faqExample">
          Get JSON template
        </a>
        <label htmlFor="file">Upload JSON</label>
        <input type="file" name="file" id="file" accept=".json" onChange={uploadFileFunction} />
      </div>
    );
  };

  return userData.isAdmin ? (
    <div>
      <div className={classes.container}>
        {uploadFromJSON()}
        <Button label="Add New" secondary onClick={() => setShowAddNewFAQ(true)} />
      </div>
    </div>
  ) : null;
};

export default UploadFaq;
