import { useContext, useState } from 'react';
import Button from '../../../components/UI/Button/Button';
import { Collapse, Modal } from 'antd';
import AddEditCategory from './AddEditCategory/AddEditCategory';
import Icon from '../../../components/UI/Icon/Icon';
import { useMutation } from '@tanstack/react-query';
import { addVideoCategory, editVideoCategory, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../shared/utility';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { VideoContext } from '../../../context/Video/VideoContext';

const ManageVideoCategories = () => {
  const { openNotification } = useContext(NotificationContext);
  const { videoCategories } = useContext(VideoContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { mutate: onAddEditCategory, isLoading: onAddEditCategoryIsLoading } = useMutation(
    (data) => (selectedCategory !== null ? editVideoCategory(selectedCategory.id, data) : addVideoCategory(data)),
    {
      onSuccess: () => {
        openNotification(null, 'success', selectedCategory !== null ? 'Successfully updated' : 'Successfully added');
        refreshFetchFunctions(['fetchVideoCategories']);
        setSelectedCategory(null);
        onCloseModal();
      },
      onError: (err) => {
        openNotification(null, 'critical', returnErrorFromResponse(err));
      }
    }
  );
  const onAddNewCategory = () => {
    setSelectedCategory(null);
    setShowModal(true);
  };
  const onCloseModal = () => {
    setSelectedCategory(null);
    setShowModal(false);
  };
  const onEditCategory = (item) => {
    setSelectedCategory(item);
    setShowModal(true);
  };
  const onActivateInactivateCategory = (item, isActive = true) => {
    setSelectedCategory(item);
    onAddEditCategory({ active: !isActive });
  };

  const returnCategories = (array, isActive = true) => (
    <Collapse
      accordion
      collapsible={'icon'}
      className={'mt-4'}
      items={array.map((elm, i) => ({
        key: i,
        label: (
          <div className={'font-bold flex justify-between'}>
            {elm.name}
            <div className={'flex'}>
              <Icon name={'edit'} onClick={() => onEditCategory(elm)} />
              <Icon name={isActive ? 'delete' : 'add'} onClick={() => onActivateInactivateCategory(elm, isActive)} />
            </div>
          </div>
        ),
        children: (
          <div>
            {elm.options.map((elem, i) => (
              <div key={i}>{elem}</div>
            ))}
          </div>
        )
      }))}
    />
  );
  return (
    <div>
      <Modal open={showModal} onCancel={onCloseModal}>
        <AddEditCategory
          category={selectedCategory}
          onSave={onAddEditCategory}
          isLoading={onAddEditCategoryIsLoading}
        />
      </Modal>

      <Button
        className={'grid justify-end'}
        label="Add Category"
        secondary={true}
        onClick={onAddNewCategory}
        dataCy={'add_category_btn'}
      />

      <div className={'md:grid md:grid-cols-2 gap-x-4 mt-4'}>
        <div>
          <div className={'text-lg font-bold'}>Active</div>
          {returnCategories(videoCategories.active, true)}
        </div>
        <div>
          <div className={'text-lg font-bold'}>Inactive</div>
          {returnCategories(videoCategories.inActive, false)}
        </div>
      </div>
    </div>
  );
};

export default ManageVideoCategories;
