import useSelectFilter from '../hooks/useSelectFilter';
import { CLIENTS_FILTERS } from '../../../constants';
import { Radio, Space } from 'antd';

const ClientTypeFilter = ({ onFilter }) => {
  const { onSelectedFilterChanged, isSelectedFilter } = useSelectFilter(CLIENTS_FILTERS.ALL_MEMBERS, onFilter);

  return (
    <Space direction="vertical" size={2} className="block">
      <h4>Client Type</h4>
      <Radio
        id={CLIENTS_FILTERS.FULL_MEMBERS}
        name="client_type"
        type="radio"
        value={CLIENTS_FILTERS.FULL_MEMBERS}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_FILTERS.FULL_MEMBERS)}>
        Gym Members
      </Radio>

      <Radio
        id={CLIENTS_FILTERS.CLASS_PASS_MEMBERS}
        name="client_type"
        type="radio"
        value={CLIENTS_FILTERS.CLASS_PASS_MEMBERS}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_FILTERS.CLASS_PASS_MEMBERS)}>
        Class Pass Members
      </Radio>

      <Radio
        id={CLIENTS_FILTERS.ALL_MEMBERS}
        name="client_type"
        type="radio"
        value={CLIENTS_FILTERS.ALL_MEMBERS}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_FILTERS.ALL_MEMBERS)}>
        All Members
      </Radio>
    </Space>
  );
};

export default ClientTypeFilter;
