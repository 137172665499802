const error = 'Something went wrong. Please try again.';
const success = 'Success!';
export default {
  default: {
    success: {
      message: success
    },
    error: {
      message: error
    }
  },
  update: {
    success: {
      message: 'Successfully updated!'
    },
    error: {
      message: error
    }
  },
  save: {
    success: {
      message: 'Successfully saved!'
    },
    error: {
      message: error
    }
  },
  delete: {
    success: {
      message: 'Successfully deleted!'
    },
    error: {
      message: error
    }
  },
  create: {
    success: {
      message: 'Successfully created!'
    },
    error: {
      message: 'Something went wrong while creating!'
    }
  },
  createGym: {
    success: {
      message: 'New gym successfully created!'
    },
    error: {
      message: error
    }
  },
  userImgUpload: {
    success: {
      message: 'Image successfully uploaded!'
    },
    error: {
      message: error
    }
  },

  payment: {
    success: {
      message: 'Successfully paid!'
    },
    error: {
      message: error
    }
  },
  login: {
    success: {
      message: 'Successfully logged in!'
    },
    error: {
      message: error
    }
  },
  logout: {
    success: {
      message: 'Successfully logged out!'
    },
    error: {
      message: error
    }
  },
  token: {
    error: {
      message: 'Your session expired, please login again.'
    }
  },
  forgotPassword: {
    success: {
      message: success
    },
    error: {
      message: error
    }
  }
};
