import { useContext } from 'react';
import { returnGymsByAccess } from '../../../../shared/userFunctions';
import RenderForm from '../../../../components/Forms/RenderForm';
import { AppContext } from '../../../../context/App/AppContext';
import Title from '../../../../components/UI/Title/Title';
import { useMutation } from '@tanstack/react-query';
import { addAdmin, editAdmin as editAdminFunction, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import Button from '../../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { returnErrorFromResponse } from '../../../../shared/utility';

const AddEditAdmin = ({ editAdmin = null, onCancel = () => {} }) => {
  const { gymsList, userData, selectedGym } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const isEditMode = editAdmin !== null;
  const navigate = useNavigate();

  const { mutate: onAdminUpdate, isLoading: onAdminUpdateIsLoading } = useMutation(
    (formData) =>
      isEditMode ? editAdminFunction(editAdmin._id, { ...formData }) : addAdmin({ ...formData, role: 'admin' }),
    {
      onSuccess: () => {
        openNotification('save', 'success');
        if (isEditMode) {
          onCancel();
        } else {
          navigate(-1);
        }
        refreshFetchFunctions([['adminsList', selectedGym._id]]);
      },
      onError: (res) => {
        openNotification(null, 'error', returnErrorFromResponse(res));
      }
    }
  );

  return (
    <>
      <div className="titleAndBtnContainer">
        <Title title={isEditMode ? 'Edit admin' : 'Add new admin'} />
        {!isEditMode && <Button primary label="Cancel" onClick={() => navigate(-1)} />}
      </div>

      <RenderForm
        className="md:order-1"
        initialValues={isEditMode ? editAdmin : { title: 'Mr', gymId: [] }}
        formFields={[
          'title',
          'userName',
          'lastName',
          'email',
          'phone',
          'password',
          'confirmPassword',
          'selectGymsAccess'
        ]}
        excludeFormFields={isEditMode ? ['password', 'confirmPassword'] : []}
        btnLabel="save"
        btnIsLoading={onAdminUpdateIsLoading}
        onFinish={onAdminUpdate}
        gymsList={returnGymsByAccess(gymsList, userData)}
        onCancel={onCancel}
        cancelBtn={isEditMode}
      />
    </>
  );
};
export default AddEditAdmin;
