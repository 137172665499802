import { useState, useEffect } from 'react';
import classes from './OldTimetableView.module.scss';
import useWindowSize from '../../../../hooks/useWindowSize';
import SingleClass from './SingleClass/SingleClass';
import moment from 'moment';
import Icon from '../../../../components/UI/Icon/Icon';
const cloneDeep = require('lodash.clonedeep');

const OldTimetableView = ({ classesList, classesDetails, tableFields }) => {
  const [firstClassIndex, setFirstClassIndex] = useState(0);
  const [lastClassIndex, setLastClassIndex] = useState(0);
  const [numberOfColumnsToShow, setNumberOfColumnsToShow] = useState(0);
  const [classesToShow, setClassesToShow] = useState([]);
  const { width } = useWindowSize();
  const totalLastIndex = classesList.length - 1;

  useEffect(
    () => {
      const noOfColumns = width >= 1280 ? 3 : width < 1280 && width > 768 ? 2 : 1;
      setClassesToShow(cloneDeep(classesList).slice(firstClassIndex, firstClassIndex + noOfColumns));
      setNumberOfColumnsToShow(noOfColumns);
      setLastClassIndex(firstClassIndex + noOfColumns - 1);
    },
    //eslint-disable-next-line
    [classesList, width]
  );

  const renderArrowLeft = () => {
    const onArrowLeftClick = () => {
      setClassesToShow(
        cloneDeep(classesList).slice(
          firstClassIndex - numberOfColumnsToShow,
          lastClassIndex - numberOfColumnsToShow + 1
        )
      );
      setFirstClassIndex((state) => state - numberOfColumnsToShow);
      setLastClassIndex((state) => state - numberOfColumnsToShow);
    };
    return firstClassIndex === 0 ? null : (
      <Icon className={classes.arrowLeft} name="previous" onClick={onArrowLeftClick} />
    );
  };

  const renderArrowRight = () => {
    const onArrowRightClick = () => {
      setClassesToShow(
        cloneDeep(classesList).slice(
          firstClassIndex + numberOfColumnsToShow,
          lastClassIndex + numberOfColumnsToShow + 1
        )
      );
      setFirstClassIndex((state) => state + numberOfColumnsToShow);
      setLastClassIndex((state) => state + numberOfColumnsToShow);
    };
    return lastClassIndex === totalLastIndex || firstClassIndex === totalLastIndex ? null : (
      <Icon name="next" className={classes.arrowRight} onClick={onArrowRightClick} />
    );
  };

  const singleDay = (day, classesDetails, tableFields) => (
    <div className={classes.singleDay} key={day._id}>
      <h4 className={['ss-list-header', classes.singleDayHeader].join(' ')}>
        {`${moment(day._id).format('dddd')}`} ({`${moment(day._id).format('DD/MM')}`})
      </h4>
      {day.classes.map((elem, index) => (
        <SingleClass
          selectedClass={elem}
          day={day._id}
          key={index}
          classesDetails={classesDetails}
          tableFields={tableFields}
        />
      ))}
    </div>
  );

  if (classesList.length === 0) {
    return 'No classes available at the moment, please check here again soon.';
  }

  return (
    <>
      <div className={classes.header}>
        {renderArrowLeft()}
        {renderArrowRight()}
      </div>

      <div
        data-testid="full-data-timetable"
        className={classes.grid}
        style={{ gridTemplateColumns: `repeat(${numberOfColumnsToShow}, 1fr` }}>
        {classesToShow.map((elem) => singleDay(elem, classesDetails, tableFields))}
      </div>
    </>
  );
};

export default OldTimetableView;
