import { useContext, useState } from 'react';
import classes from './CancelAttendanceOrClass.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';
import { returnCancelClassDetails } from '../../../../shared/classFunctions';
import { useMutation } from '@tanstack/react-query';
import Button from '../../../../components/UI/Button/Button';
import { Modal } from 'antd';

import { cancelAttendance, cancelClass, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';

import { returnFirstErrorMsg } from '../../../../shared/utility';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';

const CancelAttendanceOrClass = ({ type, selectedClass, user, attendance, cancelType = 'upcoming' }) => {
  const { openNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);
  const cancelClassDetails = returnCancelClassDetails(selectedClass, user.role);
  const isMobile = false;

  const { mutate: onCancelAttendance, isLoading: onCancelAttendanceIsLoading } = useMutation(
    ({ shouldAdminChargeClients }) => cancelAttendance(attendance._id, user._id, shouldAdminChargeClients),
    {
      onSuccess: () => {
        setShowModal(false);
        openNotification(null, 'success', 'Attendance cancelled');
        if (cancelType === 'singleClass') {
          refreshFetchFunctions(['singleClass']);
        } else {
          refreshFetchFunctions([
            ['upcomingClasses', selectedClass.__t],
            ['clientUpcomingClasses', selectedClass.__t],
            ['userData']
          ]);
        }
      },
      onError: (err) => {
        setShowModal(false);
        openNotification(null, 'error', returnFirstErrorMsg(err));
      }
    }
  );

  const { mutate: onCancelClass, isLoading: onCancelClassIsLoading } = useMutation(
    ({ shouldAdminChargeClients }) => cancelClass(selectedClass._id, shouldAdminChargeClients),
    {
      onSuccess: () => {
        setShowModal(false);
        openNotification(null, 'success', 'Successfully cancelled');

        if (cancelType === 'singleClass') {
          refreshFetchFunctions(['singleClass']);
        } else if (cancelType === 'upcomingClassesInAllGyms') {
          refreshFetchFunctions(['fetchClassesInAllGyms', selectedClass.__t], 500);
        } else {
          refreshFetchFunctions([['upcomingClasses', selectedClass.__t]]);
        }
      },
      onError: (err) => {
        setShowModal(false);
        setShowModal(false);
        openNotification(null, 'error', returnFirstErrorMsg(err));
      }
    }
  );

  if (!selectedClass.active) {
    return null;
  }

  const returnProperButton = (type) => {
    switch (type) {
      case 'cancelAttendanceButton':
        if (isMobile && window.location.pathname === '/gym') {
          return <Icon name="delete" onClick={() => setShowModal(true)} data-cy="cancelBtn" />;
        }

        return <Button fullWidth label="Cancel" primary onClick={() => setShowModal(true)} dataCy="cancelBtn" />;

      case 'cancelAttendanceXicon':
      case 'cancelClassXicon':
        return <Icon name="delete" onClick={() => setShowModal(true)} />;

      case 'cancelClassButton':
        return <Button label="Cancel" fullWidth secondary onClick={() => setShowModal(true)} dataCy="cancelBtn" />;

      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Modal onCancel={() => setShowModal(false)} open={showModal}>
        <div>{cancelClassDetails.message}</div>
        <ModalButtonsContainer isLoading={onCancelAttendanceIsLoading || onCancelClassIsLoading}>
          <Button
            label="Yes"
            dataCy="yes_btn"
            secondary
            onClick={() =>
              type === 'cancelAttendanceButton' || type === 'cancelAttendanceXicon'
                ? onCancelAttendance({ shouldAdminChargeClients: true })
                : onCancelClass({
                    shouldAdminChargeClients: true
                  })
            }
            style={cancelClassDetails.checkTiming ? { width: '100px' } : {}}
          />
          {cancelClassDetails.checkTiming && cancelClassDetails.areAnyAttendees && (
            <Button
              label="No"
              dataCy="no_btn"
              primary
              onClick={() =>
                type === 'cancelAttendanceButton' || type === 'cancelAttendanceXicon'
                  ? onCancelAttendance({ shouldAdminChargeClients: false })
                  : onCancelClass({ shouldAdminChargeClients: false })
              }
              style={cancelClassDetails.checkTiming ? { width: '100px' } : {}}
            />
          )}
          <Button
            label="Cancel"
            primary={!cancelClassDetails.checkTiming}
            onClick={() => setShowModal(false)}
            style={cancelClassDetails.checkTiming ? { width: '150px' } : {}}
          />
        </ModalButtonsContainer>
      </Modal>

      {returnProperButton(type)}
    </div>
  );
};

export default CancelAttendanceOrClass;
