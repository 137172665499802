import { useContext, useState } from 'react';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { AppContext } from '../../../context/App/AppContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteMembershipPlan, fetchMembershipPlans, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import Loading from '../../../components/Loading/Loading';
import Notification from '../../../components/UI/Notification/Notification';
import AddEditMembershipPlan from './AddEditMembershipPlan/AddEditMembershipPlan';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Button from '../../../components/UI/Button/Button';
import { Modal } from 'antd';
import Title from '../../../components/UI/Title/Title';
import Table from '../../../components/Table/Table';

const AdminManageMembershipPlans = () => {
  const { openNotification } = useContext(NotificationContext);
  const { selectedGym, userData } = useContext(AppContext);
  const [showAddNewProduct, setShowAddNewProduct] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const {
    data: membershipPlansList,
    isLoading: membershipPlansListIsLoading,
    isError: membershipPlansListIsError
  } = useQuery(['membershipPlansList', selectedGym._id], () => fetchMembershipPlans(selectedGym._id), {});

  const { mutate: onDelete, isLoading: onDeleteIsLoading } = useMutation({
    mutationFn: () => deleteMembershipPlan(selectedPlan._id),
    onSuccess: () => {
      openNotification('delete', 'success');
      setShowModal(false);
      refreshFetchFunctions([['membershipPlansList', selectedGym._id]]);
    },
    onError: () => {
      openNotification('delete', 'error');
    }
  });

  if (userData.isAdmin) {
    if (membershipPlansListIsLoading) {
      return <Loading />;
    }

    if (membershipPlansListIsError) {
      return <Notification status={'critical'} message={'Unable to fetch membership plans'} />;
    }

    const onCloseModal = () => {
      setShowModal(false);
      setSelectedPlan(null);
    };

    const modalContent = isEditModal ? (
      <AddEditMembershipPlan selectedPlan={selectedPlan} onCancel={onCloseModal} />
    ) : (
      <>
        <div className="text-center">
          Are you sure you want to delete: <br />
          {selectedPlan?.name}?
        </div>
        <ModalButtonsContainer isLoading={onDeleteIsLoading}>
          <Button label="delete" secondary onClick={onDelete} />
          <Button label="cancel" primary onClick={onCloseModal} />
        </ModalButtonsContainer>
      </>
    );
    const onIconClick = (selected, isEdit) => {
      setShowModal(true);
      setSelectedPlan(selected);
      setIsEditModal(isEdit);
    };

    return (
      <div data-cy={'membership_plans'}>
        <Modal open={showModal} onCancel={onCloseModal}>
          {modalContent}
        </Modal>
        <div className="titleAndBtnContainer">
          <Title title="Membership plans" />
          <Button
            secondary
            label={showAddNewProduct ? 'membership plans list' : 'add new'}
            onClick={() => setShowAddNewProduct((state) => !state)}
          />
        </div>
        {showAddNewProduct ? (
          <AddEditMembershipPlan onCancel={() => setShowAddNewProduct(false)} />
        ) : (
          <Table
            fields={['name', 'membershipPlanPrice', 'classesIncluded', 'active', 'displayOrder', 'actionColumn']}
            showHideFieldsBtn
            data={membershipPlansList}
            actionType={'editDelete'}
            additionalData={{
              onEdit: (selected) => onIconClick(selected, true),
              onDelete: (selected) => onIconClick(selected, false)
            }}
          />
        )}
      </div>
    );
  }
};

export default AdminManageMembershipPlans;
