import RenderForm from '../../../components/Forms/RenderForm';

const EditGymSettings = ({ onSave, onSaveIsLoading, selectedGym }) => (
  <RenderForm
    initialValues={selectedGym.settings}
    onFinish={onSave}
    formFields={[
      'currency',
      'minimalUserAge',
      'newClientsHaveToBeApprovedByAdmin',
      'qrCode',
      'membershipProducts',
      'videoOnDemand',
      'liveStreaming',
      'coupon',
      'staffShifts',
      'faq',
      'kitList'
    ]}
    btnLabel={'save'}
    btnIsLoading={onSaveIsLoading}
  />
);

export default EditGymSettings;
