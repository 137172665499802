import { classDetailsFields } from './classDetailsFields';

const ClassDetails = ({ singleClass }) => {
  const fields = classDetailsFields(singleClass.__t, singleClass);

  return (
    <div>
      <div className="font-bold">{singleClass.private && 'This session is private'}</div>
      {fields.map((elem, index) => (
        <div key={index}>
          {elem.label}: {elem.value ? elem.value : 'N/D'}
        </div>
      ))}
    </div>
  );
};

export default ClassDetails;
