import Notification from '../../../../components/UI/Notification/Notification';
import { hasClassStarted } from '../../../../shared/classFunctions';
const ClassStatus = ({ singleClass: { active, enabled, ...rest } }) =>
  !active ? (
    <Notification message="This class is cancelled" status="critical" />
  ) : !enabled ? (
    <Notification message="Hidden on the timetable" status="warning" data-cy="notification" />
  ) : hasClassStarted(rest) ? (
    <Notification message="This class has already taken place " status="warning" data-cy="notification" />
  ) : null;

export default ClassStatus;
