import { useContext } from 'react';
import classes from './SingleMembershipPlan.module.scss';
import { classNames, getCurrencySymbol, proRatedCost } from '../../../shared/utility';
import { AppContext } from '../../../context/App/AppContext';
import Button from '../../../components/UI/Button/Button';

const SingleMembershipPlan = ({ element, setSelectedPlan, onlyDetails = false }) => {
  const { userData } = useContext(AppContext);

  const returnJoiningFeeInfo = () => {
    if (!userData.joiningFeePaid && element.joiningFee) {
      const joiningFee = `${getCurrencySymbol(element.currency)} ${element.joiningFee} joining fee`;
      return (
        <>
          {joiningFee}
          <br />
        </>
      );
    }
  };

  return (
    <div
      className={
        onlyDetails
          ? 'mt-10 md:mt-0 md:border-l-[1px] md:pl-8 md:ml-8 md:border-neutral-200'
          : classNames(classes.singlePlan, 'listItem')
      }
      data-cy="singlePlan">
      <div className={classes.description}>
        <h4>{element.name}</h4>

        <div className={classes.singlePlanDescription}>{element.description}</div>

        <div>
          <span className={classes.singlePlanLabel}>Minimum contract length:</span> {element.minContractLength} month
          {element.minContractLength > 1 ? 's' : null}
        </div>

        <div>
          <span className={classes.singlePlanLabel}>Classes included:</span>{' '}
          {element.unlimited ? 'Unlimited' : element.classesIncluded}
        </div>

        {element.unlimited ? (
          ''
        ) : (
          <div>
            <span className={classes.singlePlanLabel}>Class price:</span>{' '}
            {`${getCurrencySymbol(element.currency)}`.concat(element.classPrice)}
          </div>
        )}
      </div>

      <div className={classes.controls}>
        <span className={classes.price}>
          {returnJoiningFeeInfo()}
          {getCurrencySymbol(element.currency)}
          {element.price}
          monthly ({proRatedCost(element)} for this month)
        </span>

        {onlyDetails ? (
          <div>
            Membership will be pro rated for the rest of this month, therefore you will be charged at most{' '}
            <b>{proRatedCost(element)}</b> (exact amount may differ by a few pence)
          </div>
        ) : (
          <Button label="Subscribe" secondary onClick={() => setSelectedPlan(element)} data-cy="subscribeBtn" />
        )}
      </div>
    </div>
  );
};
export default SingleMembershipPlan;
