import Loading from '../../Loading/Loading';
import { classNames } from '../../../shared/utility';

const ModalButtonsContainer = ({ isLoading, className = '', children }) => {
  return (
    <div className={classNames('modalButtonsContainer', className)}>{isLoading ? <Loading size={34} /> : children}</div>
  );
};

export default ModalButtonsContainer;
