import SingleClassTypeHistoryComponent from '../../Timetables/HistoryComponent/SingleClassTypeHistoryComponent';
import DefaultTimetable from '../../Timetables/DefaultTimetable/DefaultTimetable';
import ManagePasses from '../../ManagePasses/ManagePasses';
import AddEditSingleClass from '../../ClassComponents/AddEditSingleClass/AddEditSingleClass';
import ManageSchedules from '../../ManageSchedules/ManageSchedules';
import ManageTemplates from '../../ManageTemplates/ManageTemplates';
import useTabIndex from '../../../hooks/useTabIndex';
import { Tabs } from 'antd';
import Title from '../../../components/UI/Title/Title';
import { useContext } from 'react';
import { returnClassesDetails } from '../../../shared/classFunctions';
import { useQuery } from '@tanstack/react-query';
import { fetchTemplates } from '../../../apiFunctions/apiFunctions';
import { AppContext } from '../../../context/App/AppContext';
import { returnClassConfig } from '../../../shared/gymFunctions';
import { CLASS } from '../../../constants';
const AdminAndTrainerView = ({ classesList, classesListLoading, classesListError, classType }) => {
  const { userData, gymId, selectedGym } = useContext(AppContext);
  const { activeTabIndex, onTabChange } = useTabIndex();
  const arePassesAllowed = returnClassConfig(selectedGym.settings.classConfig, classType).allowedToBuyPasses;

  const {
    data: templatesList,
    isLoading: templatesListIsLoading,
    isError: templatesListIsError,
    refetch: templatesListRefetch
  } = useQuery(['templatesList', { gymId, classType }], () => fetchTemplates(gymId, classType), {
    enabled: userData.isAdminOrTrainer
  });

  const items = [
    {
      key: 0,
      label: 'Upcoming',
      children: (
        <DefaultTimetable
          classesList={classesList}
          classesListLoading={classesListLoading}
          classesListError={classesListError}
          classType={classType}
          filtersArray={userData.isTrainer ? [{ name: 'myEvents', value: false }] : []}
          type="upcoming"
        />
      )
    },
    {
      key: 1,
      label: 'History',
      children: <SingleClassTypeHistoryComponent classType={classType} />
    },
    {
      key: 2,
      label: 'Add New',
      children: (
        <AddEditSingleClass
          classType={classType}
          templatesList={templatesList}
          templatesListIsLoading={templatesListIsLoading}
          templatesListIsError={templatesListIsError}
          onCancel={() => onTabChange(0)}
        />
      )
    },
    arePassesAllowed &&
      userData.isAdmin && {
        key: 3,
        label: 'Passes',
        children: <ManagePasses classType={classType} />
      },
    classType !== CLASS.PERSONAL_TRAINING &&
      userData.isAdmin && {
        key: 4,
        label: 'Schedule',
        children: (
          <ManageSchedules
            classType={classType}
            templatesList={templatesList}
            templatesListIsLoading={templatesListIsLoading}
            templatesListIsError={templatesListIsError}
          />
        )
      },
    userData.isAdmin &&
      classType !== CLASS.PERSONAL_TRAINING && {
        key: 5,
        label: 'Templates',
        children: (
          <ManageTemplates
            classType={classType}
            templatesList={templatesList}
            templatesListIsLoading={templatesListIsLoading}
            templatesListIsError={templatesListIsError}
            templatesListRefetch={templatesListRefetch}
          />
        )
      }
  ];

  return (
    <div data-cy={classType + '_bookings'}>
      <Title title={returnClassesDetails(classType).title} />
      <Tabs
        defaultActiveKey={activeTabIndex}
        activeKey={activeTabIndex}
        items={items}
        onChange={onTabChange}
        data-cy="tabList"
      />
    </div>
  );
};

export default AdminAndTrainerView;
