import { useContext, useState } from 'react';
import { AppContext } from '../../../context/App/AppContext';
import { Modal } from 'antd';
import Button from '../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { returnAvailableClassTypesInGym, returnClassConfig } from '../../../shared/gymFunctions';
import { CLASS } from '../../../constants';

const ClientClassesLeft = () => {
  const { userData, selectedGym } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const availableClassTypes = returnAvailableClassTypesInGym(selectedGym?.settings)
    .map((elm) => ({
      ...elm,
      ...returnClassConfig(selectedGym.settings.classConfig, elm.type)
    }))
    .filter((elm) => elm.allowedToBuyPasses);

  const returnRemainingClasses = (classTypeDetails) => {
    const remain =
      classTypeDetails.type === CLASS.CLASS ? (
        <>
          <div>{userData[classTypeDetails.usersClassesLeft]}</div>
          <div>, next month: {userData[classTypeDetails.usersClassesLeftForNextMont]} </div>
        </>
      ) : classTypeDetails.type === CLASS.PERSONAL_TRAINING ? (
        <div>
          {userData[classTypeDetails.usersClassesLeft].length > 0
            ? userData[classTypeDetails.usersClassesLeft].map((elm, i) => (
                <div className={'mr-2'} key={i}>
                  {elm.classesLeft}
                  <span className={'text-sm ml-2'}>
                    (places: {elm.places}, duration: {elm.duration})
                  </span>
                </div>
              ))
            : '0'}
        </div>
      ) : (
        <div>{userData[classTypeDetails.usersClassesLeft]}</div>
      );

    return (
      <div key={classTypeDetails.name} className={'listItem py-3 flex justify-between items-center'}>
        <div className={'flex'}>
          <div className={'mr-2'}>{classTypeDetails.title}:</div>
          {remain}
        </div>
        <Button label={'Buy more'} onClick={() => navigate(`/gym/${classTypeDetails.passesUrl}`)} primary />
      </div>
    );
  };

  return (
    <div>
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        {availableClassTypes.map((elm) => (selectedGym.settings[elm.type] ? returnRemainingClasses(elm) : null))}
      </Modal>
      <Button secondary label={'Remaining credits'} onClick={() => setShowModal(true)} />
    </div>
  );
};

export default ClientClassesLeft;
