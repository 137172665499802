import { createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchVideos, fetchVideoCategories, fetchVideoPasses } from '../../apiFunctions/apiFunctions';

import { AppContext } from '../App/AppContext';
import { isValueCorrect } from '../../shared/userFunctions';

export const VideoContext = createContext(null);

const VideoProvider = ({ children }) => {
  const { gymId, userData } = useContext(AppContext);
  const [showVideoData, setShowVideoData] = useState(false);

  const {
    data: videos,
    isLoading: videosIsLoading,
    isError: videosIsError
  } = useQuery(['fetchVideos'], () => fetchVideos(), { enabled: showVideoData && userData.isAdmin });

  const {
    data: videoCategories,
    isLoading: videoCategoriesIsLoading,
    isError: videoCategoriesIsError
  } = useQuery(['fetchVideoCategories'], () => fetchVideoCategories(), { enabled: showVideoData && userData.isAdmin });

  const {
    data: videoPasses,
    isLoading: videoPassesLoading,
    isError: videoPassesIsError
  } = useQuery(['fetchPasses', 'videoPasses'], () => fetchVideoPasses({ gymId }), {
    enabled: showVideoData && isValueCorrect(gymId) && userData.isAdmin
  });

  const value = {
    setShowVideoData,

    videos,
    videosIsLoading,
    videosIsError,

    videoCategories,
    videoCategoriesIsLoading,
    videoCategoriesIsError,

    videoPasses,
    videoPassesLoading,
    videoPassesIsError
  };

  return <VideoContext.Provider value={value}>{children}</VideoContext.Provider>;
};
export default VideoProvider;
