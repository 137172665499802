import { useEffect, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchRecordedAttendancesReport } from '../../../apiFunctions/apiFunctions';
import Loading from '../../../components/Loading/Loading';
import Filters from '../../../components/Filters/Filters';
import Notification from '../../../components/UI/Notification/Notification';
import { AppContext } from '../../../context/App/AppContext';
import useFilters from '../../../components/Filters/useFilters';
import { isValueCorrect } from '../../../shared/userFunctions';
import RedirectToUserDetails from '../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import moment from 'moment';

const RecordAttendancesReport = ({ setCSVData }) => {
  const { gymsList, selectedGym } = useContext(AppContext);
  const { filteredData, filters, onFilters, setFiltersInitialData } = useFilters();

  useEffect(
    () => {
      setCSVData({
        data: filteredData,
        gymName: isValueCorrect(filters.gymId) ? gymsList.find((elm) => elm._id === filters.gymId).name : 'All gyms',
        reportType: 'recordedAttendancesReport'
      });
    }, //eslint-disable-next-line
    [filteredData]
  );

  useEffect(
    () => {
      if (filters.gymId === selectedGym._id && !isValueCorrect(filters.startDate) && !isValueCorrect(filters.endDate)) {
        setFiltersInitialData([]);
      }
    }, //eslint-disable-next-line
    [filters]
  );

  const { isFetching, isError } = useQuery(
    ['recordedAttendancesReport', { gymId: filters.gymId, startDate: filters.startDate, endDate: filters.endDate }],
    () => fetchRecordedAttendancesReport(filters),
    {
      enabled: isValueCorrect(filters.startDate) && isValueCorrect(filters.endDate) && filters.fetchApi,
      onSuccess: (resp) => {
        setFiltersInitialData(resp.map((elm) => ({ ...elm, ...elm.userId })));
      }
    }
  );

  return (
    <>
      <Filters
        open
        filtersTypes={['startDate', 'endDate', 'gymId', 'userName']}
        onFilters={onFilters}
        filters={filters}
        initialState={[
          {
            name: 'gymId',
            value: selectedGym._id
          }
        ]}
        apiRequestFields={['gymId', 'startDate', 'endDate']}
      />
      {isFetching ? (
        <Loading />
      ) : isError ? (
        <Notification defaultMsg />
      ) : (
        filteredData.map((elm) => (
          <div key={elm._id} className="listItem py-3 flex justify-between items-center">
            <RedirectToUserDetails client={elm} showName />
            <div>{moment(elm.createdDate).format('DD/MM/YYYY hh:mm a')}</div>
          </div>
        ))
      )}
    </>
  );
};
export default RecordAttendancesReport;
