import { useState, useEffect, useContext } from 'react';
import { returnGymsPerActiveClassType } from '../../../shared/gymFunctions';
import { returnGymsByAccess } from '../../../shared/userFunctions';
import { AppContext } from '../../../context/App/AppContext';
import { Select } from 'antd';
import Icon from '../../UI/Icon/Icon';
import Loading from '../../Loading/Loading';

/**
 * onSelect function -> return selected gym details
 */

const mapGymsAsOptions = (array) =>
  array.map((elem) => ({
    label: elem.name,
    value: elem._id
  }));

const GymSelector = ({
  onSelect = () => {},
  classType = null,
  filterGymsWithClassType = false,
  defaultChecked = null,
  refresh = false,
  placeholder = 'Gym name',
  showLabel = false,
  className = '',
  multiple = false
}) => {
  const { gymsList, gymsListIsLoading, gymsListIsError, userData } = useContext(AppContext);
  const [value, setValue] = useState(defaultChecked === null ? undefined : defaultChecked);

  useEffect(
    () => {
      setValue(defaultChecked === null ? undefined : defaultChecked);
      if (defaultChecked !== null && !gymsListIsLoading) {
        onSelect(defaultChecked);
      }
    }, //eslint-disable-next-line
    [defaultChecked, gymsList]
  );

  if (gymsListIsLoading) {
    return <Loading />;
  }
  if (gymsListIsError) {
    //eslint-disable-next-line
    console.log('Something went wrong with showing gym selector');
    return null;
  }

  return (
    <div className={className}>
      {showLabel && <div>Select gym</div>}
      <div className={refresh ? 'flex' : ''}>
        <Select
          mode={multiple && 'multiple'}
          className="w-full"
          value={value}
          showSearch
          placeholder={placeholder}
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          options={
            filterGymsWithClassType
              ? mapGymsAsOptions(returnGymsPerActiveClassType(returnGymsByAccess(gymsList, userData), classType))
              : mapGymsAsOptions(returnGymsByAccess(gymsList, userData))
          }
          onChange={onSelect}
        />
        {refresh && <Icon name="refresh" onClick={() => onSelect(null)} size={30} />}
      </div>
    </div>
  );
};

export default GymSelector;
