import ProfilePlanStatus from '../../UI/ProfilePlanStatus/ProfilePlanStatus';

import './ClientProfilePlanStatus.scss';

import { returnFormattedDate } from '../../../shared/date-utility';

import { CLIENTS_TYPES } from '../../../constants';

const ClientProfilePlanStatus = (props) => {
  const getPlanName = () => {
    if (props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER) {
      return props.selectedClient.currentMembershipPlan
        ? props.selectedClient.currentMembershipPlan.ssPlanId.name
        : "This user hasn't signed up for any plan yet";
    }
  };

  return (
    <>
      {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER && (
        <div className="profile-details__container" style={{ marginTop: '10px' }}>
          <h3 className="uppercase mt-2">{`MEMBERSHIP PLAN DETAILS - ${getPlanName()}`}</h3>

          {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER &&
            props.selectedClient.currentMembershipPlan && (
              <div>
                <div className="grid grid-cols-2">
                  <div>Membership end date</div>
                  <div>
                    <ProfilePlanStatus currentMembershipPlan={props.selectedClient.currentMembershipPlan} />
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Contract Ends</div>
                  <div>{returnFormattedDate(props.selectedClient.currentMembershipPlan.contractEnd)}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Current Period End</div>
                  <div>{returnFormattedDate(props.selectedClient.currentMembershipPlan['current_period_end'])}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Minimum Contract Paid</div>
                  <div>{props.selectedClient.currentMembershipPlan.isMinContractEndPaid ? 'Yes' : 'No'}</div>
                </div>
                {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER && (
                  <div className="grid grid-cols-2">
                    <div>Joining Fee</div>
                    <div>{props.selectedClient.joiningFeePaid ? 'Paid' : 'Not Paid'}</div>
                  </div>
                )}
              </div>
            )}
        </div>
      )}
    </>
  );
};
export default ClientProfilePlanStatus;
