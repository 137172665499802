import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context/App/AppContext';
import { fetchVideoPasses } from '../../../apiFunctions/apiFunctions';
import Loading from '../../../components/Loading/Loading';
import Notification from '../../../components/UI/Notification/Notification';
import Title from '../../../components/UI/Title/Title';
import { returnClassTypeDetailsBasedOnClassType } from '../../../shared/classFunctions';
import SingleVirtualPass from './SingleVirtualPass/SingleVirtualPass';
import { CLASS } from '../../../constants';

const VirtualBookings = ({ show = false, classType = 'class' }) => {
  const { gymId, userData } = useContext(AppContext);

  const {
    data: videoPasses,
    isLoading: videoPassesIsLoading,
    isError: videoPassesIsError
  } = useQuery(['videoPasses', classType], () => fetchVideoPasses({ gymId }), {
    enabled: show,
    select: (data) => data.filter((product) => product.forClass === classType)
  });

  if (show === false) {
    return null;
  }

  if (videoPassesIsLoading) {
    return <Loading />;
  }
  if (videoPassesIsError) {
    return <Notification defaultMsg />;
  }

  const userVideoPasses = userData.products
    .filter((elm) => elm.productType === 'videoPass' && elm.productId.forClass === classType)
    .map((elm) => ({ ...elm.productId }));

  return (
    <div>
      <Title
        title={`Virtual  ${returnClassTypeDetailsBasedOnClassType(classType).title}`}
        subtitle={classType === CLASS.INDUCTION && 'YOU CAN CHOOSE TO COMPLETE YOUR INDUCTION BY VIDEO OR IN PERSON'}
      />

      {userVideoPasses.length > 0
        ? userVideoPasses.map((elm) => <SingleVirtualPass item={elm} key={elm._id} isPaid={true} />)
        : videoPasses.map((elm) => <SingleVirtualPass item={elm} key={elm._id} isPaid={false} />)}
    </div>
  );
};

export default VirtualBookings;
