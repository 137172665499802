import classes from './SinglePassesView.module.scss';
import RenderPassActionButtons from '../../RenderPassActionButtons/RenderPassActionButtons';
import { classNames, getCurrencySymbol } from '../../../../shared/utility';
import moment from 'moment';

const SinglePassesView = ({ data = [], classType, goBack = true }) => {
  const singlePass = (elem) => {
    const renderLabel = (label, value) =>
      value && (
        <div className={classes.row}>
          <div className={classes.label}>{label}:</div>
          <div className={classes.data}> {value}</div>
        </div>
      );

    return (
      <div
        key={elem._id}
        className={classNames(classes.singlePass, 'border-4 border-secondary')}
        data-cy="singleClassPass">
        <div className={classes.singlePassHeading}>
          <h3 className="text-primary">{elem.name}</h3>
        </div>
        <div className={classes.description}>{elem.description} </div>
        <div className={classes.details}>
          {renderLabel('Places', elem.places)}
          {renderLabel('Included', elem.classCount)}
          {renderLabel('Duration', elem.duration)}
          {renderLabel('Price', `${getCurrencySymbol(elem.currency)} ${elem.cost}`)}
          {renderLabel('Expiry date', elem.expiryDate ? moment(elem.expiryDate).format('DD/MM/YYYY') : 'None')}
        </div>

        <div className={classes.actionBtn}>
          <RenderPassActionButtons classPass={elem} classType={classType} goBack={goBack} />
        </div>
      </div>
    );
  };

  return <div className={classes.root}>{data.map((elem) => singlePass(elem))}</div>;
};

export default SinglePassesView;
