import Loading from '../../../components/Loading/Loading';
import { useQuery } from '@tanstack/react-query';
import { fetchSingleClass } from '../../../apiFunctions/apiFunctions';
import ClassDetails from './Components/ClassDetails/ClassDetails';
import { returnClassConfig } from '../../../shared/gymFunctions';
import ClassActionButtons from './Components/ClassActionButtons';
import ClassStatus from './Components/ClassStatus';
import ClassAttendees from './Components/ClassAttendees/ClassAttendees';
import Notification from '../../../components/UI/Notification/Notification';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../context/App/AppContext';

const ClassPage = () => {
  const { selectedGym } = useContext(AppContext);
  const { classId } = useParams();

  const { data, isLoading, isFetching, error } = useQuery(['singleClass', classId], () => fetchSingleClass(classId));

  if (isLoading || isFetching) {
    return <Loading />;
  }
  if (error) {
    return <Notification defaultMsg />;
  }

  const classConfig = returnClassConfig(selectedGym.settings.classConfig, data.__t);

  return (
    <>
      <div className="flex justify-between">
        <ClassDetails singleClass={data} classConfig={classConfig} />
        <ClassActionButtons singleClass={data} classConfig={classConfig} />
      </div>
      <ClassStatus singleClass={data} />
      <ClassAttendees singleClass={data} />
    </>
  );
};

export default ClassPage;
