import SingleTile from './SingleTile/SingleTile';

import classes from './TilesBoard.module.scss';

const TilesBoard = ({ onClick, tiles }) => {
  return (
    <div className={classes.tilesContainer}>
      {tiles.map((elem, index) => (
        <SingleTile
          actionPayload={elem.actionPayload}
          title={elem.name ? elem.name : elem.title}
          icon={elem.icon}
          text={elem.text}
          link={elem.link}
          dataCy={elem.dataCy}
          onClick={onClick}
          badgeCount={elem.badgeCount ? elem.badgeCount : null}
          key={index}
        />
      ))}
    </div>
  );
};
export default TilesBoard;
