import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import { createRoot } from 'react-dom/client';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import './theme/index.scss';
import theme from './theme/theme';
import antdOverrideStyles from './theme/antdOverrideStyles';

import NotificationProvider from './context/Notification/NotificationContext';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AppProvider from './context/App/AppContext';
import Layout from './hoc/Layout/Layout';
import AppRoutes from './routes/AppRoutes';
import VideoProvider from './context/Video/VideoContext';

export const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_STRIPE_KEY_DEV : process.env.REACT_APP_STRIPE_KEY_PROD
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchAllOnWindowFocus: false,
      retry: 0,
      enabled: true
    }
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Elements stripe={stripePromise}>
        <ConfigProvider theme={theme} {...antdOverrideStyles}>
          <NotificationProvider>
            <AppProvider>
              <VideoProvider>
                <Layout>
                  <AppRoutes />
                </Layout>
              </VideoProvider>
            </AppProvider>
          </NotificationProvider>
        </ConfigProvider>
      </Elements>
    </QueryClientProvider>
  </BrowserRouter>
);
