import { useState } from 'react';
import Button from '../../../../components/UI/Button/Button';

const Upload = ({ className = '', onSelectVideo = () => {} }) => {
  const [file, setFile] = useState(null);
  return (
    <div className={className}>
      {!file && (
        <>
          <div id="choose-file" style={{ margin: 0 }}>
            Choose file to upload:
          </div>
          <input
            type="file"
            name="video"
            accept="video/*"
            aria-labelledby="choose-file"
            onChange={({ target }) => {
              setFile(target.files[0]);
              onSelectVideo(target.files[0]);
            }}
          />
        </>
      )}
      {file && (
        <div className={' md:grid md:grid-cols-[1fr_142px]  items-center'}>
          <div>
            <div>{`Name: ${file.name}`}</div>
            {file.size ? <div>{`Size: ${Number(file.size / 1024 / 1024).toFixed(2)}MB`}</div> : null}
            <div>{`Type: ${file.type}`}</div>
          </div>

          <Button className={'mt-2 md:mt-0'} fullWidth label="Remove" primary onClick={() => setFile(null)} />
        </div>
      )}
    </div>
  );
};

export default Upload;
