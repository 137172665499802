import { useContext, useEffect, useState } from 'react';
import { isValueCorrect } from '../../shared/userFunctions';
import { AppContext } from '../../context/App/AppContext';
import { returnSelectedClassTypesAsArray } from '../Selectors/ClassTypesCheckboxes/useClassTypesCheckoxes';
const useFilters = () => {
  const { userData } = useContext(AppContext);
  const [filtersInitialData, setFiltersInitialData] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  useEffect(
    () => {
      if (isValueCorrect(filtersInitialData) && filtersInitialData.length > 0) {
        onFilters(filters);
      } else {
        setFilteredData([]);
      }
    }, //eslint-disable-next-line
    [filtersInitialData]
  );

  const returnFiltersFromFiltersArray = (array) => array.reduce((a, v) => ({ ...a, [v.name]: v.value }), {});
  const returnArrayFromFilters = (array) => {
    let result = [];
    for (const [key, value] of Object.entries(array)) {
      result.push({ name: key, value });
    }
    return result;
  };

  const filtersLogic = (element, filters, keys) => {
    const result = keys.map((key) => {
      if (key === 'name') {
        return element.name.toLowerCase().includes(filters.name.toLowerCase());
      }
      if (key === 'title') {
        return element.title.toLowerCase().includes(filters.title.toLowerCase());
      }
      if (key === 'gyms') {
        return filters.gyms.length === 0 ? true : filters.gyms.includes(element.gymId);
      }
      if (key === 'userName') {
        return (
          element.name.toLowerCase().includes(filters.userName) ||
          element.last_name.toLowerCase().includes(filters.userName)
        );
      }
      if (key === 'classTypesCheckboxes') {
        return returnSelectedClassTypesAsArray(filters.classTypesCheckboxes).includes(element.__t);
      }
      if (key === 'notUpdated' && filters.notUpdated) {
        return element.attendances !== null && element.attendances.pending;
      }
      if (key === 'active') {
        return element.active === filters.active;
      }

      if (key === 'myEvents' && filters.myEvents === true) {
        return element.trainer.id === userData._id;
      }

      if (key === 'trainerSelector' && isValueCorrect(filters.trainerSelector)) {
        return element.trainer === filters.trainerSelector;
      }
      if (
        key === 'videoCategories' &&
        filters &&
        filters.videoCategories.length > 0 &&
        element.videoConfig.length > 0
      ) {
        const videoResult = filters.videoCategories.map((filtersConfigElement) => {
          const videoConfigElement = element.videoConfig.find(
            (elm) => elm.name.toLowerCase() === filtersConfigElement.name.toLowerCase()
          );

          return filtersConfigElement.options.length === 0
            ? true
            : videoConfigElement === undefined
              ? false
              : videoConfigElement.options.filter((option) => filtersConfigElement.options.includes(option)).length > 0;
        });
        return !videoResult.includes(false);
      }
    });

    return !result.includes(false);
  };
  const onFilters = (filtersData) => {
    setFilters({ ...filtersData });
    setFilteredData(
      filtersData !== null
        ? [...filtersInitialData].filter((elm) => filtersLogic(elm, filtersData, Object.keys(filtersData)))
        : filtersInitialData
    );
  };

  return {
    filteredData,
    onFilters,
    filters,
    returnFiltersFromFiltersArray,
    returnArrayFromFilters,
    setFilters,
    setFiltersInitialData
  };
};

export default useFilters;
