import { useContext, useState } from 'react';
import Notification from '../../../../components/UI/Notification/Notification';
import Title from '../../../../components/UI/Title/Title';
import SingleMembershipPlan from '../../SingleMembershipPlan/SingleMembershipPlan';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchAvailableMembershipPlans,
  refreshFetchFunctions,
  subscribeToMembershipPlan
} from '../../../../apiFunctions/apiFunctions';
import Loading from '../../../../components/Loading/Loading';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import { AppContext } from '../../../../context/App/AppContext';
import AddPaymentMethod from '../../../Stripe/AddPaymentMethod/AddPaymentMethod';
import Button from '../../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';

const ClientSubscribeToMembershipPlan = () => {
  const navigate = useNavigate();
  const { openNotification } = useContext(NotificationContext);
  const { userData } = useContext(AppContext);
  const [selectedPlan, setSelectedPlan] = useState(undefined);

  const {
    data: userMembershipPlansList,
    isLoading: userMembershipPlansListIsLoading,
    isError: userMembershipPlansListIsError
  } = useQuery({
    queryKey: ['plansList', userData.gymId],
    queryFn: () => fetchAvailableMembershipPlans(userData.gymId)
  });

  const { mutate: onSubscribeToMembershipPlan, isLoading: onSubscribeToMembershipPlanIsLoading } = useMutation(
    () =>
      subscribeToMembershipPlan({
        method: 'web',
        userId: userData._id,
        planId: selectedPlan._id
      }),
    {
      onSuccess: () => {
        openNotification(null, 'success', 'Thanks for subscription!');
        refreshFetchFunctions([['userData']]);
        navigate('/gym');
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );
  if (userMembershipPlansListIsLoading || onSubscribeToMembershipPlanIsLoading) {
    return <Loading size={50} />;
  }

  if (userMembershipPlansListIsError) {
    return <Notification status={'critical'} message={'Unable to fetch membership plans'} />;
  }

  if (selectedPlan !== undefined) {
    return (
      <div>
        <Title title={'Payment'} />
        <div className="md:grid grid-cols-2 items-center">
          <div>
            <AddPaymentMethod
              title={'Enter card details'}
              isSubscribeToPlan
              onSubscribeToPlan={onSubscribeToMembershipPlan}
            />
          </div>

          <SingleMembershipPlan element={selectedPlan} onlyDetails />
        </div>
        <Button label={'cancel'} onClick={() => setSelectedPlan(undefined)} fullWidth className="mt-8" primary />
      </div>
    );
  }

  return userMembershipPlansList.length === 0 ? (
    <h3>No membership plans</h3>
  ) : (
    <div>
      <Title title={'Please select membership plan'} />
      {userMembershipPlansList.map((element) => (
        <SingleMembershipPlan key={element.id} element={element} setSelectedPlan={setSelectedPlan} />
      ))}
    </div>
  );
};

export default ClientSubscribeToMembershipPlan;
