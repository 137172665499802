import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import Button from '../../../components/UI/Button/Button';
import VideosList from '../VideosList/VideosList';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Reorder from '../ShowVideos/Reorder/Reorder';

const VideoPicker = ({ onSelect = () => {}, initialSelection = [], videos = [] }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  useEffect(
    () => {
      setSelected(initialSelection);
    }, //eslint-disable-next-line
    []
  );

  const onChange = (videoId) => {
    let newSelection = selected;
    if (newSelection.includes(videoId)) {
      newSelection = newSelection.filter((elm) => elm !== videoId);
    } else {
      newSelection.push(videoId);
    }

    setSelected(newSelection);
  };

  const onReorder = (reorderedVideos) => {
    setSelected(reorderedVideos.map((elm) => elm._id));
    setSelected(reorderedVideos.map((elm) => elm._id));
  };

  return (
    <>
      <Modal open={open} onCancel={() => setOpen(false)}>
        <ModalButtonsContainer className={'mb-4'}>
          <Button
            label="Save selection"
            secondary
            onClick={() => {
              onSelect(selected);
              setOpen(false);
            }}
          />
          <Button label="Cancel" primary onClick={() => setOpen(false)} />
        </ModalButtonsContainer>
        <VideosList isSelector={true} onSelect={onChange} selectedInitialState={selected} />
      </Modal>
      <div>
        <div className={'ml-6'}>
          <Reorder videos={selected.map((id) => videos.find((elm) => elm._id === id))} onChange={onReorder} />
        </div>

        <Button className={'mt-2.5'} label="Choose Videos" onClick={() => setOpen(true)} dataCy={'choose_videos_btn'} />
      </div>
    </>
  );
};

export default VideoPicker;
