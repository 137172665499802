import { useContext, useState } from 'react';
import { classNames, getCurrencySymbol } from '../../../shared/utility';
import classes from './SingleSchedule.module.scss';
import Icon from '../../../components/UI/Icon/Icon';
import { deleteScheduled, editScheduledClass, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { useMutation } from '@tanstack/react-query';
import Button from '../../../components/UI/Button/Button';
import { Modal } from 'antd';
import { daysOfWeek } from '../../../constants/timeConstants';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import AddEditSchedule from '../AddEditSchedule/AddEditSchedule';
import { NotificationContext } from '../../../context/Notification/NotificationContext';

const SingleSchedule = ({ scheduledClass, classConfig, templatesList }) => {
  const { openNotification } = useContext(NotificationContext);
  const [selectedDay, setSelectedDay] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const { mutate: onEditSchedule, isLoading: onEditScheduleIsLoading } = useMutation(
    (formData) => editScheduledClass(scheduledClass._id, formData),
    {
      onSuccess: () => {
        openNotification('update', 'success');
        refreshFetchFunctions([['schedulesList']]);
        handleModalClose();
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );

  const { mutate: onDeleteSchedule, isLoading: onDeleteScheduleIsLoading } = useMutation(
    () => deleteScheduled(scheduledClass._id),
    {
      onSuccess: () => {
        handleModalClose();
        openNotification('delete', 'success');
        refreshFetchFunctions([['schedulesList']]);
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );
  const doDeleteDay = () => {
    const updatedScheduledClass = { ...scheduledClass };
    delete updatedScheduledClass.recurrentOn[selectedDay];
    onEditSchedule(updatedScheduledClass);
  };

  const openModal = (isOpen, modalType, day = null) => {
    if (day !== null) {
      setSelectedDay(day);
    }
    setShowModal(true);
    setModalType(modalType);
  };

  const renderNameAndDescription = () => {
    const hideFor = ['gymClass', 'swimmingClass', 'tennisClass', 'massageClass'];
    if (!hideFor.includes(scheduledClass.classType)) {
      return (
        <>
          <div className={classes.single_scheduled_class__name}>{scheduledClass.name}</div>
          <div className={classes.single_scheduled_class__description}>{scheduledClass.description}</div>
          <span className={classes.single_scheduled_class__label}>Location:</span> {scheduledClass.location}
        </>
      );
    }
    return null;
  };
  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <Modal onCancel={handleModalClose} open={showModal}>
        {modalType === 'removeSingleDay' ? (
          <>
            Do you want to delete the chosen day?
            <ModalButtonsContainer isLoading={onEditScheduleIsLoading}>
              <Button box label="Yes" secondary onClick={doDeleteDay} />
              <Button box label="Cancel" primary onClick={handleModalClose} />
            </ModalButtonsContainer>
          </>
        ) : modalType === 'edit' ? (
          <AddEditSchedule
            classType={classConfig.classType}
            selectedSchedule={scheduledClass}
            onCancel={handleModalClose}
            templatesList={templatesList}
          />
        ) : (
          <>
            Are you sure you want to delete this schedule
            <ModalButtonsContainer isLoading={onDeleteScheduleIsLoading}>
              <Button box label="Yes" secondary onClick={onDeleteSchedule} />
              <Button box label="Cancel" primary onClick={handleModalClose} />
            </ModalButtonsContainer>
          </>
        )}
      </Modal>

      <div className={classNames(classes.single_scheduled_class, 'listItem')}>
        <div>
          {renderNameAndDescription()}

          <div>
            <span className={classes.single_scheduled_class__label}>Limit:</span> {scheduledClass.limit}
          </div>

          <div>
            <span className={classes.single_scheduled_class__label}>Costs:</span>
            {getCurrencySymbol(scheduledClass.currency)}
            &nbsp;
            {scheduledClass.cost}
          </div>

          {scheduledClass.membershipClassPrice > 0 && (
            <div>
              <span className={classes.single_scheduled_class__label}>Membership Class Price:</span>
              {getCurrencySymbol(scheduledClass.currency)}&nbsp;
              {scheduledClass.membershipClassPrice}
            </div>
          )}

          <div>
            <span className={classes.single_scheduled_class__label}>Duration:</span> {scheduledClass.duration} mins
          </div>

          {classConfig.trainerRequired && scheduledClass.trainerId && (
            <div>
              <span className={classes.single_scheduled_class__label}>Trainer:</span> {scheduledClass.trainerId.name}{' '}
              {scheduledClass.trainerId.last_name}
            </div>
          )}
          <div>
            <span className={classes.single_scheduled_class__label}>Waiting list:</span>
            {scheduledClass.waitListConfig && scheduledClass.waitListConfig.active ? 'yes' : 'no'}
          </div>
        </div>

        <div className={classes.single_schedule_class__schedule}>
          {Object.keys(scheduledClass.recurrentOn).map((day) => {
            return (
              <div key={day} className={classes.single_schedule_class__schedule__item}>
                <div className={classes.single_schedule_class__schedule__item__day}>{daysOfWeek[day].shortLabel} :</div>
                <div className={classes.single_schedule_class__schedule__item__times}>
                  {scheduledClass.recurrentOn[day]}
                </div>
                {scheduledClass.recurrentOn[day].length > 0 && (
                  <Icon name={'delete'} onClick={() => openModal(true, 'removeSingleDay', day)} />
                )}
              </div>
            );
          })}
        </div>

        <div className={classes.single_schedule_class__delete}>
          <div className={classes.single_schedule_class__icon}>
            <Icon name="edit" onClick={() => openModal(true, 'edit')} />
          </div>

          <div className={classes.single_schedule_class__icon}>
            <Icon name={'delete'} onClick={() => openModal(true, 'delete')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSchedule;
