import { useContext, useEffect, useState } from 'react';
import Title from '../../components/UI/Title/Title';
import { AppContext } from '../../context/App/AppContext';
import Button from '../../components/UI/Button/Button';
import { Input, Modal } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addNewKit, editGym, fetchKitList, refreshFetchFunctions } from '../../apiFunctions/apiFunctions';
import ModalButtonsContainer from '../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import { NotificationContext } from '../../context/Notification/NotificationContext';
import Loading from '../../components/Loading/Loading';
import Notification from '../../components/UI/Notification/Notification';
import Table from '../../components/Table/Table';

const ManageKitList = () => {
  const { openNotification } = useContext(NotificationContext);
  const { selectedGym, userData, gymId } = useContext(AppContext);
  const [newKit, setNewKit] = useState(null);
  const [currentKitList, setCurrentKitList] = useState(selectedGym.kitList);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { data: kitList, isLoading: kitListIsLoading } = useQuery({
    queryKey: ['kitList'],
    queryFn: fetchKitList
  });

  const { mutate: onAddNewKit, isLoading: onAddNewKitIsLoading } = useMutation({
    mutationFn: () => addNewKit({ name: newKit }),
    onSuccess: () => {
      openNotification('save', 'success');
      setNewKit(null);
      setShowModal(false);
      refreshFetchFunctions([['kitList']]);
    },
    onError: () => openNotification('save', 'error')
  });

  const { mutate: onSaveKitList, isLoading: onSaveKitListIsLoading } = useMutation({
    mutationFn: () => editGym(gymId, { kitList: currentKitList.filter((elm) => elm.quantity > 0) }),
    onSuccess: () => {
      openNotification('save', 'success');
      setIsEditMode(false);
      refreshFetchFunctions([['selectedGym', gymId]]);
    },
    onError: () => openNotification('save', 'error')
  });

  useEffect(
    () => {
      if (kitList !== undefined && kitList.length > 0) {
        let fields =
          selectedGym.kitList.length > 0
            ? kitList.map((elm) => {
                const item = selectedGym.kitList.find((element) => element.name === elm.name);

                return {
                  name: elm.name,
                  quantity: item !== undefined ? item.quantity : 0
                };
              })
            : kitList.map((elm) => ({ ...elm, quantity: 0 }));
        setCurrentKitList(fields);
      }
    }, //eslint-disable-next-line
    [kitList]
  );

  if (kitListIsLoading) {
    return <Loading />;
  }
  const onKitChange = (index, name, value) => {
    const newState = [...currentKitList];
    newState[index][name] = value;
    return setCurrentKitList(newState);
  };

  const renderSingleKit = ({ name, quantity }, index) => (
    <div className="grid grid-cols-[1fr_1fr] items-center gap-x-4">
      <div className="items-center text-lg  text-right ">{name}</div>

      <div className="flex items-center">
        <Button
          disabled={quantity === 0}
          primary
          label={'-'}
          btnClassName={'w-[40px] py-0 px-0'}
          onClick={() => onKitChange(index, 'quantity', quantity - 1)}
        />

        <div className="font-bold text-lg w-[50px] text-center">{quantity}</div>
        <Button
          secondary
          label={'+'}
          btnClassName={'w-[40px] py-0 px-0'}
          onClick={() => onKitChange(index, 'quantity', quantity + 1)}
        />
      </div>
    </div>
  );
  const returnEditMode = () => {
    return (
      <div>
        {currentKitList.map((elm, i) => (
          <div key={i} className="listItem">
            {renderSingleKit(elm, i)}
          </div>
        ))}
        {kitList?.length > 0 ? (
          <Button
            className="mt-2"
            primary
            fullWidth
            label={'Save kit list'}
            onClick={onSaveKitList}
            isLoading={onSaveKitListIsLoading}
          />
        ) : (
          <Notification message={'Please create kit'} status={'critical'} />
        )}
      </div>
    );
  };

  const returnKitList = () => {
    if (selectedGym.kitList.length > 0 && !isEditMode) {
      return (
        <div className={'mt-4 md:mt-0 md:w-1/2 mx-auto'}>
          <Table fields={['name', 'quantity']} data={selectedGym.kitList} />
        </div>
      );
    }
  };

  return (
    <div>
      {userData.isAdmin && (
        <Button
          primary
          label={'add new kit'}
          onClick={() => setShowModal(true)}
          className="grid justify-end"
          btnClassName={':w-[184px]'}
        />
      )}
      <div className="titleAndBtnContainer">
        <Title title={'Kit list'} />
        {userData.isAdmin && (
          <Button
            secondary
            label={isEditMode ? 'show kit list' : 'edit kit list'}
            onClick={() => setIsEditMode((state) => !state)}
          />
        )}
      </div>
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        <div className={'formLabel font-bold'}>Enter kit name:</div>
        <Input
          className="mr-4 h-[41px]"
          placeholder={'Enter kit name'}
          value={newKit}
          onChange={(e) => setNewKit(e.target.value)}
        />
        <ModalButtonsContainer isLoading={onAddNewKitIsLoading}>
          <Button fullWidth secondary label={'Save'} disabled={newKit?.length === 0} onClick={onAddNewKit} />
          <Button fullWidth primary label={'Cancel'} onClick={() => setShowModal(false)} />
        </ModalButtonsContainer>
      </Modal>

      {isEditMode ? returnEditMode() : returnKitList()}
    </div>
  );
};

export default ManageKitList;
