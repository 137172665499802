export default {
  name: 'Motive8 Group',
  // gymId: '5fd7cff72eb93d371e0aa7de',
  email: 'info@m8group.co.uk',
  styles: {
    fontFamily: 'Montserrat',
    primary: '#414142',
    secondary: '#80b91e',
    accent: '#939799',
    accentDark: '#414142'
  },
  socialMedia: [
    {
      name: 'facebook',
      url: 'https://www.facebook.com/m8group'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/motive8group'
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/m8group'
    }
  ],
  googleMaps: { lat: 53.9592279, lng: -1.0781796 },
  isHomepageHeroDark: true,
  locationsPage: {
    showUpcomingEvents: false,
    upcomingEventsClassTypes: ['class', 'induction']
  }
};

/*
$brand-color: #80b91e;
$brand-color-secondary: #414142;
$brand-color-accent: #939799;
$brand-color-accent2: #80b91e;
$brand-home-page-background: #414142;
$brand-neutral-colors: (#414142, #595959, #80b91e, #939799);
$brand-accent-colors: (#595959, #80b91e, #939799, #eeeeee);
$brand-link-color: #80b91e;
$brand-grey-colors: (#000001, #333333, #3b3b3b, #434343, #666666);

 */
