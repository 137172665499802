import { useContext, useEffect, useState } from 'react';
import { Modal, Select, Checkbox, InputNumber } from 'antd';
import Icon from '../../UI/Icon/Icon';
import SignUpSummary from './SignUpSummary';
import ClientProfilePaymentMethodSelection from '../ClientProfilePaymentMethodSelection';
import { getCurrencySymbol, getIntegerSign } from '../../../shared/utility';
import './ClientProfileSignUpClient.scss';
import ModalButtonsContainer from '../../UI/ModalButtonsContainer/ModalButtonsContainer';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  addMembershipPlanPayment,
  changeClientMembershipPlans,
  fetchAvailableMembershipPlans,
  refreshFetchFunctions,
  subscribeToMembershipPlan
} from '../../../apiFunctions/apiFunctions';
import Loading from '../../Loading/Loading';
import Notification from '../../UI/Notification/Notification';
import Button from '../../UI/Button/Button';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { isValueCorrect } from '../../../shared/userFunctions';
export default function ClientProfileSignUpClient({ selectedClient, isAdmin }) {
  const { openNotification } = useContext(NotificationContext);
  const [addMembershipModalState, setAddMembershipModalState] = useState(false);
  const [changeMembershipModalState, setChangeMembershipModalState] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [plan, setPlan] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paidPeriodInMonths, setPaidPeriodInMonths] = useState(1);
  const [isChecked, setIsChecked] = useState(false);

  const {
    data: plansList,
    isLoading: plansListIsLoading,
    isError: plansListIsError
  } = useQuery({
    queryKey: ['plansList', selectedClient.gymId],
    queryFn: () => fetchAvailableMembershipPlans(selectedClient.gymId)
  });

  useEffect(
    () => {
      if (selectedPlan !== null) {
        setPlan(plansList.find((elm) => elm._id === selectedPlan));
      }
    },
    // eslint-disable-next-line
    [selectedPlan]
  );

  const { mutate: onChangeClientMembershipPlan, isLoading: onChangeClientMembershipPlanIsLoading } = useMutation(
    ['changeClientMembershipPlans'],
    () =>
      changeClientMembershipPlans({
        membershipPlanId: plan._id,
        userId: selectedClient._id,
        method: paymentMethod
      }),
    {
      onSuccess: () => {
        openNotification(null, 'success', 'Successfully changed!');
        handleModalClose();
        refreshFetchFunctions(['selectedClient', selectedClient._id]);
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );

  const { mutate: onAddMembershipPayment, isLoading: onAddMembershipPaymentIsLoading } = useMutation(
    ['onAddMembershipPayment'],
    (payload) => (isAlreadySubscribed ? addMembershipPlanPayment(payload) : subscribeToMembershipPlan(payload)),
    {
      onSuccess: () => {
        openNotification(null, 'success', 'Payment successfully added!');
        handleModalClose();
        refreshFetchFunctions(['selectedClient', selectedClient._id]);
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );

  if (plansListIsLoading) {
    return <Loading />;
  }
  if (plansListIsError) {
    return <Notification defaultMsg />;
  }
  const joiningFeePaid = selectedClient.joiningFeePaid;
  const isAlreadySubscribed = selectedClient.currentMembershipPlan ? selectedClient.currentMembershipPlan : false;
  const currentPlan = plansList.find((plan) => {
    if (plan && isAlreadySubscribed) {
      return plan._id === isAlreadySubscribed.ssPlanId._id;
    }
    return false;
  });
  const handleModalClose = () => {
    setAddMembershipModalState(false);
    setChangeMembershipModalState(false);
  };
  const changeMembershipModal = () => {
    const priceDiff = plan && currentPlan ? plan.price - currentPlan.price : 0;
    const lastPaymentMessage =
      selectedClient.paymentHistory && selectedClient.paymentHistory.length > 0
        ? `The last payment method used for this client was ${selectedClient.paymentHistory[0].method}`
        : null;

    return (
      <Modal open={changeMembershipModalState} onCancel={handleModalClose}>
        <div data-cy="choosePlan">
          <h3>Choose Plan</h3>

          <Select
            className="w-full"
            id="choose-plan"
            options={plansList
              .filter((elm) => (isValueCorrect(currentPlan) ? elm._id !== currentPlan._id : elm))
              .map((elm) => ({
                label: elm.name,
                value: elm._id
              }))}
            onChange={setSelectedPlan}
          />
        </div>

        <div style={{ marginBottom: '20px' }}>
          {lastPaymentMessage && (
            <div className="info">
              <Icon name="info" />
              {lastPaymentMessage}
            </div>
          )}

          <h3>Choose Payment Method</h3>
          <ClientProfilePaymentMethodSelection onMethodSelected={setPaymentMethod} />
        </div>

        {plan ? (
          <div className="membership-diff-container">
            <h3> Change Summary</h3>
            <div>{`Before: ${currentPlan ? currentPlan.name : ''}`}</div>
            <div>
              {`After: ${plan.name} ${
                priceDiff !== 0
                  ? `(${getIntegerSign(priceDiff)}${getCurrencySymbol(plan.currency)}${Math.abs(priceDiff)} p/m)`
                  : ''
              }`}
            </div>
          </div>
        ) : null}
        <ModalButtonsContainer isLoading={onChangeClientMembershipPlanIsLoading}>
          <Button
            label="OK"
            secondary
            onClick={onChangeClientMembershipPlan}
            data-cy="okBtn"
            disabled={plan === null}
          />
          <Button label="Cancel" primary onClick={handleModalClose} />
        </ModalButtonsContainer>
      </Modal>
    );
  };
  const addMembershipPaymentModal = () => {
    return (
      <Modal open={addMembershipModalState} onCancel={handleModalClose}>
        {!isAlreadySubscribed && (
          <div style={{ marginBottom: '20px' }} data-cy="choosePlan">
            <h3>Choose Plan</h3>
            <Select
              className="w-full"
              id="choose-plan"
              options={plansList
                .filter((elm) => (isValueCorrect(currentPlan) ? elm._id !== currentPlan._id : elm))
                .map((elm) => ({
                  label: elm.name,
                  value: elm._id
                }))}
              onChange={setSelectedPlan}
            />
          </div>
        )}
        <div style={{ marginBottom: '20px' }} data-cy="choosePaymentMethod">
          <h3>Choose Payment Method</h3>
          <ClientProfilePaymentMethodSelection onMethodSelected={setPaymentMethod} />
        </div>

        <div style={{ marginBottom: '20px' }}>
          <h3> Specify the number of months</h3>
          <InputNumber className="w-full" defaultValue={paidPeriodInMonths} onChange={setPaidPeriodInMonths} />
        </div>

        <Checkbox checked={isChecked} onChange={setIsChecked}>
          Align payment to first of the month
        </Checkbox>

        {!isAlreadySubscribed && (
          <>
            <h3>Plan Summary</h3>
            <SignUpSummary plan={plan} paidPeriodInMonths={paidPeriodInMonths} joiningFeePaid={joiningFeePaid} />
          </>
        )}
        <ModalButtonsContainer isLoading={onAddMembershipPaymentIsLoading}>
          <Button
            label="OK"
            secondary
            onClick={() =>
              onAddMembershipPayment({
                method: paymentMethod,
                userId: selectedClient._id,
                paidPeriodInMonths: Number(paidPeriodInMonths),
                alignToFirstDayOfMonth: isChecked,
                planId: isAlreadySubscribed ? currentPlan._id : selectedPlan
              })
            }
            data-cy="okBtn"
          />
          <Button label="Cancel" primary onClick={handleModalClose} />
        </ModalButtonsContainer>
      </Modal>
    );
  };

  if (isAdmin) {
    return (
      <>
        {changeMembershipModal()}
        {addMembershipPaymentModal()}
        <div className={isAlreadySubscribed && plansList.length > 1 ? 'md:grid md:grid-cols-2 gap-2' : ''}>
          {isAlreadySubscribed && plansList.length > 1 ? (
            <Button
              fullWidth
              className="client-profile__button"
              label={'Change Gym Membership'}
              primary
              onClick={() => setChangeMembershipModalState(true)}
              data-cy="changeGymMembershipBtn"
            />
          ) : null}
          <Button
            fullWidth
            className="client-profile__button"
            label={isAlreadySubscribed ? 'Add Membership Payment' : 'Add Gym Membership'}
            secondary
            onClick={() => setAddMembershipModalState(true)}
            data-cy="addGymMembershipBtn"
          />
        </div>
      </>
    );
  }
  return null;
}
