import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../containers/Login/Login';
import Homepage from '../brandConfig/components/Homepage/Homepage';
import ForgotPassword from '../containers/ForgotPassword/ForgotPassword';
import ResetPassword from '../containers/Dashboard/ResetPassword/ResetPassword';
import PrivacyPolicyPage from '../brandConfig/components/PrivatePolicyPage/PrivatePolicyPage';
import LocationComponent from '../containers/Pages/Location/Location';
import Error404 from '../components/UI/Error404/Error404';
import Signup from '../containers/Dashboard/Signup/Signup';
import useUserRoutes from './useUserRoutes';
import { AppContext } from '../context/App/AppContext';
import useAdminTrainerRoutes from './useAdminTrainerRoutes';
import useRoutes from './useRoutes';
import ContactPage from '../brandConfig/components/ContactPage/ContactPage';
import TermsOfWebsitePage from '../brandConfig/components/TermsOfWebsitePage/TermsOfWebsitePage';
import HealthStatement from '../brandConfig/components/HealthStatement/HealthStatement';

export const goToHomePage = () => <Navigate to="/" />;

const AppRoutes = () => {
  const { isAuthenticated } = useContext(AppContext);
  const userRoutes = useUserRoutes();
  const adminTrainerRoutes = useAdminTrainerRoutes();
  const classesRoutes = useRoutes();

  return (
    <Routes>
      <Route path="*" element={<Error404 />} />
      <Route path="/" exact element={<Homepage />} />

      <Route path="/login" element={!isAuthenticated ? <Login /> : goToHomePage()} />
      <Route path="/gym/:gymId/signup" element={!isAuthenticated ? <Signup /> : goToHomePage()} />
      <Route path="/forgot-password" element={!isAuthenticated ? <ForgotPassword /> : goToHomePage()} />
      <Route path="/reset-password" element={!isAuthenticated ? <ResetPassword /> : goToHomePage()} />

      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-of-website" element={<TermsOfWebsitePage />} />
      <Route
        path="/health-statement"
        element={
          <div className={'body-container'}>
            <HealthStatement />
          </div>
        }
      />
      <Route path="/contact" element={<ContactPage />} />

      {userRoutes}
      {adminTrainerRoutes}
      {classesRoutes}

      <Route path="/:city/:urlName" element={isAuthenticated ? goToHomePage() : <LocationComponent />} />
    </Routes>
  );
};

export default AppRoutes;
