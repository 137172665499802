import { useContext } from 'react';
import { returnClientDetails } from '../../../../shared/gymFunctions';
import { AppContext } from '../../../../context/App/AppContext';
import { CLASS } from '../../../../constants';

const ReturnAttendees = ({ selectedClass: { joinedUsers, limit, __t, attendanceList } }) => {
  const { usersList } = useContext(AppContext);
  if (__t === CLASS.PERSONAL_TRAINING) {
    return attendanceList.map((attendance) => {
      const attendee = returnClientDetails(attendance.user, usersList);
      if (attendance.status === 'active' || attendance.status === 'absent' || attendance.status === 'attended') {
        return (
          <div key={attendance._id}>
            {attendee.name} {attendee.last_name}
          </div>
        );
      }
    });
  }
  return (
    <div>
      {joinedUsers} / {limit}
    </div>
  );
};

export default ReturnAttendees;
