import { useContext } from 'react';
import ClientSelector from '../../../../../components/Selectors/ClientSelector/ClientSelector';
import { useMutation } from '@tanstack/react-query';
import { joinClass, refreshFetchFunctions } from '../../../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../../../shared/utility';
import { NotificationContext } from '../../../../../context/Notification/NotificationContext';
import { CLASS } from '../../../../../constants';
import { Tooltip } from 'antd';
import Icon from '../../../../../components/UI/Icon/Icon';

const AddAttendee = ({ singleClass, isLimitReached, activeAttendanceList = [] }) => {
  const { openNotification } = useContext(NotificationContext);

  const { mutate: createAttendance, isLoading } = useMutation((userId) => joinClass(singleClass._id, userId), {
    onSuccess: () => {
      refreshFetchFunctions([['singleClass', singleClass._id]]);
      openNotification(null, 'success', `Client successfully added.`);
    },
    onError: (err) => {
      openNotification(null, 'error', returnErrorFromResponse(err));
    }
  });

  if (isLimitReached) {
    return null;
  }

  return (
    <div data-cy="clientList">
      <div className={'flex'}>
        <h4>ADD ATTENDEE</h4>
        <Tooltip placement="rightTop" title={'List of all active clients who accepted terms and conditions.'}>
          <Icon name={'info'} size={18} className={'mt-3 mb-0'} />
        </Tooltip>
      </div>
      <ClientSelector
        onSelect={createAttendance}
        onlyPTClients={singleClass.__t === CLASS.PERSONAL_TRAINING}
        selectedClass={singleClass}
        isLoading={isLoading}
        excludeClientsArray={activeAttendanceList}
      />
    </div>
  );
};

export default AddAttendee;
