import { useContext, useState } from 'react';
import { Modal, Select, Tabs } from 'antd';
import Icon from '../../../components/UI/Icon/Icon';
import ClientProfilePaymentMethodSelection from '../../../components/ClientProfile/ClientProfilePaymentMethodSelection';
import { useMutation, useQuery } from '@tanstack/react-query';
import { buyProductAndUpdateAttendance, fetchPasses, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../shared/utility';
import Button from '../../../components/UI/Button/Button';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import NoResultsFound from '../../../components/UI/NoResultsFound/NoResultsFound';
import Notification from '../../../components/UI/Notification/Notification';

const SelectAndPayForProduct = ({ selectedUser, singleClass, attendanceId }) => {
  const { openNotification } = useContext(NotificationContext);
  const isOnlySinglePaymentAvailable =
    (selectedUser.customerType === 'full-member' && singleClass.__t === 'class') || singleClass.__t === 'induction';

  const [showModal, setShowModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isSinglePayment, setIsSinglePayment] = useState(isOnlySinglePaymentAvailable);
  const [selectedPassProduct, setSelectedPassProduct] = useState(null);

  const {
    data: passesList,
    isLoading: passesListIsLoading,
    isError: passesListIsError
  } = useQuery(['passesList', singleClass.__t], () => fetchPasses(singleClass.__t, singleClass.gymId._id), {
    enabled: showModal && !isOnlySinglePaymentAvailable
  });

  const { mutate: onBuyProductAndUpdateAttendance, isLoading: onBuyProductAndUpdateAttendanceIsLoading } = useMutation(
    () =>
      buyProductAndUpdateAttendance(
        attendanceId,
        selectedPaymentMethod,
        selectedUser._id,
        !isSinglePayment ? selectedPassProduct : null,
        isSinglePayment
      ),
    {
      onSuccess: () => {
        openNotification(null, 'success', `Attendance successfully updated.`);
        refreshFetchFunctions([['singleClass', singleClass._id]]);
      },
      onError: (err) => {
        openNotification(null, 'error', returnErrorFromResponse(err));
      }
    }
  );

  const renderProducts = () => {
    if (passesList && passesList.length > 0) {
      return (
        <>
          <div className="font-bold">Select pass product</div>
          <Select
            className="w-full"
            options={passesList.map((elem) => ({
              label: `${elem.name} (x${elem.classCount})`,
              value: elem._id
            }))}
            onChange={setSelectedPassProduct}
          />
          <div className="font-bold">Choose payment method</div>
          <ClientProfilePaymentMethodSelection onMethodSelected={setSelectedPaymentMethod} />
        </>
      );
    }
    if (passesListIsError) {
      return <Notification defaultMsg />;
    }
    return !passesListIsLoading && <NoResultsFound text="No passes" />;
  };

  return (
    <>
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        <Tabs
          onChange={(tabIndex) => setIsSinglePayment(tabIndex === 1)}
          items={[
            !isOnlySinglePaymentAvailable && {
              key: 0,
              label: <div className="font-bold text-lg">Choose pass product</div>,
              children: renderProducts()
            },
            {
              key: 1,
              label: <div className="font-bold text-lg">Make single payment</div>,
              children: (
                <>
                  <div className="font-bold">Choose payment method</div>
                  <ClientProfilePaymentMethodSelection onMethodSelected={setSelectedPaymentMethod} />
                </>
              )
            }
          ]}
        />

        <ModalButtonsContainer isLoading={onBuyProductAndUpdateAttendanceIsLoading}>
          <Button label="OK" secondary onClick={onBuyProductAndUpdateAttendance} />
          <Button label="Cancel" primary onClick={() => setShowModal(false)} />
        </ModalButtonsContainer>
      </Modal>
      <Icon name="money" onClick={() => setShowModal(true)} />
    </>
  );
};

export default SelectAndPayForProduct;
