import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import companyDetails from '../../../brandConfig/companyDetails';
import { Link } from 'react-router-dom';

//TODO: add animations on close/open
const CookieBanner = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const cookieItem = getCookie();
    if (cookieItem !== 'true') {
      setShow(true);
    }
  }, []);

  const getCookie = () => {
    let nameEQ = 'cookie' + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  const onClose = () => {
    document.cookie = 'cookie' + '=' + 'true';
    setShow(false);
  };

  const content = (
    <div className="text-xs md:text-normal font-bold text-white text-justify">
      We use cookies to ensure that we give you the best experience on our website. If you continue without changing
      your settings, we will assume that you are happy to receive all cookies on the {companyDetails.name} website.
      However, you can change your cookie settings at any time. More details regarding our cookie policy can be found
      within the
      <Link className="text-custom-accent cursor-pointer" to="/privacy-policy">
        {' '}
        Privacy Policy.
      </Link>
    </div>
  );

  if (show) {
    return (
      <div className="md:flex fixed bottom-0 bg-custom-accent-dark p-6 md:p-10 justify-between items-center w-full z-30">
        <div className="md:w-11/12 md:mr-10">{content}</div>
        <Button label={'close'} secondary onClick={onClose} className="mt-2 md:mx-auto my-auto md:mx-0" fullWidth />
      </div>
    );
  }
  return null;
};

export default CookieBanner;
