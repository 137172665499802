import { useState, useContext } from 'react';
import { fetchFAQs } from '../../apiFunctions/apiFunctions';
import { useQuery } from '@tanstack/react-query';
import AddEditFAQ from './AddEditFAQ/AddEditFAQ';
import Loading from '../../components/Loading/Loading';
import UploadFAQ from './UploadFAQ/UploadFAQ';
import { returnAvailableClassTypesInGym } from '../../shared/gymFunctions';
import CustomCheckboxes from '../../components/CustomCheckboxes/CustomCheckboxes';
import { AppContext } from '../../context/App/AppContext';
import Title from '../../components/UI/Title/Title';
import SingleFAQ from './SingleFAQ/SingleFAQ';
import Notification from '../../components/UI/Notification/Notification';

const ManageFAQ = () => {
  const { selectedGym, userData } = useContext(AppContext);
  const [showAddNewFAQ, setShowAddNewFAQ] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState([]);
  const questionTypes = returnAvailableClassTypesInGym(selectedGym.settings).map(({ title, name }) => ({
    label: title,
    value: name.toLowerCase()
  }));
  if (!userData.isUser) {
    questionTypes.unshift({ label: 'Non customer', value: 'non customer' });
  }
  questionTypes.unshift({ label: 'General', value: 'general' });
  const gymId = selectedGym._id;

  const { data, isLoading, error } = useQuery(['faq', gymId], () => fetchFAQs(gymId), {
    onSuccess: (data) =>
      setFilteredData(filters.length === 0 ? data : data.filter((elm) => filters.includes(elm.questionType)))
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }
  const onFilters = (filtersArray) => {
    setFilters(filtersArray);
    setFilteredData(data.filter((elm) => filtersArray.includes(elm.questionType)));
  };

  return (
    <div data-cy="faq">
      <Title title={'frequently asked questions'} />

      {showAddNewFAQ ? (
        <AddEditFAQ onCancel={() => setShowAddNewFAQ(false)} selectedFAQ={null} />
      ) : (
        <>
          <UploadFAQ setShowAddNewFAQ={setShowAddNewFAQ} />
          <div className="filterContainer">
            <div className="filterLabel">Filters:</div>
            <CustomCheckboxes
              checkboxes={questionTypes}
              defaultCheckedAll
              onChange={onFilters}
              className="grid items-center grid-cols-2 sm:grid-cols-3 "
            />
          </div>
          {filteredData.length > 0 ? (
            filteredData.map((element) => (
              <SingleFAQ key={element._id} isAdmin={userData.isAdmin} gymId={gymId} singleFAQ={element} />
            ))
          ) : (
            <h3>No FAQ</h3>
          )}
        </>
      )}
    </div>
  );
};

export default ManageFAQ;
