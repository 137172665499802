import { Checkbox } from 'antd';

const SingleTimetableDisplaySettings = ({ timetableSettings, setTimetableSettings, userType = null }) => {
  const handleTimetableSettings = (element, value) => {
    const index = timetableSettings.findIndex((elm) => elm.name === element.name);
    const newTimetableSettings = [...timetableSettings];
    newTimetableSettings[index] = { ...newTimetableSettings[index], value };
    setTimetableSettings(newTimetableSettings);
  };

  return (
    <div>
      <div className="uppercase font-bold sm:mt-2 mt-6">{userType}</div>
      {timetableSettings.map((elm) => (
        <div key={elm.name} className="mb-2">
          <Checkbox checked={elm.value} onChange={(e) => handleTimetableSettings(elm, e.target.checked)}>
            {elm.label}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default SingleTimetableDisplaySettings;
