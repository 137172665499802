export default {
  passSelector: {
    showLabel: false,
    label: 'Categories'
  },
  videoCategories: {
    showLabel: false,
    label: 'Categories'
  },
  productsSelector: {
    showLabel: true,
    label: 'Product'
  },
  trainerSelector: {
    showLabel: true,
    label: 'Trainer'
  },
  trainerName: {
    showLabel: true,
    label: "Trainer's name",
    placeholder: 'Enter name or last name'
  },
  userName: {
    showLabel: true,
    label: 'Name',
    placeholder: 'Enter name or last name'
  },
  gyms: {
    showLabel: true,
    label: 'Select gyms'
  },
  active: {
    showLabel: false,
    label: 'Active'
  },
  myEvents: {
    showLabel: false,
    label: 'My events'
  },
  name: {
    showLabel: true,
    label: 'Name',
    placeholder: 'Enter name'
  },
  title: {
    showLabel: true,
    label: 'Title',
    placeholder: 'Enter title'
  },
  notUpdated: {
    showLabel: false,
    label: 'Not updated'
  },
  endDate: {
    showLabel: true,
    label: 'End Date',
    placeholder: 'End Date',
    displayFormat: 'DD/MM/YYYY',
    dbFormat: 'YYYY-MM-DD'
  },
  startDate: {
    showLabel: true,
    label: 'Start Date',
    placeholder: 'Start Date',
    displayFormat: 'DD/MM/YYYY',
    dbFormat: 'YYYY-MM-DD'
  },
  className: { showLabel: true, label: 'Class name', placeholder: 'Enter class name' },
  gymId: { showLabel: true, label: 'Select gym', placeholder: 'Select gym' }
};
