import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { activateClass, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Button from '../../../components/UI/Button/Button';
import { Modal } from 'antd';
import { returnFirstErrorMsg } from '../../../shared/utility';

const ActivateClass = ({ classId }) => {
  const { openNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);

  const { mutate: onActivateClass, isLoading } = useMutation(() => activateClass(classId), {
    onSuccess: () => {
      setShowModal(false);
      openNotification(null, 'success', 'Successfully activated');
      refreshFetchFunctions([['singleClass']]);
    },
    onError: (err) => {
      setShowModal(false);
      openNotification(null, 'error', returnFirstErrorMsg(err));
    }
  });

  const confirmationModal = (
    <Modal onCancel={() => setShowModal(false)} open={showModal}>
      Do you want to activate this class?
      <ModalButtonsContainer isLoading={isLoading}>
        <Button label="Yes" secondary onClick={onActivateClass} />
        <Button label="Cancel" primary onClick={() => setShowModal(false)} />
      </ModalButtonsContainer>
    </Modal>
  );

  return (
    <div>
      {confirmationModal}
      <Button label="Activate" secondary onClick={() => setShowModal(true)} data-cy="activateClass" />
    </div>
  );
};

export default ActivateClass;
