import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchScheduledClasses } from '../../apiFunctions/apiFunctions';
import SingleSchedule from './SingleSchedule/SingleSchedule';
import AddEditSchedule from './AddEditSchedule/AddEditSchedule';
import { returnClassConfig } from '../../shared/gymFunctions';
import Loading from '../../components/Loading/Loading';
import { AppContext } from '../../context/App/AppContext';
import Notification from '../../components/UI/Notification/Notification';
import Button from '../../components/UI/Button/Button';
import { Modal } from 'antd';
import NoResultsFound from '../../components/UI/NoResultsFound/NoResultsFound';

const ManageSchedules = ({ classType, templatesList }) => {
  const { userData, selectedGym } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const gymId = userData.role === 'user' ? userData.gymId : selectedGym._id;

  const { data, isLoading, error } = useQuery(['schedulesList'], () => fetchScheduledClasses(classType, gymId));

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Notification message={error.message} status="critical" />;
  }

  const returnSchedules =
    data && data.length > 0 ? (
      data.map((scheduledClass) => (
        <div key={scheduledClass._id} className="listItem">
          <SingleSchedule
            templatesList={templatesList}
            scheduledClass={scheduledClass}
            classConfig={returnClassConfig(selectedGym.settings.classConfig, classType)}
          />
        </div>
      ))
    ) : (
      <NoResultsFound text={'No schedules'} />
    );

  const handleModalClose = () => {
    setSelectedSchedule(null);
    setShowModal(false);
    setShowAddSchedule(false);
  };
  const addEditSchedule = (
    <AddEditSchedule
      classType={classType}
      selectedSchedule={selectedSchedule}
      onCancel={handleModalClose}
      templatesList={templatesList}
    />
  );
  return (
    <div>
      <Modal open={showModal} onCancel={handleModalClose}>
        {addEditSchedule}
      </Modal>
      <Button
        fullWidth
        className="mb-2.5"
        secondary
        label={showAddSchedule ? 'schedules list' : 'add schedule'}
        onClick={() => setShowAddSchedule((state) => !state)}
      />
      {showAddSchedule ? addEditSchedule : returnSchedules}
    </div>
  );
};
export default ManageSchedules;
