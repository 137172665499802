import { useState, useEffect, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Modal, Tabs } from 'antd';
import Icon from '../../../components/UI/Icon/Icon';
import { AppContext } from '../../../context/App/AppContext';
import Loading from '../../../components/Loading/Loading';
import { fetchPassesReport, fetchSingleClientsDetails } from '../../../apiFunctions/apiFunctions';
import Notification from '../../../components/UI/Notification/Notification';
import Filters from '../../../components/Filters/Filters';
import useFilters from '../../../components/Filters/useFilters';
import NoResultsFound from '../../../components/UI/NoResultsFound/NoResultsFound';
import { isValueCorrect } from '../../../shared/userFunctions';
import { returnClassDate } from '../../../shared/classFunctions';
import ProfilePaymentHistory from '../../../components/UI/ProfilePaymentHistory/ProfilePaymentHistory';

export const ClassPassesReports = ({ setCSVData }) => {
  const { gymsList, selectedGym } = useContext(AppContext);
  const { filteredData, filters, onFilters, setFiltersInitialData } = useFilters();
  const [showModal, setShowModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);

  useEffect(
    () => {
      if (filters !== null && Object.keys(filters).length > 0) {
        setCSVData({
          data: filteredData,
          gymName: gymsList.find((elm) => elm._id === filters.gymId).name,
          reportType: 'classPassesReport'
        });
      }
    }, //eslint-disable-next-line
    [filteredData]
  );

  const { data, isFetching, isError } = useQuery(['passesReport', filters], () => fetchPassesReport(filters), {
    enabled: isValueCorrect(filters.gymId) && filters.fetchApi,
    onSuccess: (resp) => {
      let results = [];
      Object.keys(resp.userTotals).forEach((key) => {
        results.push({ ...resp.userTotals[key], ...resp.userTotals[key].user });
      });
      setFiltersInitialData(results);
    }
  });

  const {
    data: clientData,
    isFetching: clientDataIsFetching,
    isError: clientDataIsError
  } = useQuery(['clientData', selectedClientId], () => fetchSingleClientsDetails(selectedClientId), {
    enabled: isValueCorrect(selectedClientId)
  });

  const returnResult = () => {
    if (isFetching) {
      return <Loading />;
    }

    if (isError) {
      return <Notification defaultMsg />;
    }

    if (filteredData.length === 0) {
      return <NoResultsFound text={'No clients found'} />;
    }

    return (
      <div>
        <div className="font-bold text-lg mb-2">
          Total Passes Remaining: {isValueCorrect(data) ? data.overallTotal : 0}
        </div>
        {filteredData.map(({ user, total }) => (
          <div className="listItem py-2.5 flex justify-between" key={user._id}>
            <div>
              {user.name} {user.last_name}
            </div>
            <div className="flex">
              <div>{total}</div>
              <Icon
                name={'view'}
                onClick={() => {
                  setSelectedClientId(user._id);
                  setShowModal(true);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const returnModal = () => {
    const items = [
      {
        key: 0,
        label: 'Payment History',
        children: (
          <div>
            {clientData?.paymentHistory.map((elm, i) => (
              <div key={i} className={'sm:grid sm:grid-cols-[30%_70%] listItem py-1.5'}>
                <div className={'mr-2'}>{elm.type}</div>
                <ProfilePaymentHistory lastPaymentInfo={elm} />
              </div>
            ))}
          </div>
        )
      },
      {
        key: 1,
        label: 'Booking History',
        children: (
          <div>
            {clientData?.fitnessClassHistory.map((elm, i) => (
              <div key={i} className="listItem py-1.5 xs:grid xs:grid-cols-3 xs:gap-x-2">
                <div>{elm.class.name}</div>
                <div>{returnClassDate(elm.class.classDate, 'Do MMM YYYY')}</div>
                <div>{elm.status}</div>
              </div>
            ))}
          </div>
        )
      }
    ];

    return (
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        {clientDataIsFetching ? <Loading /> : clientDataIsError ? <Notification defaultMsg /> : <Tabs items={items} />}
      </Modal>
    );
  };

  return (
    <>
      {returnModal()}
      <Filters
        open
        filtersTypes={['userName', 'gymId', 'passSelector']}
        onFilters={onFilters}
        filters={filters}
        apiRequestFields={['gymId', 'passSelector']}
        initialState={[
          {
            name: 'gymId',
            value: selectedGym._id
          }
        ]}
      />

      {returnResult()}
    </>
  );
};

export default ClassPassesReports;
