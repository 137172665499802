import { useContext, useEffect, useState } from 'react';
import { Checkbox, DatePicker, Input, Select } from 'antd';
import filtersFields from './filtersFields';
import Icon from '../UI/Icon/Icon';
import dayjs from 'dayjs';
import { AppContext } from '../../context/App/AppContext';
import { classNames } from '../../shared/utility';
import GymSelector from '../Selectors/GymSelector/GymSelector';
import { isValueCorrect } from '../../shared/userFunctions';
import ClassTypesCheckboxes from '../Selectors/ClassTypesCheckboxes/ClassTypesCheckboxes';

import useClassTypesCheckoxes from '../Selectors/ClassTypesCheckboxes/useClassTypesCheckoxes';
import Loading from '../Loading/Loading';
import VideoConfig from '../../containers/ManageVideos/VideoConfig/VideoConfig';
import { ALL_CLASS_TYPES_DETAILS } from '../../constants';
import { useQuery } from '@tanstack/react-query';
import { fetchPasses } from '../../apiFunctions/apiFunctions';
const Filters = ({
  open = false,
  filtersTypes = [],
  initialState = null,
  onFilters = () => {},
  filters = null,
  apiRequestFields = [],
  videoCategories = [],
  productsList = []
}) => {
  const { gymsList, gymsListIsLoading, trainersList, trainersListIsLoading } = useContext(AppContext);
  const { data: passesForSelectedClassType } = useQuery(
    ['passesForSelectedClassType', { passTypeSelector: filters?.passTypeSelector, gymId: filters?.gymId }],
    () => fetchPasses(filters.passTypeSelector, filters.gymId),
    {
      enabled: isValueCorrect(filters) && isValueCorrect(filters.gymId) && isValueCorrect(filters.passTypeSelector)
    }
  );

  useEffect(
    () => {
      returnInitialStateOfFilters();
    }, //eslint-disable-next-line
    []
  );

  const {
    returnInitialState: returnInitialStateCheckboxes,
    setSelectedClassType: classTypesCheckboxes_SstSelectedClassType,
    setClassTypes: classTypesCheckboxes_SetClassTypes,
    classTypes: classTypesCheckboxes_ClassTypes,
    setInitialState: classTypesCheckboxes_setInitialState
  } = useClassTypesCheckoxes();

  const [isOpen, setIsOpen] = useState(open);

  if (
    (filtersTypes.includes('trainerSelector') && trainersListIsLoading) ||
    (filtersTypes.includes('gymId') && gymsListIsLoading)
  ) {
    return <Loading />;
  }
  const returnInitialStateOfFilters = () => {
    let init = {};
    if (initialState !== null) {
      init = { ...returnInitialState(initialState), apiRequestFields, fetchApi: true };

      if (
        filtersTypes.includes('classTypesCheckboxes') &&
        initialState.find((elm) => elm.name === 'classTypesCheckboxes') !== -1
      ) {
        const { selected, classTypesInGym } = returnInitialStateCheckboxes(
          initialState.find((elm) => elm.name === 'classTypesCheckboxes')
        );
        init = {
          ...init,
          classTypesCheckboxes: selected
        };
        classTypesCheckboxes_SstSelectedClassType(selected);
        classTypesCheckboxes_setInitialState(selected);
        classTypesCheckboxes_SetClassTypes(classTypesInGym);
      }
    }
    onFilters(init);
  };

  const returnInitialState = (state) => {
    if (state?.length > 0) {
      let result = {};
      state.map((elm) => {
        result[elm.name] = elm.value;
      });
      return result;
    }
  };

  const onFiltersChange = (name, value) => {
    const newFilters =
      apiRequestFields !== undefined
        ? { ...filters, [name]: value, fetchApi: apiRequestFields.includes(name) }
        : { ...filters, [name]: value };
    onFilters(newFilters);
  };

  const isValueNull = (filterName) => !isValueCorrect(filters) || !Object.keys(filters).includes(filterName);
  const returnFilter = (filterName) => {
    switch (filterName) {
      case 'passSelector':
        return (
          <>
            <div className="filterLabel">Select pass type</div>
            <Select
              allowClear={true}
              className="w-full"
              placeholder="Select pass type"
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              options={ALL_CLASS_TYPES_DETAILS.map((elm) => ({ value: elm.type, label: elm.title }))}
              onChange={(v) => onFilters({ ...filters, passTypeSelector: v, passSelector: null, fetchApi: true })}
              value={filters?.passTypeSelector}
            />
            {isValueCorrect(passesForSelectedClassType) && isValueCorrect(filters.passTypeSelector) && (
              <>
                <div className="filterLabel mt-2">Select pass</div>
                <Select
                  allowClear={true}
                  className="w-full"
                  placeholder="Select pass"
                  showSearch
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  options={passesForSelectedClassType.map((elm) => ({ value: elm._id, label: elm.name }))}
                  onChange={(v) => onFiltersChange('passSelector', v)}
                  value={filters?.passSelector}
                />
              </>
            )}
          </>
        );
      case 'productsSelector':
        return (
          <Select
            mode={'multiple'}
            allowClear={true}
            className="w-full"
            placeholder="Select product"
            showSearch
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            options={productsList.map((elm) => ({ value: elm._id, label: elm.name }))}
            onChange={(v) => onFiltersChange(filterName, v)}
            value={filters?.productsSelector}
          />
        );

      case 'videoCategories':
        return (
          <VideoConfig
            labelClassName={'font-bold text-sm'}
            initialState={initialState.videoCategories}
            categories={videoCategories}
            onChange={(data) => onFiltersChange('videoCategories', data)}
          />
        );
      case 'reportNameDetails':
        return (
          <div>
            <Checkbox
              className="mt-2"
              checked={isValueNull(filterName) ? false : !filters[filterName]}
              onChange={() => onFiltersChange(filterName, !filters.reportNameDetails)}>
              <span className="text-sm">Totals</span>
            </Checkbox>
            <Checkbox
              className="mt-2"
              checked={isValueNull(filterName) ? false : filters[filterName]}
              onChange={() => onFiltersChange(filterName, !filters.reportNameDetails)}>
              <span className="text-sm ">Name details</span>
            </Checkbox>
          </div>
        );
      case 'classTypesCheckboxes':
        if (isValueCorrect(filters?.classTypesCheckboxes)) {
          const init = initialState?.find((elm) => elm.name === 'classTypesCheckboxes');

          return (
            <ClassTypesCheckboxes
              classTypes={classTypesCheckboxes_ClassTypes}
              selectedClassType={filters?.classTypesCheckboxes}
              label={init.label}
              row={init.row}
              allSelected={init?.allSelected}
              onChange={(data) => {
                onFiltersChange('classTypesCheckboxes', { ...filters.classTypesCheckboxes, [data.name]: data.value });
              }}
            />
          );
        }
        return null;

      case 'myEvents':
      case 'notUpdated':
      case 'active':
        return (
          <Checkbox
            className="mt-2"
            checked={isValueNull(filterName) ? false : filters[filterName]}
            onChange={(e) => onFiltersChange(filterName, e.target.checked)}>
            <span className="text-sm">{filtersFields[filterName].label}</span>
          </Checkbox>
        );
      case 'startDate':
      case 'endDate':
        return (
          <DatePicker
            value={isValueNull(filterName) ? undefined : dayjs(filters[filterName], filtersFields[filterName].dbFormat)}
            className="w-full"
            format={filtersFields[filterName].displayFormat}
            onChange={(v) => onFiltersChange(filterName, v.format(filtersFields[filterName].dbFormat))}
          />
        );
      case 'gymId':
        return (
          <Select
            allowClear={true}
            className="w-full"
            placeholder="Select gym"
            showSearch
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            options={gymsList.map((elm) => ({ value: elm._id, label: elm.name }))}
            onChange={(v) => onFiltersChange(filterName, v)}
            value={filters?.gymId}
          />
        );
      case 'className':
      case 'userName':
      case 'name':
      case 'title':
      case 'trainerName':
        return (
          <Input
            value={filters?.[filterName]}
            placeholder={filtersFields[filterName].placeholder}
            onChange={(e) => onFiltersChange(filterName, e.target.value)}
          />
        );
      case 'gyms':
        return (
          <GymSelector
            defaultChecked={filters?.[filterName]}
            onSelect={(v) => onFiltersChange(filterName, v)}
            multiple
          />
        );
      case 'trainerSelector':
        return (
          <Select
            mode={initialState?.find((elm) => elm.name === 'trainerSelector').multiple && 'multiple'}
            allowClear={true}
            className="w-full"
            placeholder="Select trainer"
            showSearch
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            options={trainersList.map((elm) => ({ value: elm._id, label: elm.name + ' ' + elm.last_name }))}
            onChange={(v) => onFiltersChange(filterName, v)}
            value={filters?.trainerSelector}
          />
        );

      default:
        return;
    }
  };

  return (
    <div className="filterContainer">
      <div className=" grid grid-cols-[30px_1fr_50px] items-center ">
        {
          <Icon
            name={'triangle'}
            className={classNames('justify-center transition-all transform', isOpen && 'rotate-90')}
          />
        }
        <div
          className={classNames('ml-2 text-lg font-bold cursor-pointer')}
          onClick={() => setIsOpen((state) => !state)}>
          Filters
        </div>
        {isOpen && <Icon name={'refresh'} size={30} onClick={returnInitialStateOfFilters} />}
      </div>

      {isOpen ? (
        <div>
          {filtersTypes.map((filterName, i) => (
            <div key={i} className="mt-2">
              {filtersFields[filterName] && filtersFields[filterName].showLabel ? (
                <div className="filterLabel">{filtersFields[filterName].label}</div>
              ) : null}
              {returnFilter(filterName)}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Filters;
