import { useEffect, useContext, useState } from 'react';
import { AppContext } from '../../../context/App/AppContext';
import useFilters from '../../../components/Filters/useFilters';
import Filters from '../../../components/Filters/Filters';
import { Tabs } from 'antd';
import { fetchDebtorsReport } from '../../../apiFunctions/apiFunctions';
import { useQuery } from '@tanstack/react-query';
import Table from '../../../components/Table/Table';
import Loading from '../../../components/Loading/Loading';
import Notification from '../../../components/UI/Notification/Notification';
import { isValueCorrect } from '../../../shared/userFunctions';

export const DebtorsReports = ({ setCSVData }) => {
  const { gymsList } = useContext(AppContext);
  const { filteredData, filters, onFilters, setFiltersInitialData } = useFilters();
  const [reportType, setReportType] = useState('debtorsReportsPayg');

  useEffect(
    () => {
      setCSVData({
        data: filteredData,
        gymName: isValueCorrect(filters.gymId) ? gymsList.find((elm) => elm._id === filters.gymId).name : 'All gyms',
        reportType: reportType
      });
    }, //eslint-disable-next-line
    [filteredData]
  );

  const { data, isFetching, isError } = useQuery(
    ['debtorsReport', { gymId: filters.gymId }],
    () => fetchDebtorsReport(filters.gymId),
    {
      enabled: filters.fetchApi,
      onSuccess: (resp) => {
        setFiltersInitialData(
          reportType === 'debtorsReportsPayg'
            ? mapData(resp.unpaidOneOffCharges)
            : mapData(resp.failedSubscriptionCharges)
        );
      }
    }
  );
  const mapData = (items) => items.map((elm) => ({ ...elm, customerType: elm.userType, last_name: elm.lastName }));
  const onTabChange = (repType) => {
    setReportType(repType);
    setFiltersInitialData(
      repType === 'debtorsReportsPayg' ? mapData(data.unpaidOneOffCharges) : mapData(data.failedSubscriptionCharges)
    );
  };

  return (
    <>
      <Filters
        open
        filtersTypes={['gymId', 'userName']}
        onFilters={onFilters}
        filters={filters}
        apiRequestFields={['gymId']}
      />
      {isFetching ? (
        <Loading />
      ) : isError ? (
        <Notification defaultMsg />
      ) : (
        <Tabs
          onChange={onTabChange}
          items={[
            {
              key: 'debtorsReportsPayg',
              label: 'Pay as you go',
              children: (
                <Table fields={['client', 'customerType', 'createdDate', 'classDate', 'amount']} data={filteredData} />
              )
            },
            {
              key: 'debtorsReportsMembership',
              label: 'Membership',
              children: (
                <Table
                  fields={[
                    'client',
                    'customerType',
                    'createdDate',
                    'membershipPlanName',
                    'membershipPlanCurrentPeriodEnd',
                    'amount'
                  ]}
                  data={filteredData}
                />
              )
            }
          ]}
        />
      )}
    </>
  );
};
export default DebtorsReports;
