import { useContext, useEffect, useState } from 'react';
import Button from '../../components/UI/Button/Button';
import BulkModal from './BulkModal/BulkModal';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { fetchAllScheduledClasses, fetchClassesPerScheduleId, fetchClasses } from '../../apiFunctions/apiFunctions';
import { returnAllUpcomingClasses } from '../../shared/classFunctions';
import { typeSelectOptions } from './constants';
import Loading from '../../components/Loading/Loading';
import { Select } from 'antd';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import { AppContext } from '../../context/App/AppContext';
import { returnAvailableClassTypesInGym } from '../../shared/gymFunctions';
import Title from '../../components/UI/Title/Title';
import { isValueCorrect } from '../../shared/userFunctions';
import Notification from '../../components/UI/Notification/Notification';
import { useLocation } from 'react-router-dom';

const startDate = moment().add(1, 'd').format('YYYY-MM-DD');
const endDate = moment().add(15, 'd').format('YYYY-MM-DD');

const BulkEditing = () => {
  const { selectedGym } = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [dateRange, setDateRange] = useState({ dateFrom: startDate, dateTo: endDate });
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isValueCorrect(location.state) && location.state.scheduleId && location.state.scheduleId.length > 0) {
      setSelectedOption('schedule');
      setSelectedSchedule(location.state.scheduleId);
      setOpenModal(true);
    }
  }, [location]);

  const {
    data: scheduledClassesList,
    isError: scheduledClassesListIsError,
    isLoading: scheduledClassesListLoading
  } = useQuery(['allScheduledClassesList'], () => fetchAllScheduledClasses(selectedGym._id), {
    enabled: isValueCorrect(selectedOption) && selectedOption === 'schedule'
  });

  const {
    data: bulkData,
    isError,
    isLoading,
    isFetching
  } = useQuery(
    ['bulkData'],
    () =>
      selectedOption === 'schedule' && selectedSchedule.length > 0
        ? fetchClassesPerScheduleId(selectedSchedule)
        : fetchClasses(
            selectedOption,
            selectedGym._id,
            moment(dateRange.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            moment(dateRange.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD')
          ),
    {
      enabled: openModal
    }
  );

  const renderScheduleSelection = () => {
    if (selectedOption && selectedOption === 'schedule') {
      if (scheduledClassesListLoading) {
        return <Loading centered />;
      }

      if (scheduledClassesListIsError) {
        return <Notification message="Something went wrong, please try again" status="critical" />;
      }

      const options =
        scheduledClassesList && scheduledClassesList.length > 0
          ? scheduledClassesList.map((elem) => {
              return { key: elem._id, value: elem._id, label: elem.name === 'n/a' ? elem.classType : elem.name };
            })
          : [];

      return (
        <div>
          <div className="formLabel font-bold">Please select schedule name</div>
          <Select
            value={selectedSchedule}
            className={'w-full'}
            options={options}
            placeholder={scheduledClassesList.length === 0 ? 'No schedules' : null}
            onChange={(value) => {
              setSelectedSchedule(value);
              setOpenModal(true);
            }}
          />
        </div>
      );
    }
    return null;
  };

  const renderDatePicker = () => {
    if (selectedOption && selectedOption !== 'schedule') {
      return <DateRangePicker row={false} onChange={setDateRange} onlyFuture startDate={startDate} endDate={endDate} />;
    }
    return null;
  };

  const typeSelector = () => (
    <>
      <div className="formLabel font-bold">Please select type</div>
      <Select
        className="w-full mb-2.5"
        options={typeSelectOptions(returnAvailableClassTypesInGym(selectedGym.settings))}
        onChange={setSelectedOption}
        value={selectedOption}
      />
    </>
  );

  return (
    <div data-cy={'bulk_management'}>
      <Title title={'Bulk management'} />
      <div className="w-[90%] sm:w-[300px] mx-auto">
        {typeSelector()}
        {renderScheduleSelection()}
        {renderDatePicker()}
        <Button
          className={'mt-8'}
          fullWidth
          secondary
          label="Show data"
          onClick={() => setOpenModal(true)}
          disabled={
            selectedOption === null
              ? true
              : selectedOption === 'schedule'
                ? !(selectedOption === 'schedule' && selectedSchedule && selectedSchedule.length > 0)
                : false
          }
        />
      </div>

      <BulkModal
        isLoading={isLoading || isFetching}
        isError={isError}
        data={
          isValueCorrect(selectedOption)
            ? selectedOption === 'schedule'
              ? bulkData
              : returnAllUpcomingClasses(bulkData)
            : []
        }
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
        classType={selectedOption}
        scheduleId={selectedSchedule && selectedSchedule.length > 0 ? selectedSchedule : ''}
      />
    </div>
  );
};

export default BulkEditing;
