import { useContext, useState } from 'react';
import { Modal } from 'antd';
import { WaitListUserButton } from './WaitListUserButton/WaitListUserButton';
import Button from '../../../components/UI/Button/Button';
import EmptyDiv from '../../../components/UI/EmptyDiv/EmptyDiv';
import Icon from '../../../components/UI/Icon/Icon';
import { AppContext } from '../../../context/App/AppContext';
import RedirectToUserDetails from '../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';

const WaitListModal = ({ selectedClass }) => {
  const { userData, gymId } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);

  if (selectedClass && selectedClass.waitListConfig && selectedClass.waitListConfig.active) {
    const returnModal = () => {
      const waitList = selectedClass.waitList.filter((elem) => elem.status === 'active');

      return (
        <Modal open={showModal} onCancel={() => setShowModal(false)}>
          <h3 className="mb-4"> Waiting users: {waitList.length}</h3>
          {waitList.length > 0
            ? waitList.map((elm, i) => (
                <div className="listItem" key={i}>
                  <RedirectToUserDetails client={{ ...elm.userData, _id: elm.user, gymId }} showName />
                </div>
              ))
            : null}
          <Button primary fullWidth label="cancel" onClick={() => setShowModal(false)} />
        </Modal>
      );
    };

    return (
      <div>
        {returnModal()}

        {userData.isAdminOrTrainer ? (
          <Icon name={'group'} onClick={() => setShowModal(true)} />
        ) : (
          <WaitListUserButton selectedClass={selectedClass} />
        )}
      </div>
    );
  }
  return <EmptyDiv />;
};

export default WaitListModal;
