import useSelectFilter from '../hooks/useSelectFilter';
import { CLIENTS_STATUS_FILTERS } from '../../../constants';
import { Radio, Space } from 'antd';

const ClientStatusesFilter = ({ onFilter, allClientStatuses }) => {
  const { onSelectedFilterChanged, isSelectedFilter } = useSelectFilter(CLIENTS_STATUS_FILTERS.ACTIVE, onFilter, true);

  return (
    <Space direction="vertical" size={2} className="block">
      <h4>Client Status</h4>

      {allClientStatuses && (
        <div>
          <Radio
            id={CLIENTS_STATUS_FILTERS.WAITING_FOR_APPROVAL}
            name="client_status"
            type="radio"
            value={CLIENTS_STATUS_FILTERS.WAITING_FOR_APPROVAL}
            onChange={onSelectedFilterChanged}
            checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.WAITING_FOR_APPROVAL)}>
            Waiting for approval
          </Radio>

          <Radio
            id={CLIENTS_STATUS_FILTERS.PERMISSION_DENIED}
            name="client_status"
            type="radio"
            value={CLIENTS_STATUS_FILTERS.PERMISSION_DENIED}
            onChange={onSelectedFilterChanged}
            checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.PERMISSION_DENIED)}>
            Permission denied
          </Radio>
        </div>
      )}

      <Radio
        id={CLIENTS_STATUS_FILTERS.INACTIVE}
        name="client_status"
        type="radio"
        value={CLIENTS_STATUS_FILTERS.INACTIVE}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.INACTIVE)}>
        Inactive
      </Radio>

      <Radio
        id={CLIENTS_STATUS_FILTERS.ACTIVE}
        name="client_status"
        type="radio"
        label="Active"
        value={CLIENTS_STATUS_FILTERS.ACTIVE}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.ACTIVE)}>
        Active
      </Radio>

      <Radio
        id={CLIENTS_STATUS_FILTERS.ALL}
        name="client_status"
        type="radio"
        value={CLIENTS_STATUS_FILTERS.ALL}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.ALL)}>
        All clients
      </Radio>
    </Space>
  );
};

export default ClientStatusesFilter;
