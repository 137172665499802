import { useContext, useEffect } from 'react';
import Filters from '../../../components/Filters/Filters';
import useFilters from '../../../components/Filters/useFilters';
import { AppContext } from '../../../context/App/AppContext';
import { useQuery } from '@tanstack/react-query';
import { fetchClassAttendancesReport } from '../../../apiFunctions/apiFunctions';
import Loading from '../../../components/Loading/Loading';
import Notification from '../../../components/UI/Notification/Notification';
import Table from '../../../components/Table/Table';
const AttendancesReports = ({ setCSVData }) => {
  const { selectedGym, gymsList } = useContext(AppContext);
  const { onFilters, setFiltersInitialData, filters, filteredData } = useFilters();
  const apiRequestFields = ['gymId', 'startDate', 'endDate', 'reportNameDetails'];
  const isFetchingEnabled = () => filters && filters.fetchApi;

  useEffect(
    () => {
      if (filters !== null && Object.keys(filters).length > 0) {
        setCSVData({
          data: filteredData,
          gymName: gymsList.find((elm) => elm._id === filters.gymId).name,
          reportType: filters.reportNameDetails ? 'classAttendanceNameReport' : 'classAttendanceReport'
        });
      }
    }, //eslint-disable-next-line
    [filteredData]
  );

  const { isLoading, isError } = useQuery(['reportAttendance', filters], () => fetchClassAttendancesReport(filters), {
    enabled: isFetchingEnabled(),
    onSuccess: (result) => {
      if (result !== undefined && result.length > 0) {
        setFiltersInitialData(
          result.map((elm) => ({
            ...elm,
            name: elm.eventName,
            __t: elm.eventType,
            trainer: { name: elm.hostName?.split(/\s+/g)[0], last_name: elm.hostName?.split(/\s+/g)[1] }
          }))
        );
      } else {
        setFiltersInitialData([]);
      }
    }
  });

  const renderResult = () => {
    if (isLoading && isFetchingEnabled()) {
      return <Loading />;
    }
    if (isError) {
      return <Notification defaultMsg />;
    }
    const fields = ['name', 'gym', 'classType', 'trainer', 'date', 'time', 'report_attendance'];
    if (filters.reportNameDetails) {
      fields.push('firstAttendee');
    }

    return <Table fields={fields} data={filteredData} />;
  };

  return (
    <div>
      <Filters
        open
        filtersTypes={[
          'gymId',
          'startDate',
          'endDate',
          'trainerName',
          'notUpdated',
          'classTypesCheckboxes',
          'reportNameDetails'
        ]}
        filters={filters}
        onFilters={onFilters}
        initialState={[
          {
            name: 'notUpdated',
            value: false
          },
          {
            name: 'gymId',
            value: selectedGym._id
          },
          {
            name: 'reportNameDetails',
            value: false
          },
          {
            name: 'classTypesCheckboxes',
            gym: selectedGym._id,
            allSelected: true,
            row: true
          }
        ]}
        apiRequestFields={apiRequestFields}
      />
      {renderResult()}
    </div>
  );
};
export default AttendancesReports;
