import { useContext } from 'react';
import Title from '../../../../components/UI/Title/Title';
import RenderForm from '../../../../components/Forms/RenderForm';
import { AppContext } from '../../../../context/App/AppContext';
const StepOne = ({ accountType, gymData }) => {
  const { onSignUp, onSignUpIsLoading } = useContext(AppContext);

  const onFormFinish = (data) =>
    onSignUp({
      ...data,
      customerType: accountType,
      gymId: gymData._id
    });

  return (
    <>
      <Title
        title="Personal Details"
        subtitle="Before you sign up, we need to take a few details. By completing this form you agree to the Terms & Conditions of our Website."
      />

      <RenderForm
        initialValues={{ title: 'Mr' }}
        onFinish={onFormFinish}
        formFields={[
          'title',
          'userName',
          'lastName',
          'dateofbirth',
          'email',
          'phone',
          'addressLine',
          'city',
          'postcode',
          'emergencyName',
          'emergencyPhone',
          'password',
          'confirmPassword',
          'marketing'
        ]}
        btnLabel="next step"
        btnIsLoading={onSignUpIsLoading}
        gymSettings={gymData.settings}
      />
    </>
  );
};
export default StepOne;
