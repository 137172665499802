import { useContext } from 'react';
import TilesBoard from '../../components/UI/TilesBoard/TilesBoard';
import { menuItemsArray } from '../../components/Navigation/MenuNavigation/menuItems';
import { AppContext } from '../../context/App/AppContext';
import { useLocation } from 'react-router-dom';

const AdminTrainerDashboard = () => {
  const { userData, selectedGym, gymsList } = useContext(AppContext);
  const location = useLocation();

  return (
    <TilesBoard tiles={menuItemsArray(userData, selectedGym?.settings, location.pathname, gymsList, 'showAsTile')} />
  );
};

export default AdminTrainerDashboard;
