import { useContext, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deletePass, fetchPasses, refreshFetchFunctions } from '../../apiFunctions/apiFunctions';
import { returnClassesDetails, returnProductTypeName } from '../../shared/classFunctions';
import Title from '../../components/UI/Title/Title';
import AddEditPass from './AddEditPass/AddEditPass';
import Loading from '../../components/Loading/Loading';
import SinglePassView from './ShowPasses/SinglePassesView/SinglePassesView';
import { CLASS } from '../../constants';
import { AppContext } from '../../context/App/AppContext';
import Button from '../../components/UI/Button/Button';
import Notification from '../../components/UI/Notification/Notification';
import Table from '../../components/Table/Table';
import { Modal } from 'antd';
import ModalButtonsContainer from '../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import { NotificationContext } from '../../context/Notification/NotificationContext';
import { VideoContext } from '../../context/Video/VideoContext';
import Filters from '../../components/Filters/Filters';
import useFilters from '../../components/Filters/useFilters';

const ManagePasses = ({ classType }) => {
  const { openNotification } = useContext(NotificationContext);
  const { userData, selectedGym } = useContext(AppContext);
  const { videos } = useContext(VideoContext);
  const { filteredData, onFilters, setFiltersInitialData, filters } = useFilters();
  const [showAddComponent, setShowAddComponent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(true);
  const [selected, setSelected] = useState(null);
  const gymId = userData.role === 'user' ? userData.gymId : selectedGym._id;

  const { data, isLoading, error } = useQuery(
    ['passesList', { gymId, classType }],
    () => fetchPasses(classType, gymId),
    {
      onSuccess: (resp) => {
        setFiltersInitialData(resp);
      }
    }
  );

  const { mutate: onDelete, isLoading: onDeleteIsLoading } = useMutation({
    mutationFn: () => deletePass(selected._id),
    onSuccess: () => {
      openNotification('delete', 'success');
      onCloseModal();
      refreshFetchFunctions([['passesList', { gymId, classType }]]);
    },
    onError: () => {
      openNotification('delete', 'error');
    }
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Notification message={error.message} status="critical" />;
  }

  const classesDetails = returnClassesDetails(classType);
  const onIconsClick = (selected, isEditModal) => {
    setSelected(selected);
    setIsEditModal(isEditModal);
    setShowModal(true);
  };
  const onCloseModal = () => {
    setSelected(null);
    setShowModal(false);
    setShowAddComponent(false);
  };

  const addNewPassButton = (
    <Button
      fullWidth
      className="mb-2.5"
      dataCy="add_btn"
      label={showAddComponent ? 'Passes list' : 'Add new pass'}
      secondary
      onClick={() => {
        setSelected(null);
        setShowAddComponent((state) => !state);
      }}
    />
  );
  const title = (
    <Title
      title={classesDetails.title + ' Passes'}
      subtitle={
        userData.isUser &&
        `Please purchase your ${returnProductTypeName(classesDetails.productType).toLowerCase() || 'passes'} below${
          classesDetails.type === CLASS.PERSONAL_TRAINING ? ' and contact your trainer to book your session.' : '.'
        }`
      }
    />
  );
  const fields =
    classType === CLASS.PERSONAL_TRAINING
      ? ['name', 'places', 'duration', 'classCount', 'cost', 'oneOff', 'active', 'displayOrder', 'actionColumn']
      : classType === 'video'
        ? ['name', 'classCount', 'cost', 'oneOff', 'active', 'displayOrder', 'videoForClassType', 'actionColumn']
        : ['name', 'classCount', 'cost', 'oneOff', 'active', 'displayOrder', 'actionColumn'];

  const addEditComponent = (
    <AddEditPass
      classType={classType}
      selectedPass={selected}
      onCancel={onCloseModal}
      displayOrder={data.length + 1}
      videos={videos}
    />
  );

  const displayPasses = () => {
    if (userData.isAdmin) {
      return showAddComponent ? (
        addEditComponent
      ) : (
        <>
          <Filters
            filtersTypes={['active']}
            filters={filters}
            onFilters={onFilters}
            initialState={[{ name: 'active', value: true }]}
          />
          <Table
            fields={fields}
            showHideFieldsBtn
            data={filteredData}
            actionType="editDelete"
            additionalData={{
              type: 'pass',
              classType,
              onEdit: (selected) => onIconsClick(selected, true),
              onDelete: (selected) => onIconsClick(selected, false)
            }}
          />
        </>
      );
    }

    return data.length === 0 ? (
      <h3>No passes</h3>
    ) : (
      <div>
        {![CLASS.INDUCTION].includes(classType) && !!data.length && (
          <SinglePassView data={data} classType={classType} goBack={false} />
        )}
      </div>
    );
  };

  return (
    <div data-cy={classType + '_passes'}>
      {userData.isUser ? title : null}
      {userData.isAdmin ? addNewPassButton : null}

      <Modal open={showModal} onCancel={onCloseModal}>
        {isEditModal ? (
          addEditComponent
        ) : (
          <>
            <div className="text-center">Are you sure you want to delete {selected?.name}?</div>
            <ModalButtonsContainer isLoading={onDeleteIsLoading}>
              <Button label="delete" secondary onClick={onDelete} />
              <Button label="cancel" primary onClick={onCloseModal} />
            </ModalButtonsContainer>
          </>
        )}
      </Modal>

      {displayPasses()}
    </div>
  );
};

export default ManagePasses;
