import { returnClassConfig } from '../../shared/gymFunctions';
import { useQuery } from '@tanstack/react-query';
import { fetchClasses, fetchUpcomingPts } from '../../apiFunctions/apiFunctions';
import Loading from '../../components/Loading/Loading';
import AdminAndTrainerView from './AdminAndTrainerView/AdminAndTrainerView';
import { useContext } from 'react';
import { AppContext } from '../../context/App/AppContext';
import DefaultTimetable from '../Timetables/DefaultTimetable/DefaultTimetable';
import { CLASS } from '../../constants';
import VirtualBookings from './VirtualBookings/VirtualBookings';

const ManageTimetable = ({ classType }) => {
  const { userData, selectedGym } = useContext(AppContext);
  const gymId = selectedGym._id;
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);

  const {
    data: classesList,
    error: classesListError,
    isLoading: classesListLoading,
    isFetching: classesListIsFetching
  } = useQuery(['upcomingClasses', classType], () =>
    userData.isUser && classType === CLASS.PERSONAL_TRAINING ? fetchUpcomingPts() : fetchClasses(classType, gymId)
  );

  if (classesListLoading || classesListIsFetching) {
    return <Loading />;
  }

  if (userData.isUser) {
    const arrayOfClassTypesToShowVideo = [CLASS.INDUCTION];
    return (
      <>
        <VirtualBookings
          show={arrayOfClassTypesToShowVideo.includes(classType) && selectedGym.settings.videoOnDemand === true}
          classType={classType}
        />
        <DefaultTimetable
          classesList={classesList}
          classesListLoading={classesListLoading}
          classesListError={classesListError}
          classType={classType}
          classConfig={classConfig}
          type="upcoming"
        />
      </>
    );
  }
  return (
    <AdminAndTrainerView
      classType={classType}
      classesList={classesList}
      classesListLoading={classesListLoading}
      classesListError={classesListError}
      classConfig={classConfig}
    />
  );
};

export default ManageTimetable;
