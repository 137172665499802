import { theme as andTheme } from 'antd';
import companyDetails from '../brandConfig/companyDetails';
export default {
  ...andTheme,
  token: {
    colorPrimary: companyDetails.styles.primary,
    borderColor: companyDetails.styles.primary,
    fontFamily: companyDetails.styles.fontFamily,
    borderRadius: 0,
    controlInteractiveSize: 20
  },
  components: {
    Notification: {
      width: 767
    }
  }
};
