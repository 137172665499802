import Loading from '../../Loading/Loading';
import Icon from '../Icon/Icon';
import { classNames } from '../../../shared/utility';

const Button = ({
  isLoading = false,
  label = null,
  primary = false,
  secondary = false,
  red = false,
  onClick = () => {},
  fullWidth = false,
  dataCy = 'btn',
  disabled = false,
  icon = null,
  className = '',
  htmlType = 'button',
  show = true,
  btnClassName = '',
  textWrap = false,
  padding = 'px-7 py-1.5',
  style = {}
}) => {
  if (!show) {
    return null;
  }
  if (isLoading) {
    return <Loading data-cy={dataCy} className="my-2" />;
  }
  return (
    <div className={[className].join(' ')}>
      <button
        type={htmlType}
        onClick={onClick}
        disabled={disabled}
        data-cy={dataCy}
        className={classNames(
          padding,
          'font-bold text-lg uppercase border-2 ',
          primary
            ? 'bg-primary text-white border-transparent hover:border-primary'
            : secondary
              ? 'bg-secondary text-white border-transparent hover:border-secondary'
              : red
                ? 'bg-custom-red text-white border-custom-red hover:border-custom-red'
                : 'text-primary border-primary',
          disabled && 'cursor-no-drop',
          fullWidth && 'w-full',
          'hover:opacity-90',
          btnClassName
        )}
        style={style}>
        <div className={'flex align-middle justify-center'}>
          {icon && <Icon name={icon} size={22} className="mr-1" />}
          <span className={textWrap ? 'text-balance' : 'text-nowrap'}>{label}</span>
        </div>
      </button>
    </div>
  );
};

export default Button;
