import { useContext, useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../context/App/AppContext';
import Loading from '../../components/Loading/Loading';
import AdminQrScanner from '../../containers/AdminDashboard/AdminQrScanner/AdminQrScanner';
import GymSelector from '../../components/Selectors/GymSelector/GymSelector';
import { VideoContext } from '../../context/Video/VideoContext';

const Container = ({ children, show = true, dataCy = '', gymSettingsRestriction = null }) => {
  const { userData, selectedGym, onGymChange, selectedGymIsLoading } = useContext(AppContext);
  const { setShowVideoData } = useContext(VideoContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const goHome = <Navigate to={userData?.isUser ? '/gym' : selectedGym ? `/gym/${selectedGym._id}` : '/'} />;

  useEffect(
    () => {
      if (params?.gymId) {
        onGymChange(params.gymId);
      }
    }, //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (gymSettingsRestriction === 'videoOnDemand') {
        setShowVideoData(true);
      }
    }, //eslint-disable-next-line
    [gymSettingsRestriction]
  );

  // if ((selectedGymIsLoading && !['/gym', '/add-gym'].includes(pathname)) || selectedGym === undefined) {
  if (selectedGymIsLoading && !['/gym', '/add-gym'].includes(pathname)) {
    return <Loading />;
  }

  if (show) {
    const handleGymChange = (gymId) => {
      if (gymId !== selectedGym._id) {
        const restPathnameUrl = pathname.split(`/gym/${selectedGym._id}`)[1];
        onGymChange(gymId);
        navigate(restPathnameUrl.length > 1 ? `/gym/${gymId}${restPathnameUrl}` : `/gym/${gymId}`);
      }
    };
    const adminTrainerSubHeader = userData.isAdminOrTrainer ? (
      <>
        <AdminQrScanner />
        <GymSelector
          onSelect={handleGymChange}
          defaultChecked={selectedGym?._id}
          className="body-width filterContainer"
        />
      </>
    ) : null;

    if (gymSettingsRestriction !== null) {
      return selectedGym.settings[gymSettingsRestriction] ? (
        <div data-cy={dataCy} className={'body-container'}>
          {adminTrainerSubHeader}
          {children}
        </div>
      ) : (
        goHome
      );
    }

    return (
      <div data-cy={dataCy} className="body-container">
        {adminTrainerSubHeader}
        {children}
      </div>
    );
  }
  return goHome;
};

export default Container;
