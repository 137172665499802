import { useContext, useState } from 'react';
import RenderForm from '../../../components/Forms/RenderForm';
import Loading from '../../../components/Loading/Loading';
import Notification from '../../../components/UI/Notification/Notification';
import { AppContext } from '../../../context/App/AppContext';
import { useMutation } from '@tanstack/react-query';
import { addScheduledClass, editScheduledClass, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { daysOfWeek } from '../../../constants/timeConstants';
import { isValueCorrect } from '../../../shared/userFunctions';
import { Modal } from 'antd';
import ModalButtonsContainer from '../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import Button from '../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
//TODO:  Recurrent on 0 -> which day it is ?  Create schedule and show schedule now is correct (monday) but upcoming classes is  day before -> sunday
const AddEditSchedule = ({
  classType,
  selectedSchedule = null,
  onCancel = () => {},
  templatesList,
  templatesListIsLoading,
  templatesListIsError
}) => {
  const { selectedGym, trainersList, trainersListIsLoading, trainersListIsError } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const [showBulkEditingModal, setShowBulkEditingModal] = useState(false);
  const navigate = useNavigate();
  const isNew = selectedSchedule === null;

  const { mutate: onCreateSchedule, isLoading: onCreateScheduleIsLoading } = useMutation({
    mutationFn: (formData) =>
      isNew
        ? addScheduledClass(selectedGym._id, {
            ...formData,
            classType,
            description: isValueCorrect(formData.description) ? formData.description : 'description',
            name: isValueCorrect(formData.name) ? formData.name : classType
          })
        : editScheduledClass(selectedSchedule._id, formData),
    onSuccess: () => {
      openNotification('create', 'success');
      onCancel();
      setShowBulkEditingModal(!isNew);
      isNew
        ? refreshFetchFunctions([['upcomingClasses', classType], ['schedulesList']])
        : refreshFetchFunctions([['schedulesList']]);
    },
    onError: () => {
      openNotification('create', 'error');
    }
  });

  if (templatesListIsLoading || trainersListIsLoading) {
    return <Loading />;
  }
  if (templatesListIsError || trainersListIsError) {
    return (
      <Notification
        status={'critical'}
        message={'Unable to fetch ' + templatesListIsError ? 'templates' : 'trainers'}
      />
    );
  }

  const formFields = ['gymClass', 'swimmingClass', 'tennisClass', 'massageClass'].includes(classType)
    ? [
        'template',
        'currency',
        'cost',
        'membershipClassPrice',
        'limit',
        'duration',
        'minimalTimeCancellationInHours',
        'scheduledDaysOfTheWeek',
        'waitList',
        'private',
        'enabledForScheduling'
      ]
    : [
        'template',
        'name',
        'description',
        'selectTrainer',
        'location',
        'currency',
        'cost',
        'membershipClassPrice',
        'limit',
        'duration',
        'minimalTimeCancellationInHours',
        'scheduledDaysOfTheWeek',
        'waitList',
        'private',
        'enabledForScheduling'
      ];

  let initialValues = {
    currency: 'gbp',
    duration: 60,
    minimalTimeCancellationInHours: 2,
    private: false,
    enabledForScheduling: true,
    waitListConfig: {
      active: false,
      minimalTimeBeforeClassStartsToNotifyUsers: 2,
      timeDelayForSendingNotifications: 2
    }
  };

  if (!isNew) {
    initialValues = {
      ...initialValues,
      ...selectedSchedule,
      trainerId: selectedSchedule.trainerId?._id,
      templateId: selectedSchedule.classTemplateId
    };
    daysOfWeek.forEach(({ value, index }) => {
      initialValues[value] = initialValues.recurrentOn[index];
    });
  }

  let excludeFormFields = [];
  if (!selectedGym.settings.classConfig.find((elm) => elm.classType === classType).trainerRequired) {
    excludeFormFields.push('selectTrainer');
  }

  if (!selectedGym.settings.membershipProducts) {
    excludeFormFields.push('membershipClassPrice');
  }

  return (
    <>
      <Modal open={showBulkEditingModal} onCancel={() => setShowBulkEditingModal(false)}>
        Do you want to update existing classes ?
        <ModalButtonsContainer>
          <Button
            label="Yes"
            secondary
            onClick={() =>
              navigate(`/gym/${selectedGym._id}/bulk-management`, {
                state: {
                  scheduleId: selectedSchedule._id
                }
              })
            }
          />
          <Button label="Cancel" primary onClick={() => setShowBulkEditingModal(false)} />
        </ModalButtonsContainer>
      </Modal>

      <RenderForm
        formFields={formFields}
        initialValues={initialValues}
        excludeFormFields={excludeFormFields}
        onFinish={onCreateSchedule}
        btnIsLoading={onCreateScheduleIsLoading}
        cancelBtn={!isNew}
        onCancel={onCancel}
        trainersList={trainersList}
        templatesList={templatesList}
        showStaffShifts={selectedGym.settings.staffShifts}
      />
    </>
  );
};

export default AddEditSchedule;
