import { classNames } from '../../../shared/utility';

const ProfileTextWithLabel = ({ label = '', text = '', show = true, className = '' }) => {
  return show ? (
    <div className={classNames('mb-2 flex', className)} data-cy="credits">
      <span className={'block mr-[5px] text-neutral-500'}>{label}:</span>
      <span className={'block'}>{text}</span>
    </div>
  ) : null;
};
export default ProfileTextWithLabel;
