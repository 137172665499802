import { useContext, useState } from 'react';
import AddEditAdmin from '../AddEditAdmin/AddEditAdmin';
import { isFullAccessAdminOrTrainer } from '../../../../shared/userFunctions';
import { useMutation } from '@tanstack/react-query';
import { deleteAdmin, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import { AppContext } from '../../../../context/App/AppContext';
import Icon from '../../../../components/UI/Icon/Icon';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import { Modal } from 'antd';
import Loading from '../../../../components/Loading/Loading';

const SingleAdmin = ({ singleAdmin }) => {
  const { userData, selectedGym } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { mutate: onDeleteAdmin, isLoading: onDeleteAdminIsLoading } = useMutation({
    mutationFn: () => deleteAdmin(singleAdmin._id),
    onSuccess: () => {
      openNotification('delete', 'success');
      refreshFetchFunctions([['adminsList', selectedGym._id]]);
    },
    onError: () => {
      setShowModal(false);
      openNotification('delete', 'error');
    }
  });

  const handleAdminDelete = () => {
    if (!showConfirm) {
      openNotification(null, 'warning', 'Click once again to confirm the deletion of the admin in all gyms');
      return setShowConfirm(true);
    }
    onDeleteAdmin();
  };

  const handleOpenModal = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <Modal onCancel={handleModalClose} open={showModal}>
        <AddEditAdmin editAdmin={singleAdmin} onCancel={handleModalClose} />
      </Modal>

      <div className={'listItem'}>
        <div className="flex justify-between align-middle">
          <div>
            <div className="font-bold">
              {singleAdmin.name} {singleAdmin.last_name}
            </div>
            {isFullAccessAdminOrTrainer(singleAdmin) && <div className="text-sm">Full access</div>}
          </div>
          {userData._id !== singleAdmin._id ? (
            onDeleteAdminIsLoading ? (
              <Loading />
            ) : (
              <div className="flex">
                <Icon name={'edit'} onClick={handleOpenModal} />
                <Icon name={'delete'} onClick={handleAdminDelete} />
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SingleAdmin;
