import { useState } from 'react';
import { returnLabelsForDataType } from '../constants';
import classes from './BulkModal.module.scss';
import Button from '../../../components/UI/Button/Button';
import BulkEditingForm from '../BulkEditingForm/BulkEditingForm';
import { returnDate } from '../../../shared/date-utility';
import { Checkbox } from 'antd';

const BulkTable = ({ data, closeModal, updatedData = null, classType }) => {
  const [selectedId, setSelectedId] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const fields = returnLabelsForDataType(classType);

  const handleFieldSelection = (fieldName) => {
    const index = selectedFields.indexOf(fieldName);
    if (fieldName === '_id') {
      setSelectedId(index < 0 ? data.map((elem) => elem._id) : []);
      setSelectedFields(index < 0 ? fields.map((elem) => elem.name) : []);
    } else {
      let newFields = selectedFields.filter((elem) => elem !== '_id');
      newFields = index === -1 ? [...newFields, fieldName] : newFields.filter((elem) => elem !== fieldName);
      newFields =
        newFields.length === fields.length - 1 && selectedId.length === data.length
          ? [...newFields, '_id']
          : newFields.filter((elem) => elem !== '_id');
      setSelectedFields(newFields);
    }
  };

  const handleIdSelection = (id) => {
    const index = selectedId.indexOf(id);
    let newIdArr = index === -1 ? [...selectedId, id] : selectedId.filter((elem) => elem !== id);
    setSelectedId(newIdArr);
    setSelectedFields((selectedFields) =>
      newIdArr.length === data.length && selectedFields.length === fields.length - 1
        ? [...selectedFields, '_id']
        : selectedFields.filter((elem) => elem !== '_id')
    );
  };

  const listHeader = () => (
    <thead className={classes.tableHeader}>
      <tr>
        {fields.map((elem) => (
          <th key={elem.name}>
            {elem.label}
            <div className={classes.checkboxContainer}>
              <Checkbox
                checked={selectedFields.indexOf(elem.name) >= 0}
                onChange={() => handleFieldSelection(elem.name)}></Checkbox>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );

  const listBody = (data) => {
    const returnValue = (fieldName, value) => {
      if (value === undefined || value === null) {
        return null;
      }
      switch (fieldName) {
        case '_id': {
          return (
            <div className={classes.checkboxContainer}>
              <Checkbox onChange={() => handleIdSelection(value)} checked={selectedId.indexOf(value) >= 0}></Checkbox>
            </div>
          );
        }
        case 'waitListConfig': {
          return <div>{value.active === true ? 'true' : 'false'}</div>;
        }
        case 'minimalTimeCancellationInHours': {
          return <div>{value.toString()}</div>;
        }
        case 'classDate': {
          return <div>{returnDate(value)}</div>;
        }
        case 'trainer': {
          return <div>{`${value.name} ${value.last_name}`}</div>;
        }
        default:
          return <div>{value === true ? 'true' : value === false ? 'false' : value}</div>;
      }
    };

    return (
      <tbody>
        {data.map((elem, index) => (
          <tr key={elem.name + index}>
            {fields.map((field) => (
              <th key={elem._id + field.name} className={classes.headerItem}>
                {returnValue(field.name, elem[field.name])}
              </th>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  const returnButton = () => (
    <Button
      disabled={!(selectedId.length !== 0 && selectedFields.length !== 0)}
      label="Update data"
      primary
      onClick={() => setShowForm(true)}
      fullWidth
    />
  );

  if (showForm) {
    return (
      <BulkEditingForm
        data={data.filter((elem) => selectedId.includes(elem._id))}
        fields={selectedFields}
        closeModal={closeModal}
        updatedData={updatedData}
        classType={classType}
      />
    );
  }
  return (
    <div className={classes.root}>
      {returnButton()}
      <div className="text-sm">
        <table>
          {listHeader()}
          {listBody(data)}
        </table>
      </div>
    </div>
  );
};

export default BulkTable;
