import { isActiveUser, isFullAccessAdminOrTrainer, returnGymsByAccess } from '../../../shared/userFunctions';
import { returnClassConfigFieldValue } from '../../../shared/gymFunctions';
import QRIcon from '../../../assets/svg/qr.svg';
import ScheduledClassesIcon from '../../../assets/svg/scheduled-cl.svg';
import ClassPassesIcon from '../../../assets/svg/class-passes.svg';
import CalendarIcon from '../../../assets/svg/calendar.svg';
import PTSessionIcon from '../../../assets/svg/pt-session-add.svg';
import PTPackagesIcon from '../../../assets/svg/pt-packages.svg';
import PlansIcon from '../../../assets/svg/plans.svg';
import ClassesIcon from '../../../assets/svg/classes.svg';
import ClientsIcon from '../../../assets/svg/clients.svg';
import TrainersIcon from '../../../assets/svg/trainers.svg';
import { GYM_SETTINGS } from '../../../constants';

export const menuItemsArray = (userData, settings = GYM_SETTINGS, url, gyms, type = 'showInMenu') => {
  const gymsFullAccess = isFullAccessAdminOrTrainer(userData);
  let tabs = [];

  switch (userData.role) {
    case 'user':
      if (isActiveUser(userData)) {
        if (settings.qrCode) {
          tabs.push({
            link: `${url}/qr`,
            name: 'QR Code',
            icon: <QRIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'qr_code'
          });
        }

        if (settings.induction) {
          tabs.push({
            link: `${url}/induction-timetable`,
            name: 'Induction Bookings',
            icon: <ScheduledClassesIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'induction_bookings'
          });

          if (returnClassConfigFieldValue(settings.classConfig, 'induction', 'allowedToBuyPasses')) {
            tabs.push({
              link: `${url}/induction-passes`,
              name: 'Induction Passes',
              icon: <ClassPassesIcon />,
              showInMenu: true,
              showAsTile: true,
              dataCy: 'induction_passes'
            });
          }
        }

        if (settings.gymClass) {
          tabs.push({
            link: `${url}/gym-timetable`,
            name: 'Gym Bookings',
            icon: <CalendarIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'gymClass_bookings'
          });
          if (returnClassConfigFieldValue(settings.classConfig, 'gymClass', 'allowedToBuyPasses')) {
            tabs.push({
              link: `${url}/gym-passes`,
              name: 'Gym Passes',
              icon: <ClassPassesIcon />,
              showInMenu: true,
              showAsTile: true,
              dataCy: 'gymClass_passes'
            });
          }
        }

        if (settings.swimmingClass) {
          tabs.push({
            link: `${url}/swim-timetable`,
            name: 'Swim Bookings',
            icon: <CalendarIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'swimmingClass_bookings'
          });

          if (returnClassConfigFieldValue(settings.classConfig, 'swimmingClass', 'allowedToBuyPasses')) {
            tabs.push({
              link: `${url}/swim-passes`,
              name: 'Swim Passes',
              icon: <ClassPassesIcon />,
              showInMenu: true,
              showAsTile: true,
              dataCy: 'swimmingClass_passes'
            });
          }
        }

        if (settings.tennisClass) {
          tabs.push({
            link: `${url}/tennis-timetable`,
            name: 'Tennis Bookings',
            icon: <CalendarIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'tennisClass_bookings'
          });

          if (returnClassConfigFieldValue(settings.classConfig, 'tennisClass', 'allowedToBuyPasses')) {
            tabs.push({
              link: `${url}/tennis-passes`,
              name: 'Tennis Passes',
              icon: <ClassPassesIcon />,
              showInMenu: true,
              showAsTile: true,
              dataCy: 'tennisClass_passes'
            });
          }
        }

        if (settings.massageClass) {
          tabs.push({
            link: `${url}/massage-timetable`,
            name: 'Massage Bookings',
            icon: <CalendarIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'massageClass_bookings'
          });

          if (returnClassConfigFieldValue(settings.classConfig, 'massageClass', 'allowedToBuyPasses')) {
            tabs.push({
              link: `${url}/massage-passes`,
              name: 'Massage Passes',
              icon: <ClassPassesIcon />,
              showInMenu: true,
              showAsTile: true,
              dataCy: 'massageClass_passes'
            });
          }
        }

        if (settings.personalTraining) {
          tabs.push({
            link: `${url}/personal-training-timetable`,
            name: 'PT Bookings',
            icon: <PTPackagesIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'personalTraining_bookings'
          });

          if (returnClassConfigFieldValue(settings.classConfig, 'personalTraining', 'allowedToBuyPasses')) {
            tabs.push({
              link: `${url}/personal-training-passes`,
              name: 'PT Passes',
              icon: <PTSessionIcon />,
              showInMenu: true,
              showAsTile: true,
              dataCy: 'personalTraining_passes'
            });
          }
        }

        if (settings.class) {
          tabs.push({
            name: 'Class Bookings',
            exact: true,
            link: `${url}/class-timetable`,
            icon: <CalendarIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'class_bookings'
          });
        }
        if (
          userData.customerType === 'class-pass-member' &&
          returnClassConfigFieldValue(settings.classConfig, 'class', 'allowedToBuyPasses')
        ) {
          tabs.push({
            name: 'Class Passes',
            link: `${url}/class-passes`,
            icon: <ClassPassesIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'class_passes'
          });
        }

        /*      if (settings.videoOnDemand) {
          tabs.push({
            link: `${url}/videos`,
            name: 'Videos',
            icon: <CalendarIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'videos'
          });
        }*/

        if (settings.liveStreaming) {
          tabs.push({
            link: `${url}/videos/live-stream-viewer`,
            name: 'Live Streams',
            icon: <CalendarIcon />,
            showInMenu: false,
            showAsTile: false,
            dataCy: 'live_stream'
          });
        }

        if (settings.faq) {
          tabs.push({
            link: `${url}/faq`,
            name: 'FAQ',
            icon: <PlansIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'faq'
          });
        }

        if (settings.kitList) {
          tabs.push({
            link: `${url}/kit-list`,
            name: 'Kit List',
            icon: <ClassesIcon />,
            showInMenu: true,
            showAsTile: true,
            dataCy: 'kit_list'
          });
        }
      }
      break;

    case 'trainer':
      tabs.push(
        {
          name: 'Clients',
          link: `${url}/clients`,
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'clients'
        },
        {
          name: 'My Timetable',
          link: `${url}/timetable/all`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'my_timetable'
        },
        {
          link: `${url}/staff-calendar`,
          name: 'Staff Calendar',
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'staff_calendar'
        }
      );

      if (settings.class) {
        tabs.push({
          name: 'Class Bookings',
          link: `${url}/class-timetable`,
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'class_bookings'
        });
      }

      if (settings.personalTraining) {
        tabs.push({
          name: 'PT Bookings',
          link: `${url}/personal-training-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'personalTraining_bookings'
        });
      }

      if (settings.induction) {
        tabs.push({
          name: 'Induction Bookings',
          link: `${url}/induction-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'induction_bookings'
        });
      }

      if (settings.gymClass) {
        tabs.push({
          name: 'Gym Bookings',
          link: `${url}/gym-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'gymClass_bookings'
        });
      }

      if (settings.swimmingClass) {
        tabs.push({
          name: 'Swim Bookings',
          link: `${url}/swim-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'swimmingClass_bookings'
        });
      }

      if (settings.tennisClass) {
        tabs.push({
          name: 'Tennis Bookings',
          link: `${url}/tennis-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'tennisClass_bookings'
        });
      }

      if (settings.massageClass) {
        tabs.push({
          name: 'Massage Bookings',
          link: `${url}/massage-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'massageClass_bookings'
        });
      }

      if (settings.faq) {
        tabs.push({
          name: 'FAQ',
          link: `${url}/faq`,
          icon: <PlansIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'faq'
        });
      }

      if (settings.kitList) {
        tabs.push({
          name: 'Kit List',
          link: `${url}/kit-list`,
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'kit_list'
        });
      }

      tabs.push({
        name: 'History',
        link: `${url}/class-history`,
        icon: <CalendarIcon />,
        showInMenu: true,
        showAsTile: true,
        dataCy: 'history'
      });

      tabs.push(
        /*        {
          link: `${url}/videos`,
          name: 'Videos',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'videos'
        },*/
        {
          link: `${url}/videos/live-stream`,
          name: 'Live Stream',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'live_stream'
        }
      );

      break;

    case 'admin':
      tabs.shift();
      tabs.push(
        {
          link: `${url}/settings`,
          name: 'Gym Settings',
          icon: <TrainersIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'gym_settings'
        },
        {
          link: `${url}/admins`,
          name: 'Admins',
          icon: <TrainersIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'admins'
        },
        {
          link: `${url}/trainers`,
          name: 'Trainers',
          icon: <TrainersIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'trainers'
        },
        {
          link: `${url}/clients`,
          name: 'Clients',
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'clients'
        },
        {
          link: `${url}/staff-calendar`,
          name: 'Staff Calendar',
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'staff_calendar'
        },
        {
          link: `${url}/class-history`,
          name: 'History',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'history'
        },
        {
          link: `${url}/search`,
          name: 'Search',
          icon: <TrainersIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'search'
        },
        {
          link: `${url}/reports`,
          name: 'Reports',
          icon: <PlansIcon data-cy="reportsTile" />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'reports'
        }
      );
      if (gymsFullAccess) {
        tabs.push({
          link: `${url}/bulk-management`,
          name: 'Bulk management',
          icon: <PlansIcon data-cy="bulkManagementTile" />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'bulk_management'
        });
      }

      if (settings.membershipProducts) {
        tabs.push({
          link: `${url}/membership-plans`,
          name: 'Membership Plans',
          icon: <PlansIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'membership_plans'
        });
      }

      tabs.push({
        link: `${url}/timetable/all`,
        name: 'All Bookings',
        icon: <CalendarIcon />,
        showInMenu: true,
        showAsTile: true,
        dataCy: 'all_bookings'
      });

      if (settings.class) {
        tabs.push({
          link: `${url}/class-timetable`,
          name: 'Class Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'class_bookings'
        });
      }

      if (settings.personalTraining) {
        tabs.push({
          link: `${url}/personal-training-timetable`,
          name: 'PT Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'personalTraining_bookings'
        });
      }

      if (settings.induction) {
        tabs.push({
          link: `${url}/induction-timetable`,
          name: 'Induction',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'induction_bookings'
        });
      }

      if (settings.gymClass) {
        tabs.push({
          link: `${url}/gym-timetable`,
          name: 'Gym Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'gymClass_bookings'
        });
      }

      if (settings.swimmingClass) {
        tabs.push({
          link: `${url}/swim-timetable`,
          name: 'Swim Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'swimmingClass_bookings'
        });
      }

      if (settings.tennisClass) {
        tabs.push({
          link: `${url}/tennis-timetable`,
          name: 'Tennis Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'tennisClass_bookings'
        });
      }

      if (settings.massageClass) {
        tabs.push({
          link: `${url}/massage-timetable`,
          name: 'Massage Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'massageClass_bookings'
        });
      }

      if (returnGymsByAccess(gyms, userData).length > 1) {
        tabs.unshift({
          link: '/gym',
          name: 'Select Gym',
          exact: true,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'select_gym'
        });
      } else if (gymsFullAccess) {
        tabs.unshift({
          link: '/gym/add-gym',
          name: 'Add gym',
          exact: true,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'add_gym'
        });
      }

      if (settings.faq) {
        tabs.push({
          link: `${url}/faq`,
          name: 'FAQ',
          icon: <PlansIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'faq'
        });
      }

      if (settings.kitList) {
        tabs.push({
          link: `${url}/kit-list`,
          name: 'Kit List',
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'kit_list'
        });
      }

      if (settings.videoOnDemand) {
        tabs.push({
          link: `${url}/videos`,
          name: 'Videos',
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: true,
          dataCy: 'videos'
        });
      }

      if (settings.liveStreaming) {
        tabs.push({
          link: `${url}/videos/live-stream`,
          name: 'Live Stream',
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'live_stream'
        });
      }

      if (settings.coupon) {
        tabs.push({
          link: `${url}/coupons`,
          name: 'Coupons',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: false,
          dataCy: 'coupons'
        });
      }

      break;
    default:
      return tabs;
  }

  return tabs.filter((elem) => elem[type] === true);
};
