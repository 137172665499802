import { Link } from 'react-router-dom';
import Logo from '../../../components/UI/Logo/Logo';
const links = [
  {
    label: 'Contact',
    url: '/contact',
    dataCy: 'footer_contact'
  },
  {
    label: 'Privacy Policy',
    url: '/privacy-policy',
    dataCy: 'footer_privacy_policy'
  },
  {
    label: 'Terms of Website',
    url: '/terms-of-website',
    dataCy: 'footer_terms_of_website'
  }
];

export default () => (
  <div
    data-cy="footer"
    className="block w-full md:h-[80px] bg-primary md:grid md:grid-cols-[300px_1fr] md:items-center p-4 z-20">
    <Logo dark={true} className="w-[300px]" />

    <div className="block md:flex mt-4 md:mt-0 md:mr-0 md:ml-auto">
      {links.map(({ label, url, dataCy }, i) => (
        <Link
          key={i}
          className="block my-2 md:my-0 text-white mr-6 hover:font-bold hover:text-white hover:no-underline"
          to={url}
          data-cy={dataCy}>
          {label}
        </Link>
      ))}
    </div>
  </div>
);
