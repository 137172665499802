import { useContext } from 'react';
import SingleAdmin from './SingleTrainer/SingleAdmin';
import Loading from '../../../components/Loading/Loading';
import { useQuery } from '@tanstack/react-query';
import { fetchAdmins } from '../../../apiFunctions/apiFunctions';
import { AppContext } from '../../../context/App/AppContext';
import { useNavigate } from 'react-router-dom';
import Notification from '../../../components/UI/Notification/Notification';
import Title from '../../../components/UI/Title/Title';
import Button from '../../../components/UI/Button/Button';
import Filters from '../../../components/Filters/Filters';
import useFilters from '../../../components/Filters/useFilters';
import NoResultsFound from '../../../components/UI/NoResultsFound/NoResultsFound';

const ManageAdmins = () => {
  const { selectedGym } = useContext(AppContext);
  const navigate = useNavigate();
  const { filters, onFilters, filteredData, setFiltersInitialData } = useFilters();

  const { isLoading: isAdminsListLoading, error: isAdminsListError } = useQuery(
    ['adminsList', selectedGym._id],
    () => fetchAdmins(selectedGym._id),
    {
      onSuccess: (data) => setFiltersInitialData(data)
    }
  );

  const renderAdminList = () => {
    if (isAdminsListLoading) {
      return <Loading />;
    }
    if (isAdminsListError) {
      return <Notification message={'Something went wrong'} status="critical" />;
    }

    if (filteredData.length === 0) {
      return <NoResultsFound text={'No admins'} />;
    }

    return filteredData.map((admin) => <SingleAdmin singleAdmin={admin} key={admin._id} />);
  };

  return (
    <>
      <div className="titleAndBtnContainer">
        <Title title={'Manage admins'} />
        <Button label="Add New" secondary onClick={() => navigate(`/gym/${selectedGym._id}/admins/add`)} />
      </div>
      <Filters
        open
        filtersTypes={['userName']}
        onFilters={onFilters}
        filters={filters}
        initialState={[{ name: 'userName', value: '' }]}
      />

      {renderAdminList()}
    </>
  );
};

export default ManageAdmins;
