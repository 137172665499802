import { useContext, useState } from 'react';
import SingleTrainer from '../../../ManageUsers/ManageTrainers/SingleTrainer/SingleTrainer';
import SingleAdmin from '../../../ManageUsers/ManageAdmins/SingleTrainer/SingleAdmin';
import Loading from '../../../../components/Loading/Loading';
import { useMutation } from '@tanstack/react-query';
import { searchAdminsByName, searchTrainersByName } from '../../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import { Input } from 'antd';
import Button from '../../../../components/UI/Button/Button';

const SearchAdminTrainers = ({ role }) => {
  const { openNotification } = useContext(NotificationContext);
  const [name, setName] = useState('');
  const [searchData, setSearchData] = useState(null);

  const mutateSearch = useMutation(() => (role === 'admin' ? searchAdminsByName(name) : searchTrainersByName(name)), {
    onSuccess: (data) => setSearchData(data),
    onError: () => openNotification('default', 'error')
  });

  const search = (
    <div className="mb-6">
      <div className="mt-6 font-bold">Please enter name or last name</div>
      <Input value={name} onChange={(e) => setName(e.target.value)} />
      <Button fullWidth primary label="Search" onClick={mutateSearch.mutate} />
    </div>
  );

  const searchResult = () => {
    if (searchData) {
      if (searchData.length) {
        return searchData.map((user) => {
          if (role === 'admin') {
            return <SingleAdmin singleAdmin={user} key={user._id} refreshData={mutateSearch.mutate} />;
          } else {
            return <SingleTrainer key={user._id} singleTrainer={user} refreshData={mutateSearch.mutate} />;
          }
        });
      } else {
        return <h3>No {role === 'admin' ? 'admins' : 'trainers'}</h3>;
      }
    }
    return null;
  };

  return (
    <div>
      {search}
      {searchResult()}
      {mutateSearch.isLoading && <Loading />}
    </div>
  );
};

export default SearchAdminTrainers;
