import { useContext } from 'react';
import Title from '../../../components/UI/Title/Title';
import { returnErrorFromResponse } from '../../../shared/utility';
import RenderForm from '../../../components/Forms/RenderForm';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { useMutation } from '@tanstack/react-query';
import { onResetPassword } from '../../../apiFunctions/apiFunctions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isValueCorrect } from '../../../shared/userFunctions';
import { goToHomePage } from '../../../routes/AppRoutes';

const ResetPassword = () => {
  const { openNotification } = useContext(NotificationContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: (formData) => onResetPassword(formData.password, searchParams.get('token')),
    onSuccess: () => {
      openNotification(null, 'success', 'Password successfully changed!');
      navigate('/login');
    },
    onError: (err) => {
      openNotification(null, 'error', returnErrorFromResponse(err));
    }
  });

  if (isValueCorrect(searchParams.get('token'))) {
    return (
      <div className="body-container max-w-[600px] mx-auto">
        <Title title="Your new password" />

        <RenderForm
          formFields={['password', 'confirmPassword']}
          onFinish={mutate}
          btnLabel="Save new password"
          btnIsLoading={isLoading && !isError}
        />
      </div>
    );
  }
  return goToHomePage();
};
export default ResetPassword;
