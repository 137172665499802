import { useContext, useState } from 'react';
import Button from '../../UI/Button/Button';
import { Modal } from 'antd';
import ModalButtonsContainer from '../../UI/ModalButtonsContainer/ModalButtonsContainer';
import ClientProfileAddQr from '../ClientProfileAddQr';
import { editUserData, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { useMutation } from '@tanstack/react-query';
import { classNames, returnErrorFromResponse } from '../../../shared/utility';
import UserSettings from '../../../containers/ManageUsers/ManageUserSettings/User/UserSettings';

export default function ClientProfileOperationalButtons({ showQRBtn, selectedClient }) {
  const { openNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const { mutate: onUpdateSelectedClient, isLoading: onUpdateSelectedClientIsLoading } = useMutation(
    ['selectedClient', selectedClient._id],
    (data) => editUserData(selectedClient._id, data),
    {
      onSuccess: () => {
        openNotification(null, 'success', modalType === 'qr' ? 'Additional QR code saved' : 'Successfully updated!');
        refreshFetchFunctions(['selectedClient', selectedClient._id]);
        setShowModal(false);
      },
      onError: () => {
        openNotification('default', 'error');
      }
    }
  );

  const renderModalContent =
    modalType === 'qr' ? (
      <ClientProfileAddQr
        handleQrCodeScan={(scan) => onUpdateSelectedClient({ qrCode: scan })}
        handleQrCodeError={(error) => openNotification(null, 'error', returnErrorFromResponse(error))}
      />
    ) : modalType === 'edit' ? (
      <UserSettings selectedClient={selectedClient} onCancel={() => setShowModal(false)} />
    ) : (
      <>
        <div className="text-center mb-2">Do you want to deactivate chosen user?</div>

        <ModalButtonsContainer isLoading={onUpdateSelectedClientIsLoading}>
          <Button label="Yes" secondary onClick={() => onUpdateSelectedClient({ status: 'inactive' })} />
          <Button label="Cancel" primary onClick={() => setShowModal(false)} />
        </ModalButtonsContainer>
      </>
    );

  return (
    <div>
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        {renderModalContent}
      </Modal>
      <div className={classNames('md:grid  gap-2 mt-6', showQRBtn ? 'grid-cols-3' : 'grid-cols-2')}>
        {showQRBtn && (
          <Button
            fullWidth
            label="Add extra QR code"
            primary
            onClick={() => {
              setShowModal(true);
              setModalType('qr');
            }}
          />
        )}

        <Button
          fullWidth
          label="Edit client"
          secondary={true}
          onClick={() => {
            setShowModal(true);
            setModalType('edit');
          }}
        />

        <Button
          red
          fullWidth
          label="Deactivate client"
          onClick={() => {
            setShowModal(true);
            setModalType('delete');
          }}
        />
      </div>
    </div>
  );
}
