import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../context/App/AppContext';
import useFilters from '../../../components/Filters/useFilters';
import { useQuery } from '@tanstack/react-query';
import { fetchPayrollReport } from '../../../apiFunctions/apiFunctions';
import Loading from '../../../components/Loading/Loading';
import Notification from '../../../components/UI/Notification/Notification';
import Table from '../../../components/Table/Table';
import Filters from '../../../components/Filters/Filters';
import { isValueCorrect } from '../../../shared/userFunctions';

const PayrollReports = ({ setCSVData }) => {
  const { selectedGym } = useContext(AppContext);
  const { onFilters, filters } = useFilters();
  const [filteredData, setFilteredData] = useState([]);
  const apiRequestFields = ['startDate', 'endDate', 'selectTrainer', 'classTypesCheckboxes'];

  const isFetchingEnabled = (filtersData) => {
    return (
      filtersData &&
      isValueCorrect(filtersData.startDate) &&
      isValueCorrect(filtersData.endDate) &&
      isValueCorrect(filtersData.trainerSelector)
    );
  };

  useEffect(
    () => {
      if (filters !== null && Object.keys(filters).length > 0) {
        setCSVData({
          data: filteredData,
          gymName: selectedGym.name,
          reportType: 'payrollReport'
        });
      }
    }, //eslint-disable-next-line
    [filteredData]
  );

  useEffect(() => {
    if (Object.keys(filters).length === 2) {
      setFilteredData([]);
    }
  }, [filters]);

  const { isLoading, isError } = useQuery(['reportPayroll', filters], () => fetchPayrollReport(filters), {
    enabled: isFetchingEnabled(filters),
    onSuccess: (result) => {
      if (result !== undefined && result.length > 0) {
        let arr = [];
        result.map((elm) =>
          elm.classes.map((element) => {
            arr.push({
              ...element,
              trainer: { name: elm.trainer.split(/\s+/g)[0], last_name: elm.trainer.split(/\s+/g)[1], _id: elm._id },
              __t: element.classType
            });
          })
        );
        setFilteredData(arr);
      } else {
        setFilteredData([]);
      }
    }
  });

  const renderResult = () => {
    if (isLoading && isFetchingEnabled(filters)) {
      return <Loading />;
    }
    if (isError) {
      return <Notification defaultMsg />;
    }
    return <Table fields={['name', 'classType', 'gym', 'trainer', 'classDate', 'classTime']} data={filteredData} />;
  };

  return (
    <div>
      <Filters
        open
        filtersTypes={['startDate', 'endDate', 'trainerSelector', 'classTypesCheckboxes']}
        filters={filters}
        onFilters={onFilters}
        initialState={[
          {
            name: 'trainerSelector',
            value: undefined,
            multiple: true
          },
          {
            name: 'classTypesCheckboxes',
            gym: selectedGym._id,
            allSelected: true,
            row: true
          }
        ]}
        apiRequestFields={apiRequestFields}
      />
      {renderResult()}
    </div>
  );
};
export default PayrollReports;
