import { useContext, useState } from 'react';

import StepZero from './StepZero/StepZero';
import StepOne from './StepOne/StepOne';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchGymDetails } from '../../../apiFunctions/apiFunctions';
import { isValueCorrect } from '../../../shared/userFunctions';
import Loading from '../../../components/Loading/Loading';
import { NotificationContext } from '../../../context/Notification/NotificationContext';

const Signup = () => {
  const { openNotification } = useContext(NotificationContext);
  const [currentStep, setCurrentStep] = useState(-1);
  const [accountType, setAccountType] = useState(null);
  const navigate = useNavigate();
  const { gymId } = useParams();

  const { data: gymData, isLoading: gymDataIsLoading } = useQuery({
    queryKey: ['gymData'],
    queryFn: () => fetchGymDetails(gymId),
    enabled: isValueCorrect(gymId),
    onSuccess: (data) => {
      if (data === null || data === undefined) {
        openNotification(null, 'error', 'Provided gymId is incorrect.');
        navigate('/');
      } else if (!data.settings.membershipProducts) {
        setAccountType('class-pass-member');
        setCurrentStep(1);
      } else {
        setCurrentStep(0);
      }
    }
  });
  if (gymDataIsLoading || currentStep === -1) {
    return (
      <div className={'body-container'}>
        <Loading />
      </div>
    );
  }

  const handleNextStep = () => {
    if (currentStep === 2) {
      navigate('/gym');
    } else {
      setCurrentStep((state) => state + 1);
    }
  };

  const handleStepZeroChange = (type) => {
    setAccountType(type);
    handleNextStep();
  };

  return (
    <div className={'body-container'}>
      {currentStep === 0 ? (
        <StepZero setAccountType={handleStepZeroChange} />
      ) : currentStep === 1 ? (
        <StepOne accountType={accountType} handleNextStep={handleNextStep} gymData={gymData} />
      ) : null}
    </div>
  );
};

export default Signup;
