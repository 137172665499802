import { useContext, useEffect, useState } from 'react';
import classes from './StaffCalendar.module.scss';
import moment from 'moment';
import DayView from './DayView/DayView';
import WeekView from './WeekView/WeekView';
import Loading from '../../../components/Loading/Loading';
import { DatePicker, Modal } from 'antd';
import ClassDetailModal from './ClassDetailModal/ClassDetailModal';
import UsersDayEventModal from './UsersDayEventModal/UsersDayEventModal';
import { useQuery } from '@tanstack/react-query';
import { fetchTrainersEvents } from '../../../apiFunctions/apiFunctions';
import { AppContext } from '../../../context/App/AppContext';
import Button from '../../../components/UI/Button/Button';
import Icon from '../../../components/UI/Icon/Icon';
import Notification from '../../../components/UI/Notification/Notification';
import Title from '../../../components/UI/Title/Title';
import Filters from '../../../components/Filters/Filters';
import useFilters from '../../../components/Filters/useFilters';

const StaffCalendar = () => {
  const { selectedGym, trainersList, trainersListIsLoading, trainersListIsError } = useContext(AppContext);
  const { onFilters, filters } = useFilters();
  const [currentView, setCurrentView] = useState('Day');
  const [showDayModal, setShowDayModal] = useState(false);
  const [showClassDetail, setShowClassDetail] = useState(null);
  const [dayTimetable, setDayTimetable] = useState({ date: moment().format('DD.MM.yyyy') });
  const [weekTimeTable, setWeekTimeTable] = useState({
    startDate: moment().startOf('isoWeek').format('DD.MM.YYYY'),
    endDate: moment().endOf('isoWeek').format('DD.MM.YYYY')
  });
  const [dayEvents, setDayEvents] = useState([]);
  const [searchParams, setSearchParams] = useState(null);
  const [timetable, setTimetable] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState([]);

  useEffect(() => {
    let data = {
      type: currentView.toLowerCase(),
      date: moment(dayTimetable.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      startDate: moment(weekTimeTable.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      endDate: moment(weekTimeTable.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
    };

    if (selectedTrainer.length > 0) {
      data['trainers'] = selectedTrainer;
    }
    setSearchParams(data);
  }, [currentView, weekTimeTable, dayTimetable, selectedTrainer]);

  const { isLoading: eventsIsLoading, isError: eventsIsError } = useQuery({
    queryKey: ['trainerEvents', selectedGym._id, searchParams],
    queryFn: () => fetchTrainersEvents(selectedGym._id, searchParams),
    onSuccess: (data) => {
      const filteredTrainers =
        selectedTrainer.length === 0 ? trainersList : trainersList.filter((elm) => selectedTrainer.includes(elm._id));
      const availableTrainers = filteredTrainers.map((trainer) => {
        return {
          leader: {
            _id: trainer._id,
            name: trainer.name,
            last_name: trainer.last_name
          },
          classes: []
        };
      });
      setTimetable(data.concat(availableTrainers));
    }
  });

  if (eventsIsLoading || trainersListIsLoading) {
    return <Loading />;
  }

  if (eventsIsError || trainersListIsError) {
    return <Notification defaultMsg />;
  }
  const openClassDetailModal = (classObj) => setShowClassDetail(classObj);
  const dayViewInModal = () => (
    <Modal close={() => setShowDayModal(false)} open={showDayModal}>
      <UsersDayEventModal
        timetable={dayEvents}
        showClassDetail={openClassDetailModal}
        onClose={() => setShowDayModal(false)}
      />
    </Modal>
  );

  const classDetailModal = () => (
    <Modal close={() => setShowClassDetail(null)} open={showClassDetail !== null}>
      <ClassDetailModal event={showClassDetail} onClose={() => setShowClassDetail(null)} />
    </Modal>
  );
  const changeView = (view) => setCurrentView(view);

  const nextPreviousClicked = (type) => {
    if (currentView === 'Week') {
      const startDate = moment(type === 'previous' ? weekTimeTable.startDate : weekTimeTable.endDate, 'DD.MM.YYYY')
        .add(type === 'previous' ? -1 : +1, 'days')
        .startOf('isoWeek')
        .format('DD.MM.YYYY');
      const endDate = moment(startDate, 'DD.MM.YYYY').endOf('isoWeek').format('DD.MM.YYYY');
      const newWeekTimeTable = { ...weekTimeTable };
      newWeekTimeTable.startDate = startDate;
      newWeekTimeTable.endDate = endDate;
      setWeekTimeTable(newWeekTimeTable);
    } else {
      const nextDate = moment(dayTimetable.date, 'DD.MM.YYYY')
        .add(type === 'previous' ? -1 : +1, 'days')
        .format('DD.MM.YYYY');
      const newDayTimetable = { ...dayTimetable };
      newDayTimetable.date = nextDate;
      setDayTimetable(newDayTimetable);
    }
  };
  const dateChange = (date) => {
    if (currentView === 'Week') {
      const startDate = moment(date).startOf('isoWeek').format('DD.MM.YYYY');
      const endDate = moment(date).endOf('isoWeek').format('DD.MM.YYYY');
      let newWeekTimetable = { ...weekTimeTable };
      newWeekTimetable.startDate = startDate;
      newWeekTimetable.endDate = endDate;
      setWeekTimeTable(newWeekTimetable);
    } else {
      let newDayTimetable = { ...dayTimetable };
      newDayTimetable.date = date.format('DD.MM.YYYY');
      setDayTimetable(newDayTimetable);
    }
  };

  const calendarHeader = () => (
    <div className="grid grid-cols-3 items-center">
      <DatePicker id="id" format="DD.MM.YYYY" onChange={dateChange} />
      <div className="flex justify-center">
        <Icon name={'previous'} onClick={() => nextPreviousClicked('previous')} />
        <span className="font-bold">
          {currentView === 'Day'
            ? dayTimetable.date
            : `${weekTimeTable.startDate.substring(0, 2)}-${weekTimeTable.endDate}`}
        </span>
        <Icon name={'next'} onClick={() => nextPreviousClicked('next')} />
      </div>

      <div className="grid grid-cols-2">
        <Button label="Day" onClick={() => changeView('Day')} primary={currentView === 'Day'} fullWidth />
        <Button label="Week" onClick={() => changeView('Week')} primary={currentView === 'Week'} fullWidth />
      </div>
    </div>
  );
  const openDayInModal = (event) => {
    setShowDayModal(true);
    setDayEvents(event);
  };

  const calendar = () => {
    return (
      <div className={classes.flexContainer}>
        {currentView === 'Day' ? (
          <DayView timetable={timetable} showClassDetail={openClassDetailModal} />
        ) : (
          <WeekView timetable={timetable} openDayInModal={openDayInModal} />
        )}
      </div>
    );
  };

  return (
    <div data-cy={'staff_calendar'}>
      <Title title={'staff calendar'} />
      {dayViewInModal()}
      {classDetailModal()}
      {calendarHeader()}
      <Filters
        open
        filtersTypes={['trainerSelector']}
        filters={filters}
        onFilters={(filtersData) => {
          setSelectedTrainer(filtersData.trainerSelector);
          onFilters(filtersData);
        }}
        initialState={[{ name: 'trainerSelector', multiple: true, value: [] }]}
      />

      {calendar()}
    </div>
  );
};

export default StaffCalendar;
