import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import ProfilePaymentHistory from '../../UI/ProfilePaymentHistory/ProfilePaymentHistory';
import ClientProfilePaymentHistoryTabs from '../ClientProfilePaymentHistoryTabs/ClientProfilePaymentHistoryTabs';
import './ClientProfilePayments.scss';

export default function ClientProfilePayments({ selectedClient }) {
  const lastPaymentInfo = selectedClient.paymentHistory ? selectedClient.paymentHistory[0] : null;
  return (
    <>
      <div>
        <h3 className="uppercase mt-2">Payments</h3>
        <div className="last-payment">
          <ProfileTextWithLabel
            label="Last payment"
            text={
              lastPaymentInfo ? <ProfilePaymentHistory lastPaymentInfo={lastPaymentInfo} /> : 'No payments received yet'
            }
          />
        </div>

        <ClientProfilePaymentHistoryTabs paymentHistory={selectedClient.paymentHistory} />
      </div>
    </>
  );
}
