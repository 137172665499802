import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../context/App/AppContext';
import NoResultsFound from '../../../../components/UI/NoResultsFound/NoResultsFound';
import Title from '../../../../components/UI/Title/Title';
import Icon from '../../../../components/UI/Icon/Icon';
import Loading from '../../../../components/Loading/Loading';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchVideoById, videoPassCompleted } from '../../../../apiFunctions/apiFunctions';
import Thumbnail from '../../../ManageVideos/ShowVideos/Thumbnail/Thumbnail';
import Notification from '../../../../components/UI/Notification/Notification';
import { isValueCorrect } from '../../../../shared/userFunctions';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import Button from '../../../../components/UI/Button/Button';

const VideosListForVirtualPass = () => {
  const { userData, gymId } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const { id } = useParams();
  const [lastWatchedVideoIndex, setLastWatchedVideoIndex] = useState(-1);

  const passPurchase = userData.products.filter(
    (elm) => elm.productType === 'videoPass' && elm.status !== 'pending' && elm.productId._id === id
  );
  const selectedPass = passPurchase.map((elm) => ({ ...elm.productId }))[0];

  useEffect(
    () => {
      if (selectedPass.enforceVideoOrder) {
        const lastWatchedVidId = localStorage.getItem('VOD');

        if (isValueCorrect(lastWatchedVidId)) {
          setLastWatchedVideoIndex(selectedPass.videos.indexOf(lastWatchedVidId));
        }
      }
    },
    //eslint-disable-next-line
    []
  );

  const {
    data: passVideos,
    isLoading: passVideosIsLoading,
    error: passVideosIsError
  } = useQuery([selectedPass.videos], () => Promise.all(selectedPass.videos.map((id) => fetchVideoById(id))), {
    enabled: selectedPass && !!selectedPass.videos.length
  });

  const { mutate: onFinishedWatchingVideoPass, isLoading: onFinishedWatchingVideoPassIsLoading } = useMutation({
    mutationFn: () => videoPassCompleted(passPurchase[0]._id),
    onSuccess: () => {
      openNotification(null, 'success', 'You have successfully confirmed that you have watched all the videos!');
    },
    onError: ({ response }) => {
      if (response?.data?.errors[0]?.msg === 'you have already watched all the videos') {
        openNotification(null, 'warning', 'Your confirmation is already registered');
      } else {
        openNotification(null, 'error', 'An error occurred submitting your confirmation');
      }
    }
  });

  if (!isValueCorrect(selectedPass) || userData.products.length === 0) {
    return <NoResultsFound />;
  }

  if (passVideosIsError) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }
  if (passVideosIsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className={'titleAndBtnContainer'}>
        <Title title={selectedPass.name} subtitle={selectedPass.description} />
        <Button
          secondary
          onClick={onFinishedWatchingVideoPass}
          label={'Confirm completed'}
          show={lastWatchedVideoIndex === selectedPass.videos.length - 1}
          isLoading={onFinishedWatchingVideoPassIsLoading}
        />
      </div>

      <div className={'flex'}>
        <Icon name={'info'} cursorPointer={false} /> {selectedPass.explainer}
      </div>

      <div className={'flex flex-wrap justify-around'}>
        {passVideos.map((video, index) => (
          <Thumbnail
            className={'w-[250px]'}
            key={`${video._id}-`}
            video={video}
            gymId={gymId}
            locked={selectedPass.enforceVideoOrder ? index > lastWatchedVideoIndex + 1 : false}
            isLastWatchedVideo={selectedPass.enforceVideoOrder ? index === lastWatchedVideoIndex : true}
          />
        ))}
      </div>
    </div>
  );
};

export default VideosListForVirtualPass;
