import { useLayoutEffect, useState } from 'react';

const useWindowSize = () => {
  const [size, setSize] = useState(null);

  useLayoutEffect(() => {
    function updateSize() {
      const bodyWidth = getElementWidthById('body');
      setSize({ width: window.innerWidth, height: window.innerHeight, bodyWidth: bodyWidth - 2 * 0.05 * bodyWidth });
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const getElementWidthById = (id) => document.getElementById(id)?.offsetWidth;

  const isElementOverflowingWindowScreen = (id) => {
    const elemWidth = getElementWidthById(id);
    const bigScreenValue = size?.width > 1180 ? 1180 : size?.width * 0.9;
    return elemWidth > bigScreenValue;
  };

  const isElementWiderThenValue = (id, value = 10) => {
    const elemWidth = getElementWidthById(id);
    return elemWidth > value;
  };

  return {
    ...size,
    getElementWidthById,
    isElementOverflowingWindowScreen,
    isElementWiderThenValue
  };
};
export default useWindowSize;
