import { useContext } from 'react';
import { Dropdown } from 'antd';
import Icon from '../../UI/Icon/Icon';
import { AppContext } from '../../../context/App/AppContext';
import { Link } from 'react-router-dom';

const UserNavigation = () => {
  const { onLogout, userData } = useContext(AppContext);

  const items = [
    userData.isUser &&
      userData.isActive && {
        key: 'settings',
        label: <Link to={'/user/settings'}>Settings</Link>
      },
    userData.isActiveMembershipUser && {
      key: 'membership',
      label: <Link to={'/gym/membership'}>Membership</Link>
    },
    userData.isUser && {
      key: 'terms',
      label: <Link to={'/terms-of-website'}>Terms of website</Link>
    },
    userData.isUser && {
      key: 'privacyPolicy',
      label: <Link to={'/privacy-policy'}>Privacy policy</Link>
    },
    userData.isUser && {
      key: 'termsOfService',
      label: <Link to={'/gym/terms-of-service'}>Terms of service</Link>
    },
    userData.isUser && {
      key: 'healthStatement',
      label: <Link to={'/health-statement'}>Health statement</Link>
    },
    {
      key: 'logout',
      label: <div onClick={onLogout}>Logout</div>
    }
  ];

  return (
    <Dropdown menu={{ items }}>
      <a onClick={(e) => e.preventDefault()}>
        <Icon name={'user'} size={30} />
      </a>
    </Dropdown>
  );
};

export default UserNavigation;
