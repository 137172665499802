import { useEffect, useState } from 'react';
import { returnFieldsArray, returnFieldValue } from '../../constants/fieldsConstatnts';
import { Checkbox, Dropdown } from 'antd';
import Button from '../UI/Button/Button';
import TableActionColumn from './TableActionColumn';
import useWindowSize from '../../hooks/useWindowSize';
import NoResultsFound from '../UI/NoResultsFound/NoResultsFound';

//TODO: add hover on table row

const Table = ({
  fields = [],
  fieldsToShow = [],
  showHideFieldsBtn = false,
  additionalData = {},
  data = [],
  actionType = '',
  children = null
}) => {
  const { bodyWidth } = useWindowSize();
  const [customTableFields, setCustomTableFields] = useState([{ name: 'name', label: 'name' }]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const showActionColumn = fields.indexOf('actionColumn') >= 0;
  const actionColumnWidth = 100;

  useEffect(
    () => {
      const tableFields = returnFieldsArray(fields, fieldsToShow);
      setCustomTableFields(tableFields);
      checkIsMobileView(tableFields);
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      checkIsMobileView(customTableFields);
    }, //eslint-disable-next-line
    [bodyWidth, customTableFields]
  );

  const checkIsMobileView = () => {
    const tableWidth = bodyWidth - actionColumnWidth;
    const fieldWidth = tableWidth / customTableFields.filter((elm) => elm.show).length - 1;
    const areTableLabelsOverflowing =
      customTableFields.filter((elm) => elm.show && elm.minWidth > fieldWidth).length > 0;
    setIsMobile(areTableLabelsOverflowing);
  };

  const customTableFieldsSelector = () => {
    const onFieldChange = ({ key }) => {
      if (key < customTableFields.length && !['action', 'actionColumn'].includes(customTableFields[key].name)) {
        let newState = [...customTableFields];
        newState[key].show = !newState[key].show;
        checkIsMobileView(newState);
        return setCustomTableFields(newState);
      }
    };

    const items = customTableFields.map((elm, index) => ({
      key: index,
      label: ['action', 'actionColumn'].includes(elm.name) ? null : (
        <>
          <Checkbox checked={elm.show} className="pr-2">
            {elm.label}
          </Checkbox>
        </>
      )
    }));
    items.push({
      key: items.length,
      label: (
        <div className="p-1 text-center font-bold " onClick={() => setIsDropdownOpen(false)}>
          Close
        </div>
      )
    });

    return (
      <Dropdown
        trigger={['click']}
        menu={{ items, onClick: onFieldChange }}
        open={isDropdownOpen}
        onClick={() => setIsDropdownOpen((state) => !state)}>
        <Button primary label={'Show/hide fields'} className="grid justify-end" />
      </Dropdown>
    );
  };

  const renderTable =
    data.length > 0 ? (
      isMobile ? (
        <div>
          <div>
            {data.map((element, i) => (
              <div key={i} className="flex justify-between items-center listItem">
                <div>
                  {customTableFields
                    .filter((elm) => elm.show && !['action', 'actionColumn'].includes(elm.name))
                    .map((elm, i) => (
                      <div key={i} className="grid grid-cols-[100px_1fr] gap-x-2">
                        <div className="font-bold">{elm.label}:</div>
                        <div className="">{returnFieldValue(elm, element)}</div>
                      </div>
                    ))}
                </div>
                {showActionColumn ? (
                  <TableActionColumn actionType={actionType} data={{ ...additionalData, element }} />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${
              customTableFields.filter((elm) => elm.show).length - 1
            }, minmax(20px, 1fr)) ${actionColumnWidth}px`
          }}>
          {customTableFields
            .filter((elm) => elm.show)
            .map(({ label }, i) => (
              <div key={i} className="tableHeader">
                {label}
              </div>
            ))}
          {data.map((element) =>
            customTableFields
              .filter((elm) => elm.show)
              .map((elm, index) => (
                <div key={index} className="tableItem">
                  {showActionColumn &&
                  index > 0 &&
                  index % (customTableFields.filter((elm) => elm.show).length - 1) === 0 ? (
                    <TableActionColumn actionType={actionType} data={{ ...additionalData, element }} />
                  ) : (
                    returnFieldValue(elm, element)
                  )}
                </div>
              ))
          )}
        </div>
      )
    ) : (
      <NoResultsFound />
    );

  return (
    <div>
      {showHideFieldsBtn ? customTableFieldsSelector() : null}
      {children}
      {renderTable}
    </div>
  );
};

export default Table;
