import { useContext } from 'react';
import Button from '../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/App/AppContext';

const cards = [
  {
    title: 'Gym Inductions',
    description:
      'Our expert team of trainers are looking forward to seeing new residents for their upcoming gym induction sessions. We pride ourselves in ensuring all residents leave their gym inductions feeling motivated, educated and welcomed into their new residential fitness space. Our trainers will provide the required safety and gym equipment information needed to make the most out of your residential gym.',

    children: (
      <Button
        className={'p-6 absolute bottom-0 w-full'}
        secondary
        fullWidth
        label={'Read more'}
        onClick={() => window.open('https://m8group.co.uk/wp-content/uploads/2023/08/motive8-Personal-Training.pdf')}
      />
    )
  },
  {
    title: 'Personal Training',
    description:
      '1-1 and group sessions available at your doorstep. Our team specialises in providing a tailored service to ensure you achieve your desired fitness and health goals. Contact us today on inductions@m8group.co.uk to book your free consultation so we can understand your needs in full, enabling us to connect you with the most suitable trainer for maximum results.',
    children: (
      <Button
        className={'p-6 absolute bottom-0 w-full'}
        secondary
        fullWidth
        label={'Read more'}
        onClick={() => window.open('https://m8group.co.uk/wp-content/uploads/2023/08/motive8-Personal-Training.pdf')}
      />
    )
  },
  {
    title: 'Sign up for our newsletter',
    description:
      'To stay on top of upcoming fitness trends, as well as building a better understanding of key training, nutrition and health related topics, ensure to opt in to our monthly Fitness Newsletter. Each month, our expert team of trainers and instructors work together to produce newsletters full of valuable content for our readers.',
    children: (
      <div className={'mt-4 p-6 absolute bottom-0 w-full'}>
        <div className={'font-bold uppercase'}> Sign up for our newsletter!</div>
        <div className={'grid grid-cols-2 mt-2 gap-x-3'}>
          <Button
            fullWidth
            secondary
            label={'London'}
            onClick={() => window.open('https://motive8.activehosted.com/f/5')}
          />
          <Button
            fullWidth
            secondary
            label={'Leeds'}
            onClick={() => window.open('https://motive8.activehosted.com/f/7')}
          />
        </div>
      </div>
    )
  }
];
const Homepage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AppContext);
  const hero = <img id="hero-img" className="homepage_hero" src={require('../../assets/hero-image.jpg')} alt="hero" />;

  const loginSignUpCard = (
    <div className="mx-8 md:mr-8 md:ml-auto md:w-[40%] mt-[-200px] relative p-8 bg-primary text-white">
      <div className="text-2xl pb-2 border-b-2 border-white mb-4">Login!</div>
      <div className="mb-8">
        If you would like to book an induction please contact us at inductions@m8group.co.uk or call 0800 028 0198.
      </div>
      <Button
        label={isAuthenticated ? 'dashboard' : 'login'}
        onClick={() => navigate(isAuthenticated ? `/gym` : `/login`)}
        secondary
        fullWidth
      />
    </div>
  );
  const cardsContainer = (
    <div className="mt-20 sm:flex justify-center flex-wrap mb-14 gap-8  ">
      {cards.map(({ title, description, url = null, children = null }, i) => (
        <div
          key={i}
          className="text-center mb-8 w-full sm:mb-0 md:w-[350px] bg-neutral-200 md:h-[500px] relative pb-28 md:pb-20 ">
          <div className="py-6 text-white text-lg font-bold bg-secondary">{title}</div>
          <div className="pb-10 sm:pb-0 px-6 pt-10">{description}</div>
          {url ? (
            <Button
              className={'p-6 absolute bottom-0 w-full'}
              secondary
              fullWidth
              label={'Read more'}
              onClick={() => window.open(url)}
            />
          ) : children ? (
            children
          ) : null}
        </div>
      ))}
    </div>
  );
  return (
    <div className="homepage_header">
      {hero}
      <div className="homepage_body_container">{loginSignUpCard}</div>
      <div className="mx-8">{cardsContainer}</div>
    </div>
  );
};

export default Homepage;
