import { returnCurrencyAndCostString } from '../shared/utility';
import {
  returnClassDate,
  returnClassDateAndTimeEnds,
  returnClassTime,
  returnClassTypeDetailsBasedOnClassType
} from '../shared/classFunctions';
import ReturnAttendees from '../containers/Timetables/HelperComponents/ReturnAttendees/ReturnAttendees';
import ReturnClassPrice from '../containers/Timetables/HelperComponents/ReturnClassPrice/ReturnClassPrice';
import { isValueCorrect } from '../shared/userFunctions';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

const returnMinWidth = (string) => {
  const result = string.split(' ').map((elm) => elm.length * 9.4);
  return Math.max(...result);
};

export const returnFieldsArray = (fieldsArray, fieldsToShow = []) => {
  const fields = {
    membershipPlanName: { label: 'Plan', name: 'planName' },
    membershipPlanCurrentPeriodEnd: { label: 'Expired', name: 'currentPeriodEnd' },
    client: { label: 'Name', name: 'client' },
    customerType: { label: 'Name', name: 'customerType' },
    gym: { label: 'Location', name: 'gymName' },
    trainer: { label: 'Trainer', name: 'trainer' },
    attendees: { label: 'Attendees', name: 'attendees' },
    location: { label: 'Location', name: 'location' },
    description: { label: 'Description', name: 'description' },
    classDate: { label: 'Date', name: 'classDate' },
    createdDate: { label: 'Created', name: 'createdDate' },
    date: { label: 'Date', name: 'date' },
    classTime: { label: 'Time', name: 'classTime' },
    time: { label: 'Time', name: 'time' },
    name: { label: 'Name', name: 'name' },
    defaultDescription: { label: 'Description', name: 'defaultDescription' },
    defaultLimit: { label: 'Capacity', name: 'defaultLimit' },
    defaultCost: { label: 'Cost', name: 'defaultCost' },
    cost: { label: 'Price', name: 'cost' },
    amount: { label: 'Amount', name: 'cost' },
    membershipClassPrice: { label: 'Membership Price', name: 'membershipClassPrice' },
    defaultDuration: { label: 'Duration', name: 'defaultDuration' },
    duration: { label: 'Duration', name: 'duration' },
    places: { label: 'Places', name: 'places' },
    classCount: { label: 'Classes', name: 'classCount' },
    oneOff: { label: 'One off', name: 'oneOff' },
    active: { label: 'Active', name: 'active' },
    displayOrder: { label: 'Display order', name: 'displayOrder' },
    classesIncluded: { label: 'Classes', name: 'classesIncluded' },
    price: { label: 'Price', name: 'price' },
    membershipPlanPrice: { label: 'Price', name: 'membershipPlanPrice' },
    spaces: { label: 'Space', name: 'spaces' },
    notesForClient: { label: 'Notes for client', name: 'notesForClient' },
    notesForCompany: { label: 'Notes', name: 'notesForCompany' },
    addToCalendar: { label: 'Add to calendar', name: 'addToCalendar' },
    classType: { label: 'Type', name: 'classType' },
    videoForClassType: { label: 'Type', name: 'videoForClassType' },
    report_attendance: { label: 'Attendance', name: 'report_attendance' },
    firstAttendee: { label: 'First Attendee', name: 'firstAttendee' },
    quantity: { label: 'Quantity', name: 'quantity' },
    options: { label: 'Options', name: 'options' },

    actionColumn: { label: '', name: 'action' }
  };

  return fieldsArray.map((name) => ({
    ...fields[name],
    show: fieldsToShow.length > 0 ? fieldsToShow.includes(name) : true,
    minWidth: returnMinWidth(fields[name].label)
  }));
};

export const returnFieldValue = ({ name }, item) => {
  switch (name) {
    case 'client':
      return item.name + ' ' + item.last_name;
    case 'trainer':
      return item.trainer ? item.trainer.name + ' ' + item.trainer.last_name : 'N/D';
    case 'classTime':
    case 'time':
      return <div>{returnClassTime(item[name])}</div>;
    case 'classDate':
    case 'date':
    case 'createdDate':
    case 'currentPeriodEnd':
      return returnClassDate(item[name]);
    case 'cost':
    case 'price':
    case 'amount':
      return <ReturnClassPrice selectedClass={item} classType={item.__t} />;
    case 'defaultCost':
    case 'membershipClassPrice':
      return item[name] !== null ? returnCurrencyAndCostString(item.currency, item[name]) : 'N/D';
    case 'membershipPlanPrice':
      return returnCurrencyAndCostString(item.currency, item.price);
    case 'duration':
    case 'defaultDuration':
      return item[name] + ' mins';
    case 'oneOff':
    case 'active':
      return item[name] === true ? 'Yes' : 'No';
    case 'classesIncluded':
      return item.unlimited ? 'Unlimited' : item.classesIncluded;
    case 'attendees':
      return <ReturnAttendees selectedClass={item} />;
    case 'addToCalendar':
      return (
        <AddToCalendarButton
          name={item.name}
          startDate={returnClassDate(item.classDate, 'YYYY-MM-DD').toString()}
          endDate={returnClassDateAndTimeEnds(item.classDate, item.classTime, item.duration, 'YYYY-MM-DD').toString()}
          startTime={returnClassTime(item.classTime, 'HH:mm').toString()}
          endTime={returnClassDateAndTimeEnds(item.classDate, item.classTime, item.duration, 'HH:mm').toString()}
          options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo', 'MicrosoftTeams', 'Microsoft365']}
          location={item.locationName}
          hideTextLabelButton
          buttonStyle="date"
          hideBackground></AddToCalendarButton>
      );
    case 'report_attendance':
      const countAttendees = (attendance) => {
        const activeCount = attendance && attendance.active && attendance.active.count ? attendance.active.count : 0;
        const attendedCount =
          attendance && attendance.attended && attendance.attended.count ? attendance.attended.count : 0;
        return activeCount + attendedCount;
      };
      return countAttendees(item.attendance) + '/' + item.capacity;
    case 'classType':
      return returnClassTypeDetailsBasedOnClassType(item.__t).title;
    case 'videoForClassType':
      const classType = returnClassTypeDetailsBasedOnClassType(item.forClass);
      return isValueCorrect(classType) ? classType.title : 'N/D';
    case 'firstAttendee':
      return item.attendances &&
        item.attendances.active &&
        item.attendances.active.attendeesDetail !== undefined &&
        item.attendances.active.attendeesDetail.length > 0
        ? item.attendances.active.attendeesDetail[0].name + ' ' + item.attendances.active.attendeesDetail[0].lastName
        : 'N/D';
    default:
      return <div>{item[name]}</div>;
  }
};
