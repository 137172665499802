export const daysOfWeek = [
  {
    value: 'sunday',
    label: 'Sunday',
    shortLabel: 'Sun',
    index: 0
  },
  {
    value: 'monday',
    label: 'Monday',
    shortLabel: 'Mon',
    index: 1
  },
  {
    value: 'tuesday',
    label: 'Tuesday',
    shortLabel: 'Tue',
    index: 2
  },
  {
    value: 'wednesday',
    label: 'Wednesday',
    shortLabel: 'Wed',
    index: 3
  },
  {
    value: 'thursday',
    label: 'Thursday',
    shortLabel: 'Thur',
    index: 4
  },
  {
    value: 'friday',
    label: 'Friday',
    shortLabel: 'Fri',
    index: 5
  },
  {
    value: 'saturday',
    label: 'Saturday',
    shortLabel: 'Sat',
    index: 6
  }
];
