import QrCode from 'qrcode.react';
import Title from '../../../components/UI/Title/Title';

export default ({ isActive = false, id = '' }) => (
  <div className={'body-container'}>
    {isActive ? (
      <>
        <Title header="Your QR code" subHeader="Scan the code below and get ready for a great workout" />
        <QrCode value={id} style={{ margin: '0 auto', display: 'block' }} size={200} />
      </>
    ) : (
      <Title header="Your QR code is inactive" subHeader="Please update your membership" />
    )}
  </div>
);
