import useSelectFilter from '../hooks/useSelectFilter';
import { Input } from 'antd';

const GymNameFilter = ({ onFilter }) => {
  const { onSelectedFilterChanged } = useSelectFilter('', onFilter);

  return (
    <div className="block">
      <h4 className="client__gym-name-filter__gym">Filter by gym</h4>
      <Input name="client-name-filter" placeholder="gym name" onChange={onSelectedFilterChanged} />
    </div>
  );
};

export default GymNameFilter;
