import { useContext, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import AddEditVideo from './VideosList/AddEditVideo/AddEditVideo';
import Title from '../../components/UI/Title/Title';
import { AppContext } from '../../context/App/AppContext';
import { Tabs } from 'antd';
import Notification from '../../components/UI/Notification/Notification';
import ManageVideoCategories from './ManageVideoCategories/ManageVideoCategories';
import VideosList from './VideosList/VideosList';
import ManagePasses from '../ManagePasses/ManagePasses';
import { VideoContext } from '../../context/Video/VideoContext';

const ManageVideos = () => {
  const { userData } = useContext(AppContext);
  const {
    videosIsLoading,
    videosIsError,

    videoCategoriesIsLoading,
    videoCategoriesIsError,

    videoPassesLoading,
    videoPassesIsError
  } = useContext(VideoContext);
  const [activeKey, setActiveKey] = useState(0);

  if (videosIsLoading || videoCategoriesIsLoading || videoPassesLoading) {
    return <Loading />;
  }

  if (videosIsError || videoCategoriesIsError || videoPassesIsError) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  const items = [
    {
      key: 0,
      label: 'Videos',
      children: <VideosList />
    },
    {
      key: 1,
      label: 'Add video',
      children: <AddEditVideo onCancel={() => setActiveKey(0)} />
    },
    {
      key: 2,
      label: 'Passes',
      children: <ManagePasses classType="video" />
    },
    {
      key: 3,
      label: 'Categories',
      children: <ManageVideoCategories />
    }
  ];

  return (
    <>
      <Title title={'Videos'} />
      <Tabs
        tabPosition={userData.isAdmin ? 'left' : 'top'}
        items={items}
        activeKey={activeKey}
        onTabClick={(i) => setActiveKey(i)}
      />
    </>
  );
};

export default ManageVideos;
