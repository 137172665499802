import SingleClassValidator from '../SingleClassValidator/SingleClassValidator';
import ScheduleValidator from '../ScheduleValidator/ScheduleValidator';

/**
 * staff shift logic for validation is bad -
 * if trainer has access to multiple  gyms we have to select shifts for specific gyms\
 * So.... validation is not implemented
 */

const CheckClassDateAndTrainersShifts = ({ data = null, type = 'class', setIsStaffShiftsValid = () => {} }) => {
  return type === 'schedule' ? (
    <ScheduleValidator data={data} setIsStaffShiftsValid={setIsStaffShiftsValid} />
  ) : (
    <SingleClassValidator data={data} setIsStaffShiftsValid={setIsStaffShiftsValid} />
  );
};

export default CheckClassDateAndTrainersShifts;
