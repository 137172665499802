import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import companyDetails from '../../brandConfig/companyDetails';
import { isValueCorrect } from '../../shared/userFunctions';
const containerStyle = {
  width: '400px',
  height: '400px'
};

const GoogleMaps = () => {
  const coordinates = companyDetails?.googleMaps;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
  });
  //eslint-disable-next-line
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(coordinates);
      map.fitBounds(bounds);

      setMap(map);
    },
    //eslint-disable-next-line
    []
  );

  const onUnmount = React.useCallback(
    function callback() {
      setMap(null);
    },
    //eslint-disable-next-line
    []
  );
  if (isValueCorrect(coordinates) && isValueCorrect(coordinates.lat) && isValueCorrect(coordinates.lng)) {
    return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coordinates}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}>
        {/* Child components, such as markers, info windows, etc. */}
        <></>
      </GoogleMap>
    ) : (
      <></>
    );
  }
  return null;
};

export default React.memo(GoogleMaps);
