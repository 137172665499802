import { useState } from 'react';
import { Modal } from 'antd';
import AcceptStatements from '../../../containers/AcceptStatements/AcceptStatements';
import Button from '../../UI/Button/Button';
import { refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import Notification from '../../UI/Notification/Notification';

const ClientProfileAcceptStatements = ({ client }) => {
  const [showModal, setShowModal] = useState(false);

  if (client.areTermsAndConditionsAccepted === undefined || client.areTermsAndConditionsAccepted === false) {
    const closeModal = () => {
      refreshFetchFunctions([['selectedClient', client._id]]);
      setShowModal(false);
    };

    return (
      <>
        <Modal open={showModal} onCancel={closeModal} id={'acceptStatementsModal'}>
          <AcceptStatements selectedClient={client} handleCloseModal={closeModal} />
        </Modal>
        <Notification message={`This customer has not accepted legal statements`} status="critical" />
        <div className="flex justify-between p-4 mb-4 items-center bg-neutral-200">
          Does client accept all legal statements?
          <Button label="YES" primary onClick={() => setShowModal(true)} />
        </div>
      </>
    );
  }
  return null;
};

export default ClientProfileAcceptStatements;
