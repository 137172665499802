import moment from 'moment';
import Icon from '../Icon/Icon';
const ProfilePlanStatus = (props) => {
  if (!props.currentMembershipPlan || !props.currentMembershipPlan.current_period_end) {
    return (
      <div className="flex">
        <Icon name="statusCritical" className="mr-4 md:mr-2" size={30} />
        <div>Inactive</div>
      </div>
    );
  }

  if (
    props.currentMembershipPlan.current_period_end &&
    moment() > moment(props.currentMembershipPlan.current_period_end)
  ) {
    return (
      <div className="flex">
        <Icon name="statusWarning" className="mr-4 md:mr-2" size={30} />
        <div>Expired plan: {props.currentMembershipPlan.planName}</div>
      </div>
    );
  }

  if (
    props.currentMembershipPlan.current_period_end &&
    moment() < moment(props.currentMembershipPlan.current_period_end)
  ) {
    return (
      <div className="flex">
        <Icon name="statusOk" className="mr-4 md:mr-2" size={30} />
        <div>
          {props.currentMembershipPlan.planName} Membership renews on:{' '}
          {moment(props.currentMembershipPlan.current_period_end).format('Do MMM h:mm A')}
        </div>
      </div>
    );
  }

  return null;
};
export default ProfilePlanStatus;
