import { useContext, useEffect } from 'react';
import EditGymSettings from './EditGymSettings/EditGymSettings';
import EditAssociatedNames from './AddEditAssociatedNames/EditAssociatedNames';
import { ALL_CLASS_TYPES_DETAILS } from '../../constants';
import ClassConfig from './ClassConfig/ClassConfig';
import useTabIndex from '../../hooks/useTabIndex';
import { AppContext } from '../../context/App/AppContext';
import Title from '../../components/UI/Title/Title';
import { Tabs } from 'antd';
import AddEditGym from './AddEditGym/AddEditGym';
import { useMutation } from '@tanstack/react-query';
import { returnErrorFromResponse } from '../../shared/utility';
import { NotificationContext } from '../../context/Notification/NotificationContext';
import { editGymSettings, refreshFetchFunctions } from '../../apiFunctions/apiFunctions';
import { useLocation } from 'react-router-dom';

const ManageGym = () => {
  const { openNotification } = useContext(NotificationContext);
  const { selectedGym, timetableDisplaySettings } = useContext(AppContext);
  const { activeTabIndex, secondActiveTabIndex, onTabChange } = useTabIndex();
  const { state } = useLocation();

  useEffect(
    () => {
      onTabChange(state?.activeTabIndex, state?.secondActiveTabIndex);
    },
    //eslint-disable-next-line
    []
  );

  const { mutate: onSaveGymSettings, isLoading: onSaveGymSettingsIsLoading } = useMutation({
    mutationFn: (data) => editGymSettings(selectedGym.settings._id, { ...selectedGym.settings, ...data }),
    onSuccess: () => {
      openNotification('save', 'success');
      refreshFetchFunctions([['selectedGym', selectedGym._id]]);
    },
    onError: (data) => {
      openNotification(null, 'error', returnErrorFromResponse(data));
    }
  });

  const items = [
    {
      key: 0,
      label: 'Basic information',
      children: <AddEditGym isAddNewGym={false} />
    },
    {
      key: 1,
      label: 'Associated names',
      children: <EditAssociatedNames />
    },
    {
      key: 2,
      label: 'Gym properties',
      children: (
        <EditGymSettings
          selectedGym={selectedGym}
          onSave={onSaveGymSettings}
          onSaveIsLoading={onSaveGymSettingsIsLoading}
        />
      )
    },
    {
      key: 3,
      label: 'Gym assortment',
      children: (
        <Tabs
          tabPosition={'left'}
          defaultActiveKey={secondActiveTabIndex}
          items={ALL_CLASS_TYPES_DETAILS.map((classType, i) => ({
            key: i,
            label: classType.title,
            children: (
              <ClassConfig
                classType={classType}
                selectedGym={selectedGym}
                timetableDisplaySettings={timetableDisplaySettings}
                onSave={onSaveGymSettings}
                onSaveIsLoading={onSaveGymSettingsIsLoading}
              />
            )
          }))}
        />
      )
    }
  ];

  return (
    <div>
      <Title title={'Gym settings'} />
      <Tabs
        defaultActiveKey={activeTabIndex}
        activeKey={activeTabIndex}
        items={items}
        onChange={onTabChange}
        data-cy="tabList"
      />
    </div>
  );
};
export default ManageGym;
