import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useTabIndex = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [secondActiveTabIndex, setSecondActiveTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state !== undefined) {
      setActiveTabIndex(location.state?.activeTabIndex || 0);
      setSecondActiveTabIndex(location.state?.secondActiveTabIndex || 0);
    }
  }, [location.state]);

  const onTabChange = (index, secondIndex = 0) => {
    setActiveTabIndex(index);
    setSecondActiveTabIndex(secondIndex);
    navigate(location.pathname, {
      state: {
        activeTabIndex: index,
        secondActiveTabIndex: secondIndex
      }
    });
  };

  return {
    activeTabIndex,
    secondActiveTabIndex,
    onTabChange
  };
};

export default useTabIndex;
