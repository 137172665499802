import moment from 'moment';

// backend can return date in both string ("date") format and unix, that's why we are checking isNaN()

export const returnFormattedDate = (date) => {
  if (!date) {
    return '';
  }

  if (isNaN(date)) {
    return moment(date).format('Do MMM YY h:mm A');
  } else {
    return moment.unix(date).format('Do MMM YY h:mm A');
  }
};

export const isDateExpired = (date) => {
  if (isNaN(date)) {
    return moment().isAfter(date);
  } else {
    return moment().unix() > date;
  }
};

export const returnDateAndTime = (classDate, classTime) =>
  moment(classDate)
    .format('YYYY-MM-DD')
    .set({
      hour: moment(classTime, ['hh:mm A']).get('hour'),
      minute: moment(classTime, ['hh:mm A']).get('minute')
    })
    .format('DD/MM/YYYY h:mm a');

export const returnDate = (date) => {
  if (!date) {
    return '';
  }
  return moment(date, 'YYYY-MM-DD').format('DD/MM');
};

export const sortDaysAndTme = (arrayOfObjects = []) => {
  let sortedByDay = [...arrayOfObjects];
  let sortedByDayAndTime = [];
  const sorter = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7
  };

  sortedByDay.sort(function sortByDay(a, b) {
    let day1 = a.day.toLowerCase();
    let day2 = b.day.toLowerCase();
    return sorter[day1] - sorter[day2];
  });

  Object.keys(sorter).map((day) => {
    let sortedByTimeInOneDay = sortedByDay.filter((elm) => elm.day === day);
    sortedByTimeInOneDay.sort((a, b) => moment(a.startingFrom, 'HH').diff(moment(b.startingFrom, 'HH')));
    sortedByDayAndTime = [...sortedByDayAndTime, ...sortedByTimeInOneDay];
  });

  return sortedByDayAndTime;
};
