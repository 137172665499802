import classes from './ClassDetailModal.module.scss';
import Icon from '../../../../components/UI/Icon/Icon';

function ClassDetailModal({ event, history, onClose }) {
  if (event !== null) {
    return (
      <div className={classes.root}>
        <div className={classes.modalBtnContainer}>
          <Icon name={'notes'} onClick={() => history.push(`/gym/${event.locationId}/class/${event.classId}`)} />
          <Icon name="close" onClick={onClose} />
        </div>
        <h2 className={classes.classDetail}>{event.className}</h2>
        <div className={classes.classDetail}>
          <span className={classes.classDetailLabel}>Gym:</span> {event.locationName}
        </div>
        <div className={classes.classDetail}>
          <span className={classes.classDetailLabel}>Location:</span> {event.locationInfo}
        </div>
        <div className={classes.classDetail}>
          <span className={classes.classDetailLabel}>Trainer:</span> {event.trainerName}
        </div>
        <div className={classes.classDetail}>
          <span className={classes.classDetailLabel}>Date:</span> {event.date}
        </div>
        <div className={classes.classDetail}>
          <span className={classes.classDetailLabel}>Time:</span> {event.time}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default ClassDetailModal;
