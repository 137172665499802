import { useContext, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import { useMutation } from '@tanstack/react-query';
import { searchUsersByName } from '../../../../apiFunctions/apiFunctions';
import Button from '../../../../components/UI/Button/Button';

import ClientsList from './ClientsList/ClientsList';
import { Input } from 'antd';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';

const SearchClients = () => {
  const { openNotification } = useContext(NotificationContext);
  const [name, setName] = useState('');
  const [searchData, setSearchData] = useState(null);

  const mutateSearch = useMutation(() => searchUsersByName(name), {
    onSuccess: (data) => setSearchData(data),
    onError: () => openNotification('default', 'error')
  });

  const search = (
    <div className="mb-6">
      <div className="mt-6 font-bold">Please enter name or last name</div>
      <Input value={name} onChange={(e) => setName(e.target.value)} />
      <Button fullWidth primary label="Search" onClick={mutateSearch.mutate} />
    </div>
  );

  return (
    <div>
      {search}
      {mutateSearch.isLoading && <Loading />}
      <ClientsList searchData={searchData} />
    </div>
  );
};

export default SearchClients;
