import { useContext } from 'react';
import { AppContext } from '../context/App/AppContext';
import ClientDashboard from '../containers/ClientDashboard/ClientDashboard';
import { Route } from 'react-router-dom';
import PaymentMethodsPage from '../containers/ManageUsers/ManageUserSettings/User/PaymentMethods/PaymentMethodsPage';
import ClientQrCode from '../containers/ClientDashboard/ClientQrCode/ClientQrCode';
import Container from '../hoc/Container/Container';
import ClientMembership from '../containers/ManageMembershipPlans/UserView/ClientMembership/ClientMembership';

const useUserRoutes = () => {
  const { isAuthenticated, userData } = useContext(AppContext);
  if (isAuthenticated && userData.isUser) {
    const mainPath = '/gym';

    return (
      <>
        <Route path={mainPath} element={<ClientDashboard />} />

        <Route path={`${mainPath}/payment-methods`} element={<PaymentMethodsPage />} />
        <Route
          path={`${mainPath}/qr`}
          element={
            <Container show={userData.isActive} gymSettingsRestriction={'qrCode'}>
              <ClientQrCode
                id={userData.qrCode ? userData.qrCode : userData._id}
                isActive={userData.customerType === 'full-member' ? userData.isActive : true}
              />
            </Container>
          }
        />

        <Route
          path={`${mainPath}/membership`}
          element={
            <Container show={userData.isMembershipUser}>
              <ClientMembership />
            </Container>
          }
        />
      </>
    );
  }
  return null;
};

export default useUserRoutes;
