import moment from 'moment';
import RedirectToUserDetails from '../../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import classes from './ClientsList.module.scss';

const ClientsList = ({ searchData }) => {
  const header = ['Name', 'Last name', 'Date of birth', 'Gym', ''];

  if (searchData === null) {
    return null;
  }
  if (searchData && searchData.length === 0) {
    return <h3>No clients</h3>;
  }
  const renderHeader = (
    <div className={[classes.grid, classes.header, 'tableHeader'].join(' ')}>
      {header.map((elm, i) => (
        <div key={i}>{elm}</div>
      ))}
    </div>
  );
  const singleClient = ({ name, gymId, userId, lastName, gymName, dateOfBirth }) => (
    <div className={[classes.grid, classes.singleClient, 'hover:bg-custom-hover py-1'].join(' ')} key={userId}>
      <div className={[classes.name, 'flex items-center'].join(' ')}>
        <RedirectToUserDetails client={{ gymId, name, last_name: lastName, _id: userId }} /> {name}
      </div>
      <div className={classes.name}>{lastName}</div>
      <div>
        <div className={classes.subheader}>DATE OF BIRTH</div>
        {dateOfBirth ? moment(dateOfBirth).format('DD/MM/YYYY') : null}{' '}
      </div>
      <div>
        <div className={classes.subheader}>GYM</div>
        {gymName}
      </div>
    </div>
  );

  const renderList = searchData.map((elm) => singleClient(elm));
  return (
    <div>
      {renderHeader}
      {renderList}
    </div>
  );
};

export default ClientsList;
