import Button from '../../../components/UI/Button/Button';
import Notification from '../../../components/UI/Notification/Notification';

const ClientStatus = ({
  selectedClient,
  isAdmin,
  onUpdateSelectedClient,
  isUserActive,
  onUpdateSelectedClientIsLoading
}) => {
  if (isUserActive) {
    return null;
  }

  const userStatus =
    selectedClient.status === 'waitingForApproval'
      ? 'waiting for admin approval'
      : selectedClient.status === 'permissionDenied'
        ? 'permission denied'
        : 'inactive';

  return (
    <>
      <Notification message={`This customer status is: ${userStatus}`} status="critical">
        {isAdmin && (
          <div className="grid grid-cols-2 justify-between mt-6 mb-2 items-center font-bold ">
            Do you want to activate this user?
            <Button
              fullWidth
              label="YES"
              primary
              onClick={() => onUpdateSelectedClient({ status: 'active' })}
              isLoading={onUpdateSelectedClientIsLoading}
            />
          </div>
        )}
      </Notification>
    </>
  );
};

export default ClientStatus;
