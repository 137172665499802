import { useState, useEffect } from 'react';
import TimetableDisplaySettings from './TimetableDisplaySettings/TimetableDisplaySettings';
import ClassSettings from './ClassSettings/ClassSettings';
import { CLASS_CONFIG_INITIAL_STATE } from '../../../constants';
import { Checkbox } from 'antd';
import { classNames } from '../../../shared/utility';
import Button from '../../../components/UI/Button/Button';

const cloneDeep = require('lodash.clonedeep');

const ClassConfig = ({ classType: { type }, timetableDisplaySettings, selectedGym, onSave, onSaveIsLoading }) => {
  const [prevType, setPrevType] = useState(null);
  const [config, setConfig] = useState(CLASS_CONFIG_INITIAL_STATE(type));
  const [isClassTypeActive, setIsClassTypeActive] = useState(false);
  const [timetableSettingsUser, setTimetableSettingsUser] = useState([]);
  const [timetableSettingsAdmin, setTimetableSettingsAdmin] = useState([]);
  const [timetableSettingsTrainer, setTimetableSettingsTrainer] = useState([]);
  const [data, setData] = useState(selectedGym.settings);

  useEffect(
    () => {
      if (prevType !== type) {
        let newConfig = selectedGym.settings.classConfig.find((elm) => elm.classType === type);
        if (newConfig !== undefined) {
          setConfig(newConfig);
        } else {
          setConfig(CLASS_CONFIG_INITIAL_STATE(type));
        }
        setIsClassTypeActive(selectedGym.settings[type]);
        setTimetableSettingsUser(timetableDisplaySettings[type].user.filter((elm) => elm.showInSettings));
        setTimetableSettingsAdmin(timetableDisplaySettings[type].admin.filter((elm) => elm.showInSettings));
        setTimetableSettingsTrainer(timetableDisplaySettings[type].trainer.filter((elm) => elm.showInSettings));
        setPrevType(type);
      }
    }, //eslint-disable-next-line
    [type]
  );

  useEffect(
    () => {
      const returnData = () => {
        const copiedSettings = cloneDeep(selectedGym.settings);
        const indexOfClassConfigArray = copiedSettings.classConfig.findIndex((elm) => elm.classType === type);

        const updatedTimetableDisplaySettings = {
          user: timetableSettingsUser
            .filter((elm) => elm.value)
            .map((elm) => {
              {
                return elm.name;
              }
            }),
          admin: timetableSettingsAdmin
            .filter((elm) => elm.value)
            .map((elm) => {
              {
                return elm.name;
              }
            }),
          trainer: timetableSettingsTrainer
            .filter((elm) => elm.value)
            .map((elm) => {
              {
                return elm.name;
              }
            })
        };

        copiedSettings[type] = isClassTypeActive;

        if (indexOfClassConfigArray === -1) {
          copiedSettings.classConfig = [
            ...copiedSettings.classConfig,
            { ...config, timetableDisplaySettings: updatedTimetableDisplaySettings }
          ];
        } else {
          copiedSettings.classConfig[indexOfClassConfigArray] = { ...config };
          copiedSettings.classConfig[indexOfClassConfigArray].timetableDisplaySettings =
            updatedTimetableDisplaySettings;
        }
        return copiedSettings;
      };

      setData(returnData());
    },
    //eslint-disable-next-line
    [isClassTypeActive, timetableSettingsUser, timetableSettingsAdmin, timetableSettingsTrainer, config]
  );

  const renderIsActiveClassCheckbox = () => (
    <div
      className={classNames(
        'p-6 mb-4',
        isClassTypeActive
          ? 'bg-custom-ok border-2 border-custom-ok-border'
          : 'bg-custom-error  border-2 border-custom-error-border'
      )}>
      <Checkbox key={'active'} checked={isClassTypeActive} onChange={(e) => setIsClassTypeActive(e.target.checked)}>
        {isClassTypeActive ? 'Active' : 'Inactive'}
      </Checkbox>
    </div>
  );

  const renderSettings = () =>
    isClassTypeActive ? (
      <div className="mb-4">
        <h3 className="">Settings</h3>
        <ClassSettings config={config} setConfig={setConfig} />

        <h3 className=" mt-4 pt-4 border-t-2">Timetable Display Settings</h3>
        <div className="sm:grid grid-cols-3 mb-6">
          <TimetableDisplaySettings
            timetableSettings={timetableSettingsUser}
            setTimetableSettings={setTimetableSettingsUser}
            userType="user"
            type={type}
            key={type}
          />
          <TimetableDisplaySettings
            timetableSettings={timetableSettingsAdmin}
            setTimetableSettings={setTimetableSettingsAdmin}
            userType="admin"
            key="admin"
          />
          <TimetableDisplaySettings
            timetableSettings={timetableSettingsTrainer}
            setTimetableSettings={setTimetableSettingsTrainer}
            userType="trainer"
            key="trainer"
          />
        </div>
      </div>
    ) : null;

  return (
    <div>
      {renderIsActiveClassCheckbox()}
      {renderSettings()}
      <Button onClick={() => onSave(data)} isLoading={onSaveIsLoading} label={'Save'} secondary fullWidth />
    </div>
  );
};

export default ClassConfig;
