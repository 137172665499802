import { useContext } from 'react';
import { AppContext } from '../../../context/App/AppContext';
import { useNavigate } from 'react-router-dom';
import Title from '../../../components/UI/Title/Title';
import RenderForm from '../../../components/Forms/RenderForm';
import { useMutation } from '@tanstack/react-query';
import { createGym, editGym, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { returnErrorFromResponse } from '../../../shared/utility';

const AddEditGym = ({ isAddNewGym = true }) => {
  const { openNotification } = useContext(NotificationContext);
  const { onGymChange, selectedGym } = useContext(AppContext);
  const navigate = useNavigate();

  const { mutate: onCreateGym, isLoading: onCreateGymIsLoading } = useMutation({
    mutationFn: (data) => (isAddNewGym ? createGym(data) : editGym(selectedGym._id, data)),
    onSuccess: (data) => {
      openNotification(isAddNewGym ? 'createGym' : 'save', 'success');
      refreshFetchFunctions([['gymsList']]);
      if (isAddNewGym) {
        onGymChange(data._id);
        navigate(`/gym/${data._id}/settings`);
      } else {
        refreshFetchFunctions([['selectedGym', selectedGym._id]]);
      }
    },
    onError: (data) => {
      openNotification(null, 'error', returnErrorFromResponse(data));
    }
  });

  const formFields = ['gymName', 'gymUrl', 'address', 'city', 'postcode', 'currency', 'termsUrl'];
  if (!isAddNewGym) {
    formFields.push('email');
  }

  return (
    <>
      <Title title={isAddNewGym ? 'Add new gym' : null} />
      <RenderForm
        initialValues={!isAddNewGym && selectedGym}
        onFinish={onCreateGym}
        formFields={['gymName', 'gymUrl', 'address', 'city', 'postcode', 'currency', 'termsUrl']}
        excludeFormFields={isAddNewGym ? [] : ['gymUrl', 'currency']}
        btnLabel={'save'}
        btnIsLoading={onCreateGymIsLoading}
      />
    </>
  );
};

export default AddEditGym;
