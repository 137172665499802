import Icon from '../Icon/Icon';
import moment from 'moment';
import { getCurrencySymbol } from '../../../shared/utility';
import customColors from '../../../theme/customColors';
import { isValueCorrect } from '../../../shared/userFunctions';

const ProfilePaymentHistory = ({ lastPaymentInfo }) => {
  return (
    <div className={'flex'}>
      <Icon
        size={20}
        className="mr-2 cursor-default"
        name={lastPaymentInfo.success ? 'statusOk' : 'statusCritical'}
        color={lastPaymentInfo.success ? customColors['custom-icon-ok'] : customColors['custom-icon-error']}
      />
      {moment(lastPaymentInfo.date).format('Do MMM YY h:mm A')}
      {lastPaymentInfo.success ? (
        `, ${getCurrencySymbol(lastPaymentInfo.currency)}
        ${lastPaymentInfo.amount}`
      ) : isValueCorrect(lastPaymentInfo.reason) ? (
        <div>,reason: {lastPaymentInfo.reason}</div>
      ) : null}
    </div>
  );
};
export default ProfilePaymentHistory;
