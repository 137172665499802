import { useContext, useState } from 'react';
import Button from '../../../components/UI/Button/Button';
import { Modal } from 'antd';
import StepOne_ClientSelector from './StepOne_ClientSelector/StepOne_ClientSelector';
import StepTwo_ClassSelector from './StepTwo_ClassSelector/StepTwo_ClassSelector';
import StepTree_Summary from './StepTree_Summary/StepTree_Summary';
import { AppContext } from '../../../context/App/AppContext';

const MoveClientsBetweenClasses = ({ currentClass = null }) => {
  const {
    userData: { isAdmin }
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);

  const handleCloseModal = () => {
    setShowModal(false);
    setStep(0);
    setSelectedClient(null);
    setSelectedClass(null);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  if (isAdmin) {
    const handleModalContent = () => {
      if (step === 2) {
        return (
          <StepTwo_ClassSelector
            currentClass={currentClass}
            selectedClient={selectedClient}
            nextStep={setStep}
            onSelect={setSelectedClass}
          />
        );
      }
      if (step === 3) {
        return (
          <StepTree_Summary
            currentClass={currentClass}
            selectedClient={selectedClient}
            selectedClass={selectedClass}
            onCloseModal={handleCloseModal}
          />
        );
      }
      return (
        <StepOne_ClientSelector
          onSelect={setSelectedClient}
          attendanceList={currentClass.attendanceList}
          nextStep={setStep}
          onCloseModal={handleCloseModal}
        />
      );
    };

    return (
      <>
        <Modal onCancel={handleCloseModal} open={showModal} width={step === 0 ? '500px' : '1000px'}>
          {handleModalContent()}
        </Modal>
        <Button fullWidth secondary label="Move clients" onClick={handleOpenModal} />
      </>
    );
  }
  return null;
};

export default MoveClientsBetweenClasses;
