import { useState, useEffect } from 'react';
import Icon from '../../../../components/UI/Icon/Icon';
import { Input } from 'antd';
import Button from '../../../../components/UI/Button/Button';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';

const AddEditCategory = ({ category = null, onClose = () => {}, onSave = () => {}, isLoading = false }) => {
  const [name, setName] = useState('');
  const [options, setOptions] = useState(['']);
  const canSave = name.length && options.every((option) => option.length);

  useEffect(() => {
    if (category !== null) {
      setName(category.name);
      setOptions(category.options);
    }
  }, [category]);

  const addOption = () => setOptions([...options, '']);

  const deleteOption = (index) => {
    let arrOptions = [...options];
    arrOptions.splice(index, 1);
    setOptions(arrOptions);
  };

  const handleOptionChange = (value, index) => {
    const arrOptions = [...options];
    arrOptions[index] = value;
    setOptions(arrOptions);
  };

  const handleCategoryChange = ({ target }) => setName(target.value);

  return (
    <>
      <div className={'font-bold'}>Category name:</div>
      <Input value={name} onChange={handleCategoryChange} placeholder="Enter category name" />

      <div className={'grid grid-cols-[1fr_20px] gap-x-3 items-center my-4'}>
        <div className={'font-bold '}>Options:</div>
        <Icon name={'add'} onClick={addOption} />
      </div>

      {options.map((option, index) => (
        <div key={index} className={'grid grid-cols-[1fr_20px] gap-x-3 items-center my-3'}>
          <Input
            value={option}
            onChange={({ target }) => handleOptionChange(target.value, index)}
            placeholder="Enter tag"
          />
          <Icon name={'trash'} onClick={() => deleteOption(index)} />
        </div>
      ))}

      <ModalButtonsContainer isLoading={isLoading}>
        <Button label="Save" secondary onClick={() => onSave({ name, options })} disabled={!canSave} />
        <Button label="Cancel" primary onClick={onClose} />
      </ModalButtonsContainer>
    </>
  );
};
export default AddEditCategory;
