import { useContext } from 'react';
import SingleTrainer from './SingleTrainer/SingleTrainer';
import Loading from '../../../components/Loading/Loading';
import { fetchTrainers } from '../../../apiFunctions/apiFunctions';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../context/App/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Notification from '../../../components/UI/Notification/Notification';
import Title from '../../../components/UI/Title/Title';
import Button from '../../../components/UI/Button/Button';
import Filters from '../../../components/Filters/Filters';
import useFilters from '../../../components/Filters/useFilters';
import NoResultsFound from '../../../components/UI/NoResultsFound/NoResultsFound';

const ManageTrainers = () => {
  const { selectedGym } = useContext(AppContext);
  const navigate = useNavigate();
  const { onFilters, filteredData, filters, setFiltersInitialData } = useFilters();
  const location = useLocation();

  const { isLoading: isTrainersListLoading, error: isTrainersListError } = useQuery(
    ['trainersList', selectedGym._id],
    () => fetchTrainers(selectedGym._id),
    {
      onSuccess: (data) => setFiltersInitialData(data)
    }
  );

  const renderTrainersList = () => {
    if (isTrainersListLoading) {
      return <Loading />;
    }
    if (isTrainersListError) {
      return <Notification message={'Something went wrong'} status="critical" />;
    }

    if (filteredData.length === 0) {
      return <NoResultsFound text={'No trainers'} />;
    }

    return filteredData.map((trainer) => (
      <SingleTrainer key={trainer._id} singleTrainer={trainer} show={location.state?.trainerId === trainer._id} />
    ));
  };

  return (
    <div data-cy={'trainers'}>
      <div className="titleAndBtnContainer" data-cy={'trainers'}>
        <Title title={'Manage trainers'} />
        <Button label="Add New" secondary onClick={() => navigate(`/gym/${selectedGym._id}/trainers/add`)} />
      </div>
      <Filters
        open
        filtersTypes={['userName']}
        onFilters={onFilters}
        filters={filters}
        initialState={[{ name: 'userName', value: '' }]}
      />

      {renderTrainersList()}
    </div>
  );
};

export default ManageTrainers;
