import { useContext, useState } from 'react';
import CancelAttendanceOrClass from '../../../ActionButtons/ClassActionButtons/CancelAttandanceOrClass/CancelAttendanceOrClass';
import ActivateClass from '../../../ActionButtons/ActivateClass/ActivateClass';
import ExportToCSV from '../../../AdminDashboard/ExportToCSV/ExportToCSV';
import { Modal } from 'antd';
import AddEditSingleClass from '../../AddEditSingleClass/AddEditSingleClass';
import Button from '../../../../components/UI/Button/Button';
import MoveClientsBetweenClasses from '../../../ActionButtons/MoveClientsBetweenClasses/MoveClientsBetweenClasses';
import { AppContext } from '../../../../context/App/AppContext';

const ClassActionButtons = ({ singleClass }) => {
  const { userData } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        <AddEditSingleClass
          classType={singleClass.__t}
          selectedClass={singleClass}
          onCancel={() => setShowModal(false)}
        />
      </Modal>
      <div>
        <div className="grid grid-cols-2">
          <Button label="Edit" onClick={() => setShowModal(true)} dataCy="editBtn" fullWidth />

          {userData.isAdminOrTrainer && (
            <CancelAttendanceOrClass
              cancelType="singleClass"
              type={'cancelClassButton'}
              selectedClass={singleClass}
              user={userData}
              attendance={singleClass.attendanceList}
            />
          )}
          {userData.isAdminOrTrainer && !singleClass.active && <ActivateClass classId={singleClass._id} />}
        </div>
        <div className="my-2">
          <ExportToCSV data={singleClass} type={'classDetail'} gymName={singleClass.gymId.name} fullWidth={true} />
        </div>
        <MoveClientsBetweenClasses currentClass={singleClass} />
      </div>
    </div>
  );
};

export default ClassActionButtons;
