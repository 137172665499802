import classes from './SingleTile.module.scss';
import { useNavigate } from 'react-router-dom';

export default ({ title, link = null, text, icon, dataCy, onClick = () => {}, actionPayload }) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    if (link !== null) {
      navigate(link);
    } else {
      onClick(actionPayload);
    }
  };

  return (
    <div className={classes.tileContainer} onClick={clickHandler} data-cy={dataCy}>
      <div className={classes.tile}>
        {text && <span className={classes.text}>{text}</span>}
        {icon && <img className={classes.tileIcon} src={icon.type} alt="dashboard icon" />}
      </div>

      <div className={classes.title}>{title}</div>
    </div>
  );
};
