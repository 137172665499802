import RenderPassActionButtons from '../../../ManagePasses/RenderPassActionButtons/RenderPassActionButtons';
import { returnCurrencyAndCostString } from '../../../../shared/utility';
import Button from '../../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';

const SingleVirtualPass = ({ item, isPaid = false }) => {
  const navigate = useNavigate();
  const renderRow = (label, text) => (
    <div className={'grid grid-cols-2 gap-x-4'}>
      <div className={'font-bold'}>{label}:</div>
      <div>{text}</div>
    </div>
  );
  return (
    <div className="listItem md:flex justify-between items-center bg-neutral-200 my-2 ">
      <div>
        {renderRow('Name', item.name)}
        {renderRow('Description', item.description)}
        {renderRow('Cost', returnCurrencyAndCostString(item.currency, item.cost))}
      </div>
      <div className={'mt-2 md:mt-0 md:w-[150px]'}>
        {isPaid ? (
          <Button secondary onClick={() => navigate(`/gym/videos/pass/${item._id}`)} label={'view'} fullWidth />
        ) : (
          <RenderPassActionButtons
            buttonStyle={{ width: '100%' }}
            classPass={item}
            classType={item.forClass}
            editPass={() => {}}
            label={item.cost === 0 ? 'Add' : 'Pay'}
            successMessage={item.cost === 0 ? 'Pass added to account' : 'Successfully paid'}
            goBack={false}
          />
        )}
      </div>
    </div>
  );
};

export default SingleVirtualPass;
