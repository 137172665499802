import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { addPass, editPass, refreshFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { CLASS } from '../../../constants';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import { AppContext } from '../../../context/App/AppContext';
import RenderForm from '../../../components/Forms/RenderForm';
import { isValueCorrect } from '../../../shared/userFunctions';

const AddEditPass = ({ classType, selectedPass = null, onCancel = () => {}, displayOrder = 0, videos = [] }) => {
  const { openNotification } = useContext(NotificationContext);
  const { gymId, selectedGym } = useContext(AppContext);
  const isNew = selectedPass === null;

  const { mutate: onPassSave, isLoading: onPassSaveIsLoading } = useMutation({
    mutationFn: (formData) =>
      isNew
        ? addPass(classType, {
            ...formData,
            gymId,
            duration: isValueCorrect(formData.duration) ? formData.duration : 60
          })
        : editPass(selectedPass._id, formData),
    onSuccess: () => {
      refreshFetchFunctions([['passesList', { gymId, classType }]]);
      openNotification(null, 'success', isNew ? 'Successfully created!' : 'Successfully updated!');
      onCancel();
    },
    onError: () => {
      openNotification('default', 'error');
    }
  });

  const formFields =
    classType === CLASS.PERSONAL_TRAINING
      ? [
          'name',
          'description',
          'explainer',
          'places',
          'duration',
          'classCount',
          'currency',
          'cost',
          'expiryDate',
          'displayOrder',
          'oneOff',
          'active'
        ]
      : classType === CLASS.VIDEO
        ? [
            'name',
            'description',
            'explainer',
            'forClass',
            'classCount',
            'currency',
            'cost',
            'expiryDate',
            'displayOrder',
            'selectVideos',
            'active',
            'enforceVideoOrder'
          ]
        : [
            'name',
            'description',
            'explainer',
            'classCount',
            'currency',
            'cost',
            'expiryDate',
            'displayOrder',
            'oneOff',
            'active'
          ];
  let initialValues = {
    currency: 'gbp',
    active: true,
    oneOff: false,
    classCount: 1,
    displayOrder,
    enforceVideoOrder: false
  };
  if (!isNew) {
    initialValues = { ...initialValues, ...selectedPass };
  }

  return (
    <div>
      <RenderForm
        formFields={formFields}
        initialValues={initialValues}
        onFinish={onPassSave}
        btnIsLoading={onPassSaveIsLoading}
        cancelBtn={!isNew}
        onCancel={onCancel}
        excludeFormFields={!isNew ? ['currency', 'expiryDate'] : []}
        gymSettings={selectedGym.settings}
        videos={videos}
      />
    </div>
  );
};

export default AddEditPass;
