import moment from 'moment/moment';
import { daysOfWeek } from '../../constants/timeConstants';
export const isValidScheduleDay = (value, allowNullValue = false) => {
  if (value === undefined || value.length === 0) {
    return allowNullValue;
  }
  let timeValue = value.split(',');
  let isTimeValid = [];
  timeValue.forEach((time) => {
    isTimeValid.push(moment(time.trim(), 'h:mm a', true).isValid());
  });

  return !(isTimeValid.filter((elem) => elem === false).length > 0);
};

export const areScheduleDaysValid = (data) =>
  daysOfWeek.map(({ value }) => data[value] !== undefined && data[value].length > 0).includes(true);

export default {
  customerType: {
    label: 'Customer type',
    name: 'customerType',
    rules: [
      {
        required: true,
        message: 'Please select customer type!'
      }
    ],
    placeholder: 'Select customer type'
  },
  videoConfig: {
    label: 'Categories',
    name: 'videoConfig',
    rules: [
      {
        required: true,
        message: 'Please select category!'
      }
    ],
    placeholder: 'Select category'
  },
  fileTitle: {
    label: 'Title',
    name: 'title',
    rules: [
      {
        required: true,
        message: 'Please enter title!'
      }
    ],
    placeholder: 'Enter title'
  },
  uploadFile: {
    label: 'File',
    name: 'file',
    rules: [
      {
        required: true,
        message: 'Please upload file!'
      }
    ],
    placeholder: 'Upload file'
  },
  selectProducts: {
    label: 'Select product',
    name: 'products',
    rules: [
      {
        required: false,
        message: 'Please select at least one product!'
      }
    ],
    placeholder: 'Select product'
  },
  selectVideos: {
    label: 'Videos',
    name: 'videos',
    rules: [
      {
        required: true,
        message: 'Please select videos!'
      }
    ],
    placeholder: 'Select videos'
  },
  selectClassType: {
    label: 'Select Class Type',
    name: 'classType',
    rules: [
      {
        required: true,
        message: 'Please select class type!'
      }
    ],
    placeholder: 'Select class type'
  },
  forClass: {
    label: 'Select Class Type - where videos will appear',
    name: 'forClass',
    rules: [
      {
        required: true,
        message: 'Please select class type!'
      }
    ],
    placeholder: 'Select class type',
    isCheckbox: false
  },
  enforceVideoOrder: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Enforce video order',
    name: 'enforceVideoOrder',
    placeholder: ''
  },
  sendClassChangeNotification: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Send Class change notification',
    name: 'sendClassChangeNotification',
    placeholder: ''
  },
  unlimitedClasses: {
    label: null,
    checkboxLabel: 'Unlimited classes included',
    isCheckbox: true,
    name: 'unlimited',
    placeholder: ''
  },
  classesIncluded: {
    label: 'Classes included',
    name: 'classesIncluded',
    rules: [
      {
        required: true,
        message: 'Please enter classes included!'
      }
    ],
    placeholder: 'Enter classes included'
  },
  joiningFee: {
    label: null,
    checkboxLabel: 'Joining fee',
    isCheckbox: true,
    name: 'joiningFee',
    placeholder: 'Enter joining fee'
  },
  classPrice: {
    label: 'Class price',
    name: 'classPrice',
    rules: [
      {
        required: true,
        message: 'Please enter class price!'
      }
    ],
    placeholder: 'Enter class price'
  },
  minContractLength: {
    label: 'Minimum contract length (in months)',
    name: 'minContractLength',
    rules: [
      {
        required: true,
        message: 'Please enter minimum contract length!'
      }
    ],
    placeholder: 'Enter minimum contract length'
  },
  price: {
    label: 'Price',
    name: 'price',
    rules: [
      {
        required: true,
        message: 'Please enter price!'
      }
    ],
    placeholder: 'Enter price'
  },
  question: {
    label: 'Question',
    name: 'question',
    rules: [
      {
        required: true,
        message: 'Please enter question!'
      }
    ],
    placeholder: 'Enter question'
  },
  answer: {
    label: 'Answer',
    name: 'answer',
    rules: [
      {
        required: true,
        message: 'Please enter answer!'
      }
    ],
    placeholder: 'Enter answer'
  },
  selectQuestionType: {
    label: 'Question Type',
    name: 'questionType',
    rules: [
      {
        required: true,
        message: 'Please select question type!'
      }
    ],
    placeholder: 'Select question type'
  },
  kitList: { label: null, checkboxLabel: 'Kit List', isCheckbox: true, name: 'kitList', placeholder: '' },
  faq: { label: null, checkboxLabel: 'FAQ', isCheckbox: true, name: 'faq', placeholder: '' },
  staffShifts: { label: null, checkboxLabel: 'Staff Shifts', isCheckbox: true, name: 'staffShifts', placeholder: '' },
  coupon: { label: null, checkboxLabel: 'Coupons', isCheckbox: true, name: 'coupon', placeholder: '' },
  liveStreaming: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Live Streaming',
    name: 'liveStreaming',
    placeholder: ''
  },
  videoOnDemand: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Video On Demand',
    name: 'videoOnDemand',
    placeholder: ''
  },
  minimalUserAge: { label: 'Enter minimal client age', name: 'minimalUserAge', placeholder: '' },
  newClientsHaveToBeApprovedByAdmin: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Admin approves new clients',
    name: 'newClientsHaveToBeApprovedByAdmin',
    placeholder: ''
  },
  membershipProducts: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Membership Products',
    name: 'membershipProducts',
    placeholder: ''
  },
  qrCode: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'QR Code',
    name: 'qrCode',
    placeholder: ''
  },
  enabledForScheduling: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Enabled for scheduling',
    name: 'enabledForScheduling',
    placeholder: ''
  },
  daysOfTheWeek: {
    rules: [
      {
        validator: (field, value) => isValidScheduleDay(value),
        message: 'Please enter a time in "h:mm a" format!  (e.g. 1:00 AM, 1:00 PM)'
      }
    ],
    placeholder: 'Enter time'
  },
  oneOff: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Available only once per user',
    name: 'oneOff',
    placeholder: ''
  },
  displayOrder: {
    label: 'Display order',
    name: 'displayOrder',
    placeholder: 'Enter display order'
  },
  expiryDate: {
    label: 'Expiry date (optional) - set date to hide for purchase by customers',
    name: 'expiryDate',
    onlyFuture: true,
    placeholder: 'Enter expiry date'
  },
  classCount: {
    label: 'Classes included',
    name: 'classCount',
    minValue: 1,
    rules: [
      {
        required: true,
        message: 'Please enter number!'
      }
    ],
    placeholder: 'Enter classes included'
  },
  places: {
    label: 'Places',
    name: 'places',
    placeholder: 'Enter places'
  },
  explainer: {
    label: 'Explainer (additional information about how the pass works)',
    name: 'explainer',
    placeholder: 'Enter explainer'
  },
  selectClients: {
    label: null,
    name: 'selectClients',
    placeholder: 'Select Clients'
  },
  waitList: {
    label: null,
    name: 'waitListConfig',
    placeholder: ''
  },
  waitListConfig: {
    label: null,
    name: 'waitListConfig',
    placeholder: ''
  },
  selectTrainer: {
    label: 'Trainer',
    name: 'trainerId',
    rules: [
      {
        required: true,
        message: 'Please select trainer!'
      }
    ],
    placeholder: 'Select trainer'
  },
  trainer: {
    label: 'Trainer',
    name: 'trainer',
    rules: [
      {
        required: true,
        message: 'Please select trainer!'
      }
    ],
    placeholder: 'Select trainer'
  },
  active: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Active',
    name: 'active'
  },
  template: {
    label: 'Template',
    name: 'templateId',
    rules: [
      {
        required: true,
        message: 'Please select template!'
      }
    ],
    placeholder: 'Select template'
  },
  name: {
    label: 'Name',
    name: 'name',
    rules: [
      {
        required: true,
        message: 'Please enter name!'
      }
    ],
    placeholder: 'Enter name'
  },
  defaultDescription: {
    label: 'Description',
    name: 'defaultDescription',
    rules: [
      {
        required: true,
        message: 'Please enter description!'
      }
    ],
    placeholder: 'Enter description'
  },
  description: {
    label: 'Description',
    name: 'description',
    rules: [
      {
        required: true,
        message: 'Please enter description!'
      }
    ],
    placeholder: 'Enter description'
  },
  location: {
    label: 'Location',
    name: 'location',
    placeholder: 'Enter location'
  },
  defaultLimit: {
    label: 'Limit',
    name: 'defaultLimit',
    rules: [
      {
        required: true,
        message: 'Please enter limit!'
      }
    ],
    placeholder: 'Enter limit'
  },
  limit: {
    label: 'Limit',
    name: 'limit',
    rules: [
      {
        required: true,
        message: 'Please enter limit!'
      }
    ],
    placeholder: 'Enter limit'
  },
  defaultCost: {
    label: 'Cost',
    name: 'defaultCost',
    rules: [
      {
        required: true,
        message: 'Please enter cost!'
      }
    ],
    placeholder: 'Enter cost'
  },
  cost: {
    label: 'Cost',
    name: 'cost',
    rules: [
      {
        required: true,
        message: 'Please enter cost!'
      }
    ],
    placeholder: 'Enter cost'
  },
  membershipClassPrice: {
    label: 'Membership Class Price',
    name: 'membershipClassPrice',
    rules: [
      {
        required: true,
        message: 'Please enter membership class price!'
      }
    ],
    placeholder: 'Enter membership class price'
  },
  notesForClient: {
    label: 'Notes for client',
    name: 'notesForClient',
    placeholder: 'Enter notes for client'
  },
  notesForCompany: {
    label: 'Notes for you',
    name: 'notesForCompany',
    placeholder: 'Enter notes for company'
  },
  classTime: {
    label: 'Time',
    name: 'classTime',
    rules: [
      {
        required: true,
        message: 'Please select time!'
      }
    ],
    placeholder: 'Enter time'
  },
  classDate: {
    label: 'Date',
    name: 'classDate',
    rules: [
      {
        required: true,
        message: 'Please select date!'
      }
    ],
    placeholder: 'Enter date'
  },
  defaultMinimalTimeCancellationInHours: {
    label: 'Minimal cancellation time (in hours)',
    name: 'defaultMinimalTimeCancellationInHours',
    rules: [
      {
        required: true,
        message: 'Please select minimal time cancellation'
      }
    ],
    placeholder: 'Enter minimal cancellation time'
  },
  minimalTimeCancellationInHours: {
    label: 'Minimal cancellation time (in hours)',
    name: 'minimalTimeCancellationInHours',
    rules: [
      {
        required: true,
        message: 'Please select minimal time cancellation'
      }
    ],
    placeholder: 'Enter minimal cancellation time'
  },
  defaultDuration: {
    label: 'Duration (in minutes)',
    name: 'defaultDuration',
    rules: [
      {
        required: true,
        message: 'Please enter duration'
      }
    ],
    placeholder: 'Enter duration'
  },
  duration: {
    label: 'Duration (in minutes)',
    name: 'duration',
    rules: [
      {
        required: true,
        message: 'Please enter duration'
      }
    ],
    placeholder: 'Enter duration'
  },
  private: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Private',
    name: 'private',
    placeholder: ''
  },
  enabled: {
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Enabled',
    name: 'enabled',
    placeholder: ''
  },
  selectGymsAccess: {
    label: "Select gym's access",
    name: 'gymId',
    placeholder: "Select gym's access"
  },
  generalNotes: {
    label: 'General notes',
    name: 'generalNotes',
    placeholder: 'Enter general notes'
  },
  paymentNotes: {
    label: 'Notes',
    name: 'paymentNotes',
    placeholder: 'Enter payment notes'
  },
  selectGyms: {
    label: 'Select gym access',
    name: 'gymId',
    placeholder: 'Select gyms'
  },
  selectGym: {
    label: 'Select gym',
    name: 'gymId',
    placeholder: 'Select gym'
  },
  termsUrl: {
    label: 'Terms URL',
    name: 'termsUrl',
    rules: [
      {
        required: true,
        message: 'Please enter terms url!'
      }
    ],
    placeholder: 'Enter terms URL'
  },
  currency: {
    label: 'Currency',
    name: 'currency',
    rules: [
      {
        required: true,
        message: 'Please select currency!'
      }
    ],
    placeholder: 'Enter currency'
  },
  address: {
    label: 'Address',
    name: 'address',
    rules: [
      {
        min: 3,
        message: 'Address must be minimum 3 characters!'
      },
      {
        required: true,
        message: 'Please enter address!'
      }
    ],
    placeholder: 'Enter address'
  },
  gymUrl: {
    label: 'Gym URL',
    name: 'urlName',
    rules: [
      {
        required: true,
        message: 'Please enter gym name!'
      }
    ],
    placeholder: 'Enter gym URL'
  },
  gymName: {
    label: 'Gym name',
    name: 'name',
    rules: [
      {
        required: true,
        message: 'Please enter gym name!'
      }
    ],
    placeholder: 'Enter gym name'
  },
  rememberMe: {
    name: 'rememberMe',
    label: null,
    isCheckbox: true,
    checkboxLabel: 'Remember me',
    placeholder: ''
  },
  marketing: {
    name: 'marketing',
    label: null,
    isCheckbox: true,
    checkboxLabel: "We'd love to keep you up to date with our news & offers, tick here if you'd like to stay in touch",
    placeholder: ''
  },
  confirmPassword: {
    label: 'Confirm password',
    name: 'confirmPassword',
    dependencies: ['password'],
    hasFeedback: true,
    rules: [
      {
        required: true,
        message: 'Please confirm your password!'
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('The password that you entered do not match!'));
        }
      })
    ],
    placeholder: 'Enter password'
  },
  emergencyPhone: {
    label: 'Emergency contact phone',
    name: 'emergency_contact_number',
    rules: [
      {
        pattern: '^[0]',
        message: 'Phone name must starts with 0!'
      },
      {
        required: true,
        message: 'Please enter phone number!'
      }
    ],
    placeholder: 'Enter emergency phone'
  },
  emergencyName: {
    label: 'Emergency contact name',
    name: 'emergency_contact_name',
    rules: [
      {
        min: 2,
        message: 'Name must be minimum 2 characters!'
      },
      {
        required: true,
        message: 'Please enter name!'
      }
    ],
    placeholder: 'Enter emergency contact name'
  },
  dateofbirth: {
    label: 'Date of birth',
    name: 'dateofbirth',
    onlyPast: true,
    placeholder: 'Enter date of birth',
    isCheckbox: false
  },
  title: {
    label: 'Title',
    name: 'title',
    rules: [
      {
        required: true,
        message: 'Please select title!'
      }
    ],
    placeholder: 'Enter title'
  },
  postcode: {
    label: 'Postcode',
    name: 'postcode',
    rules: [
      {
        min: 2,
        message: 'Last name must be minimum 2 characters!'
      },
      {
        required: true,
        message: 'Please enter postcode!'
      }
    ],
    placeholder: 'Enter postcode'
  },
  city: {
    label: 'City',
    name: 'city',
    rules: [
      {
        min: 2,
        message: 'Last name must be minimum 2 characters!'
      },
      {
        required: true,
        message: 'Please enter city!'
      }
    ],
    placeholder: 'Enter city'
  },
  addressLine: {
    label: 'Address line',
    name: 'address_line1',
    rules: [
      {
        min: 3,
        message: 'Address must be minimum 3 characters!'
      },
      {
        required: true,
        message: 'Please enter address!'
      }
    ],
    placeholder: 'Enter address line'
  },
  phone: {
    label: 'Phone number',
    name: 'phone_number',
    rules: [
      {
        pattern: '^[0]',
        message: 'Phone name must starts with 0!'
      },
      {
        required: true,
        message: 'Please enter phone number!'
      }
    ],
    placeholder: 'Enter phone'
  },
  lastName: {
    label: 'Last name',
    name: 'last_name',
    rules: [
      {
        min: 2,
        message: 'Last name must be minimum 2 characters!'
      },
      {
        required: true,
        message: 'Please enter last name!'
      }
    ],
    placeholder: 'Enter last name'
  },
  userName: {
    label: 'First name',
    name: 'name',
    rules: [
      {
        min: 2,
        message: 'Name must be minimum 2 characters!'
      },
      {
        required: true,
        message: 'Please enter name!'
      }
    ],
    placeholder: 'Enter first name'
  },
  email: {
    label: 'E-mail',
    name: 'email',
    rules: [
      {
        type: 'email',
        message: 'Please enter valid e-mail!'
      },
      {
        required: true,
        message: 'Please enter e-mail!'
      }
    ],
    placeholder: 'Enter email'
  },
  password: {
    label: 'Password',
    name: 'password',
    rules: [
      {
        required: true,
        message: 'Please enter password!'
      }
    ],
    placeholder: 'Enter password'
  }
};
