import { useContext, useEffect, useState } from 'react';
import classes from './AcceptStatements.module.scss';
import { Checkbox } from 'antd';
import Button from '../../components/UI/Button/Button';

import TermsOfService from '../Pages/TermsOfService/TermsOfService';
import TermsOfWebsiteText from '../../brandConfig/components/TermsOfWebsitePage/TermsOfWebsitePage';
import HealthStatement from '../../brandConfig/components/HealthStatement/HealthStatement';
import { useQuery } from '@tanstack/react-query';
import { fetchGymDetails } from '../../apiFunctions/apiFunctions';
import { AppContext } from '../../context/App/AppContext';
import { useNavigate } from 'react-router-dom';
import Notification from '../../components/UI/Notification/Notification';

const AcceptStatements = ({ selectedClient = null, handleCloseModal = () => {} }) => {
  const { userData, selectedGym, onUpdateUserData } = useContext(AppContext);
  const [stepNo, setStepNo] = useState(1);
  const [healthStatementAccepted, setHealthStatementAccepted] = useState(false);
  const [termsOfWebsiteAccepted, setTermsOfWebsiteAccepted] = useState(false);
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState(false);
  const [termsOfServiceLoaded, setTermsOfServiceLoaded] = useState(false);
  const navigate = useNavigate();
  const gymId = userData.isUser ? userData.gymId : selectedGym._id;
  const userId = userData.isUser ? userData._id : selectedClient._id;

  const { data: gymData, isError } = useQuery(['usersList'], () => fetchGymDetails(gymId));

  useEffect(
    () => {
      if (healthStatementAccepted && termsOfWebsiteAccepted && termsOfServiceAccepted) {
        onUpdateUserData({ userId, data: { areTermsAndConditionsAccepted: true } });
      }
    },
    // eslint-disable-next-line
    [healthStatementAccepted, termsOfWebsiteAccepted, termsOfServiceAccepted]
  );

  const termsOfWebsite = stepNo === 1 && (
    <>
      <TermsOfWebsiteText />
      <Checkbox
        className="my-8 text-lg text-secondary"
        checked={termsOfWebsiteAccepted}
        onChange={() => setTermsOfWebsiteAccepted((state) => !state)}>
        I Accept Terms of Website
      </Checkbox>
    </>
  );
  const termsOfService = stepNo === 2 && (
    <>
      <TermsOfService termsUrl={gymData.termsUrl} onComponentDidMount={setTermsOfServiceLoaded} />
      {termsOfServiceLoaded && (
        <Checkbox
          className="my-8 text-lg text-secondary"
          checked={termsOfServiceAccepted}
          onChange={() => setTermsOfServiceAccepted((state) => !state)}>
          I Accept the Facility Terms & Conditions
        </Checkbox>
      )}
    </>
  );

  const healthStatement = stepNo === 3 && (
    <>
      <HealthStatement />
      <Checkbox
        className="my-8 text-lg text-secondary"
        checked={healthStatementAccepted}
        onChange={() => setHealthStatementAccepted((state) => !state)}>
        I Accept Health Statement
      </Checkbox>
    </>
  );

  const successMsg = stepNo === 4 && (
    <div className={classes.text}>
      {userData.isUser
        ? 'Thank You. You can now access your account to book your sessions.'
        : "Customer's legal statements have been updated"}
    </div>
  );

  const onBtnClick = () => {
    if (stepNo === 4) {
      if (userData.isUser) {
        navigate('/gym');
      } else {
        handleCloseModal();
      }
    } else {
      setStepNo(stepNo + 1);
      if (userData.isUser) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  const nextBtn =
    !termsOfServiceLoaded && stepNo === 2 ? null : (
      <Button
        disabled={
          stepNo === 1
            ? !termsOfWebsiteAccepted
            : stepNo === 2
              ? !termsOfServiceAccepted
              : stepNo === 3
                ? !healthStatement
                : false
        }
        label="Next"
        secondary
        onClick={onBtnClick}
        fullWidth
      />
    );

  if (isError) {
    return <Notification defaultMsg />;
  }

  return (
    <div>
      <div className="uppercase text-secondary text-3xl text-center font-bold my-14">
        Before you can access your account, please read and accept all terms and conditions and statements.
      </div>
      {termsOfWebsite}
      {termsOfService}
      {healthStatement}
      {successMsg}
      {nextBtn}
    </div>
  );
};

export default AcceptStatements;
