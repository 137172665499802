import logo from '../../../brandConfig/assets/logo-icons/brand-logo.png';
import logoDark from '../../../brandConfig/assets/logo-icons/brand-logo-for-dark-background.png';
import { useNavigate } from 'react-router-dom';
export default ({ isAuthenticated = false, dark = false }) => {
  const navigate = useNavigate();
  return (
    <img
      data-cy="header_logo"
      className="cursor-pointer z-20 object-contain max-h-[60px]  "
      src={dark ? logoDark : logo}
      alt="Logo"
      onClick={() => navigate(isAuthenticated ? '/gym' : '/')}
    />
  );
};
