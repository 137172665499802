import { useContext } from 'react';
import { returnUserClassesLeftAmount } from '../../../../shared/userFunctions';
import { returnClassPrice } from '../../../../shared/classFunctions';
import { getCurrencySymbol } from '../../../../shared/utility';

import { AppContext } from '../../../../context/App/AppContext';

const ReturnClassPrice = ({ selectedClass }) => {
  const { userData, selectedGym } = useContext(AppContext);
  const classType = selectedClass.__t;
  const classesLeft = returnUserClassesLeftAmount(classType, userData, selectedClass);
  const classPrice = returnClassPrice(selectedClass, userData);
  let price = 'Free';
  const membershipClassPrice = selectedGym.settings.membershipProducts ? selectedClass.membershipClassPrice : null;
  const currency = getCurrencySymbol(selectedClass.currency);

  if (userData.role === 'user') {
    price =
      userData.customerType === 'full-member' || classesLeft > 0 ? null : <div>{currency + ' ' + classPrice}</div>;
  } else {
    price = (
      <>
        {currency}
        {selectedClass.cost} {membershipClassPrice && `(${currency}${membershipClassPrice})`}
      </>
    );
  }

  return price;
};

export default ReturnClassPrice;
