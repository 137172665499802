import Icon from '../Icon/Icon';
import { classNames } from '../../../shared/utility';

const NoResultsFound = ({ className = '', text = 'No results found' }) => (
  <div className={classNames('my-4 mx-auto text-center', className)}>
    <Icon name={'noData'} className="mx-auto" size={40} />
    <h3>{text}</h3>
  </div>
);

export default NoResultsFound;
