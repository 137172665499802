import { Checkbox } from 'antd';
import useCustomCheckboxes from './useCustomCheckboxes';

const CustomCheckboxes = ({
  checkboxes = [],
  defaultChecked = [],
  defaultCheckedAll = false,
  onChange = () => {},
  className = null
}) => {
  const initialState = defaultCheckedAll ? checkboxes.map((elm) => elm.value) : defaultChecked;
  const { onCheckboxCheck, selected } = useCustomCheckboxes(initialState, onChange);

  return (
    <div className={className}>
      {checkboxes.map(({ label, value }) => (
        <Checkbox checked={selected[value]} onChange={() => onCheckboxCheck(value)} key={value}>
          {label}
        </Checkbox>
      ))}
    </div>
  );
};

export default CustomCheckboxes;
