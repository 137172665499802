import { useState } from 'react';
import axios from '../../../axios-global';
import ProfilePicture from '../../UI/ProfilePicture/ProfilePicture';
import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import './ClientProfileMainDetails.scss';
import Button from '../../UI/Button/Button';
import Title from '../../UI/Title/Title';

import { CLIENTS_TYPES } from '../../../constants';
import ModalButtonsContainer from '../../UI/ModalButtonsContainer/ModalButtonsContainer';
import { Modal } from 'antd';

const ClientProfileMainDetails = ({ selectedClient, isAdmin }) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const recordAttendance = () =>
    axios
      .post(`/gym-attendances`, {
        attendanceTime: new Date(),
        userId: selectedClient._id,
        gymId: selectedClient.gymId
      })
      .then(() => {
        handleClose();
      })
      .catch(() => {
        handleClose();
      });

  return (
    <>
      <Modal close={handleClose} open={showModal}>
        Record Attendance
        <ModalButtonsContainer>
          <Button label="Confirm" secondary onClick={recordAttendance} />
          <Button label="Cancel" primary onClick={handleClose} />
        </ModalButtonsContainer>
      </Modal>
      <div className="titleAndBtnContainer">
        <Title title={`${selectedClient.name} ${selectedClient.last_name}`} />
        <Button label="Record Attendance" secondary onClick={handleShow} />
      </div>

      <div className="grid grid-cols-[65%_35%]">
        <div>
          <h3 className="uppercase mt-2">
            {selectedClient.customerType === CLIENTS_TYPES.CLASS_PASS_MEMBER
              ? 'PAYG (Class Pass)'
              : selectedClient.currentMembershipPlan
                ? `Membership - ${selectedClient.currentMembershipPlan.planName}`
                : ''}
          </h3>
          <ProfileTextWithLabel label={'Email'} text={`${selectedClient.email}`} />
          <ProfileTextWithLabel label={'Phone'} text={`${selectedClient.phone_number}`} />
          <ProfileTextWithLabel label={'Emergency contact name'} text={`${selectedClient.emergency_contact_name}`} />
          <ProfileTextWithLabel label={'Emergency contact phone'} text={`${selectedClient.emergency_contact_number}`} />
          <ProfileTextWithLabel
            show={selectedClient.gymSettings.qrCode}
            label={'QR Code'}
            text={`${
              selectedClient.qrCode && selectedClient.qrCode !== ''
                ? selectedClient.qrCode + ' - extra/external'
                : 'Internal/no extra QR code set'
            }`}
          />
        </div>
        <ProfilePicture user={selectedClient} editable={isAdmin} />
      </div>
    </>
  );
};
export default ClientProfileMainDetails;
