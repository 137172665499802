import { useContext } from 'react';
import Loading from '../../../../components/Loading/Loading';
import Notification from '../../../../components/UI/Notification/Notification';
import { useQuery } from '@tanstack/react-query';
import { fetchClientUpcomingClasses } from '../../../../apiFunctions/apiFunctions';
import { returnClassConfig } from '../../../../shared/gymFunctions';
import { AppContext } from '../../../../context/App/AppContext';
import { CLASS } from '../../../../constants';
import { returnClassDate, returnClassDateAndTimeEnds, returnClassTime } from '../../../../shared/classFunctions';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import ClassActionButtons from '../../../ActionButtons/ClassActionButtons/ClassActionButtons';
import { Tooltip } from 'antd';
import Icon from '../../../../components/UI/Icon/Icon';

const SingleUpcomingClasses = ({ classType }) => {
  const { selectedGym } = useContext(AppContext);
  const {
    data: upcomingClasses,
    isLoading: upcomingClassesLoading,
    error: upcomingClassesError
  } = useQuery(['clientUpcomingClasses', classType.type], () => fetchClientUpcomingClasses(classType.type));
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType.type);

  if (upcomingClassesLoading) {
    return <Loading />;
  }
  if (upcomingClassesError) {
    return <Notification defaultMsg />;
  }
  const returnInfoContent = ({ name, duration, trainer }) => (
    <div className={'grid grid-cols-[80px_1fr]'}>
      {classType.type === CLASS.CLASS && (
        <>
          <div>Name: </div>
          <div>{name}</div>
        </>
      )}
      <div>Duration: </div>
      <div>{duration} mins</div>
      {classType.type === CLASS.PERSONAL_TRAINING && (
        <>
          <div>Trainer: </div>
          <div>
            {trainer.name} {trainer.last_name}
          </div>
        </>
      )}
    </div>
  );

  return upcomingClasses.length > 0 ? (
    <div className="my-4">
      <h2>{classType.title} bookings </h2>
      <div className="md:grid grid-cols-2 gap-4 ">
        {upcomingClasses
          .filter((classes) => classes.status === 'active' || classes.status === 'pending')
          .map((elm) => ({
            ...elm.class,
            userAttendanceStripeOrderId: elm.stripeOrderId,
            userAttendanceStatus: elm.status,
            userAttendanceId: elm._id
          }))
          .map((item) => (
            <div key={item._id} className={'bg-custom-add-to-calendar-bg my-2.5 md:my-0'}>
              <div className={'flex justify-between'}>
                <AddToCalendarButton
                  styleLight="--btn-border-radius: 0px; --btn-shadow: 0px; --date-btn-shadow: 0px; --date-btn-hover-shadow: 0px;"
                  name={classType.type === CLASS.CLASS ? item.name : classType.title}
                  startDate={returnClassDate(item.classDate, 'YYYY-MM-DD').toString()}
                  endDate={returnClassDateAndTimeEnds(
                    item.classDate,
                    item.classTime,
                    item.duration,
                    'YYYY-MM-DD'
                  ).toString()}
                  startTime={returnClassTime(item.classTime, 'HH:mm').toString()}
                  endTime={returnClassDateAndTimeEnds(
                    item.classDate,
                    item.classTime,
                    item.duration,
                    'HH:mm'
                  ).toString()}
                  options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo', 'MicrosoftTeams', 'Microsoft365']}
                  location={item.locationName}
                  hideTextLabelButton
                  buttonStyle="date"
                  hideBackground></AddToCalendarButton>

                <Tooltip placement="rightTop" title={returnInfoContent(item)}>
                  <Icon name={'info'} size={30} />
                </Tooltip>
              </div>
              <div className={'text-center'}>
                <ClassActionButtons
                  cancelType={'upcoming'}
                  selectedClass={item}
                  isHistoryComponent={false}
                  classConfig={classConfig}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : null;
};

export default SingleUpcomingClasses;
