import Title from 'antd/es/skeleton/Title';
import PaymentMethods from './PaymentMethods';

const PaymentMethodsPage = () => {
  return (
    <div className={'body-container sm:w-3/4 md:w-1/3'}>
      <Title tile="Payment methods" subtitle="Please add card details" />
      <PaymentMethods />
    </div>
  );
};

export default PaymentMethodsPage;
