import { Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../../UI/Icon/Icon';
import SideDrawer from '../SideDrawer/SideDrawer';

const MenuNavigation = ({ menuItems, show = true, dashboardLink, showSideDrawer, setShowSideDrawer }) => {
  if (menuItems.length === 0) {
    return null;
  }
  const items = menuItems
    .filter((elm) => elm.showInMenu)
    .map((elm, index) => ({
      label: (
        <Link className="hover:no-underline p-2 " to={elm.link}>
          {elm.name}
        </Link>
      ),
      key: index
    }));

  if (show && items.length > 0) {
    return (
      <>
        <Dropdown menu={{ items }} className="px-2 hidden md:block">
          <a key={items.name} onClick={(e) => e.preventDefault()}>
            <Icon name={'menu'} size={30} />
          </a>
        </Dropdown>

        <Icon name={'menu'} size={30} className={'md:hidden'} onClick={() => setShowSideDrawer(true)} />
        <SideDrawer
          className={'md:hidden'}
          menuItems={menuItems}
          dashboardLink={dashboardLink}
          showSideDrawer={showSideDrawer}
          setShowSideDrawer={setShowSideDrawer}
        />
      </>
    );
  }
  return null;
};

export default MenuNavigation;
