import { useContext, useState } from 'react';
import QrReader from 'react-qr-reader';
import notificationAudio from '../../../assets/notification-admin.mp3';
import classes from './AdminQrScanner.module.scss';
import { returnErrorFromResponse } from '../../../shared/utility';
import { AppContext } from '../../../context/App/AppContext';
import { NotificationContext } from '../../../context/Notification/NotificationContext';
import Toggle from '../../../components/UI/Toggle/Toggle';
import Icon from '../../../components/UI/Icon/Icon';
import { useNavigate } from 'react-router-dom';

const AdminQrScanner = () => {
  const { selectedGym } = useContext(AppContext);
  const { openNotification } = useContext(NotificationContext);
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const [isSwitchOnScanner, setIsSwitchOnScanner] = useState(true);
  const [isShowPreview, setIsShowPreview] = useState(true);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const handleError = (error) => {
    openNotification(null, 'error', `QR scanner error: ${returnErrorFromResponse(error)}`);
    setIsError(true);
  };

  const handleScan = (data) => {
    if (data) {
      new Audio(notificationAudio).play();
      setIsError(false);

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      navigate(`/gym/${selectedGym._id}/clients/${data}`);
    }
  };

  const scanner = isSwitchOnScanner ? (
    <div style={isShowPreview ? { display: 'block' } : { display: 'none' }}>
      <QrReader
        delay={1000}
        onError={handleError}
        onScan={(data) => handleScan(data)}
        showViewFinder={false}
        style={{ width: '150px', marginRight: '20px' }}
      />
    </div>
  ) : null;

  if (selectedGym?.settings.qrCode) {
    return isSmallScreen ? (
      <>
        <div className={classes.smallPreview} onClick={() => setIsSmallScreen((state) => !state)}>
          QR SCANNER
          <Icon
            name={isError ? 'statusCritical' : isSwitchOnScanner ? 'statusOk' : 'statusDisabled'}
            style={{ marginLeft: '5px', zIndex: 1000 }}
          />
          {scanner}
        </div>
      </>
    ) : (
      <div className={classes.scannerBox}>
        <Icon name={'next'} className={classes.closeIcon} onClick={() => setIsSmallScreen((state) => !state)} />

        <div className="flex">
          {scanner}

          <div className="mt-8">
            <Toggle
              label="Scanner"
              disabled={isError}
              checked={isSwitchOnScanner}
              onChange={() => setIsSwitchOnScanner((state) => !state)}
            />
            <Toggle
              label="Preview"
              disabled={!isSwitchOnScanner || isError}
              checked={isShowPreview && !isError}
              onChange={() => setIsShowPreview((state) => !state)}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default AdminQrScanner;
