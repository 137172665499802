import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Loading from '../../../components/Loading/Loading';
import { isValueCorrect } from '../../../shared/userFunctions';
import { AppContext } from '../../../context/App/AppContext';

const TermsOfService = ({
  termsUrl = null,
  onComponentDidMount = () => {},
  setShowButtons = () => {},
  termsLoadFailed
}) => {
  const { selectedGym } = useContext(AppContext);
  const [terms, setTerms] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);

  useEffect(
    () => {
      setLoading(true);
      setShowButtons(false);

      const getTerms = async () => {
        const url = isValueCorrect(termsUrl) ? termsUrl : isValueCorrect(selectedGym) ? selectedGym.termsUrl : '';
        try {
          const termsResponse = await axios.get(url);
          setTerms(termsResponse.data.terms);
          setLoading(false);
          termsLoadFailed !== undefined && termsLoadFailed(false);
          setFailedToLoad(false);
          onComponentDidMount(true);
          setShowButtons(true);
        } catch (error) {
          setLoading(false);
          termsLoadFailed !== undefined && termsLoadFailed(true);
          setFailedToLoad(true);
          setTerms('We are experiencing some issues please try to create your account at a later date.');
          onComponentDidMount(false);
          setShowButtons(true);
        }
      };

      getTerms();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (isLoading) {
    return <Loading />;
  }
  if (failedToLoad) {
    return (
      <div className="body-container">
        <div className="container--center">
          <h3 style={{ marginTop: '10%', textAlign: 'center', lineHeight: '60px' }}>
            Sorry, we're facing some technical issues showing the terms and conditions. Please visit again soon or
            contact us to find out when the issue will be resolved.
          </h3>
        </div>
      </div>
    );
  }

  return (
    <dic data-cy={'terms_and_conditions'} className="body-container">
      <div dangerouslySetInnerHTML={{ __html: terms }}></div>
    </dic>
  );
};

export default TermsOfService;
