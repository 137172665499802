import companyDetails from '../../companyDetails';
import Title from '../../../components/UI/Title/Title';

export default () => (
  <>
    <Title title={'HEALTH STATEMENTS'} />

    <h3>Pre-Exercise Agreement</h3>

    <div className="my-4">
      Please read the following statements and the accompanying {companyDetails.name} (“the Company”) Terms & Conditions
      for the Club which incorporate the following statements and confirm that you are in full agreement with them
      before using the Club by ticking the box below.
    </div>
    <div className="my-4">
      Many health benefits are associated with regular exercise. For most people physical exercise should not pose any
      problem or hazard. You are, however, responsible for making sure you are physically fit before starting any form
      of exercise and should not exercise beyond your own abilities.
    </div>
    <div className="my-4">
      If you are concerned that you have a medical condition which might interfere with you exercising safely or if
      there is any other reason why you should take care exercising, for example, you are pregnant, then before you use
      the equipment and facilities you should get advice from a relevant medical professional and follow that advice.
    </div>
    <div className="my-4">
      You must notify us of any pre-existing medical condition(s). We reserve the right to ask you to produce a doctor’s
      letter or certificate of fitness before you will be allowed to use the Club.
    </div>
    <div className="my-4">
      You should make yourself aware of any rules and instructions, including warning notices. Exercise carries its own
      risks. You should not carry out any activities which you have been told are not suitable for you. If you feel
      unwell you should stop exercising immediately and let somebody know so that appropriate medical advice can be
      sought.
    </div>

    <h3>Limitation of Liability</h3>

    <div className="my-4">
      By signing this Agreement and/or using the Club and equipment contained therein you acknowledge that there are
      certain inherent risks and dangers related to exercise. You acknowledge that you have voluntarily chosen to
      participate in a programme of physical exercise within the Club. You further acknowledge that where inductions are
      solely given virtually or you choose to be given a virtual induction, that it is your sole responsibility to
      ensure that you fully understand the information provided and that where you are unsure about how to use the
      equipment, that you contact us for further guidance
    </div>

    <div className="my-4">
      All activities and treatments are taken at the Member’s or Guests’ own risk. Neither the Club,{' '}
      {companyDetails.name}, their employees, servants or agents shall be liable for any personal injury sustained by
      Members or Guests whilst on the Club’s premises, except in so far as it can be proven that this relates to the
      wilful act, neglect or default of the Club, the Company, their employees, servants or agents. Members or Guests
      who suffer an accident or injury on the Club’s premises must report the accident or injury, and the circumstances
      in which it occurred, to the Club immediately following the accident. Accidents should be reported immediately to
      relevant Estate Staff, in normal circumstances this would be the property/estate manager or concierge.
    </div>

    <h3>Declaration</h3>

    <div className="my-4">
      I confirm that I know of no reason why I should not participate in any form of physical exercise. I acknowledge
      that I participate in the use of the facilities at my own risk and take full responsibility for my actions.
    </div>
    <div className="my-4">
      I also confirm that I have read, understood and agree to be bound by this Agreement and the Terms and Conditions
      relating to use of the Club and facilities.
    </div>
    <div className="my-4">
      I understand that any breaches of the Terms and Conditions may result in the withdrawal of access to the{' '}
      {companyDetails.name} facilities. I also acknowledge that these Terms and Conditions may change subject to notice
      by the Company.
    </div>
    <div className="my-4">
      I can confirm that I have been shown/will ensure that I am shown how to use all the equipment. In the case of
      viewing a virtual induction prior to using the facilities, I agree that I will view all of the virtual induction
      content in its entirety before using the facilities. I also understand that I must not use any item of exercise
      equipment that I am unfamiliar with and I understand that I should ask a qualified professional for assistance if
      I am unsure and I understand how to seek advice in the event that I am unsure about how to use any such exercise
      equipment.
    </div>
    <div className="my-4">
      I agree that this is an unmanned and unsupervised facility and that the Company cannot therefore accept any
      responsibility for any injury/health complaint that may occur.
    </div>

    <h3>Pre-Exercise Agreement</h3>

    <div className="my-4">
      Please read the following statements and the accompanying {companyDetails.name} (“the Company”) Terms & Conditions
      for the Club which incorporate the following statements and confirm that you are in full agreement with them
      before using the Club by ticking the box below.
    </div>
    <div className="my-4">
      Many health benefits are associated with regular exercise. For most people physical exercise should not pose any
      problem or hazard. You are, however, responsible for making sure you are physically fit before starting any form
      of exercise and should not exercise beyond your own abilities.
    </div>
    <div className="my-4">
      If you are concerned that you have a medical condition which might interfere with you exercising safely or if
      there is any other reason why you should take care exercising, for example, you are pregnant, then before you use
      the equipment and facilities you should get advice from a relevant medical professional and follow that advice.
    </div>
    <div className="my-4">
      You must notify us of any pre-existing medical condition(s). We reserve the right to ask you to produce a doctor’s
      letter or certificate of fitness before you will be allowed to use the Club.
    </div>
    <div className="my-4">
      You should make yourself aware of any rules and instructions, including warning notices. Exercise carries its own
      risks. You should not carry out any activities which you have been told are not suitable for you. If you feel
      unwell you should stop exercising immediately and let somebody know so that appropriate medical advice can be
      sought.
    </div>

    <h3>Limitation of Liability</h3>

    <div className="my-4">
      By signing this Agreement and/or using the Club and equipment contained therein you acknowledge that there are
      certain inherent risks and dangers related to exercise. You acknowledge that you have voluntarily chosen to
      participate in a programme of physical exercise within the Club.
    </div>

    <div className="my-4">
      All activities and treatments are taken at the Member’s or Guests’ own risk. Neither the Club,{' '}
      {companyDetails.name}, their employees, servants or agents shall be liable for any personal injury sustained by
      Members or Guests whilst on the Club’s premises, except in so far as it can be proven that this relates to the
      wilful act, neglect or default of the Club, the Company, their employees, servants or agents. Members or Guests
      who suffer an accident or injury on the Club’s premises must report the accident or injury, and the circumstances
      in which it occurred, to the Club immediately following the accident or
    </div>

    <h3>Declaration</h3>

    <div className="my-4">
      I confirm that I know of no reason why I should not participate in any form of physical exercise. I acknowledge
      that I participate in the use of the facilities at my own risk and take full responsibility for my actions.
    </div>
    <div className="my-4">
      I also confirm that I have read, understood and agree to be bound by this Agreement and the Terms and Conditions
      relating to use of the Club and facilities.
    </div>
    <div className="my-4">
      I understand that any breaches of the Terms and Conditions may result in the withdrawal of access to the
      {companyDetails.name} facilities. I also acknowledge that these Terms and Conditions may change subject to notice
      by the Company.
    </div>
    <div className="my-4">
      I also understand that it is strongly recommended that I do not use any item of exercise equipment that I am
      unfamiliar with and I understand that I should ask a qualified professional for assistance if I am unsure. I have
      been shown/will ensure that I am shown how to use all the equipment and agree that this is an unmanned and
      unsupervised facility and that the Company cannot therefore accept any responsibility for any injury/health
      complaint that may occur.
    </div>
  </>
);
