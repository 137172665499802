import { useContext, useState } from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import Button from '../../../../components/UI/Button/Button';
import { payForAttendance, refreshFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../../shared/utility';
import ModalButtonsContainer from '../../../../components/UI/ModalButtonsContainer/ModalButtonsContainer';
import { NotificationContext } from '../../../../context/Notification/NotificationContext';
import Icon from '../../../../components/UI/Icon/Icon';

//TODO:fix isMobile
//TODO: currency - icon to fix

const PayButton = ({ attendanceId, stripeOrderId, classType }) => {
  const { openNotification } = useContext(NotificationContext);
  const [showModal, setShowModal] = useState(false);
  const isMobile = false;

  const mutatePayForAttendance = useMutation(() => payForAttendance(attendanceId, stripeOrderId), {
    onSuccess: () => {
      openNotification('payment');
      setShowModal(false);
      refreshFetchFunctions([['upcomingClasses', classType], ['clientUpcomingClasses', classType], ['userData']]);
    },
    onError: (err) => {
      openNotification(null, 'error', returnErrorFromResponse(err));
    }
  });

  const paymentModal = (
    <Modal close={() => setShowModal(false)} open={showModal}>
      <div className="grommet">
        Do you want to pay for chosen class?
        <br />
        This class will be paid for using your default card payment. You can change it{' '}
        <Link to="/gym/settings#payments">here</Link> if you prefer.
        <ModalButtonsContainer isLoading={mutatePayForAttendance.isLoading}>
          <Button label="Yes" secondary onClick={mutatePayForAttendance.mutate} />
          <Button label="Cancel" primary onClick={() => setShowModal(false)} />
        </ModalButtonsContainer>
      </div>
    </Modal>
  );

  return (
    <div>
      {paymentModal}
      {!isMobile ? (
        <Button label="Pay for class" secondary={true} onClick={() => setShowModal(true)} dataCy="payForClass" />
      ) : (
        <Icon name={'currency'} onClick={() => setShowModal(true)} data-cy="payForClass" />
      )}
    </div>
  );
};

export default PayButton;
