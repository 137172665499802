import { useContext, useState } from 'react';
import Header from '../../components/Navigation/Header/Header';

import Footer from '../../brandConfig/components/Footer/Footer';

import { useLocation } from 'react-router-dom';
import CookieBanner from '../../components/UI/CookieBanner/CookieBanner';
import { AppContext } from '../../context/App/AppContext';
import Loading from '../../components/Loading/Loading';
import Error404 from '../../components/UI/Error404/Error404';

const Layout = ({ children }) => {
  const { isAppLoading, isGlobalError } = useContext(AppContext);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const location = useLocation();

  if (isAppLoading) {
    return <Loading size={100} className="h-screen items-center" />;
  }

  return (
    <>
      <Header location={location} setShowSideDrawer={setShowSideDrawer} showSideDrawer={showSideDrawer} />

      <main id="body" onClick={() => setShowSideDrawer(false)}>
        {isGlobalError ? <Error404 errorMsg={'The service  is unavailable'} errorNumber={'503'} /> : children}
      </main>

      <Footer />
      <CookieBanner />
    </>
  );
};

export default Layout;
