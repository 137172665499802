import { useContext } from 'react';
import RedirectToUserDetails from '../../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import { useMutation } from '@tanstack/react-query';
import { changeAttendanceStatus, refreshFetchFunctions } from '../../../../../apiFunctions/apiFunctions';
import { returnFirstErrorMsg } from '../../../../../shared/utility';
import CancelAttendanceOrClass from '../../../../ActionButtons/ClassActionButtons/CancelAttandanceOrClass/CancelAttendanceOrClass';
import SelectAndPayForProduct from '../../../../ActionButtons/SelectAndPayForProduct/SelectAndPayForProduct';
import { NotificationContext } from '../../../../../context/Notification/NotificationContext';
import { AppContext } from '../../../../../context/App/AppContext';
import { Checkbox } from 'antd';

const defaultDescription = (type, attendanceList, singleClass) => {
  const active = {
    header: `ATTENDANCE LIST (${attendanceList.length}/${singleClass.limit})`,
    noUsers: `No attendees`
  };

  const waiting = {
    header: `WAITING LIST (${attendanceList.length})`,
    noUsers: `No waiting list`
  };

  const pending = {
    header: `AWAITING PAYMENT (${attendanceList.length})`,
    noUsers: `No payments pending`
  };

  const lateCancellation = {
    header: `LATE CANCELLATION (${attendanceList.length})`,
    noUsers: `No late cancellations`
  };

  const cancelled = {
    header: `CANCELLED (${attendanceList.length})`,
    noUsers: `No cancellations`
  };
  return type === 'active'
    ? active
    : type === 'pending'
      ? pending
      : type === 'late-cancellation'
        ? lateCancellation
        : type === 'cancelled'
          ? cancelled
          : waiting;
};

const RenderAttendees = ({ attendanceList, type, singleClass, isLimitReached }) => {
  const { openNotification } = useContext(NotificationContext);
  const { userData } = useContext(AppContext);
  const description = defaultDescription(type, attendanceList, singleClass);

  const mutateUpdateAttendance = useMutation(
    ({ attendanceId, status }) => changeAttendanceStatus(attendanceId, status),
    {
      onSuccess: () => {
        openNotification(null, 'success', 'Attendance successfully updated.');
        refreshFetchFunctions([['singleClass']]);
      },
      onError: (err) => {
        openNotification(null, 'error', returnFirstErrorMsg(err));
      }
    }
  );

  const actionButtons = (attendance) => {
    let content = null;

    if (type === 'active') {
      content = (
        <div className="flex items-center">
          <Checkbox
            id={attendance._id + 'attended'}
            value="attended"
            checked={attendance.status === 'attended'}
            onChange={() =>
              mutateUpdateAttendance.mutate({
                attendanceId: attendance._id,
                status: 'attended'
              })
            }>
            Present
          </Checkbox>
          <Checkbox
            id={attendance._id + 'absent'}
            value="absent"
            checked={attendance.status === 'absent'}
            onChange={() =>
              mutateUpdateAttendance.mutate({
                attendanceId: attendance._id,
                status: 'absent'
              })
            }>
            Absent
          </Checkbox>
          <CancelAttendanceOrClass
            type={'cancelAttendanceXicon'}
            selectedClass={singleClass}
            user={attendance.user}
            attendance={attendance}
            cancelType="singleClass"
          />
        </div>
      );
    }
    if (type === 'pending') {
      content = userData.isAdmin && !isLimitReached && (
        <SelectAndPayForProduct
          selectedUser={attendance.user}
          singleClass={singleClass}
          attendanceId={attendance._id}
        />
      );
    }

    return <div className="flex justify-center">{content}</div>;
  };

  const attendees =
    attendanceList.length === 0 ? (
      <div>{description.noUsers}</div>
    ) : (
      <div>
        {attendanceList.map((elem) => (
          <div key={elem._id} data-cy="singleAttendee" className="listItem flex justify-between">
            <RedirectToUserDetails client={elem.user} showName />
            {actionButtons(elem)}
          </div>
        ))}
      </div>
    );

  return (
    <div className="mt-[30px]">
      <h4>{description.header}</h4>
      {attendees}
    </div>
  );
};

export default RenderAttendees;
