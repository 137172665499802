import { useContext, useState } from 'react';

import { returnErrorFromResponse } from '../../../../shared/utility';
import { uploadVideo, refreshFetchFunctions, editVideo } from '../../../../apiFunctions/apiFunctions';

import { NotificationContext } from '../../../../context/Notification/NotificationContext';

import RenderForm from '../../../../components/Forms/RenderForm';
import { AppContext } from '../../../../context/App/AppContext';
import { useMutation } from '@tanstack/react-query';
import { VideoContext } from '../../../../context/Video/VideoContext';

function AddEditVideo({ selected = null, onCancel = () => {} }) {
  const { openNotification } = useContext(NotificationContext);
  const { trainersList } = useContext(AppContext);
  const { videoCategories, videoPasses } = useContext(VideoContext);
  const isNew = selected === null;

  const { mutate: onAddVideo, isLoading: onAddVideoIsLoading } = useMutation(
    (formData) =>
      isNew
        ? uploadVideo(
            formData,
            videoPasses.filter((product) => formData.products.includes(product._id))
          )
        : editVideo(selected._id, {
            ...formData,
            videoConfig: formData.videoConfig.map(({ name, options }) => ({ name, options }))
          }),
    {
      onSuccess: () => {
        openNotification(null, 'success', isNew ? 'Successfully uploaded!' : 'Successfully updated!');
        refreshFetchFunctions([['fetchVideos'], ['videoPasses']]);
        onCancel();
      },
      onError: (error) => {
        openNotification(null, 'error', returnErrorFromResponse(error));
      }
    }
  );

  let initialValues = isNew ? { products: [] } : selected;

  return (
    <>
      <RenderForm
        formFields={['fileTitle', 'selectProducts', 'trainer', 'uploadFile', 'videoConfig']}
        excludeFormFields={isNew ? [] : ['uploadFile', 'selectProducts']}
        onFinish={onAddVideo}
        btnIsLoading={onAddVideoIsLoading}
        productsList={videoPasses}
        videoCategoriesList={videoCategories}
        onCancel={onCancel}
        cancelBtn={!isNew}
        initialValues={initialValues}
        trainersList={trainersList}
      />
    </>
  );
}

export default AddEditVideo;
