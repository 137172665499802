import { useState } from 'react';
import SearchClients from './SearchClients/SearchClients';
import SearchAdminTrainers from './SearchAdminsTrainers/SearchAdminTrainers';
import Button from '../../../components/UI/Button/Button';
import Title from '../../../components/UI/Title/Title';
const databaseSearches = [
  {
    name: 'Client Search',
    type: 'client-search'
  },
  {
    name: 'Trainer Search',
    type: 'trainer-search'
  },
  {
    name: 'Admin Search',
    type: 'admin-search'
  }
];
const DatabaseSearches = () => {
  const [selected, setSelected] = useState(null);

  const returnProperSearch = () => {
    if (selected !== null) {
      return (
        <>
          {selected === 'client-search' && <SearchClients />}
          {selected === 'trainer-search' && <SearchAdminTrainers role="trainer" />}
          {selected === 'admin-search' && <SearchAdminTrainers role="admin" />}
        </>
      );
    }
    return (
      <div data-cy={'search'}>
        <div>
          {databaseSearches.map((elem, index) => (
            <div className="listItem cursor-pointer hover:font-bold" key={index} onClick={() => setSelected(elem.type)}>
              <span>{elem.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="titleAndBtnContainer">
        <Title title="Search" />
        {selected !== null && <Button icon="previous" label="Back" onClick={() => setSelected(null)} />}
      </div>

      {returnProperSearch()}
    </>
  );
};

export default DatabaseSearches;
