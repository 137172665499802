import Notification from '../../../components/UI/Notification/Notification';
import BulkTable from './BulkTable';
import Loading from '../../../components/Loading/Loading';
import { Modal } from 'antd';

const BulkModal = ({ isLoading, isError, data, isOpen, closeModal, updatedData = null }) => {
  const noBulkData = <div className="text-3xl font-bold my-10 text-center">No classes</div>;
  let content = <Loading size={60} />;

  if (isError) {
    content = <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  if (!isLoading) {
    content =
      data && data.length > 0 ? (
        <BulkTable data={data} closeModal={closeModal} updatedData={updatedData} classType={data[0].__t} />
      ) : (
        noBulkData
      );
  }

  return (
    <Modal open={isOpen} onCancel={closeModal} width={'1200px'}>
      {content}
    </Modal>
  );
};

export default BulkModal;
