import { Switch } from 'antd';

const Toggle = ({ label = null, ...restProps }) => {
  return (
    <div className="flex my-2">
      <Switch {...restProps} />
      <div className="ml-2">{label}</div>
    </div>
  );
};

export default Toggle;
