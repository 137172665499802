import moment from 'moment';
import { ALL_CURRENCIES } from '../constants';
export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const updateObject = (oldObject, updatedProperties) => Object.assign({}, oldObject, updatedProperties);

const defaultErrorMassage = 'Oops, something went wrong';

export const returnFirstErrorMsg = (err) => err.response.data.errors[0].msg;

export const returnErrorFromResponse = (err, isAdmin = false) => {
  const errorMessage = getMessageFromResponse(err, defaultErrorMassage);

  if (errorMessage === defaultErrorMassage) {
    return defaultErrorMassage;
  }

  switch (errorMessage) {
    case 'You already joined this class':
      return isAdmin ? 'The client is already on the attendance list' : errorMessage;
    case 'Sorry, you’ve run out of your class passes.':
      return isAdmin ? 'The client has run out of class passes.' : errorMessage;
    case 'The given data was invalid.':
      return err.response.data.errors[0].message;
    default:
      return errorMessage;
  }
};

const getMessageFromResponse = (response, defaultMessage) => {
  // multiple options due to not standardized responses from backend

  if (response.response && response.response.data) {
    return response.response.data.error && response.response.data.error.message
      ? response.response.data.error.message
      : response.response.data.message
        ? response.response.data.message
        : defaultMessage;
  }

  if (response.data) {
    return response.data.message ? response.data.message : defaultMessage;
  }

  if (response.message) {
    return response.message ? response.message : defaultMessage;
  }

  return defaultMessage;
};

export const getCurrencySymbol = (value) =>
  value === undefined ? '£' : ALL_CURRENCIES.find((currency) => currency.value === value).symbol;
export const getCurrencyDetail = (value) =>
  value === undefined || value === null
    ? {
        label: 'Pound sterling',
        value: 'gbp',
        symbol: '£'
      }
    : ALL_CURRENCIES.find((currency) => currency.value === value);

export const getIntegerSign = (number) => {
  const sign = Math.sign(number);

  if (sign > 0) {
    return '+';
  }
  if (sign < 0) {
    return '-';
  }

  return '';
};

export const returnCurrencyAndCostString = (currency, value) => getCurrencySymbol(currency) + ' ' + value;

export const proRatedCost = (membershipPlan) => {
  const startOfThisMonth = moment().startOf('month');
  const startOfNextMonth = moment().add(1, 'M').startOf('month');
  const costPerMilliseconds = membershipPlan.price / startOfNextMonth.diff(startOfThisMonth, 'milliseconds');
  return `${getCurrencySymbol(membershipPlan.currency)}${(
    costPerMilliseconds * startOfNextMonth.diff(moment(), 'milliseconds')
  ).toFixed(2)}`;
};

export const firstLetterToUpperCase = (word) => word.charAt(0).toUpperCase() + word.slice(1);
